import React from "react";
import { CustomPage } from "components";

const PromissoryNote = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/703"
            searchForm="https://nca.margytech.in/api/form/706"
            url="/nursing/promissorynote/"
            baseSearchParam=""
            tableName="promissorynote"
            tableTitle="Promissory Note"
            createFormButtonText="Add New Promissory Note"
            formTitle="Promissory Note"
            modalFullscreen={false}
            addPermissions={['his:inpatient:promissorynote:add']}
        />
    )
}

export default PromissoryNote;