import React, { Component } from 'react';
import { Navbar, Container } from 'react-bootstrap';

const Footer = () => {
    return (

        <Navbar className="fixed-bottom" bg="primary" expand="lg" >
            <Container style={{ width: "80%" }} fluid>
                <h5 className="text-white text-center w-100 p-0 m-0" style={{ fontSize: 12 }}>Copyright	&#169; 2022 Margy Tech Pvt. Ltd. All Rights Reserved. | Support: 888-888-8888, support@margytech.com</h5>
            </Container>
        </Navbar>
    );
}

export default Footer;