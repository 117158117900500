import React from "react";
import { CustomPage } from "components";

const ExpenseEntry = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/647"
            searchForm="https://nca.margytech.in/api/form/663"
            url="/account/expenseentry/"
            baseSearchParam=""
            tableName="expenseentry"
            tableTitle="Expense"
            createFormButtonText="Add New Expense"
            formTitle="Expense Entry"
            modalFullscreen={true}
            addPermissions={['lis:account:expenseentry:add']}
        />
    )
}

export default ExpenseEntry;