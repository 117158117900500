import React, { useState, useEffect } from "react";
import { CustomPageNew, FullPageForm, FullPageTable } from "components";
import { Tabs, Tab, Row, Col, Container } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const OPDPatientInfo = () => {
    const { opdadmissionId } = useParams();
    const [opdAdmission, setOpdAdmission] = useState(null);
    const [preData, setPreData] = useState(null);

    const [primaryComplaint, setPrimaryComplaint] = useState(null);
    const [vital, setVital] = useState(null);
    const [visit, setVisit] = useState(null);

    const [complaintFlag, setComplaintFlag] = useState(false);
    const [vitalFlag, setVitalFlag] = useState(false);
    const [visitFlag, setVisitFlag] = useState(false);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchOpdAdmission = async () => {
        const response = await actions.customAction("/frontdesk/opdadmission/" + opdadmissionId + "/", "", "Patient", "load", null);
        if (response) {
            setOpdAdmission(response);
            setPreData({ opd: response.id, uhid: response.uhid });
        }
    }

    const fetchPrimaryComplaints = async () => {
        const response = await actions.customAction("/doctor/primarycomplaint/", "?opd__id=" + opdadmissionId, "Primary ", "load", null);
        if (response) {
            if (response[0]?.id) {
                setComplaintFlag(true);
                setPrimaryComplaint({
                    id: response[0].id,
                    opd: opdadmissionId,
                    primary_complaint: response[0].primary_complaint,
                    initial_observation: response[0].initial_observation,
                    symptoms: response[0].symptoms,
                });
            } else {
                setPrimaryComplaint({ opd: opdadmissionId });
            }
        }
    }

    const fetchVitals = async () => {
        const response = await actions.customAction("/doctor/vital/", "?opd__id=" + opdadmissionId, "Vitals ", "load", null);
        if (response) {
            if (response[0]?.id) {
                setVitalFlag(true);
                setVital({
                    id: response[0].id,
                    opd: opdadmissionId,
                    height: response[0].height,
                    weight: response[0].weight,
                    bmi: response[0].bmi,
                    pulse: response[0].pulse,
                    bp_systolic: response[0].bp_systolic,
                    bp_diastolic: response[0].bp_diastolic,
                    spo2: response[0].spo2,
                    temperature: response[0].temperature,
                });
            } else {
                setVital({ opd: opdadmissionId });
            }
        }
    }

    const fetchVisit = async () => {
        const response = await actions.customAction("/doctor/nextvisit/", "?opd__id=" + opdadmissionId, "Visit ", "load", null);
        if (response) {
            if (response[0]?.id) {
                setVisitFlag(true);
                setVisit({
                    id: response[0].id,
                    opd: opdadmissionId,
                    next_visit_date_time: response[0].next_visit_date_time,
                });
            } else {
                setVisit({ opd: opdadmissionId });
            }
        }
    }

    useEffect(() => {
        if (opdadmissionId) {
            fetchOpdAdmission();
        }
    }, [opdadmissionId]);

    useEffect(() => {
        if (opdadmissionId && preData) {
            fetchPrimaryComplaints();
            fetchVitals();
            fetchVisit();
        }
    }, [opdadmissionId, preData]);

    console.log('opdAdmission', opdAdmission);

    return (
        <>
            <div className="m-5 d-flex justify-content-between">
                <div className="d-flex flex-column">
                    <b>Visit ID:</b>
                    <h5>{opdAdmission?.visit_id}</h5>
                </div>
                <div className="d-flex flex-column">
                    <b>UHID:</b>
                    <h5>{opdAdmission?.uhid}</h5>
                </div>
                <div className="d-flex flex-column">
                    <b>Patient Name:</b>
                    <h5>{opdAdmission?.patient_name}/{opdAdmission?.patient_data?.age} {opdAdmission?.patient_data?.age_type}/{opdAdmission?.patient_data?.gender}</h5>
                </div>
                <div className="d-flex flex-column">
                    <b>Cabin:</b>
                    <h5>{opdAdmission?.cabin_data?.cabin_code} {opdAdmission?.cabin_data?.name}</h5>
                </div>
                <div className="d-flex flex-column">
                    <b>Speciality:</b>
                    <h5>{opdAdmission?.specialty_data?.type_description}</h5>
                </div>
                <div className="d-flex flex-column">
                    <b>Doctor:</b>
                    <h5>{opdAdmission?.consulting_doctor_data?.name}</h5>
                </div>
            </div>
            <Tabs className="mx-5">
                <Tab eventKey="primarycomplaint" title="Primary Complaints">
                    <div className="mt-3 px-5 overflow-hidden">
                        <p><strong>Patient History: </strong>{opdAdmission?.patient_history}</p>
                        <p><strong>Patient Primary Complaint / Observation / Remarks: </strong>{opdAdmission?.remarks}</p>
                        <p><strong>Patient Allergies: </strong>{opdAdmission?.allergies}</p>
                    </div>
                    {preData && primaryComplaint && <FullPageForm
                        submission={primaryComplaint}
                        form="https://nca.margytech.in/api/form/640"
                        url="/doctor/primarycomplaint/"
                        tableTitle="Primary Complaints"
                        navigateTo={`/${param1}/outpatient/opdpatientinfo/${preData.opd}`}
                        patch={complaintFlag}
                    />}
                </Tab>
                <Tab eventKey="vital" title="Vitals">
                    {/* {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/641"
                        searchForm=""
                        url="/doctor/vital/"
                        baseSearchParam={`?opd__id=${preData.opd}`}
                        tableName="opdvital"
                        tableTitle="Vital"
                        formTitle="Vital"
                        modalFullscreen={false}
                        preData={preData}
                    />} */}
                    {preData && vital && <FullPageForm
                        submission={vital}
                        form="https://nca.margytech.in/api/form/641"
                        url="/doctor/vital/"
                        tableTitle="Vital"
                        navigateTo={`/${param1}/outpatient/opdpatientinfo/${preData.opd}`}
                        patch={vitalFlag}
                    />}
                </Tab>
                <Tab eventKey="advice" title="Investigation Advice">
                    <Row>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/618"
                                searchForm=""
                                url="/doctor/labadvice/"
                                // baseSearchParam={`?opd=${preData.opd}&input_type=DOCTOR`}
                                baseSearchParam={`?opd=${preData.opd}`}
                                tableName="opdlabadvice"
                                tableTitle="Lab Advice"
                                formTitle="Lab Advice"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/646"
                                searchForm=""
                                url="/frontdesk/opdadmission/opdstudy/"
                                // baseSearchParam={`?opd__id=${preData.opd}&input_type__icontains=DOCTOR`}
                                baseSearchParam={`?opd__id=${preData.opd}`}
                                tableName="opdradiologyadvice"
                                tableTitle="Radiology Advice"
                                formTitle="Radiology Advice"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="service" title="Services">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/642"
                        searchForm=""
                        url="/frontdesk/opdadmission/opdservice/"
                        // baseSearchParam={`?opd__id=${preData.opd}&input_type__icontains=DOCTOR`}
                        baseSearchParam={`?opd__id=${preData.opd}`}
                        tableName="opdpatientservice"
                        tableTitle="Service"
                        formTitle="Service"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="medicine" title="Medicine Prescription">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/643"
                        searchForm=""
                        url="/doctor/medicine/"
                        baseSearchParam={`?opd__id=${preData.opd}`}
                        tableName="opdmedicine"
                        tableTitle="Medicine"
                        formTitle="Medicine"
                        modalFullscreen={false}
                        preData={preData}
                        fileDownload={true}
                    />}
                </Tab>
                <Tab eventKey="diet" title="Diet Plan">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/644"
                        searchForm=""
                        url="/doctor/patientdiet/"
                        baseSearchParam={`?opd__id=${preData.opd}`}
                        tableName="opdpatientdiet"
                        tableTitle="Patient Diet"
                        formTitle="Patient Diet"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="otschedule" title="OT Schedule">
                    {preData && <FullPageTable
                        searchForm=""
                        url="/operationtheatre/otschedule/"
                        tableName="opdotschedule"
                        tableTitle="OT Approval"
                        baseSearchParam={`?opd__id=${preData.opd}&ot_schedule_status=APPROVED`}
                    />}
                </Tab>
                <Tab eventKey="nextvisit" title="Next Visit">
                    {/* {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/645"
                        searchForm=""
                        url="/doctor/nextvisit/"
                        baseSearchParam={`?opd__id=${preData.opd}`}
                        tableName="opdnextvisit"
                        tableTitle="Next Visit"
                        formTitle="Next Visit"
                        modalFullscreen={false}
                        preData={preData}
                    />} */}
                    {preData && visit && <FullPageForm
                        submission={visit}
                        form="https://nca.margytech.in/api/form/645"
                        url="/doctor/nextvisit/"
                        tableTitle="Next Visit"
                        navigateTo={`/${param1}/outpatient/opdpatientinfo/${preData.opd}`}
                        patch={visitFlag}
                    />}
                </Tab>
            </Tabs>
        </>
    )
}

export default OPDPatientInfo;
