import React, { useEffect } from "react";
import { FullPageTable } from "components";

const ResultEntry = () => {

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/657"
            url="/samplemanagement/sampletestprofile/resultentry/"
            tableName="resultentry"
            tableTitle="Result"
            formTitle="Result Entry"
            modalFullscreen={false}
            baseSearchParam=""
            // baseSearchParam={`?status=RESULT&lab_status=INLAB`}
            searchURL=""
            setForm=""
            testId={true}
        />
    )
}

export default ResultEntry;
