import React from "react";
import { CustomPage } from "components";

const EmployeeMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/393"
            searchForm="https://nca.margytech.in/api/form/554"
            url="/master/employee/"
            baseSearchParam=""
            tableName="employee"
            tableTitle="Employee"
            createFormButtonText="Add Employee"
            formTitle="Employee Master"
            modalFullscreen={true}
            addPermissions={['his:master:employee:add']}
        />
    )
}

export default EmployeeMaster;