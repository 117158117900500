import React from "react";
import { CustomPage } from "components";

const PatientDischarge = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/710"
            searchForm="https://nca.margytech.in/api/form/718"
            url="/emergency/patientdischarge/"
            baseSearchParam=""
            tableName="edpatientdischarge"
            tableTitle="Patient Discharge"
            createFormButtonText="Add New Discharge"
            formTitle="Patient Discharge"
            modalFullscreen={false}
            addPermissions={['his:edpatient:patientdischarge:add']}
        />
    )
}

export default PatientDischarge;