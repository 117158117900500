import axios from "axios";

const dropdownUrl = (form, currentItem=null, location=null) => {
    if (currentItem){
        return JSON.parse(JSON.stringify(form).replaceAll("##BASE_URL##", axios.defaults.baseURL).replaceAll("##PARAMS##", `test=${currentItem.test}`))
    } else {
        return JSON.parse(JSON.stringify(form).replaceAll("##BASE_URL##", axios.defaults.baseURL).replaceAll("##LOCATION##", `location=${location}`))
    }
}

export default dropdownUrl;