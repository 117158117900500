import React, { useState, useRef, useEffect } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { useParams, Outlet, Link, useNavigate } from 'react-router-dom';
import * as actions from "actions/PublicAuthActions";
import { useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';

const TenantMain = () => {
    const [tenants, setTenants] = useState([]);
    const { tenantId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchAllTenants = async () => {
        const response = await actions.loadTenants();
        if (response) {
            dispatch(actions.tenantsLoad(response));
            setTenants(response);
        }
    }

    useEffect(() => {
        fetchAllTenants();
    }, [])

    if (tenantId) {
        return (
            <Outlet />
        );
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Client Code',
            selector: row => row.schema_name,
            sortable: true,
        },
        {
            cell: (row) => <Button onClick={() => navigate(row?.id)}>View</Button>,
            button: true,
            allowOverflow: true,
        },
    ]

    return (
        <div className="pt-5">
            <Container fluid className="mt-5">
                <h3>Clients:</h3>
                {/* {tenants?.map((tenant) => {
                    return (
                        <Card
                            className="shadow mt-5 p-5"
                            style={{ width: 200 }}
                            onClick={() => navigate(tenant?.id)}
                        >
                            {tenant?.schema_name}
                        </Card>
                    )
                })} */}
                <DataTable
                    columns={columns}
                    data={tenants}
                    dense
                    responsive
                    pagination
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    persistTableHead
                />
            </Container>
        </div>
    );
}

export default TenantMain;