import React from "react";
import { FullPageTable } from "components";

const PharmacyPORaise = (params) => {
  return (
    <FullPageTable
      searchForm="https://nca.margytech.in/api/form/691"
      url="/purchaseorder/purchaseorder/"
      tableName="pharmacyporaise"
      tableTitle="po raise"
      modalFullscreen={true}
      baseSearchParam="?status=HO_ACCOUNTS_APPROVAL&department__department_code=PHARMACY"
    />
  );
};

export default PharmacyPORaise;
