import React, { useState, useRef, useEffect } from "react";
import { Container, Button, Modal, Row, Col } from "react-bootstrap";
import { Form } from "react-formio";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as actions from "actions/CommonActions";
import { CustomTable, AccessControlHidden } from "components";
import axios from "axios";
import { AiOutlineClose, AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { dropdownUrl } from "utilities";

const CustomPage = (params) => {
    const { form, searchForm, url, baseSearchParam, tableName, tableTitle, createFormButtonText, formTitle, modalFullscreen, addPermissions = [], setGoToNextPage } = params;
    const [modal, setModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [itemForm, setItemForm] = useState(null);
    const [filterForm, setFilterForm] = useState(null);
    const [tagTypeForm, setTagTypeForm] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [filterParams, setFilterParams] = useState("");
    const [submissionData, setSubmissionData] = useState("");
    const [fold, setFold] = useState(true);
    const modalRef = useRef();
    const userPermissions = useSelector((state) => state.publicAuthReducer.permissions)

    // Refs
    const customTableRef = useRef()
    const filterFormRef = useRef()

    const user = useSelector((state) => state.authReducer.user);
    const client = useSelector((state) => state.authReducer.client);
    const currentLocation = useSelector((state) => state.adminReducer.currentLocation)


    useEffect(() => {
        if (filterFormRef) {
            console.log(filterFormRef)
        }
    }, [filterFormRef])

    useEffect(() => {
        if (modal) {
            if (document.getElementsByClassName('modal')[0]) {
                document
                    .getElementsByClassName('modal')[0]
                    .removeAttribute("tabindex");
            }
        }
    }, [modal])

    const fetchForm = async () => {
        setItemForm(await axios.get(form));
    }

    const fetchFilterForm = async () => {
        setFilterForm(await axios.get(searchForm));
    }

    const fetchMetaTagTypeForm = async () => {
        setTagTypeForm(await axios.get("https://nca.margytech.in/api/form/528"))
    }

    useEffect(() => {
        fetchForm();
        fetchFilterForm();
        fetchMetaTagTypeForm();
    }, []);

    const createItem = async (e) => {
        const response = await actions.customAction(url, "", tableTitle, "create", e.data);
        if (response) {
            const keys = Object.keys(response);
            setModal(false);
            customTableRef.current.updateTable();
            keys.map((key) => {
                if (key == 'navigateTo') {
                    setGoToNextPage(response.navigateTo);
                }
            })
        }
    }

    const updateItem = async (e) => {
        const response = await actions.customAction(url + currentItem.id + "/", "", tableTitle, "update", e.data);
        if (response) {
            const keys = Object.keys(response);
            setModal(false);
            customTableRef.current.updateTable();
            keys.map((key) => {
                if (key == 'navigateTo') {
                    setGoToNextPage(response.navigateTo);
                }
            })
        }
    }

    const filterItems = (e) => {
        const keys = Object.keys(e.data);
        let x = "";
        if (baseSearchParam === "") {
            x = "?";
        }
        else {
            x = "&";
        }
        keys.map((key) => {
            if (e.data[key] && key !== 'submit') {
                x = x + key + "=" + e.data[key] + "&";
            }
        })
        setFilterParams(x);
    }

    console.log(itemForm);
    console.log(filterParams);
    console.log(currentItem);

    return (
        <Container fluid className="mt-2 overflow-hidden">
            <AccessControlHidden
                userPermissions={userPermissions}
                allowedPermissions={addPermissions}
            >
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={() => {
                            fetchForm();
                            setCurrentItem(null);
                            setModal(true);
                        }}
                    >{createFormButtonText}</Button>
                </div>
            </AccessControlHidden>
            <Row>
                {searchForm &&
                    <Col sm={3} className={`${fold ? "d-none" : "d-flex"}`}>
                        {filterForm?.data &&
                            <Form
                                ref={filterFormRef}
                                form={dropdownUrl(filterForm?.data)}
                                onSubmit={(e) => { setSubmissionData(e); filterItems(e); }}
                                submission={submissionData}
                            // onChange={(e) => console.log("FilterData:", e)}
                            />
                        }
                    </Col>
                }
                <Col sm={(searchForm && !fold) ? 9 : 12}>
                    {!fold && <div className=""><Button onClick={() => setFold(true)}><AiOutlineMenuFold size={30} /></Button></div>}
                    {fold && <div className=""><Button onClick={() => setFold(false)}><AiOutlineMenuUnfold size={30} /></Button></div>}
                    <CustomTable
                        ref={customTableRef}
                        tableName={tableName}
                        tableTitle={tableTitle}
                        url={url}
                        baseSearchParam={baseSearchParam}
                        searchURL={filterParams}
                        setForm={setItemForm}
                        setModal={setModal}
                        setUpdateData={setCurrentItem}
                    />
                </Col>
            </Row>
            <Modal
                ref={modalRef}
                show={modal}
                onHide={() => setModal(false)}
                centered
                onExited={() => {
                    setModal(false);
                }}
                size="lg"
                fullscreen={modalFullscreen}
            // style={{zIndex: 10000}}
            >
                <Modal.Header className="d-flex flex-row justify-content-center">
                    <h2>{formTitle}</h2>
                </Modal.Header>
                <div className="removeButtonRight position-absolute cursor-pointer">
                    <AiOutlineClose size={20} onClick={() => setModal(false)} />
                </div>
                <Modal.Body>
                    {itemForm?.data &&
                        <Form
                            submission={{ 'data': currentItem }}
                            form={dropdownUrl(itemForm?.data)}
                            onSubmit={(e) => {
                                if (currentItem) {
                                    updateItem(e);
                                } else {
                                    createItem(e);
                                }
                            }}
                            onChange={(e) => console.log("FormData:", e)}
                        // options={{readOnly: true}}
                        // onCustomEvent={(e) => {
                        //     console.log(e.type);
                        //     setAddModal(true);
                        //     if (e.type = "addCategory") {
                        //         console.log(e);
                        //     } else if (e.type = "addItemType") {
                        //         console.log(e);
                        //     } else if (e.type = "addItemDepartment") {
                        //         // setMetaTag("ITEM_DEPARTMENT")
                        //     }
                        // }}
                        />
                    }
                </Modal.Body>
            </Modal>
            <Modal
                show={addModal}
                onHide={() => setAddModal(false)}
                centered
                size="lg"
                onExited={() => {
                    setAddModal(false);
                }}
            >
                <Modal.Header className="d-flex flex-row justify-content-center">
                    <h2>Add Type</h2>
                </Modal.Header>
                <div className="removeButtonRight position-absolute cursor-pointer">
                    <AiOutlineClose size={20} onClick={() => setAddModal(false)} />
                </div>
                <Modal.Body>
                    {tagTypeForm?.data &&
                        <Form
                            form={dropdownUrl(tagTypeForm?.data)}
                        />
                    }
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default CustomPage;