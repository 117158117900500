import React from "react";
import { CustomPage } from "components";

const ConsultingServiceMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/128"
            searchForm="https://nca.margytech.in/api/form/564"
            url="/master/service/"
            baseSearchParam=""
            tableName="service"
            tableTitle="Service"
            createFormButtonText="Add New Service"
            formTitle="Consulting Service Master"
            modalFullscreen={false}
            addPermissions={['his:master:service:add']}
        />
    )
}

export default ConsultingServiceMaster;