import React from "react";
import { FullPageTable } from "components";

const InventoryWorkOrderRaise = (params) => {

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/701"
            url = "/workorder/workorder/"
            tableName="inventoryworkorderraise"
            tableTitle="workorderraise"
            formTitle="Approve Work Order"
            modalFullscreen={true}
            baseSearchParam="?status=HO_ACCOUNTS_APPROVAL"
        />
    )
}

export default InventoryWorkOrderRaise;