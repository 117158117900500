import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FullPageForm } from "components";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const RegistrationPayment = () => {
    const { opdId } = useParams();
    const [opdInfo, setOpdInfo] = useState(null);
    const [submission, setSubmission] = useState(null);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchPatient = async () => {
        const response = await actions.customAction("/frontdesk/opdadmission/" + opdId + "/", "", "Registration Info", "load", null);
        if (response) {
            setOpdInfo(response);
            setSubmission({
                opd: response.id,
                grand_total: response.grand_total,
                balance_amount: response.balance_amount,
                previous_transactions: response.previous_transactions,
            });
        }
    }

    useEffect(() => {
        if (opdId) {
            fetchPatient();
        }
    }, [opdId]);

    console.log(opdInfo);

    return (
        <Container>
            <div className="mx-1 d-flex justify-content-between">
                <span>Patient Name: <b>{opdInfo?.patient_name}</b></span>
                <span>Referred By: <b>{opdInfo?.referred_doctor_data?.name}</b></span>
                <span>Registered On: <b>{opdInfo?.created_on}</b></span>
            </div>
            {submission && <FullPageForm
                submission={submission}
                form="https://nca.margytech.in/api/form/202"
                url="/frontdesk/opdadmission/opdtransaction/"
                tableTitle="Payment"
                navigateTo={`/${param1}/frontdesk/summary`}
                patch={false}
            />}
        </Container>
    )
}

export default RegistrationPayment;