import React, { useState, useRef, useEffect } from "react";
import { Container, Button, Row, Col, Modal } from "react-bootstrap";
import { Form } from "react-formio";
import axios from "axios";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";
import { CustomTable } from "components";
import { AiOutlineClose } from "react-icons/ai";
import { dropdownUrl } from "utilities";

const MetaDataTagType = () => {
    const { metaTagId } = useParams();
    const [tagTypeForm, setTagTypeForm] = useState(null);
    const [metaTag, setMetaTag] = useState(null)
    const [filterForm, setFilterForm] = useState(null);
    const [filterParams, setFilterParams] = useState("");
    const [submissionData, setSubmissionData] = useState("");
    const customTableRef = useRef()

    console.log(useParams())

    const fetchTagTypeForm = async () => {
        setTagTypeForm(await axios.get("https://nca.margytech.in/api/form/528"));
    }

    const fetchFilterForm = async () => {
        setFilterForm(await axios.get("https://nca.margytech.in/api/form/673"));
    }

    useEffect(() => {
        fetchTagTypeForm();
        fetchFilterForm();
    }, []);

    const fetchMetaTag = async (metaTagId) => {
        const response = await actions.customAction("/metadata/metatag/" + metaTagId + "/", "", "Meta Data Tag", "load", null);
        if (response) {
            setMetaTag(response);
        }
    }

    useEffect(() => {
        if (metaTagId) {
            fetchMetaTag(metaTagId);
        }
    }, [metaTagId]);

    const createMetaTagType = async (e) => {
        console.log(e.data);
        const data = Object.assign(e.data, { metatag: metaTagId });
        console.log(data);
        const response = await actions.customAction("/metadata/metatagtype/", "", "Meta Data Tag Type", "create", data);
        customTableRef.current.updateTable();
    }

    const filterItems = (e) => {
        const keys = Object.keys(e.data);
        let x = "?";
        keys.map((key) => {
            if (e.data[key] && key !== 'submit') {
                x = x + key + "=" + e.data[key] + "&";
            }
        })
        setFilterParams(x);
    }

    return (
        <Container fluid className="mt-5">
            <h3 className="d-flex justify-content-center">{metaTag?.tag_name}</h3>
            <Container className="d-flex justify-content-center my-2 pt-2 border">
                <Form form={tagTypeForm?.data} onSubmit={(e) => createMetaTagType(e)} />
            </Container>
            <Row>
                <Col sm={3}>
                    {filterForm?.data &&
                        <Form
                            form={dropdownUrl(filterForm?.data)}
                            onSubmit={(e) => { setSubmissionData(e); filterItems(e); }}
                            submission={submissionData}
                        />
                    }
                </Col>
                <Col sm={9}>
                    {metaTag && <CustomTable
                        ref={customTableRef}
                        tableName="metatagtype"
                        tableTitle="Meta Data Tag Type"
                        searchURL={filterParams}
                        url="/metadata/metatagtype/"
                        baseSearchParam={`?metatag__tag_code=` + metaTag?.tag_code}
                    />}
                </Col>
            </Row>
        </Container>
    );
}

export default MetaDataTagType;