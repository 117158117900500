import React from "react";
import { FullPageTable } from "components";

const OPDConsultation = (params) => {

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/66"
            url="/frontdesk/opdadmission/"
            tableName="opdconsultation"
            tableTitle="Patient Info"
            formTitle=""
            modalFullscreen={true}
            baseSearchParam="?admission_type=OPD_ADMISSION"
        />
    )
}

export default OPDConsultation;