import React from "react";
import { FullPageTable } from "components";

const DoctorNursingConsole = (params) => {

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/115"
            url="/frontdesk/ipdadmission/"
            tableName="doctornursingconsole"
            tableTitle="Nursing Console"
            formTitle=""
            modalFullscreen={true}
            baseSearchParam="?status=CONFIRMED"
        />
    )
}

export default DoctorNursingConsole;