import React from "react";
import { FullPageTable } from "components";

const InventoryPurchaseOrderHoAccounts = (params) => {
  return (
    <FullPageTable
    form="https://nca.margytech.in/api/form/436"
      searchForm="https://nca.margytech.in/api/form/736"
      url="/purchaseorder/purchaseorder/"
      tableName="inventorypohoaccounts"
      tableTitle="HO accounts approval"
      formTitle="Approve Purchase Order"
      modalFullscreen={true}
      baseSearchParam="?status=HO_MANAGER_APPROVAL&department__department_code=INVENTORY"
    />
  );
};

export default InventoryPurchaseOrderHoAccounts;
