import React, { useEffect, useState } from "react";
import { FullPageForm } from "components";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const EDBillingInfo = () => {
    const { edId } = useParams();
    const [edInfo, setEdInfo] = useState(null);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchEdInfo = async () => {
        const response = await actions.customAction("/frontdesk/edadmission/" + edId + "/", "", "ED Admission", "load", null);
        if (response) {
            // const transactions = await actions.customAction("/frontdesk/ipdreceipt/", "?ipd__id="+ edId, "IPD Transactions", "loadMany", null);
            // if (transactions) {
            //     let info = {...response, previous_transactions: transactions}
            //     setIpdInfo(info)
            // } else {
            //     setIpdInfo(response);
            // }
            setEdInfo(response);
        }
    }

    useEffect(() => {
        if (edId) {
            fetchEdInfo();
        }
    }, [edId]);

    console.log(edInfo);

    return (
        <>
            {edInfo && <FullPageForm
                submission={edInfo}
                form="https://nca.margytech.in/api/form/711"
                url="/frontdesk/edreceipt/"
                tableTitle="ED Billing"
                navigateTo={`/${param1}/billing/edbilling`}
                patch={false}
            />}
        </>
    )
}

export default EDBillingInfo;