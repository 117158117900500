import React from "react";
import { FullPageForm } from "components";

const PharmacyDeptBill = (params) => {

    return (
        <FullPageForm
            form="https://nca.margytech.in/api/form/422"
            patch={false}
        />
    )
}

export default PharmacyDeptBill;