// import logo from './logo.svg';
import './App.css';
import './App.scss';
import { Container } from "react-bootstrap";
import Routers from "./RoutersNew";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "./store";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "react-toastify/dist/ReactToastify.css";

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// persistor.purge();

const App = () => {
  return (
    <Container className="p-0" fluid>
      <Provider store={store}>
        <Routers />
      </Provider>
    </Container>
  );
};

export default App;

// import logo from './logo.svg';
// import './App.css';
// import './App.scss';
// import React from 'react';
// import { CKEditor } from 'ckeditor4-react';
// import { Container } from 'react-bootstrap';
// // import { plugins } from './pastefromgdocs';

// function App() {

//   const editorConfig = {
//     // extraPlugins: ['imagebrowser'],
//     // filebrowserBrowseUrl: 'http://localhost:4000/media/b24e5120-2919-4ad9-9619-89843194fa0c/template/home-advt_jzx7KBU.jpeg',
//     filebrowserImageUploadUrl: 'http://localhost:4000/template/upload_template_image/',
//     contentsCss: [
//       // 'http://cdn.ckeditor.com/4.19.0/full-all/contents.css',
//       // 'https://ckeditor.com/docs/ckeditor4/4.19.0/examples/https://ckeditor.com/docs/ckeditor4/4.19.0/examples/assets/css/pastefromgdocs.css',
//       "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css"

//     ],
//     // plugins: 'wysiwygarea,toolbar,basicstyles,format,stylescombo,find,link,image',
//     // toolbar: [{
//     //   name: 'find',
//     //   items: ['Find', 'Replace']
//     // }]
//   }

//   return (
//     <Container className="d-flex flex-column justify-content-center align-items-center">
//       <h2>Using CKEditor 4 in React</h2>
//       <CKEditor
//         initData={<p>Hello from CKEditor 4!</p>}
//         config={editorConfig}
//         // editorUrl="https://cdn.ckeditor.com/4.19.0/full-all/ckeditor.js"
//         editorUrl="http://localhost:4000/media/ckeditor/4.19.0/full-all/ckeditor.js"
//         onInstanceReady={() => {
//           // alert('Editor is ready!');
//         }}

//       />

//     </Container>
//   );
// }


// export default App;