import React, { useRef, useState } from "react";
import { CustomPage } from "components";
import * as actions from "actions/CommonActions";
import CustomListDropdownStockReturn from "./Utils/CustomListDropdownStockReturn";
import { Button, Col, Container, Form, Row, Form as BootstrapForm } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const PharmacyReturnToVendor = () => {
  const [returnStocks, setReturnStocks] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const form = useRef(null);
  const navigate = useNavigate();

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        padding: "15px",
        border: "1px solid #eee",
        color: "#fff",
        borderBottom: "1px solid #999",
        backgroundColor: "#006699",
      },
    },
    cells: {
      style: {
        borderLeft: "1px solid #eee",
        borderRight: "1px solid #eee",
        minHeight: "50px",
      },
    },
  };

  const columns = [
    {
        name: "Item Code",
        selector: row => row.item_data?.code
    },
    {
        name: "Item Name",
        selector: row => row.item_data?.name
    },
    {
        name: "Batch No.",
        selector: row => row.batch
    },
    {
        name: "Expiry",
        selector: row => moment(row.expiry_date).format("YYYY-MM-DD")
    },
    {
        name: "Batch Quantity",
        selector: row => row.quantity
    },
    {
        name: "Return Quantity",
        selector: row => row.selected_quantity,
    },
    {
        name: "Action",
        selector: null,
        cell: (row, index) => <Button onClick={() => {
            const newData = returnStocks.filter((item, indexElem) => index != indexElem)
            setReturnStocks(newData)
        }}>Delete</Button>,
        button: true,
    },
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(form.current);
    let object = {};
    formData.forEach((value, key) => object[key] = value);
    object["return_items"] = returnStocks;
    const response = await actions.customAction("/purchaseorder/vendorreturn/", "", "Vendor Return", "create", object)
    if (response) {
        navigate(`/pharmacy/medicalstock/returntovendor/`);
    }
  }

  return (
    <>
      <Form ref={form} onSubmit={(e) => handleSubmit(e)}>
        <Container className="card-form">
          <h1>Return To Vendor</h1>
          <Row className="mb-3 mt-4">
            <Col sm="4">
              <Form.Group>
                <Form.Label>Select Item</Form.Label>
                <CustomListDropdownStockReturn returnStocks={returnStocks} setReturnStocks={setReturnStocks} isOpen={isOpen} setIsOpen={setIsOpen} />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <Form.Label>Placed On</Form.Label>
                <Form.Control name="placed_on" type="date" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Form.Group>
                <Form.Label>Remarks</Form.Label>
                <Form.Control name="remarks" as="textarea" rows={3} />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5">
          <DataTable data={returnStocks} columns={columns} customStyles={customStyles} />
        </Container>
        <div className="d-flex justify-content-center align-items-center mt-5">
        <Button type="submit">Return to Vendor</Button>
        </div>
      </Form>
    </>
  );
};

export default PharmacyReturnToVendor;
