import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Image } from "react-bootstrap";
import * as actions from "actions/CommonActions";
import { Outlet, useLocation, Link } from 'react-router-dom';

const BarcodeMain = () => {
    // const [qrs, setQrs] = useState(null);
    const [qr, setQr] = useState(null);
    const form = useRef(null);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param2 = params.length > 1 ? params[1] : "";

    // useEffect(async () => {
    //     const response = await actions.loadQr();
    //     setQrs(response);
    // }, [])

    const handleBarcodeGeneration = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        const response = await actions.createBarcode(formData);
        if (response) {
            alert("Successfull!");
            setQr(response)
        }
    };

    if (param2) {
        return (
            <Outlet />
        );
    }

    return (
        <div className="m-5">
            <Form className="d-flex flex-column justify-content-center" ref={form} onSubmit={(e) => handleBarcodeGeneration(e)}>
                <Form.Group className="mt-3 mb-5">
                    <Form.Label>
                        Enter Number of Barcodes to be generated
                    </Form.Label>
                    <Form.Control
                        required
                        name="count"
                        type="number"
                        placeholder="Enter number here"
                    />
                </Form.Group>
                <Button className="w-25" type="submit">
                    Create
                </Button>
            </Form>
        </div>
    )
}

export default BarcodeMain;