import React from "react";
import { Card, Row, Col, Container, Form as BForm } from 'react-bootstrap'

// const ModuleCard = ({ cardTitle, cardContent, cardContent2, cardColor, size, type }) => {
    const ModuleCard = ({ cardTitle, cardContentTitle1, cardContent1, cardContentTitle2, cardContent2, cardContentTitle3, cardContent3,
        cardContentTitle4, cardContent4, size, type, iconBoxColor, icon, stackicon  }) => {
    switch (type) {
        case "2":
            return (
                <Col sm={size} className="my-4 d-flex justify-content-center">
                    <Card className={`shadow dashboard-card`} style={{ width: '100%' }}>
                        <Card.Body>
                            <Row>
                                <Col sm={8}>
                                    <Card.Title className="fw-500">{cardTitle}</Card.Title>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <div className="icon-box d-flex justify-content-center align-items-center" style={{backgroundColor: iconBoxColor}}>
                                        {icon}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="d-flex flex-row justify-content-between pt-4">
                                <Col>
                                    <div style={{ fontSize: 30 }}>{cardContent1}</div>
                                    <div>{cardContentTitle1}</div>
                                </Col>
                                <Col>
                                    <div style={{ fontSize: 30 }}>{cardContent2}</div>
                                    <div>{cardContentTitle2}</div>
                                </Col>
                            </Row>
                            <Card.Text style={{ fontSize: 30 }}>

                            </Card.Text>
                            {/* <Button variant="primary">Go somewhere</Button> */}
                        </Card.Body>
                    </Card>
                </Col>
            )
        case "1":
            return (
                <Col sm={size} className="my-4 d-flex justify-content-center">
                    <Card className={`shadow dashboard-card`} style={{ width: '100%' }}>
                        <Card.Body>
                            <Row>
                                <Col sm={8}>
                                    <Card.Title className="fw-500">{cardTitle}</Card.Title>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <div className="icon-box d-flex justify-content-center align-items-center" style={{backgroundColor: iconBoxColor}}>
                                        {icon}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="d-flex flex-row justify-content-between pt-4">
                                <Col>
                                    <div style={{ fontSize: 30 }}>{cardContent1}</div>
                                    <div>{cardContentTitle1}</div>
                                </Col>
                            </Row>
                            <Card.Text style={{ fontSize: 30 }}>

                            </Card.Text>
                            {/* <Button variant="primary">Go somewhere</Button> */}
                        </Card.Body>
                    </Card>
                </Col>
            )
        case "3":
            return (
                <Col sm={size} className="my-4 d-flex justify-content-center">
                    <Card className={`shadow dashboard-card`} style={{ width: '100%' }}>
                        <Card.Body>
                            <Row>
                                <Col sm={8}>
                                    <Card.Title className="fw-500">{cardTitle}</Card.Title>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <div className="icon-box d-flex justify-content-center align-items-center" style={{backgroundColor: iconBoxColor}}>
                                        {icon}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="d-flex flex-row justify-content-between pt-4">
                                <Col>
                                    <div style={{ fontSize: 30 }}>{cardContent1}</div>
                                    <div>{cardContentTitle1}</div>
                                </Col>
                                <Col>
                                    <div style={{ fontSize: 30, textAlign: 'center' }}>{cardContent2}</div>
                                    <div style={{ textAlign: 'center' }}>{cardContentTitle2}</div>
                                </Col>
                                <Col>
                                    <div style={{ fontSize: 30, textAlign: 'right' }}>{cardContent3}</div>
                                    <div style={{ textAlign: 'right' }}>{cardContentTitle3}</div>
                                </Col>
                            </Row>
                            <Card.Text style={{ fontSize: 30 }}>

                            </Card.Text>
                            {/* <Button variant="primary">Go somewhere</Button> */}
                        </Card.Body>
                    </Card>
                </Col>
            )
        case "4":
                return (
                    <Col sm={size} className="my-4 d-flex justify-content-center">
                        <Card className={`shadow dashboard-card`} style={{ width: '100%' }}>
                            <Card.Body>
                                <Row>
                                    <Col sm={8}>
                                        <Card.Title className="fw-500">{cardTitle}</Card.Title>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <div className="icon-box d-flex justify-content-center align-items-center" style={{backgroundColor: iconBoxColor}}>
                                            {icon}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="d-flex flex-row justify-content-between pt-4">
                                    <Col>
                                        <div style={{ fontSize: 30 }}>{cardContent1}</div>
                                        <div>{cardContentTitle1}</div>
                                    </Col>
                                    <Col>
                                        <div style={{ fontSize: 30, textAlign: 'center' }}>{cardContent2}</div>
                                        <div style={{ textAlign: 'center' }}>{cardContentTitle2}</div>
                                    </Col>
                                    <Col>
                                        <div style={{ fontSize: 30, textAlign: 'center' }}>{cardContent3}</div>
                                        <div style={{ textAlign: 'center' }}>{cardContentTitle3}</div>
                                    </Col>
                                    <Col>
                                        <div style={{ fontSize: 30, textAlign: 'right' }}>{cardContent4}</div>
                                        <div style={{ textAlign: 'right' }}>{cardContentTitle4}</div>
                                    </Col>
                                </Row>
                                <Card.Text style={{ fontSize: 30 }}>
    
                                </Card.Text>
                                {/* <Button variant="primary">Go somewhere</Button> */}
                            </Card.Body>
                        </Card>
                    </Col>
                )
        case "stack":
            return (
                <Col sm={size} className="my-4 d-flex justify-content-center">
                    <Card className={`shadow dashboard-card`} style={{ width: '100%' }}>
                        <Card.Body>
                            <Row>
                                <Col sm={8}>
                                    <Card.Title className="fw-500">{cardTitle}</Card.Title>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <div className="icon-box d-flex justify-content-center align-items-center" style={{backgroundColor: iconBoxColor}}>
                                        {icon} 
                                        <sup className="sup-top">{stackicon}</sup>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="d-flex flex-row justify-content-between pt-4">
                                <Col>
                                    <div style={{ fontSize: 30 }}>{cardContent1}</div>
                                    <div>{cardContentTitle1}</div>
                                </Col>
                            </Row>
                            <Card.Text style={{ fontSize: 30 }}>

                            </Card.Text>
                            {/* <Button variant="primary">Go somewhere</Button> */}
                        </Card.Body>
                    </Card>
                </Col>
            )
        default:
            return (<div>Dashboard</div>)

    }
}

export default ModuleCard