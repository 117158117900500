import React from "react";
import { CustomPage } from "components";

const OutlabMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/249"
            searchForm="https://nca.margytech.in/api/form/546"
            url="/master/outlab/"
            baseSearchParam=""
            tableName="outlab"
            tableTitle="Outlab"
            createFormButtonText="Add Outlab"
            formTitle="Outlab Master"
            modalFullscreen={true}
            addPermissions={['lis:opmaster:outlab:add']}
        />
    )
}

export default OutlabMaster;