import React from "react";
import { CustomPage } from "components";

const CentreMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/448"
            searchForm="https://nca.margytech.in/api/form/536"
            url="/master/center/"
            baseSearchParam=""
            tableName="center"
            tableTitle="Center"
            createFormButtonText="Add Centre"
            formTitle="Centre Master"
            modalFullscreen={true}
            addPermissions={['lis:opmaster:centre:add']}
        />
    )
}

export default CentreMaster;