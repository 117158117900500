// import axios from "axios";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";

const axios = require("axios").default;

export const notify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    default:
      toast.error(msg);
  }
};

////////////////////////// SETTING - Actions Creator //////////////////////////

export const settingLoad = (data) => {
  return {
    type: "SET_SETTING",
    payload: data,
  };
};

////////////////////////// TEMPLATE - Actions //////////////////////////

export const loadTemplate = async (templateId) => {
  try {
    const response = await axios.get("/template/" + templateId + "/");
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("Template could not be fetched!", "warning");
  }
};

export const templateToPDF = async (templateId) => {
  try {
    const response = await axios.post("/template/" + templateId + "/print_pdf/", {}, {
      responseType: "blob",
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      fileDownload(response.data, "template.pdf");
      // return response.data;
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("Template could not be downloaded!", "warning");
  }
};

export const templateToPDFPreview = async (templateId) => {
  try {
    const response = await axios.post("/template/" + templateId + "/print_pdf/", {}, {
      responseType: "blob",
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      // fileDownload(response.data, "template.html");
      return response.data;
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("Template could not be downloaded!", "warning");
  }
};



export const loadTemplates = async () => {
  try {
    const response = await axios.get(`/template/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Templates fetched successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("There was a problem loading Templates!", "error");
  }
};

export const createTemplate = async (data) => {
  try {
    const response = await axios.post(`/template/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 201) {
      notify("Your Template has been saved!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    alert("Template could not be saved!");
  }
};

export const updateTemplate = async (templateId, data) => {
  try {
    const response = await axios.put(`/template/${templateId}/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      notify("Your Template has been updated!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("Template could not be updated!", "error");
  }
};

////////////////////////// TABLES - Actions //////////////////////////

export const loadTableFields = async (tableName) => {
  try {
    const response = await axios.get(`/tablefield/?table__uniquename=` + tableName, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Patient loaded successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("There is a problem loading Table Fields!", "error");
  }
};

////////////////////////// SETTING - Actions //////////////////////////

export const loadSetting = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/setting/setting/`);
      if (response.status) {
        dispatch(settingLoad(response.data));
        // return response.data;
      }
    } catch (err) {
      notify("There is as problem loading settings!", "error")
    }
  }
}

export const createOrUpdateSetting = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/setting/setting/create_or_update/`, data);
      if (response.status == 200) {
        notify("Settings updated successfully!", "success");
        dispatch(settingLoad(response.data));
        // return response.data;
      }
    } catch (err) {
      console.log(err.response)
      notify(err?.response?.data?.msg, "error")
    }
  }
}


export const sendEmail = async () => {
  try {
    const response = await axios.post(`/setting/setting/send_test_mail/`, {});
    if (response.status == 200) {
      notify("Mail sent successfully!", "success");
      return response.data;
    }
  } catch (err) {
    notify("Problem in sending email", "error")
  }
}