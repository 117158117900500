import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button } from "react-bootstrap";
import * as actions from "actions/TenantAuthActions";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ProfileDropdown = ({ profileDropdown }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.authReducer.user.is_admin);

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";

  return (
    <Card
      className={`position-absolute p-2 m-2 d-flex justify-content-center align-items-center ${profileDropdown ? "d-block" : "d-none"
        }`}
      style={{ width: "150px", right: 25 }}
    >
      <Link className="text-black" to="/profile" style={{ textDecoration: 0 }}>
        Profile
      </Link>
      <Link
        to={`${param1 === "admin" ? "/admin" : (param1 === "superadmin" ? "/superadmin" : "/")}`}
        style={{ textDecoration: 0 }}
      >
        <Button
          className="border-0 text-black"
          onClick={() => {
            dispatch(actions.loggingOut());
            dispatch({type: 'CLEAR_LOCATION'})
          }}
          style={{
            background: "transparent",
            textDecoration: "none",
          }}
        >
          Logout
        </Button>
      </Link>
    </Card>
  );
};

export default ProfileDropdown;
