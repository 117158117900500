import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const Master = () => {

    return (
        <Outlet />
    );
}

export default Master;