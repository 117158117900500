import React from "react";
import { CustomPage } from "components";

const AccountReceivable = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/649"
            searchForm="https://nca.margytech.in/api/form/668"
            url="/account/accountreceivable/"
            baseSearchParam=""
            tableName="accountreceivable"
            tableTitle="Transaction"
            createFormButtonText="Add New Transaction"
            formTitle="Transaction Entry"
            modalFullscreen={true}
            addPermissions={['lis:account:accountreceivable:add']}
        />
    )
}

export default AccountReceivable;