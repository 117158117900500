import React, { useEffect, useState } from "react";
import { CustomPage } from "components";
import { useNavigate } from "react-router-dom";

const SearchPatientRis = () => {
    const [goToNextPage, setGoToNextPage] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (goToNextPage){
            navigate(goToNextPage);
        }
    }, [goToNextPage]);

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/728"
            searchForm="https://nca.margytech.in/api/form/531"
            url="/frontdesk/patient/"
            baseSearchParam=""
            tableName="rispatient"
            tableTitle="Patient"
            createFormButtonText="Register Patient"
            formTitle="Patient Registration"
            modalFullscreen={true}
            addPermissions={['ris:frontdesk:patient:add']}
            setGoToNextPage={setGoToNextPage}
        />
    )
}

export default SearchPatientRis;