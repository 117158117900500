import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Modal,
  Row,
  Col,
  Form as BForm,
} from "react-bootstrap";
import { Form } from "react-formio";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as actions from "actions/CommonActions";
import * as adminactions from "actions/AdminActions";
import { CustomTable } from "components";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { dropdownUrl } from "utilities";
import { Document, Page, pdfjs } from "react-pdf";
import { CKEditor } from "ckeditor4-react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "core-ui/components/TemplatePage.scss";

import "/node_modules/codemirror/mode/jinja2/jinja2.js";
import "/node_modules/codemirror/mode/javascript/javascript.js";

const TemplatePage = (params) => {
  const {
    form,
    searchForm,
    url,
    tableName,
    tableTitle,
    createFormButtonText,
    formTitle,
    modalFullscreen,
    baseSearchParam,
    special_types=[]
  } = params;
  const [modal, setModal] = useState(false);
  const [itemForm, setItemForm] = useState(null);
  const [filterForm, setFilterForm] = useState(null);
  const [tagTypeForm, setTagTypeForm] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [filterParams, setFilterParams] = useState("");
  const [pdfPreviewData, setPdfPreviewData] = useState(null);
  const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [templateInfo, setTemplateInfo] = useState(null);
  const [reportingStyles, setReportingStyles] = useState([]);
  const [templateTypes, setTemplateTypes] = useState([]);
  const [isSpecial, setIsSpecial] = useState(false)
  const [specialDepartments, setSpecialDepartments] = useState([])
  const [showCodeMirror, setShowCodemirror] = useState(false);
  const [codeMirrorData, setCodeMirrorData] = useState("tehas");
  const [confirmModal, setConfirmModal] = useState("");
  const [codeMirrorLatestData, setCodeMirrorLatestData] = useState("");
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);

  const [roles1, setRoles1] = useState([]);
  const [roles2, setRoles2] = useState([]);
  const [roles3, setRoles3] = useState([]);

  const [users1, setUsers1] = useState([]);
  const [users2, setUsers2] = useState([]);
  const [users3, setUsers3] = useState([]);

  const [templateType, setTemplateType] = useState(null);
  const [selectedSpecialDepartment, setSelectedSpecialDepartment] = useState(null);
  const [header, setHeader] = useState(true);
  const [footer, setFooter] = useState(true);
  //   Refs
  const templateForm = useRef();
  const margin_top_ref = useRef();
  const margin_bottom_ref = useRef();
  const margin_left_ref = useRef();
  const margin_right_ref = useRef();
  const customTablRef = useRef();
  let instance = null;

  const page_size_ref = useRef();
  const page_orientation_ref = useRef();
  const page_number_format_ref = useRef();
  const page_number_position_ref = useRef();
  const page_border_ref = useRef();

  const user = useSelector((state) => state.authReducer.user);
  const client = useSelector((state) => state.authReducer.client);

  const fetchForm = async () => {
    setItemForm(await axios.get(form));
  };

  const fetchFilterForm = async () => {
    setFilterForm(await axios.get(searchForm));
  };

  const fetchMetaTagTypeForm = async () => {
    setTagTypeForm(await axios.get("https://nca.margytech.in/api/form/528"));
  };

  const fetchTemplateTypes = async () => {
    const response = await actions.fetchMetaDataTags("TEMPLATE_TYPE");
    if (response) {
      if(special_types?.length > 0){
        let new_template_types = [];
        for(let i in response){
          for(let special_type in special_types){
            if(response[i].key.startsWith(special_types[special_type])){
              new_template_types.push(response[i]);
            }
          }
        }
        setTemplateTypes(new_template_types);
      } else {
        setTemplateTypes(response);
      }
    }
  };

  const fetchSpecialReportDepartments = async () => {
    const response = await actions.customAction("/metadata/metatagtype/", "?metatag__tag_code=TEST_DEPARTMENT", "Special Report Departments", "loadMany", null)
    if (response){
      setSpecialDepartments(response)
    }
  }

  const fetchDepartments = async () => {
    const response = await actions.customAction(
      "/user/department/",
      "",
      "Department",
      "loadMany",
      null
    );
    if (response) {
      setDepartments(response);
    }
  };

  const fetchRoles = async () => {
    const response = await actions.customAction(
      "/user/role/",
      "",
      "Role",
      "loadMany",
      null
    );
    if (response) {
      setRoles(response);
    }
  };

  const fetchUsers = async () => {
    const response = await actions.customAction(
      "/user/",
      "",
      "User",
      "loadMany",
      null
    );
    if (response) {
      setUsers(response);
    }
  };

  const fetchReportingStyles = async () => {
    const response = await actions.fetchMetaDataTags("REPORTING_STYLE");
    if (response) {
      setReportingStyles(response);
    }
  };

  useEffect(() => {
    fetchForm();
    fetchFilterForm();
    fetchMetaTagTypeForm();
    fetchTemplateTypes();
    fetchReportingStyles();
    fetchDepartments();
    fetchRoles();
    fetchUsers();
    fetchSpecialReportDepartments();
  }, []);

  useEffect(() => {
    if (currentItem) {
      console.log(currentItem);
      setTemplateInfo(currentItem.html_content);
      setCodeMirrorData(currentItem.content);
      setCodeMirrorLatestData(currentItem.content);
      setTemplateType(currentItem.template_type);
      setSelectedSpecialDepartment(currentItem.department);
      setHeader(currentItem.has_header);
      setFooter(currentItem.has_footer);
    }
  }, [currentItem]);

  useEffect(() => {
    if (showCodeMirror) {
      console.log(instance);
      instance.refresh();
    }
  }, [showCodeMirror]);

  const createItem = async (e) => {
    const response = await actions.customAction(
      url,
      "",
      tableTitle,
      "create",
      e.data
    );
    if (response) {
      setModal(false);
    }
  };

  const updateItem = async (e) => {
    const response = await actions.customAction(
      url + currentItem.id + "/",
      "",
      tableTitle,
      "update",
      e.data
    );
    if (response) {
      setModal(false);
    }
  };

  const filterItems = (e) => {
    const keys = Object.keys(e.data);
    let x = "?";
    keys.map((key) => {
      if (e.data[key] && key !== "submit") {
        x = x + key + "=" + e.data[key] + "&";
      }
    });
    setFilterParams(x);
  };

  const handleIsSpecialCheck = () => {
    setIsSpecial((checked) => !checked)
  }

  console.log(itemForm);
  console.log(filterParams);
  console.log(currentItem);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // const templateTypes = [
  //   { id: 1, value: "QUANTITATIVE", label: "QUANTITATIVE" },
  //   { id: 2, value: "QUALITATIVE", label: "QUALITATIVE" },
  //   { id: 3, value: "DESCRIPTIVE", label: "DESCRIPTIVE" }
  // ]

  // Letter - 8.5in x 11in (21.6cm x 27.9cm)
  // Legal - 8.5in x 14in (21.6cm x 35.6cm)
  // Tabloid - 11in x 17in (27.9cm x 43.2cm)
  // Ledger - 17in x 11in (43.2cm x 27.9cm)
  // A0 - 33.1in x 46.8in (84.1cm x 118.9cm)
  // A1 - 23.4in x 33.1in (59.4cm x 84.1cm)
  // A2 - 16.54in x 23.4in (42.0cm x 59.4cm)
  // A3 - 11.7in x 16.54in (29.7cm x 42.0cm)
  // A4 - 8.27in x 11.7in (21.0cm x 29.7cm)
  // A5 - 5.83in x 8.27in (14.8cm x 21.0cm)
  // A6 - 4.13in x 5.83in (10.5cm x 14.8cm)

  const page_sizes = [
    {
      id: "A3",
      width: 29.7,
      height: 42.0,
    },
    {
      id: "A4",
      width: 21,
      height: 29.7,
    },
    {
      id: "A5",
      width: 14.8,
      height: 21.0,
    },
    {
      id: "A6",
      width: 10.5,
      height: 14.8,
    },
    {
      id: "Letter",
      width: 21.6,
      height: 27.9,
    },
    {
      id: "Barcode",
      width: 4,
      height: 1,
    },
    // {
    //     id: "Legal",
    //     width: 21.6,
    //     height: 35.6
    // }, {
    //     id: "Tabloid",
    //     width: 27.9,
    //     height: 43.2
    // }, {
    //     id: "Ledger",
    //     width: 43.2,
    //     height: 27.9
    // },
    // {
    //     id: "A0",
    //     width: 84.1,
    //     height: 118.9
    // }, {
    //     id: "A1",
    //     width: 59.4,
    //     height: 84.1
    // }, {
    //     id: "A2",
    //     width: 42.0,
    //     height: 59.4
    // }
  ];

  const page_orientation = [
    {
      id: "portrait",
      label: "Portrait",
    },
    {
      id: "landscape",
      label: "Landscape",
    },
  ];

  const page_number_position = [
    {
      id: "top-left",
      label: "Top Left",
    },
    {
      id: "top-center",
      label: "Top Center",
    },
    {
      id: "top-right",
      label: "Top Right",
    },
    {
      id: "bottom-left",
      label: "Bottom Left",
    },
    {
      id: "bottom-center",
      label: "Bottom Center",
    },
    {
      id: "bottom-right",
      label: "Bottom Right",
    },
  ];

  //   useEffect(() => {
  //     fetchTemplates(templates);
  //   }, []);

  //   useEffect(() => {
  //     fetchTemplates(templates);
  //   }, [openCreateModal]);

  const resetPageFormatting = () => {
    margin_top_ref.current.value = 12;
    margin_bottom_ref.current.value = 12;
    margin_left_ref.current.value = 10;
    margin_right_ref.current.value = 10;
    page_size_ref.current.value = "A4";
    page_orientation_ref.current.value = "portrait";
    page_number_format_ref.current.value =
      '"Page " counter(page) " of " counter(pages)';
    page_number_position_ref.current.value = "bottom-center";
    page_border_ref.current.value = "1px solid #ccc";
  };

  const handleTemplateSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(templateForm.current);
    formData.append("created_by", user.id);
    formData.append("modified_by", user.id);
    formData.append("html_content", templateInfo);
    formData.append("content", codeMirrorLatestData);
    formData.append("has_header", header);
    formData.append("has_footer", footer);
    const response = await adminactions.createTemplate(formData);
    if (response) {
      // actions.notify("Successfull!", "success");
      // setOpenCreateModal(false);
      setCurrentItem(response);
      setTemplateInfo(response.html_content);
      setCodeMirrorData(response.content);
      setCodeMirrorLatestData(response.content);
      customTablRef.current.updateTable();
    }
  };

  const handleTemplateUpdate = async (e) => {
    console.log(currentItem.id);
    e.preventDefault();
    const formData = new FormData(templateForm.current);
    formData.append("created_by", user.id);
    formData.append("modified_by", user.id);
    formData.append("html_content", templateInfo);
    formData.append("content", codeMirrorLatestData);
    formData.append("has_header", header);
    formData.append("has_footer", footer);
    const response = await adminactions.updateTemplate(
      currentItem.id,
      formData
    );
    if (response) {
      customTablRef.current.updateTable();
      // actions.notify("Successfull!", "success");
      // setThisTemplate(null);
      // setOpenCreateModal(false);
    }
  };

  const downloadPdf = async () => {
    await adminactions.templateToPDF(currentItem?.id);
  };

  const previewPdf = async () => {
    console.log("hello");
    const response = await adminactions.templateToPDFPreview(currentItem?.id);
    if (response) {
      setPdfPreviewData(response);
    }
    setPreviewPdfVisible(true);
  };

  const editorConfig = {
    extraPlugins: ["tableresize", "uploadimage", "createpdf"],
    removePlugins: [
      "exportpdf",
      "forms",
      "smiley",
      "language",
      "iframe",
      "about",
    ],
    // filebrowserBrowseUrl: 'http://localhost:4000/media/24b358cf-8e56-4b03-9843-29fabf589b8d/template/home-advt_jzx7KBU.jpeg',
    filebrowserImageUploadUrl:
      process.env.REACT_APP_BASE_URL +
      `client/${client}/` +
      `template/upload_template_image/`,
    fileTools_requestHeaders: axios.defaults.headers.common,
    uploadUrl:
      process.env.REACT_APP_BASE_URL +
      `client/${client}/` +
      `template/upload_template_image/`,
    contentsCss: [
      // "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css",
      process.env.REACT_APP_BASE_URL +
      "staticfiles/ckeditor/4.19.0/full-all/document-style.css",
    ],
    bodyClass: "document-editor",
    // height: 900,
    // width: 1000,
    height: "15.8cm",
    // width: "18.6cm",
    width: "21cm",
    allowedContent: true,
    // extraPlugins: [
    //     'timestamp'
    // ]
    // exportPdf_options: {
    //     header_html: '<div class="styled">This is the Header</div>',
    //     footer_html: '<div class="styled-counter"><span class="date></span></div>',
    //     header_and_footer_css: '.styled { font-weight: bold; padding: 10px; display: flex; color: red; } .styled-counter {font-size: 10px; color: red; }',
    //     margin_top: '2cm',
    //     margin_bottom: '10cm',
    // }
    // exportPdf_options: {
    //     format: "A3"
    // }
    exportPdf_options: {
      header_html: '<div class="styled">Header content</div>',
      footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
      header_and_footer_css:
        ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
      margin_left: "1cm",
      margin_right: "2cm",
      format: "A5",
      page_orientation: "landscape",
    },
    protectedSource: [/{%[\s\S]*?%}+/g, /{{[\s\S]*?}}+/g],
    // formatOutput: false,
    // formatSource: false
  };

  return (
    <Container fluid className="mt-2">
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            fetchForm();
            setCurrentItem(null);
            setModal(true);
          }}
        >
          {createFormButtonText}
        </Button>
      </div>
      <Row>
        {searchForm && (
          <Col sm={3}>
            {filterForm?.data && (
              <Form
                form={dropdownUrl(filterForm?.data)}
                onSubmit={(e) => filterItems(e)}
              />
            )}
          </Col>
        )}
        <Col sm={searchForm ? 9 : 12}>
          <CustomTable
            ref={customTablRef}
            tableName={tableName}
            tableTitle={tableTitle}
            url={url}
            searchURL={filterParams}
            setForm={setItemForm}
            setModal={setModal}
            baseSearchParam={baseSearchParam}
            setUpdateData={setCurrentItem}
          />
        </Col>
      </Row>
      {/* <Modal
        show={modal}
        onHide={() => setModal(false)}
        centered
        onExited={() => {
          setModal(false);
        }}
        size="lg"
        fullscreen={modalFullscreen}
      >
        <Modal.Header className="d-flex flex-row justify-content-center">
          <h2>{formTitle}</h2>
        </Modal.Header>
        <div className="removeButtonRight position-absolute cursor-pointer">
          <AiOutlineClose size={20} onClick={() => setModal(false)} />
        </div>
        <Modal.Body>
          {itemForm?.data && (
            <Form
              submission={{ data: currentItem }}
              form={dropdownUrl(itemForm?.data)}
              onSubmit={(e) => {
                if (currentItem) {
                  updateItem(e);
                } else {
                  createItem(e);
                }
              }}
              onChange={(e) => console.log(e)}
              // onCustomEvent={(e) => {
              //     console.log(e.type);
              //     setModal(true);
              //     if (e.type = "addCategory") {
              //         console.log(e);
              //     } else if (e.type = "addItemType") {
              //         console.log(e);
              //     } else if (e.type = "addItemDepartment") {
              //         // setMetaTag("ITEM_DEPARTMENT")
              //     }
              // }}
            />
          )}
        </Modal.Body>
      </Modal> */}
      <div
        className={`overflow-auto position-fixed w-100 h-100 fixed-top py-5 ${modal ? "" : "d-none"
          }`}
        style={{ backgroundColor: "rgb(241, 243, 250)" }}
      // show={modal}
      // onHide={() => setModal(false)}
      // fullscreen={true}
      // centered
      // onExited={() => {
      //     setModal(false);
      // }}
      // onClick={(e) => e.preventDefault()}
      >
        <div className="d-flex flex-row justify-content-center">
          <h4 className="mb-4">Create Your Template</h4>
        </div>
        <div className="removeButtonRight position-absolute">
          <AiOutlineClose
            className="cursor-pointer"
            size={20}
            onClick={() => {
              setModal(false);
              setCurrentItem(null);
              setShowCodemirror(false);
            }}
          />
        </div>
        <BForm
          className=""
          ref={templateForm}
          onSubmit={(e) => {
            if (currentItem) {
              handleTemplateUpdate(e);
            } else {
              handleTemplateSubmit(e);
            }
          }}
        >
          <Row>
            <Col sm={4} className="d-flex justify-content-end">
              <div className="border bg-white">
                <Container className="m-2 fluid" style={{ width: 400 }}>
                  <h5 className="text-primary">Meta Data</h5>
                  <BForm.Group className="mt-3 mb-5">
                    <BForm.Label>Template/Report Name</BForm.Label>
                    <BForm.Control
                      required
                      size="sm"
                      name="name"
                      type="text"
                      placeholder="Enter Template Name"
                      defaultValue={currentItem?.id ? currentItem.name : ""}
                    />
                    {/* <BForm.Label>Unique Name</BForm.Label>
                    <BForm.Control
                      disabled
                      size="sm"
                      name="name"
                      type="text"
                      placeholder="Enter Template Name"
                      value={currentItem?.id ? currentItem.slug : ""}
                    /> */}
                    <BForm.Label>Template Type(unique)<sup>**</sup></BForm.Label>
                    <BForm.Select
                      aria-label="Default select example"
                      size="sm"
                      name="template_type"
                      onChange={e => setTemplateType(e.target.value)}
                      defaultValue={
                        currentItem?.id ? currentItem.template_type : "A4"
                      }
                    >
                      {templateTypes.map((item, index) => {
                        return (
                          <option
                            key={item.id}
                            value={item.key}
                            selected={item.key == currentItem?.template_type}
                          >
                            {item.key}
                          </option>
                        );
                      })}
                    </BForm.Select>
                    <p><small><small className="text-secondary">**Types starting with DISCHARGE_, BARCODE_ are special types.</small></small></p>
                    <BForm.Check className="mt-2" type="checkbox" id="is_special_report" label="Is Special Report?" checked={isSpecial} onChange={(e) => handleIsSpecialCheck()}/>
                    <p><small><small className="text-secondary">Check if the template is special report</small></small></p>
                    {isSpecial && <BForm.Label>Special Report Department</BForm.Label>}
                    {isSpecial && <BForm.Select
                      aria-label="Default select example"
                      size="sm"
                      name="department"
                      onChange={e => setSelectedSpecialDepartment(e.target.value)}
                      defaultValue={
                        currentItem?.id ? currentItem.department : null
                      }
                    >
                      <option value="" selected disabled>--Special Department--</option>
                      {specialDepartments.map((item, index) => {
                        return (
                          <option
                            key={item.id}
                            value={item.id}
                            selected={item.id == currentItem?.department}
                          >
                            {item.key}
                          </option>
                        );
                      })}
                    </BForm.Select>}
                    <BForm.Label>Reporting Style</BForm.Label>
                    <BForm.Select
                      aria-label="Default select example"
                      size="sm"
                      name="template_style"
                      defaultValue={
                        currentItem?.id ? currentItem.template_style : "A4"
                      }
                    >
                      <option value="">Select</option>
                      {reportingStyles.map((item, index) => {
                        return (
                          <option
                            key={item.id}
                            value={item.key}
                            selected={item.key == currentItem?.template_style}
                          >
                            {item.key}
                          </option>
                        );
                      })}
                    </BForm.Select>
                    <BForm.Label>Description</BForm.Label>
                    <BForm.Control
                      as="textarea"
                      size="sm"
                      name="description"
                      placeholder="Description"
                      defaultValue={
                        currentItem?.id ? currentItem.description : ""
                      }
                    />
                    <div className="d-flex">
                      {templateType == "HEADER" && <div className="w-100">
                        <BForm.Label>Header Size"</BForm.Label>
                        <BForm.Control
                          size="sm"
                          name="header_size"
                          placeholder="Footer Size"
                          type="number"
                          defaultValue={
                            currentItem?.id ? currentItem.header_size : 40
                          }
                        />
                      </div>}
                      {templateType == "FOOTER" && <div className="w-100">
                        <BForm.Label>Footer Size</BForm.Label>
                        <BForm.Control
                          size="sm"
                          name="footer_size"
                          placeholder="Footer Size"
                          type="number"
                          defaultValue={
                            currentItem?.id ? currentItem.footer_size : 18
                          }
                        />
                      </div>}
                    </div>
                    {templateType?.startsWith("BARCODE_") && <div>
                      <div className="d-flex">

                        <div className="w-100 me-3">
                          <BForm.Label>Barcode Width</BForm.Label>
                          <BForm.Control
                            size="sm"
                            name="barcode_width"
                            placeholder="Barcode Width"
                            type="number"
                            defaultValue={
                              currentItem?.id ? currentItem.barcode_width : 65
                            }
                          />
                        </div>
                        <div className="w-100">
                          <BForm.Label>Barcode Height</BForm.Label>
                          <BForm.Control
                            size="sm"
                            name="barcode_height"
                            placeholder="Barcode Height"
                            type="number"
                            defaultValue={
                              currentItem?.id ? currentItem.barcode_height : 45
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex">

                        <div className="w-100 me-3">
                          <BForm.Label>Barcode Font Size</BForm.Label>
                          <BForm.Control
                            size="sm"
                            name="barcode_font_size"
                            placeholder="Barcode Font Size"
                            type="number"
                            defaultValue={
                              currentItem?.id ? currentItem.barcode_font_size : 8
                            }
                          />
                        </div>
                        <div className="w-100">
                          <BForm.Label>Barcode Module Height</BForm.Label>
                          <BForm.Control
                            size="sm"
                            name="barcode_module_height"
                            placeholder="Barcode Module Height"
                            type="number"
                            defaultValue={
                              currentItem?.id ? currentItem.barcode_module_height : 8
                            }
                          />
                        </div>
                      </div>
                    </div>}
                  </BForm.Group>
                  <div className={`${(["FOOTER", "HEADER"].includes(templateType) || templateType?.startsWith("BARCODE_")) ? "d-none" : "d-block"}`}>
                    <div className="border p-4">
                      <h5 className="text-primary">Page Format</h5>
                      <BForm.Group className="my-2 d-flex">
                        <BForm.Check
                          type="checkbox"
                          label="Header"
                          checked={header} onChange={(e) => setHeader(e.target.checked)}
                          onChecked={() => {
                            setHeader(!header);
                          }}
                          defaultChecked={
                            currentItem?.id ? currentItem.has_header : true
                          }
                        />
                        <BForm.Check
                          className="ms-4"
                          type="checkbox"
                          label="Footer"
                          checked={footer} onChange={(e) => setFooter(e.target.checked)}
                          onChecked={() => {
                            setFooter(!footer);
                          }}
                          defaultChecked={
                            currentItem?.id ? currentItem.has_footer : true
                          }
                        />
                      </BForm.Group>
                      <BForm.Group className="my-2">
                        <BForm.Label>Margin (in mm)</BForm.Label>
                        <div className="d-flex">
                          {!header && <div>
                            <BForm.Label>
                              <small>Top</small>
                            </BForm.Label>
                            <BForm.Control
                              ref={margin_top_ref}
                              required
                              size="sm"
                              name="page_margin_top"
                              type="number"
                              defaultValue={
                                currentItem?.id ? currentItem.page_margin_top : 12
                              }
                            />
                          </div>}
                          {!footer && <div className="mx-2">
                            <BForm.Label>
                              <small>Bottom</small>
                            </BForm.Label>
                            <BForm.Control
                              ref={margin_bottom_ref}
                              required
                              size="sm"
                              name="page_margin_bottom"
                              type="number"
                              defaultValue={
                                currentItem?.id
                                  ? currentItem.page_margin_bottom
                                  : 12
                              }
                            />
                          </div>}
                          <div className="mx-2">
                            <BForm.Label>
                              <small>Left</small>
                            </BForm.Label>
                            <BForm.Control
                              ref={margin_left_ref}
                              required
                              size="sm"
                              name="page_margin_left"
                              type="number"
                              defaultValue={
                                currentItem?.id
                                  ? currentItem.page_margin_left
                                  : 10
                              }
                            />
                          </div>
                          <div>
                            <BForm.Label>
                              <small>Right</small>
                            </BForm.Label>
                            <BForm.Control
                              ref={margin_right_ref}
                              required
                              size="sm"
                              name="page_margin_right"
                              type="number"
                              defaultValue={
                                currentItem?.id
                                  ? currentItem.page_margin_right
                                  : 10
                              }
                            />
                          </div>
                        </div>
                      </BForm.Group>
                      <BForm.Group className="my-2">
                        <BForm.Label>Border</BForm.Label>
                        <BForm.Control
                          ref={page_border_ref}
                          required
                          size="sm"
                          name="page_border"
                          type="text"
                          defaultValue={
                            currentItem?.id
                              ? currentItem.page_border
                              : "1px solid #ccc"
                          }
                        />
                      </BForm.Group>
                      <BForm.Group className="my-2">
                        <BForm.Label>Page Size</BForm.Label>
                        <BForm.Select
                          ref={page_size_ref}
                          aria-label="Default select example"
                          size="sm"
                          name="page_size"
                          defaultValue={
                            currentItem?.id ? currentItem.page_size : "A4"
                          }
                        >
                          {page_sizes.map((item, index) => {
                            return (
                              <option
                                key={item.id}
                                value={item.id}
                                selected={item.id == currentItem?.page_size}
                              >
                                {item.id}
                              </option>
                            );
                          })}
                        </BForm.Select>
                      </BForm.Group>
                      <div
                        className={`${page_size_ref?.current?.value != "Barcode"
                          ? "d-block"
                          : "d-none"
                          }`}
                      >
                        <BForm.Group className="my-2">
                          <BForm.Label>Orientation</BForm.Label>
                          <BForm.Select
                            ref={page_orientation_ref}
                            aria-label="Default select example"
                            size="sm"
                            name="page_orientation"
                            defaultValue={
                              currentItem?.id
                                ? currentItem.page_orientation
                                : "portrait"
                            }
                          >
                            {page_orientation.map((item, index) => {
                              return (
                                <option
                                  key={item.id}
                                  value={item.id}
                                  selected={
                                    item.id == currentItem?.page_orientation
                                  }
                                >
                                  {item.label}
                                </option>
                              );
                            })}
                          </BForm.Select>
                        </BForm.Group>
                        <BForm.Group className="my-2">
                          <BForm.Label>Page Number Format</BForm.Label>
                          <BForm.Control
                            ref={page_number_format_ref}
                            required
                            size="sm"
                            name="page_number_format"
                            type="text"
                            defaultValue={
                              currentItem?.id
                                ? currentItem.page_number_format
                                : '"Page " counter(page) " of " counter(pages)'
                            }
                          />
                        </BForm.Group>
                        <BForm.Group className="my-2">
                          <BForm.Label>Page Number Position</BForm.Label>
                          <BForm.Select
                            ref={page_number_position_ref}
                            aria-label="Default select example"
                            size="sm"
                            name="page_number_position"
                            defaultValue={
                              currentItem?.id
                                ? currentItem.page_number_position
                                : "bottom-right"
                            }
                          >
                            {page_number_position.filter(item => !(item.id == "bottom-center" && footer)).map((item, index) => {
                              return (
                                <option
                                  key={item.id}
                                  value={item.id}
                                  selected={
                                    item.id == currentItem?.page_number_position
                                  }
                                >
                                  {item.label}
                                </option>
                              );
                            })}
                          </BForm.Select>
                        </BForm.Group>
                      </div>
                      <Button
                        className="my-2"
                        size="sm"
                        onClick={resetPageFormatting}
                      >
                        Reset Page Format
                      </Button>
                    </div>
                    <div className="border p-4">
                      <h5 className="text-primary mt-2">
                        Signature Auhorization
                      </h5>
                      <h6>Signature 1</h6>
                      <BForm.Group className="my-2">
                        <div className="d-flex">
                          <div className="me-2">
                            <BForm.Label>
                              <small>Department</small>
                            </BForm.Label>
                            <BForm.Select
                              aria-label="Default select example"
                              size="sm"
                              name="signature1_department"
                              defaultValue={
                                currentItem?.id
                                  ? currentItem.signature1_department
                                  : "portrait"
                              }
                              onChange={(e) => {
                                setRoles1(roles.filter(item => item.department == e.target.value))
                              }}
                            >
                              <option value="">Select Department</option>
                              {departments.map((item, index) => {
                                return (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    selected={
                                      item.id ==
                                      currentItem?.signature1_department
                                    }
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </BForm.Select>
                          </div>
                          <div>
                            <BForm.Label>
                              <small>Role</small>
                            </BForm.Label>
                            <BForm.Select
                              aria-label="Default select example"
                              size="sm"
                              name="signature1_role"
                              defaultValue={
                                currentItem?.id
                                  ? currentItem.signature1_role
                                  : "portrait"
                              }
                              onChange={(e) => {
                                setUsers1(users.filter(item => item.role == e.target.value))
                              }}
                            >
                              <option value="">Select Role</option>
                              {roles1.map((item, index) => {
                                return (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    selected={
                                      item.id == currentItem?.signature1_role
                                    }
                                  >
                                    {item.role}
                                  </option>
                                );
                              })}
                            </BForm.Select>
                          </div>
                        </div>
                      </BForm.Group>
                      <BForm.Group className="my-2">
                        <BForm.Label>
                          <small>User</small>
                        </BForm.Label>
                        <BForm.Select
                          aria-label="Default select example"
                          size="sm"
                          name="signature1_user"
                          defaultValue={
                            currentItem?.id
                              ? currentItem.signature1_user
                              : "portrait"
                          }
                        >
                          <option value="">Select User</option>
                          {users1.map((item, index) => {
                            return (
                              <option
                                key={item.id}
                                value={item.id}
                                selected={item.id == currentItem?.signature1_user}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                        </BForm.Select>
                      </BForm.Group>
                      <hr />
                      <h6>Signature 2</h6>
                      <BForm.Group className="my-2">
                        <div className="d-flex">
                          <div className="me-2">
                            <BForm.Label>
                              <small>Department</small>
                            </BForm.Label>
                            <BForm.Select
                              aria-label="Default select example"
                              size="sm"
                              name="signature2_department"
                              defaultValue={
                                currentItem?.id
                                  ? currentItem.signature2_department
                                  : "portrait"
                              }
                              onChange={(e) => {
                                setRoles2(roles.filter(item => item.department == e.target.value))
                              }}
                            >
                              <option value="">Select Department</option>
                              {departments.map((item, index) => {
                                return (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    selected={
                                      item.id ==
                                      currentItem?.signature2_department
                                    }
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </BForm.Select>
                          </div>
                          <div>
                            <BForm.Label>
                              <small>Role</small>
                            </BForm.Label>
                            <BForm.Select
                              aria-label="Default select example"
                              size="sm"
                              name="signature2_role"
                              defaultValue={
                                currentItem?.id
                                  ? currentItem.signature2_role
                                  : "portrait"
                              }
                              onChange={(e) => {
                                setUsers2(users.filter(item => item.role == e.target.value))
                              }}
                            >
                              <option value="">Select Role</option>
                              {roles2.map((item, index) => {
                                return (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    selected={
                                      item.id == currentItem?.signature2_role
                                    }
                                  >
                                    {item.role}
                                  </option>
                                );
                              })}
                            </BForm.Select>
                          </div>
                        </div>
                      </BForm.Group>
                      <BForm.Group className="my-2">
                        <BForm.Label>
                          <small>User</small>
                        </BForm.Label>
                        <BForm.Select
                          aria-label="Default select example"
                          size="sm"
                          name="signature2_user"
                          defaultValue={
                            currentItem?.id
                              ? currentItem.signature2_user
                              : "portrait"
                          }
                        >
                          <option value="">Select User</option>
                          {users2.map((item, index) => {
                            return (
                              <option
                                key={item.id}
                                value={item.id}
                                selected={item.id == currentItem?.signature2_user}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                        </BForm.Select>
                      </BForm.Group>
                      <hr />
                      <h6>Signature 3</h6>
                      <BForm.Group className="my-2">
                        <div className="d-flex">
                          <div className="me-2">
                            <BForm.Label>
                              <small>Department</small>
                            </BForm.Label>
                            <BForm.Select
                              aria-label="Default select example"
                              size="sm"
                              name="signature3_department"
                              defaultValue={
                                currentItem?.id
                                  ? currentItem.signature3_department
                                  : "portrait"
                              }
                              onChange={(e) => {
                                setRoles3(roles.filter(item => item.department == e.target.value))
                              }}
                            >
                              <option value="">Select Department</option>
                              {departments.map((item, index) => {
                                return (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    selected={
                                      item.id ==
                                      currentItem?.signature3_department
                                    }
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </BForm.Select>
                          </div>
                          <div>
                            <BForm.Label>
                              <small>Role</small>
                            </BForm.Label>
                            <BForm.Select
                              aria-label="Default select example"
                              size="sm"
                              name="signature3_role"
                              defaultValue={
                                currentItem?.id
                                  ? currentItem.signature3_role
                                  : "portrait"
                              }
                              onChange={(e) => {
                                setUsers3(users.filter(item => item.role == e.target.value))
                              }}
                            >
                              <option value="">Select Role</option>
                              {roles3.map((item, index) => {
                                return (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    selected={
                                      item.id == currentItem?.signature3_role
                                    }
                                  >
                                    {item.role}
                                  </option>
                                );
                              })}
                            </BForm.Select>
                          </div>
                        </div>
                      </BForm.Group>
                      <BForm.Group className="my-2">
                        <BForm.Label>
                          <small>User</small>
                        </BForm.Label>
                        <BForm.Select
                          aria-label="Default select example"
                          size="sm"
                          name="signature3_user"
                          defaultValue={
                            currentItem?.id
                              ? currentItem.signature3_user
                              : "portrait"
                          }
                        >
                          <option value="">Select User</option>
                          {users3.map((item, index) => {
                            return (
                              <option
                                key={item.id}
                                value={item.id}
                                selected={item.id == currentItem?.signature3_user}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                        </BForm.Select>
                      </BForm.Group>
                    </div>
                  </div>
                </Container>
              </div>
            </Col>
            <Col sm={8}>
              <Container>
                <div>
                  <Button
                    className="my-3 me-2"
                    variant="success"
                    type="submit"
                    size="sm"
                  >
                    {currentItem?.id ? "UPDATE TEMPLATE" : "SUBMIT TEMPLATE"}
                  </Button>
                  {currentItem && (
                    <Button
                      size="sm"
                      onClick={downloadPdf}
                      className="my-3 mx-2"
                    >
                      Export PDF
                    </Button>
                  )}
                  {currentItem && (
                    <Button
                      size="sm"
                      onClick={previewPdf}
                      className="my-3 mx-2"
                    >
                      Preview PDF
                    </Button>
                  )}
                  {currentItem && (
                    <Button
                      size="sm"
                      onClick={() => {
                        setShowCodemirror(!showCodeMirror);
                      }}
                      className="my-3 mx-2"
                    >
                      {showCodeMirror
                        ? "Go to HTML Editor"
                        : "Go to Template Editor"}
                    </Button>
                  )}
                  {currentItem && showCodeMirror && (
                    <Button
                      size="sm"
                      onClick={() => {
                        setConfirmModal(true);
                      }}
                      className="my-3 mx-2"
                    >
                      Fetch Created HTML
                    </Button>
                  )}
                  <div className={`${showCodeMirror ? "d-none" : "d-block"}`}>
                    {currentItem && (
                      <CKEditor
                        initData={
                          currentItem
                            ? currentItem.html_content
                            : "<p>Create your Template here!</p>"
                        }
                        config={editorConfig}
                        editorUrl={
                          process.env.REACT_APP_BASE_URL +
                          "staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
                        }
                        onInstanceReady={() => {
                          if (currentItem) {
                            setTemplateInfo(currentItem.html_content);
                          }
                        }}
                        onChange={(event) => {
                          const data = event.editor.getData();
                          // console.log({ event, editor, data });
                          setTemplateInfo(data);
                        }}
                      />
                    )}
                    {!currentItem && (
                      <CKEditor
                        initData={"<p>Create your Template here!</p>"}
                        config={editorConfig}
                        editorUrl={
                          process.env.REACT_APP_BASE_URL +
                          "staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
                        }
                        onChange={(event) => {
                          const data = event.editor.getData();
                          // console.log({ event, editor, data });
                          setTemplateInfo(data);
                        }}
                      />
                    )}
                  </div>
                </div>
              </Container>
              <Container
                className={`my-5 ${showCodeMirror ? "d-block" : "d-none"}`}
              >
                <div>
                  {showCodeMirror && (
                    <CodeMirror
                      style={{ padding: 20 }}
                      value={codeMirrorData}
                      editorDidMount={(editor) => {
                        instance = editor;
                      }}
                      options={{
                        mode: "jinja2",
                        theme: "material",
                        lineNumbers: true,
                        autoRefresh: true,
                      }}
                      onChange={(editor, data, value) => {
                        setCodeMirrorLatestData(value);
                      }}
                    />
                  )}
                </div>
              </Container>
            </Col>
          </Row>
        </BForm>
      </div>
      <Modal
        show={previewPdfVisible}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onClose={() => setPreviewPdfVisible(false)}
        onHide={() => setPreviewPdfVisible(false)}
        fullscreen={true}
        onExited={() => {
          setPreviewPdfVisible(false);
        }}
      >
        <Modal.Header
          closeButton
          className="d-flex justify-content-center w-100"
        >
          <Modal.Title className="d-flex justify-content-center w-100">
            PDF Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="d-flex justify-content-center align-items-center py-3 h-100vh"
            style={{ backgroundColor: "#999" }}
          >
            <Document
              file={pdfPreviewData}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {[...Array(numPages).keys()].map((item, index) => {
                return (
                  <Page
                    key={index + 1}
                    className="my-3"
                    pageNumber={index + 1}
                  />
                );
              })}
            </Document>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button onClick={() => console.log("console")}>Close</Button>
                </Modal.Footer> */}
      </Modal>
      <Modal
        show={confirmModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onClose={() => setConfirmModal(false)}
        onHide={() => setConfirmModal(false)}
        onExited={() => {
          setConfirmModal(false);
        }}
      >
        <Modal.Body>
          <p>This will flush your CKEditor code. Do you want to proceed</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              console.log(templateInfo);
              setCodeMirrorData(templateInfo);
              setConfirmModal(false);
            }}
          >
            Confirm
          </Button>
          <Button variant="secondary" onClick={() => setConfirmModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default TemplatePage;
