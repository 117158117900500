const initialState = {
    client: null,
    user: {
        id: null,
        username: null,
        name: null,
        email: null,
        is_admin: false,
    },
    token: null,
    isAuthenticated: false,
    currnetInvoiceTemplate: null,
    currnetReportTemplate: null,
    currentAdmissionCardTemplate: null,
};

export const authReducer = (state = initialState, action) => {
    console.log(action);
    switch (action.type) {
        case "SET_CLIENT":
            console.log("STATE:", state);
            state.client = action.payload;
            break;
        case "LOGIN":
            console.log("STATE:", state);
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isAuthenticated = action.payload.token ? true : false;
            break;
        case "LOGOUT":
            console.log("STATE:", state);
            state.user = initialState.user;
            state.token = null;
            state.isAuthenticated = false;
            state.client = null;
            break;
        case "CURRENT_ADMISSION_CARD_TEMPLATE":
            console.log("STATE", state);
            state.currentAdmissionCardTemplate = action.payload;
            break;
        case "CURRENT_INVOICE_TEMPLATE":
            console.log("STATE", state);
            state.currnetInvoiceTemplate = action.payload;
            break;
        case "CURRENT_REPORT_TEMPLATE":
            console.log("STATE", state);
            state.currnetReportTemplate = action.payload;
            break;
        default:
            console.log("State", state);
    }
    return state;
};