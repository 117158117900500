import React, {
  useState,
  useRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  Button,
  Container,
  Modal,
  Spinner,
  Form as BootstrapForm,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import * as actions from "actions/CommonActions";
import * as authActions from "actions/PublicAuthActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "core-ui/components/CustomTable.scss";
import { AiOutlineClose } from "react-icons/ai";
import { EmailPill, PdfPreview, Attachment } from "components";
import { CKEditor } from "ckeditor4-react";
import moment from "moment";
// import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';

// // Import styles
// import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
// const thumbnailPluginInstance = thumbnailPlugin(props);

// const { Thumbnails } = thumbnailPluginInstance;


const CustomTable = forwardRef(
  (
    {
      tableName,
      tableTitle,
      url,
      baseSearchParam = "",
      searchURL = "",
      setForm,
      setModal,
      setUpdateData,
    },
    ref
  ) => {
    // const  = props;
    const [data, setData] = useState([]);
    const [tableFields, setTableFields] = useState([]);
    const [columns, setColumns] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const [waModal, setWaModal] = useState(false);
    const [emails, setEmails] = useState([]);
    const [mobiles, setMobiles] = useState([]);
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [attachments, setAttachments] = useState([{ title: "test1.pdf" }, { title: "test2.pdf" }]);
    const [emailValue, setEmailValue] = useState(null);
    const [mobileValue, setMobileValue] = useState(null);
    const [previewPdfVisible, setPreviewPdfVisible] = useState(false);
    const [pdfData, setPdfData] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [currentTemplateType, setCurrentTemplateType] = useState(null);
    const [holdDeleteRecord, setHoldDeleteRecord] = useState(null);
    const [pending, setPending] = useState(true);
    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";
    const user = useSelector((state) => state.authReducer.user);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const openEmailModal = async (id, backend_function = []) => {
      // console.log('backend_function', backend_function);
      let fetched_attachments = [];
      for (let i = 0; i < backend_function.length; i++) {
        const response = await actions.templateToPDFData({
          id: id,
          preview: true,
          template_type: backend_function[i].template_type,
          url: url + id + `/${backend_function[i].func}/`,
        });
        console.log("############", response)
        fetched_attachments.push({ title: backend_function[i].template_type + ".pdf", data: response });
      }
      console.log('ID', id)
      // console.log('fetched_attachments', fetched_attachments);
      setEmailModal(true);
      setAttachments(fetched_attachments);
    }

    const openVendorEmailModal = async (id, backend_function = []) => {
      console.log(backend_function);
      const template_data = await actions.fetchTemplateData(url + id + `/${backend_function[1].func}`)
      console.log('TEMPLATE DATA', template_data)
      if (validateEmail(template_data.data.vendor_data.contact_person_email)) {
        setEmails([template_data.data.vendor_data.contact_person_email])
      }
      setSubject(`Order No. - ${template_data.data.po_number ? template_data.data.po_number : template_data.data.wo_number}`)
      setMessage(template_data.template_text)
      setOrderNumber(template_data.data.po_number ? template_data.data.po_number : template_data.data.wo_number)
      let fetched_attachments = [];
      for (let i = 0; i < backend_function.length; i++) {
        if (!backend_function[i].template_type.startsWith("EMAIL_")) {
          const response = await actions.templateToPDFData({
            id: id,
            preview: true,
            template_type: backend_function[i].template_type,
            url: url + id + `/${backend_function[i].func}/`,
          });
          fetched_attachments.push({ title: backend_function[i].template_type + ".pdf", data: response });
        }
      }
      console.log(fetched_attachments);
      setEmailModal(true);
      setAttachments(fetched_attachments);
    }

    const openWaModal = async (id, backend_function = []) => {
      console.log(backend_function);
      let fetched_attachments = [];
      for (let i = 0; i < backend_function.length; i++) {
        const response = await actions.templateToPDFData({
          id: id,
          preview: true,
          template_type: backend_function[i].template_type,
          url: url + id + `/${backend_function[i].func}/`,
        });
        fetched_attachments.push({ title: backend_function[i].template_type + ".pdf", data: response });
      }
      console.log(fetched_attachments);
      setWaModal(true);
      setAttachments(fetched_attachments);
    }

    const userPermissions = useSelector(
      (state) => state.publicAuthReducer.permissions
    );

    const currentAdmissionCardTemplate = useSelector(
      (state) => state.authReducer.currentAdmissionCardTemplate
    );

    const fetchRecords = async () => {
      setPending(true);
      const response = await actions.customAction(
        url + baseSearchParam,
        searchURL,
        tableTitle,
        "loadMany",
        null
      );
      console.log(response);
      setData(response);
      setPending(false);
    };

    useImperativeHandle(ref, () => ({
      updateTable() {
        fetchRecords();
      },
    }));

    const deleteRecord = async (id) => {
      const response = await actions.customAction(
        url + id + "/",
        "",
        tableTitle,
        "delete",
        null
      );
      if (response) {
        fetchRecords();
      }
    };

    const updatePartialRecord = async (e, data, id) => {
      e.preventDefault();
      const response = await actions.customAction(
        url + id + "/",
        "",
        tableTitle,
        "partialUpdate",
        data
      );
      if (response) {
        fetchRecords();
      }
      return response;
    };

    const downloadRecord = async (e, id, template_type) => {
      e.preventDefault();
      const data = {
        template_type: template_type,
        preview: false,
      };
      const response = await actions.customAction(
        url + id + `/pdfDownload/`,
        "",
        tableTitle,
        "download",
        data
      );
      // alert(response);
    };

    const fetchTableFields = async () => {
      const response = await actions.loadTableFields(tableName);
      setTableFields(response);
    };

    useEffect(() => {
      fetchRecords();
      fetchTableFields();
      dispatch(authActions.loadPermissions(user));
    }, []);

    useEffect(() => {
      fetchRecords();
    }, [searchURL, baseSearchParam]);

    const checkPermissions = (userPermissions, allowedPermissions) => {
      if (allowedPermissions.length == 0) {
        return true;
      } else {
        for (let i in allowedPermissions) {
          if (userPermissions?.find((item) => item == allowedPermissions[i])) {
            return true;
            break;
          }
        }
      }
      return false;
    };

    const createColumns = () => {
      let cols = [];
      tableFields?.map((tableField, index) => {
        let field = {
          name: tableField.name,
          selector: tableField.selector
            ? (row, index) => eval(tableField.selector)
            : null,
          sortable: tableField.sortable,
          cell: tableField.cell ? (row) => eval(tableField.cell) : null,
          allowOverflow: tableField.allowOverflow,
          button: tableField.button,
          wrap: true,
          style: tableField.style ? eval(JSON.parse(tableField.style)) : null,
          width: tableField.width ? tableField.width : null,
        };
        if (tableField?.permission) {
          if (checkPermissions(userPermissions, [tableField?.permission])) {
            cols.push(field);
          }
        } else {
          cols.push(field);
        }
      });
      setColumns(cols);
    };

    useEffect(() => {
      createColumns();
    }, [tableFields]);

    const downloadPdf = async (
      e,
      id,
      preview,
      template_type,
      action_name = "",
      downloadUrl = null
    ) => {
      console.log("hello");
      const response = await actions.templateToPDF({
        id: id,
        preview: preview,
        template_type: template_type,
        url: downloadUrl
          ? downloadUrl + id + `${action_name}`
          : url + id + `${action_name}`,
      });
      if (response) {
        if (preview) {
          setPdfData(response);
          setCurrentId(id);
          setCurrentTemplateType(template_type);
          setPreviewPdfVisible(true);
        }
      }
      // const response = await actions.customAction(url + id + `/pdfDownload/`, "", tableTitle, "download", data);
    };

    const createLinks = (row, array, url) => {
      return (
        <div>
          {array.map((item, index) => {
            return (
              <span>
                <Link
                  key={item.id}
                  to={`${url}${item.mode}/${row?.opd ? row.opd : (row.ipd ? row.ipd : row.ed)}/${item.id
                    }/UPDATE/`}
                >
                  {item.label}
                </Link>
                <span>{`${index < array.length - 1 ? ",\u00a0" : ""}`}</span>
              </span>
            );
          })}
        </div>
      );
    };

    const specialReportButton = (row, url, buttonText, buttonIcon) =>  {
      return (
          <Button
            size="sm"
            className={`${buttonIcon} my-1 btn-table-action`}
            as={Link}
            // to={`/${param1}${url}/${row.id}/${row.special_report_status == "CREATED" ? 'UPDATE' : row.special_report_status == "UPDATED" ? 'AUTHORIZE' : row.special_report_status == 'AUTHORIZED' ? 'APPROVE' : ""}/`}
            to={`/${param1}${url}/${row.id}/UPDATE`}
            id={row.id}
          >
            {buttonIcon ? "" : buttonText}
          </Button>
      )
    }

    const momentDate = (date, format) => {
      const dateToFormat = new Date(date);
      // return (<Moment date={dateToFormat}  format="DD MMMM YYYY, HH:mm a"/>)
      return moment(dateToFormat).format(format);
    };

    const customLink = (
      row,
      title,
      isIcon,
      goNext,
      method,
      action,
      isButton,
      successMsg,
      errorMsg
    ) => {
      if (isButton) {
        if (method) {
          return (
            <div>
              <Button
                className={`${isIcon ? title : ""} my-1 btn-table-action`}
                size="sm"
                onClick={() => {
                  actions.customLink(
                    method,
                    url,
                    action,
                    row.id,
                    successMsg,
                    errorMsg
                  );
                }}
              >
                {isIcon ? "" : title}
              </Button>
            </div>
          );
        } else {
          return (
            <div>
              <Button
                className={`${isIcon ? title : ""} my-1 btn-table-action`}
                size="sm"
                as={Link}
                to={
                  goNext ? row.id : `${window.location.href + row.id + action}`
                }
              >
                {isIcon ? "" : title}
              </Button>
            </div>
          );
        }
      } else {
        if (method) {
          return (
            <div>
              <a
                className="no-text-decoration text-primary"
                href="#"
                onClick={() => {
                  actions.customLink(
                    method,
                    url,
                    action,
                    row.id,
                    successMsg,
                    errorMsg
                  );
                }}
              >
                {isIcon ? "" : title}
              </a>
            </div>
          );
        } else {
          return (
            <div>
              <a
                className="no-text-decoration text-primary"
                href={
                  goNext ? `${window.location.href + "/" + row.id}` : `${row.id}`
                }
              >
                {isIcon ? "" : title}
              </a>
            </div>
          );
        }
      }
      // return (<div>{row.patient_data.first_name}</div>)
    };

    const printDetails = (row, type) => {
      switch (type) {
        case "patient_opd":
          return (
            <div>
              <div>{row.patient_name}</div>
              <div style={{ color: "red" }}>{row?.uhid || row?.patient_data?.uhid}</div>
              <div>{`${row?.patient_data?.age} ${row?.patient_data?.age_type}/${row?.patient_data?.gender}`}</div>
            </div>
          );

        case "patient_ipd":
          return (
            <div>
              <div>{row?.patient_name}</div>
              <div style={{ color: "red" }}>{row?.uhid || row?.patient_data?.uhid}</div>
              <div>{`${row?.patient_data?.age} ${row?.patient_data?.age_type}/${row?.patient_data?.gender}`}</div>
            </div>
          );
        case "patient_ed":
          return (
            <div>
              <div>{row?.patient_name}</div>
              <div style={{ color: "red" }}>{row?.uhid || row?.patient_data?.uhid}</div>
              <div>{`${row?.patient_data?.age} ${row?.patient_data?.age_type}/${row?.patient_data?.gender}`}</div>
            </div>
          );

        case "ref_ranges_available":
          return (
            <div>
              {row.ref_ranges.length > 0 && <div style={{ color: "green" }}>Yes</div>}
              {row.ref_ranges.length == 0 && <div style={{ color: "red" }}>No</div>}
            </div>
          );

        case "special_values":
          return (
            <div>
              {row.special_values.length > 0 && <div style={{ color: "green" }}>Yes</div>}
              {row.special_values.length == 0 && <div style={{ color: "red" }}>No</div>}
            </div>
          );
      }
      // return (<div>{row.patient_data.first_name}</div>)
    };

    const pdfButton = (
      row,
      preview,
      buttonText,
      template_type,
      action_name,
      downloadUrl = null
    ) => {
      return (
        <Button
          size="sm"
          className="my-1 btn-table-action"
          onClick={(e) =>
            downloadPdf(
              e,
              row.id,
              preview,
              template_type,
              action_name,
              downloadUrl
            )
          }
          id={row.id}
        >
          {buttonText}
        </Button>
      );
    };

    const customButton = (
      row,
      actionType,
      buttonText,
      urlFromTableField,
      modal,
      buttonIcon
    ) => {
      // const fetchedData = await actions.customAction(urlFromTableField, '', 'Special Report', 'loadMany', null)
      // console.log(fetchedData)
      switch (actionType) {
        case "checkbox":
          return (
            <BootstrapForm.Group>
              <BootstrapForm.Check
                type="checkbox"
                name={`check_${row.id}`}
                label=""
                id={row.id}
                defaultChecked={eval(buttonText)}
              />
            </BootstrapForm.Group>
          );
        case "textbox":
          return (
            <BootstrapForm.Group>
              <BootstrapForm.Control
                name={`comment_${row.id}`}
                size="sm"
                id={row.id}
                defaultValue={eval(buttonText)}
              />
            </BootstrapForm.Group>
          );
        case "textbox_2":
          return (
            <BootstrapForm.Group>
              <BootstrapForm.Control
                name={`schedule_${row.id}`}
                size="sm"
                id={row.id}
                defaultValue={eval(buttonText)}
              />
            </BootstrapForm.Group>
          );
        case "post":
          return (
            <Button
              size="sm"
              className={`${buttonIcon} my-1 btn-table-action`}
              onClick={(e) =>
                updatePartialRecord(e, { status: "CONFIRMED" }, row.id)
              }
              id={row.id}
            >
              {buttonIcon ? "" : buttonText}
            </Button>
          );
        case "appointment":
          return (
            <Button
              size="sm"
              className={`${buttonIcon} my-1 btn-table-action`}
              onClick={async (e) => {
                const response = await updatePartialRecord(
                  e,
                  { status: "CONFIRMED" },
                  row.id
                );
                if (response) {
                  navigate(
                    `/his/frontdesk/opdadmission/${response.patient_data.id}`
                  );
                }
              }}
              id={row.id}
            >
              {buttonIcon ? "" : buttonText}
            </Button>
          );
        case "reject":
          return (
            <Button
              size="sm"
              className={`${buttonIcon} my-1 btn-table-action`}
              onClick={(e) =>
                updatePartialRecord(e, { status: "REJECTED" }, row.id)
              }
              id={row.id}
            >
              {buttonIcon ? "" : buttonText}
            </Button>
          );
        case "edit":
          if (modal) {
            return (
              <Button
                size="sm"
                className="my-1 btn-table-action"
                onClick={() => eval(modal)}
              >
                {/* <Button
                            size="sm"
                            className="my-1"
                            onClick={async () => { setForm(await axios.get('https://nca.margytech.in/api/form/530')); setModal(true); setUpdateData(row); }}> */}
                {buttonText}
              </Button>
            );
          }
          else {
            return (
              <Button
                size="sm"
                className={`${buttonIcon} my-1 btn-table-action`}
                as={Link}
                to={`/${param1}${urlFromTableField}/${row.id}`}
                id={row.id}
              >
                {buttonIcon ? "" : buttonText}
              </Button>
            );
          }
        case "delete":
          return (
            // <Button size="sm" className="my-1 btn-table-action btn-danger" onClick={(e) => deleteRecord(e, row.id)} id={row.id}>
            <Button
              size="sm"
              className="my-1 btn-table-action btn-danger"
              onClick={(e) => {
                e.preventDefault();
                setDeleteModal(true);
                setHoldDeleteRecord(row.id);
              }}
              id={row.id}
            >
              {buttonText}
            </Button>
          );
        case "download":
          return (
            <Button
              size="sm"
              className="my-1 btn-table-action"
              onClick={(e) => downloadRecord(e, row.id)}
              id={row.id}
            >
              {buttonText}
            </Button>
          );
        case "dummy":
          return (
            <Button
              size="sm"
              className={`${buttonIcon} my-1 btn-table-action`}
              id={row.id}
            >
              {buttonIcon ? "" : buttonText}
            </Button>
          );
        case "paymentdone":
          return (
            <span className="no-text-decoration text-muted">Payment Done</span>
          );
      }
    };

    const fetchAttachments = (row) => {

    }

    // const send_mail = (row, buttonText, subject = null, message = null, templates = []) => {
    //   console.log(templates);
    //   let backend_function = [];
    //   for (let i = 0; i < templates.length; i++) {
    //     const x = templates[i].split("##");
    //     backend_function.push({ template_type: x[0], func: x[1] });
    //   }
    //   return (
    //     <Button
    //       size="sm"
    //       className="my-1 btn-table-action"
    //       onClick={(e) => { e.preventDefault(); openEmailModal(row.id, backend_function); }}
    //       id={row.id}
    //     >
    //       {buttonText}
    //     </Button>
    //   );
    // }

    const send_mail = (row, buttonText, subject, message, templates = []) => {
      // console.log(templates);
      let backend_function = [];
      for (let i = 0; i < templates.length; i++) {
        const x = templates[i].split("##");
        backend_function.push({ template_type: x[0], func: x[1] });
      }
      return (
        <Button
          size="sm"
          className="my-1 btn-table-action"
          onClick={(e) => { e.preventDefault(); openEmailModal(row.id, backend_function); }}
          id={row.id}
        >
          {buttonText}
        </Button>
      );
    }

    const send_mail_vendor = (row, buttonText, subject = null, message = null, templates = []) => {
      console.log(templates);
      let backend_function = [];
      for (let i = 0; i < templates.length; i++) {
        const x = templates[i].split("##");
        backend_function.push({ template_type: x[0], func: x[1] });
      }
      return (
        <Button
          size="sm"
          className="my-1 btn-table-action"
          onClick={(e) => { e.preventDefault(); openVendorEmailModal(row.id, backend_function); }}
          id={row.id}
        >
          {buttonText}
        </Button>
      );
    }

    const send_wa_msg = (row, buttonText, subject = null, message = null, templates = ['OPD_INVOICE##opdInvoiceDownload', 'OPD_CASE_PAPER##opdPaperDownload']) => {
      console.log(templates);
      let backend_function = [];
      for (let i = 0; i < templates.length; i++) {
        const x = templates[i].split("##");
        backend_function.push({ template_type: x[0], func: x[1] });
      }
      return (
        <Button
          size="sm"
          className="my-1 btn-table-action"
          onClick={(e) => { e.preventDefault(); openWaModal(row.id, backend_function); }}
          id={row.id}
        >
          {buttonText}
        </Button>
      );
    }

    const customStyles = {
      rows: {
        style: {
          minHeight: "72px",
        },
      },
      headCells: {
        style: {
          fontWeight: "bold",
          padding: "15px",
          border: "1px solid #eee",
          color: "#fff",
          borderBottom: "1px solid #999",
          backgroundColor: "#006699",
        },
      },
      cells: {
        style: {
          borderLeft: "1px solid #eee",
          borderRight: "1px solid #eee",
          minHeight: "50px",
        },
      },
    };


    // custom text
    const customText = (
      actionType,
      message,

    ) => {
      switch (actionType) {
        case "success":
          return (
            <p className="text-success text-center">
              {message}
            </p>
          );
        case "error":
          return (
            <p className="text-danger text-center">
              {message}
            </p>
          );
      }
    }


    const otScheduleStyle = [
      {
        when: (row) => row.ot_schedule_status === "APPROVED",
        style: { backgroundColor: "#90ee90" },
      },
      {
        when: (row) => row.ot_schedule_status === "REJECTED",
        style: { backgroundColor: "#e53c3c" },
      },
    ];

    const removeEmail = (index) => {
      let x = [...emails];
      x.splice(index, 1);
      console.log(index, x);
      setEmails(x)
    }

    const removeAttachment = (index) => {
      let x = [...attachments];
      x.splice(index, 1);
      console.log(index, x);
      setAttachments(x);
    }

    const validateEmail = (email) => {
      if (emails.includes(email)) {
        actions.notify("Email already exists in recipients list!", "error")
        return false;
      } else {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      }
    };

    const validateMobile = (mobile) => {
      if (mobiles.includes(mobile)) {
        actions.notify("Mobile already exists in recipients list!", "error")
        return false;
      } else {
        return String(mobile)
          .toLowerCase()
          .match(
            /^[1-9]{1,2}[0-9]{3,14}$/
          );
      }
    }

    return (
      <>
        <Container className="p-0 py-3" fluid>
          <div className="shadow">
            <DataTable
              columns={columns}
              data={data}
              dense
              responsive
              pagination
              customStyles={customStyles}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              conditionalRowStyles={otScheduleStyle}
              persistTableHead={pending ? false : true}
              progressPending={pending}
              noDataComponent={
                <div className="py-5">
                  <p className="my-5">No data available.</p>
                </div>
              }
              progressComponent={
                <div className="py-5">
                  <Spinner
                    className="my-5"
                    animation="border"
                    variant="primary"
                  />
                </div>
              }
            />
          </div>
        </Container>
        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          centered
          onExited={() => {
            setDeleteModal(false);
            setHoldDeleteRecord(null);
          }}
        >
          <Modal.Header className="d-flex flex-row justify-content-center">
            <h2>Do you want to delete the item?</h2>
          </Modal.Header>
          <div className="removeButtonRight position-absolute cursor-pointer">
            <AiOutlineClose
              size={20}
              onClick={() => {
                setDeleteModal(false);
                setHoldDeleteRecord(null);
              }}
            />
          </div>
          <Modal.Body>
            <BootstrapForm>
              <Button
                onClick={() => {
                  setDeleteModal(false);
                  deleteRecord(holdDeleteRecord);
                  setHoldDeleteRecord(null);
                }}
              >
                YES
              </Button>
            </BootstrapForm>
          </Modal.Body>
        </Modal>
        
        <Modal
          show={emailModal}
          onHide={() => setEmailModal(false)}
          centered
          size="lg"
          onExited={() => {
            setEmailModal(false);
            // setHoldDeleteRecord(null);
          }}
        >
          <Modal.Header className="d-flex flex-row justify-content-center">
            <h2>Send Email</h2>
          </Modal.Header>
          <div className="removeButtonRight position-absolute cursor-pointer">
            <AiOutlineClose
              size={20}
              onClick={() => {
                setEmailModal(false);
                // setHoldDeleteRecord(null);
              }}
            />
          </div>
          <Modal.Body>
            <BootstrapForm onSubmit={async (e) => {
              e.preventDefault();
            }}>
              <BootstrapForm.Group className="my-2">
                <BootstrapForm.Label>Recipients</BootstrapForm.Label>
                <div className="mb-3">
                  {emails.map((item, index) => {
                    return (
                      <EmailPill key={index} email={item} removeEmail={() => removeEmail(index)} />
                    );
                  })}
                </div>
                <BootstrapForm.Control
                  type="email"
                  placeholder="Enter email Id"
                  multiple
                  onChange={(e) => {
                    setEmailValue(e.target.value)
                  }}
                  value={emailValue}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      const y = e.target.value;
                      if (validateEmail(e.target.value)) {
                        setEmails([...emails, e.target.value]);
                        setEmailValue("");
                      }
                    }
                  }}
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group className="my-2">
                <BootstrapForm.Label>Subject</BootstrapForm.Label>
                <BootstrapForm.Control placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
              </BootstrapForm.Group>
              <CKEditor
                initData={message}
                onChange={(event) => {
                  const data = event.editor.getData();
                  setMessage(data);
                }}
              />
              {/* <BootstrapForm.Group className="my-2">
                <BootstrapForm.Label>Message</BootstrapForm.Label>
                <BootstrapForm.Control placeholder="Message" value={message} as="textarea" rows={5} onChange={(e) => setMessage(e.target.value)}/>
              </BootstrapForm.Group> */}

              {/* <Viewer plugins={[thumbnailPluginInstance]} />; */}
              <BootstrapForm.Group className="my-2">
                <BootstrapForm.Label>Attachments</BootstrapForm.Label>
                <div className="d-flex flex-column">
                  {attachments.map((item, index) => {
                    return (
                      <Attachment key={index} attachment={item} removeAttachment={() => removeAttachment(index)} />
                    )
                  })}
                </div>
              </BootstrapForm.Group>
              <BootstrapForm.Group className="my-2 text-center">
                <Button type="button" onClick={async (e) => {
                  if (emails.length == 0) {
                    actions.notify("Please add recipients.", "danger");
                  } else if (subject == null || subject == "") {
                    actions.notify("Please add subject.", "danger");
                  } else if (message == null || message == "") {
                    actions.notify("Please add message", "danger");
                  } else {
                    const formData = new FormData();
                    formData.append("emails", emails.join(","));
                    formData.append("subject", subject);
                    formData.append("message", message);
                    formData.append("order_number", orderNumber)

                    formData.append("attachments_title", attachments.map((item, index) => item.title));
                    // formData.append("attachments", attachments.map((item, index) => item.data));
                    // formData.append("attachments", attachments.map((item, index) => item.data));
                    for (let i = 0; i < attachments.length; i++) {
                      formData.append(attachments[i].title, new File([attachments[i].data], attachments[i].title, {
                        type: attachments[i].data.type,
                      }));
                    }
                    const response = await actions.send_email(formData);
                    // console.log(response)
                    if (response) {
                      setEmailModal(false)
                    }
                  }
                }}>Send Email</Button>
              </BootstrapForm.Group>
            </BootstrapForm>
          </Modal.Body>
        </Modal>
        <Modal
          show={waModal}
          onHide={() => setWaModal(false)}
          centered
          size="lg"
          onExited={() => {
            setWaModal(false);
            // setHoldDeleteRecord(null);
          }}
        >
          <Modal.Header className="d-flex flex-row justify-content-center">
            <h2>Send Whatsapp Message/Files</h2>
          </Modal.Header>
          <div className="removeButtonRight position-absolute cursor-pointer">
            <AiOutlineClose
              size={20}
              onClick={() => {
                setWaModal(false);
                // setHoldDeleteRecord(null);
              }}
            />
          </div>
          <Modal.Body>
            <BootstrapForm onSubmit={async (e) => {
              e.preventDefault();
            }}>
              <BootstrapForm.Group className="my-2">
                <BootstrapForm.Label>Recipients</BootstrapForm.Label>
                <div className="mb-3">
                  {mobiles.map((item, index) => {
                    return (
                      <EmailPill key={index} email={item} removeEmail={() => removeEmail(index)} />
                    );
                  })}
                </div>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Enter mobile and hit enter"
                  multiple
                  onChange={(e) => {
                    setMobileValue(e.target.value)
                  }}
                  value={emailValue}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      const y = e.target.value;
                      if (validateMobile(e.target.value)) {
                        setMobiles([...mobiles, e.target.value]);
                        setMobileValue("");
                      }
                    }
                  }}
                  pattern="^[0-9]*$"
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group className="my-2">
                <BootstrapForm.Label>Subject</BootstrapForm.Label>
                <BootstrapForm.Control placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
              </BootstrapForm.Group>
              <BootstrapForm.Group className="my-2">
                <BootstrapForm.Label>Message</BootstrapForm.Label>
                <BootstrapForm.Control placeholder="Message" value={message} as="textarea" rows={5} onChange={(e) => setMessage(e.target.value)} />
              </BootstrapForm.Group>

              {/* <Viewer plugins={[thumbnailPluginInstance]} />; */}
              <BootstrapForm.Group className="my-2">
                <BootstrapForm.Label>Attachments</BootstrapForm.Label>
                <div className="d-flex mb-2 flex-row">
                  {attachments.map((item, index) => {
                    return (
                      <Attachment key={index} attachment={item} removeAttachment={() => removeAttachment(index)} />
                    )
                  })}
                </div>
              </BootstrapForm.Group>
              <BootstrapForm.Group className="my-2 text-center">
                <Button type="button" onClick={async (e) => {
                  if (mobiles.length == 0) {
                    actions.notify("Please add recipients.", "danger");
                  } else if (subject == null || subject == "") {
                    actions.notify("Please add subject.", "danger");
                  } else if (message == null || message == "") {
                    actions.notify("Please add message", "danger");
                  } else {
                    const formData = new FormData();
                    formData.append("mobiles", mobiles);
                    // formData.append("subject", subject);
                    formData.append("message", message);

                    formData.append("attachments_title", attachments.map((item, index) => item.title));
                    // formData.append("attachments", attachments.map((item, index) => item.data));
                    // formData.append("attachments", attachments.map((item, index) => item.data));
                    for (let i = 0; i < attachments.length; i++) {
                      formData.append(attachments[i].title, new File([attachments[i].data], attachments[i].title, {
                        type: attachments[i].data.type,
                      }));
                    }
                    await actions.send_wa_msg(formData);
                  }
                }}>Send Message</Button>
              </BootstrapForm.Group>
            </BootstrapForm>
          </Modal.Body>
        </Modal>
        {currentId && (
          <PdfPreview
            visible={previewPdfVisible}
            setVisible={(value) => setPreviewPdfVisible(value)}
            pdfData={pdfData}
            downloadRecord={(e) => {
              downloadRecord(e, currentId, currentTemplateType);
            }}
          />
        )}
      </>
    );
  }
);

const styles = {
  tableContainer: {
    border: "1px solid #ccc",
  },
};

export default CustomTable;
