import React, { useState, useEffect, useRef } from "react";
import {
    Container,
    Form,
    Spinner,
    Button,
} from "react-bootstrap";
import * as actions from "actions/TenantAuthActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { customAction } from "actions/CommonActions";
import axios from "axios";

const Login = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [locations, setLocations] = useState([])
    const currentLocation = useSelector((state) => state.adminReducer.currentLocation);
    const setting = useSelector((state) => state.adminReducer.setting);
    const [multiLocation, setMultiLocation] = useState(false)

    const form = useRef(null);
    const dispatch = useDispatch();

    const fetchLocationData = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/client/${process.env.REACT_APP_TENANT}/user/location`)
        setLocations(response.data)
    }

    useEffect(() =>{
        if(process.env.REACT_APP_TENANT && setting){
            fetchLocationData()
            setMultiLocation(setting.location_enabled)

        }
    }, [process.env.REACT_APP_TENANT, setting])

    const onLogin = async (e) => {
        e.preventDefault();
        // let numbers = /^[0-9]+$/;
        // if (username.match(numbers)) {
        setIsSubmitting(true);
        const formData = new FormData(form.current);
        const client = formData.get("client");
        const response = await actions.loggingIn(formData, client);
        if (response) {
            dispatch(actions.login(response));
            dispatch(actions.setClient(client));
            setIsSubmitting(false);
        } else {
            setIsSubmitting(false);
        }
        // window.location.reload(false)
        // } else {
        //     toast.error("Please enter a valid phone number!");
        // }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            onLogin(e);
        }
    };

    return (
        <div className="mt-5 pt-5">
            <Container className="shadow mt-5 p-5 bg-white" style={{ width: 500 }}>
                <Form
                    ref={form}
                    className="text-start mx-3"
                    onSubmit={(e) => onLogin(e)}
                >
                    <Form.Group className="mb-3" style={{ visibility: `${process.env.REACT_APP_TENANT != undefined ? 'hidden' : 'visible'}`, height: `${process.env.REACT_APP_TENANT != undefined ? 0 : ""}`}}>
                        <Form.Label>Client Code</Form.Label>
                        <Form.Control name="client" type="text" defaultValue={process.env.REACT_APP_TENANT != undefined ? process.env.REACT_APP_TENANT : ""} placeholder="Enter your Client Code" required />
                    </Form.Group> 
                    {process.env.REACT_APP_LOCATION_SET === "true" && multiLocation ? <Form.Group className="mb-3">
                        <Form.Label>Location</Form.Label>
                        <Form.Select name="location" type="select" onClick={(e) => dispatch({type: "SET_LOCATION", payload: e.target.value})}>
                            <option value="">Select Location</option>
                            {locations && locations?.map((location) => (
                                <option key={location.id} value={location.id}>{location.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group> : ""}
                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control name="username" type="text" placeholder="Enter your Username" required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control name="password" type="password" placeholder="Enter your Password" required />
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                        <Button type="submit">
                            {isSubmitting ? (
                                <span>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    LOGGING IN ...
                                </span>
                            ) : (
                                <span className="poppins">LOGIN</span>
                            )}
                        </Button>
                    </div>
                </Form>
            </Container>
        </div>
    );
};

export default Login;
