import React, { useRef, useState } from "react";
import { FullPageTable } from "components";
import { Form, Button } from "react-bootstrap";
import * as actions from "actions/CommonActions";

const InventoryVendorSpecialRate = () => {
    const form = useRef(null);
    const [submitType, setSubmitType] = useState("");

    const submitRates = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        let info = [];
        for (let i of formData.keys()) {
            const value = formData.get(i);
            console.log(i);
            console.log(value);
            if (i.startsWith("comment_")) {
                let id = i.substring(8, i.length);
                if (!isNaN(parseFloat(value))) {
                    info.push({ id: id, agreed_rate: formData.get(`comment_${id}`), delivery_schedule: formData.get(`schedule_${id}`)});
                }
            }
        }
        const response = await actions.customAction(`/master/vendoritemmapping/${formData.get("data[vendor]")}/`, "", "Vendor Special Rates", "update", info);
        if (response.status == 201) {
            form.current.updateTable();
        }
    }

    return (
        <Form ref={form} onSubmit={(e) => submitRates(e)}>
            <div>
                <div className="d-flex justify-content-end">
                    <Button
                        type="submit"
                    >
                        Save Rates
                    </Button>
                </div>
                <FullPageTable
                    form="https://nca.margytech.in/api/form/363"
                    searchForm="https://nca.margytech.in/api/form/712"
                    url="/master/vendoritemmapping/"
                    baseSearchParam=""
                    tableName="vendoritemmapping"
                    tableTitle="Vendor Special Rates"
                    createFormButtonText=""
                    formTitle="Vendor Item Mapping"
                    modalFullscreen={true}
                />
            </div>
        </Form>
    )
}

export default InventoryVendorSpecialRate;