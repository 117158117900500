import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { FullPageTable } from "components";
import { Link } from "react-router-dom";

const InventoryReturnToVendorMain = () => {
    return (
        <>
            <div className="d-flex justify-content-end align-items-end mx-4 py-2">
                <Button as={Link} to={`/Inventory/stock/returntovendorform/`}>Create Vendor Return</Button>
            </div>
            <FullPageTable
                form="https://nca.margytech.in/api/form/473"
                searchForm="https://nca.margytech.in/api/form/696"
                url="/purchaseorder/vendorreturn/"
                baseSearchParam=""
                // baseSearchParam="?department__department_code=Inventory"
                tableName="vendorreturn"
                tableTitle="Return to Vendor"
                formTitle="Return to Vendor"
                modalFullscreen={true}
            />
        </>
    );
};

export default InventoryReturnToVendorMain;
