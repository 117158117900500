import React, { useRef, useState } from "react";
import { CustomPage } from "components";
import * as actions from "actions/CommonActions";
import CustomListDropDown from "./Utils/CustomListDropdown";
import { Button, Col, Container, Form, Row, Form as BootstrapForm } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";

const PharmacyPOGeneration = () => {
  const { poId } = useParams();
  const [poItems, setPoItems] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const form = useRef(null);
  const navigate = useNavigate();

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        padding: "15px",
        border: "1px solid #eee",
        color: "#fff",
        borderBottom: "1px solid #999",
        backgroundColor: "#006699",
      },
    },
    cells: {
      style: {
        borderLeft: "1px solid #eee",
        borderRight: "1px solid #eee",
        minHeight: "50px",
      },
    },
  };

  const columns = [
    {
      name: "Item Code",
      selector: row => row.item_data?.code
    },
    {
      name: "Item Name",
      selector: row => row.item_data?.name
    },
    {
      name: "Vendor Name",
      selector: row => row.vendor_data?.name
    },
    {
      name: "VAT",
      selector: row => row.item_data?.vat
    },
    {
      name: "GST",
      selector: row => row.item_data?.gst
    },
    {
      name: "PO Rate",
      selector: null,
      cell: (row) => <BootstrapForm.Group>
        <BootstrapForm.Control
          name={`po_rate_${row.id}`}
          size="sm"
          id={row.id}
          defaultValue={row.agreed_rate}
        />
      </BootstrapForm.Group>,
      button: true,
    },
    {
      name: "Master Rate",
      selector: row => row.item_data?.patient_rate
    },
    {
      name: "Quantity",
      selector: null,
      cell: (row) => <BootstrapForm.Group>
        <BootstrapForm.Control
          name={`quantity_${row.id}`}
          size="sm"
          id={row.id}
        />
      </BootstrapForm.Group>,
      button: true,
    }
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(form.current);
    formData.append("department", "PHARMACY");
    let object = {};
    formData.forEach((value, key) => object[key] = value);
    let vendor_items = [];
    for (let i of formData.keys()) {
      const value = formData.get(i);
      if (i.startsWith("quantity_")) {
        let id = i.substring(9, i.length);
        console.log(id)
        if (!isNaN(parseFloat(value))) {
          const total_price = value * formData.get("po_rate_" + id)
          vendor_items.push({ id: id, quantity: value, po_rate: formData.get("po_rate_" + id), total_price: total_price});
        }
      }
    }
    object["vendor_items"] = vendor_items;
    // let json = JSON.stringify(object);
    const response = await actions.customAction("/purchaseorder/purchaseorder/", "", "Purchase Order", "create", object);
    if (response) {
      navigate(`/pharmacy/order/generation/`)
    }
  }

  return (
    <>
      <Form ref={form} onSubmit={(e) => handleSubmit(e)}>
        <Container className="card-form">
          <h1>Purchase Order</h1>
          <Row className="mb-3 mt-4">
            <Col sm="4">
              <Form.Group>
                <Form.Label>Select Item</Form.Label>
                <CustomListDropDown poItems={poItems} setPoItems={setPoItems} isOpen={isOpen} setIsOpen={setIsOpen} />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <Form.Label>GST Type</Form.Label>
                <Form.Select
                  name="gst_type"
                  aria-label="Default select example"
                >
                  <option>Select GST</option>
                  <option value="CGST">CGST</option>
                  <option value="IGST">IGST</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <Form.Label>PO Number</Form.Label>
                <Form.Control
                  name="po_number"
                  type="text"
                  placeholder="PO Number"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <Form.Group>
                <Form.Label>Reference Number</Form.Label>
                <Form.Control name="reference_number" type="text" />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <Form.Label>Reference Date</Form.Label>
                <Form.Control name="reference_date" type="date" />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <Form.Label>Subject</Form.Label>
                <Form.Control name="subject" type="textarea" />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5">
          <DataTable data={poItems} columns={columns} customStyles={customStyles} />
        </Container>
        <div className="d-flex justify-content-center align-items-center mt-5">
        <Button type="submit">Generate PO</Button>
        </div>
      </Form>
    </>
  );
};

export default PharmacyPOGeneration;
