import React, { useState, useRef, useEffect } from "react";
import { Container, Breadcrumb, Form, Alert } from "react-bootstrap";
import { Outlet, useLocation, Link } from 'react-router-dom';
import { IoHome } from "react-icons/io5";
import * as actions from "actions/CommonActions"
import { useSelector, useDispatch } from "react-redux";
import { user } from "assets";
import axios from "axios";

const NavBreadcrumbBar = () => {
    const location = useLocation();
    const [multiLocation, setMultiLocation] = useState(false)
    const [locationData, setLocationData] = useState([])
    // const [selectedLocation, setSelectedLocation] = useState("")
    const setting = useSelector((state) => state.adminReducer.setting);
    const currentLocation = useSelector((state) => state.adminReducer.currentLocation);
    const userData = useSelector((state) => state.authReducer.user)
    const dispatch = useDispatch()

    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";
    const param2 = params.length > 1 ? params[1] : "";
    const param3 = params.length > 2 ? params[2] : "";


    const fetchLocationData = async () => {
        // const response = await actions.customAction('/user/location/', "", 'Location', 'loadMany', null)
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/client/${process.env.REACT_APP_TENANT}/user/location`)
        if (response) {
            setLocationData(response.data)
        }
    }

    useEffect(() => {
        // alert('hello')
        if(setting){
            setMultiLocation(setting.location_enabled)
        }
        if(userData)
            if (userData.is_admin) {
                fetchLocationData();
            } else {
                setLocationData(userData.locations_data)
            }
    }, [userData, setting])

    console.log(param1);
    if (param1 == "admin") {
        return (
            <Container
                fluid
                className="p-0 mt-5 pt-4"
            >
                <Breadcrumb>
                    <Breadcrumb.Item href="/"><span className="px-2"><IoHome /></span>Home</Breadcrumb.Item>
                    <Breadcrumb.Item
                        href={`/${param1}`}
                    >
                        {param1.charAt(0).toUpperCase() + param1.slice(1)}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        active
                    >
                        {param2 ? param2.charAt(0).toUpperCase() + param2.slice(1) : "Dashboard"}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Container>
        );
    }

    return (
        <Container
            fluid
            className={`${param1 && param1 !== "admin" && param1 !== "superadmin" ? "" : "d-none"} p-0`}
        >
            {!param3 && <Breadcrumb>
                <Breadcrumb.Item href="/"><span className="px-2"><IoHome /></span>Home</Breadcrumb.Item>
                <Breadcrumb.Item
                    href={`/${param1}`}
                >
                    {param1.charAt(0).toUpperCase() + param1.slice(1)}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    active
                >
                    {param2 ? param2.charAt(0).toUpperCase() + param2.slice(1) : "Dashboard"}
                </Breadcrumb.Item>
                {multiLocation && process.env.REACT_APP_LOCATION_SET && <Form className="location-dropdown">
                    <Form.Group>
                    <Form.Select name="location" onClick={(e) => {
                            dispatch({ type: 'SET_LOCATION', payload: e.target.value })
                            }
                        }>
                            {userData.is_admin && <option value="">Master Application</option>}
                            {locationData?.map((item) => (
                                <option value={item.id}>{item.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Form>}
            </Breadcrumb>}
            {param3 && <Breadcrumb>
                <Breadcrumb.Item href="/"><span className="px-2"><IoHome /></span>Home</Breadcrumb.Item>
                <Breadcrumb.Item
                    href={`/${param1}`}
                >
                    {param1.charAt(0).toUpperCase() + param1.slice(1)}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    href={`/${param1}/${param2}`}
                >
                    {param2 ? param2.charAt(0).toUpperCase() + param2.slice(1) : "Dashboard"}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    active
                >
                    {param3.charAt(0).toUpperCase() + param3.slice(1)}
                </Breadcrumb.Item>
                {multiLocation && process.env.REACT_APP_LOCATION_SET && <Form className="location-dropdown">
                    <Form.Group>
                        <Form.Select name="location" onClick={(e) => {
                            dispatch({ type: 'SET_LOCATION', payload: e.target.value })
                            }
                        }>
                            {userData.is_admin && <option value="">Master Application</option>}
                            {locationData?.map((item) => (
                                <option value={item.id}>{item.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Form>}
            </Breadcrumb>}
        </Container>
    );
}

export default NavBreadcrumbBar;