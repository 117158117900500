import React from "react";
import { FullPageTable } from "components";

const HisMedicalItemExpiry = (params) => {

    return (
        <FullPageTable
        searchForm="https://nca.margytech.in/api/form/700"
        url = "/purchaseorder/stock/"
        tableName="pharmacyitemexpiry"
        tableTitle="Item Expiry"
        baseSearchParam="?departments_medical=OPD,IPD,OT,Emergency,Lab"
    />
    )
}

export default HisMedicalItemExpiry;