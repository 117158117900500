import React from "react";
import { CustomPage } from "components";

const CashCounterMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/126"
            searchForm="https://nca.margytech.in/api/form/565"
            url="/master/cashcounter/"
            baseSearchParam=""
            tableName="cashcounter"
            tableTitle="Cash Counter"
            createFormButtonText="Add New Cash Counter"
            formTitle="Cash Counter Master"
            modalFullscreen={false}
            addPermissions={['his:frontdesk:cashcounter:add']}
        />
    )
}

export default CashCounterMaster;