import React from "react";
import { CustomPage } from "components";

const PharmacyItemMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/335"
            // form="https://nca.margytech.in/api/form/363"
            searchForm="https://nca.margytech.in/api/form/544"
            url="/master/item/"
            baseSearchParam="?item_type__type_description=MEDICAL"
            tableName="item"
            tableTitle="Item"
            createFormButtonText="Add New Item"
            formTitle="Item Master"
            modalFullscreen={true}
        />
    )
}

export default PharmacyItemMaster;