import React from "react";
import { FullPageTable } from "components";

const PharmacyStockSummary = (params) => {

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/694"
            url = "/purchaseorder/stock/"
            tableName="pharmacystock"
            tableTitle="Stock Summary"
            baseSearchParam="?departments_medical=Pharmacy"
        />
    )
}

export default PharmacyStockSummary;