import React from "react";
import { FullPageTable } from "components";

const InventoryHoAccountsApproval = (params) => {

    return (
        <FullPageTable
            form="https://nca.margytech.in/api/form/358"
            searchForm="https://nca.margytech.in/api/form/701"
            url = "/workorder/workorder/"
            tableName="inventoryhoaccountsapproval"
            tableTitle="hoaccountsapproval"
            formTitle="Approve Work Order"
            modalFullscreen={true}
            baseSearchParam="?status=HO_MANAGER_APPROVAL"
        />
    )
}

export default InventoryHoAccountsApproval;