import React from "react";
import { CustomPage } from "components";

const InventoryItemMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/335"
            searchForm="https://nca.margytech.in/api/form/544"
            url="/master/item/"
            baseSearchParam="?item_type__type_description=NON-MEDICAL"
            tableName="item"
            tableTitle="Inventory Item"
            createFormButtonText="Add New Item"
            formTitle="Item Master"
            modalFullscreen={true}
            addPermissions={['inventory:master:item:add']}

        />
    )
}

export default InventoryItemMaster;