
import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Form as BootstrapForm, Accordion } from "react-bootstrap";
import { useParams, useLocation, Link } from "react-router-dom";
import { FullPageForm, ResultEntryTable } from "components";
import * as actions from "actions/CommonActions";
import { AiOutlineClose } from "react-icons/ai";

const ResultEntryTest = () => {
    const { id, opd, opdipd, status } = useParams();
    const [data, setData] = useState(null);
    const [testSample, setTestSample] = useState([]);
    const [barcodes, setBarcodes] = useState([]);
    const [commentTests, setCommentTests] = useState([]);
    const [modal, setModal] = useState(false);
    const [currentComment, setCurrentComment] = useState("");
    const customTableRef = useRef()
    const form = useRef(null);
    const commentForm = useRef(null);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    console.log("Params", params);
    const param1 = params.length > 0 ? params[0] : "";
    const param2 = params.length > 0 ? params[1] : "";
    const param3 = params.length > 0 ? params[2] : "";
    const param4 = params.length > 0 ? params[3] : "";

    const fetchOpdIpdData = async (searchParam = "") => {
        if (opdipd === "opd") {
            const response = await actions.customAction("/frontdesk/opdadmission/" + opd + "/", "", "Patient", "load", null)
            if (response) {
                setData(response)
                const sampleTestProfiles = await actions.customAction("/samplemanagement/sampletestprofile/", "?opd__id=" + response.id + "&test__id=" + id + "&status=RESULT&lab_status=INLAB" + searchParam, "Test Sample", "load", null)
                if (sampleTestProfiles) {
                    setTestSample(sampleTestProfiles);
                    console.log(sampleTestProfiles);
                }
            }
        } else if (opdipd === "ipd") {
            const response = await actions.customAction("/frontdesk/ipdadmission/" + opd + "/", "", "Patient", "load", null)
            if (response) {
                setData(response)
                const sampleTestProfiles = await actions.customAction("/samplemanagement/sampletestprofile/", "?ipd__id=" + response.id + "&test__id=" + id + "&status=RESULT&lab_status=INLAB" + searchParam, "Test Sample", "loadMany", null)
                if (sampleTestProfiles) {
                    setTestSample(sampleTestProfiles);
                    console.log(sampleTestProfiles);
                }
            }
        } else {
            const response = await actions.customAction("/frontdesk/edadmission/" + opd + "/", "", "Patient", "load", null)
            if (response) {
                setData(response)
                const sampleTestProfiles = await actions.customAction("/samplemanagement/sampletestprofile/", "?ed__id=" + response.id + "&test__id=" + id + "&status=RESULT&lab_status=INLAB" + searchParam, "Test Sample", "loadMany", null)
                if (sampleTestProfiles) {
                    setTestSample(sampleTestProfiles);
                    console.log(sampleTestProfiles);
                }
            }
        }
    }

    const fetchBarcodes = () => {
        let unique_barcodes = [];
        for (let i = 0; i < testSample.length; i++) {
            if (!unique_barcodes.includes(testSample[i].sample_data.barcode)) {
                unique_barcodes.push(testSample[i].sample_data.barcode);
            }
        }
        setBarcodes(unique_barcodes);
    }

    const fecthUniqueTestProfile = () => {
        let unique_test = [];
        let unique_profile_id = [];
        for (let i = 0; i < testSample.length; i++) {
            if (testSample[i].profile) {
                if (!unique_profile_id.includes(testSample[i].profile_data.id)) {
                    unique_profile_id.push(testSample[i].profile_data.id);
                    unique_test.push(testSample[i]);
                }
            } else {
                unique_test.push(testSample[i]);
            }
        }
        setCommentTests(unique_test);
    }

    useEffect(() => {
        fetchBarcodes();
        fecthUniqueTestProfile();
    }, [testSample])

    useEffect(() => {
        if (opd && status) {
            if (status === "UPDATE") {
                fetchOpdIpdData("&inlab_status=CREATED");
            } else if (status === "AUTHORIZE") {
                fetchOpdIpdData("&inlab_status=UPDATED");
            } else if (status === "APPROVE") {
                fetchOpdIpdData("&inlab_status=AUTHORIZED");
            }
        }
    }, [opd, status]);

    const handleSampleValueUpdate = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        let data = {};
        data["status"] = status;
        let info = [];
        for (var value of formData.keys()) {
            if (formData.get(value) === "on") {
                let this_id = value.substring(7, value.length);
                info.push(
                    {
                        id: this_id,
                        less_than_greater_than: formData.get("less_than_greater_than_" + this_id),
                        test_value: formData.get("test_value_" + this_id),
                        ref_range: formData.get("ref_range_" + this_id),
                    }
                );
            }
            data["sample_test_profile"] = info;
        }
        const response = await actions.customAction("/samplemanagement/sampletestprofile/updateresults/", "", "Update Results", "create", data);
        customTableRef.current.updateTable();
    };

    const handleCommentSave = async (e) => {
        e.preventDefault();
        const formData = new FormData(commentForm.current);
        const response = await actions.customAction("/samplemanagement/sampletestprofile/updatecomment/", "", "Comments", "create", formData);
        if (response) {
            setModal(false);
            customTableRef.current.updateTable();
        }
    };

    console.log(testSample);
    console.log(data);

    return (
        <>
            <Accordion className="mx-5" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <span>Patient Name: <b>{data?.patient_name} </b></span>
                        <span>/<b>{data?.patient_data?.age} {data?.patient_data?.age_type} </b></span>
                        <span>/<b>{data?.patient_data?.gender} </b></span>
                        <span className="ms-5">Referred By: <b>{data?.referred_doctor_data?.name} </b></span>
                        <span className="ms-5">Barcodes:
                            <b>
                                {barcodes.map((barcode, index) => {
                                    if (index === 0) {
                                        return " " + barcode;
                                    } else {
                                        return ", " + barcode;
                                    }
                                })}
                            </b>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body className="d-flex">
                        <div className="d-flex">
                            <BootstrapForm.Group>
                                <BootstrapForm.Control
                                    as="textarea"
                                    name="instructions_to_lab"
                                    rows={3}
                                    defaultValue={data?.instructions_to_lab}
                                    disabled
                                />
                            </BootstrapForm.Group>
                            <BootstrapForm.Group className="mx-4">
                                <BootstrapForm.Control
                                    as="textarea"
                                    name="patient_history"
                                    rows={3}
                                    defaultValue={data?.patient_history}
                                    disabled
                                />
                            </BootstrapForm.Group>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <Button
                                className="mx-2"
                                size="sm"
                                onClick={() => setModal(true)}
                            >
                                Add Comments
                            </Button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <BootstrapForm ref={form} onSubmit={(e) => handleSampleValueUpdate(e)}>
                {testSample && <ResultEntryTable
                    ref={customTableRef}
                    data={testSample}
                    opdipd={opdipd}
                />}
                <div className={`${status === "UPDATE" ? "d-flex justify-content-center" : "d-none"}`}>
                    <Button type="submit" className="mx-2">
                        Update Entries
                    </Button>
                    <Button as={Link} to={`/${param1}/${param2}/${param3}/${param4}/${opdipd}/${opd}/${id}/AUTHORIZE/`}>
                        Proceed to Authorize
                    </Button>
                </div>
                <div className={`${status === "AUTHORIZE" ? "d-flex justify-content-center" : "d-none"}`}>
                    <Button type="submit" className="mx-2">
                        Authorize Entries
                    </Button>
                    <Button as={Link} to={`/${param1}/${param2}/${param3}/${param4}/${opdipd}/${opd}/${id}/APPROVE/`}>
                        Proceed to Approve
                    </Button>
                </div>
                <div className={`${status === "APPROVE" ? "d-flex justify-content-center" : "d-none"}`}>
                    <Button type="submit">
                        Approve
                    </Button>
                </div>
            </BootstrapForm>
            <Modal
                show={modal}
                onHide={() => {
                    setModal(false);
                    setCurrentComment("");
                }}
                centered
                onExited={() => {
                    setModal(false);
                    setCurrentComment("");
                }}
                size="lg"
            >
                <Modal.Header className="d-flex flex-row justify-content-center">
                    <h2>Add Comment</h2>
                </Modal.Header>
                <div className="removeButtonRight position-absolute cursor-pointer">
                    <AiOutlineClose
                        size={20}
                        onClick={() => {
                            setModal(false)
                            setCurrentComment("");
                        }} />
                </div>
                <Modal.Body>
                    <BootstrapForm ref={commentForm} onSubmit={(e) => handleCommentSave(e)}>
                        <BootstrapForm.Group className="my-3">
                            <BootstrapForm.Label>Test/Profile</BootstrapForm.Label>
                            {/* <BootstrapForm.Select name="id" onChange={(e) => setCurrentSample(e.target.value)}> */}
                            <BootstrapForm.Select name="id" onChange={(e) => setCurrentComment(commentTests.filter((commentTest) => commentTest.id === e.target.value)[0]?.comment)}>
                                <option value="">SELECT</option>
                                {commentTests.map((commentTest) => {
                                    return <option value={commentTest?.id}>{commentTest?.profile ? commentTest?.profile_data.name : commentTest?.test_data.name}</option>
                                })}
                            </BootstrapForm.Select>
                        </BootstrapForm.Group>
                        <BootstrapForm.Group>
                            <BootstrapForm.Label>Comment</BootstrapForm.Label>
                            <BootstrapForm.Control
                                as="textarea"
                                name="comment"
                                rows={4}
                                defaultValue={currentComment}
                            />
                        </BootstrapForm.Group>
                        <div className="d-flex justify-content-center align-items-center">
                            <Button type="submit" className="mt-3">
                                Add Comment
                            </Button>
                        </div>
                    </BootstrapForm>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ResultEntryTest;