import React from "react";
import { CustomPage } from "components";

const FormulaMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/607"
            searchForm="https://nca.margytech.in/api/form/677"
            url="/master/formula/"
            baseSearchParam=""
            tableName="formula"
            tableTitle="Formula"
            createFormButtonText="Add Formula"
            formTitle="Formula Master"
            modalFullscreen={true}
            addPermissions={['lis:master:formula:add']}
        />
    )
}

export default FormulaMaster;