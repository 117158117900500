import React, { useState, useRef, useEffect } from "react";
import { Container, Card, Form, Row, Button } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import * as actions from "actions/PublicAuthActions";
import { useDispatch, useSelector } from "react-redux";

const TenantInfo = () => {
    const { tenantId } = useParams();
    const dispatch = useDispatch();

    const tenants = useSelector((state) => state.publicAuthReducer.tenants)
    const tenant = tenants.filter((tenant) => tenant.id === tenantId);

    useEffect(() => {
        dispatch(actions.loadProducts());
        dispatch(actions.loadModules());
        dispatch(actions.loadTenantProducts(tenant[0]?.schema_name));
        dispatch(actions.loadTenantModules(tenant[0]?.schema_name));
    }, []);

    const products = useSelector((state) => state.publicAuthReducer.products);
    const modules = useSelector((state) => state.publicAuthReducer.modules);
    const tenantProducts = useSelector((state) => state.publicAuthReducer.tenantproducts);
    const tenantModules = useSelector((state) => state.publicAuthReducer.tenantmodules);

    let tenantProductsList = [];
    tenantProducts.map((tenantProduct) => {
        tenantProductsList.push(tenantProduct.product_data.title);
    })
    let tenantModulesList = [];
    tenantModules.map((tenantModule) => {
        tenantModulesList.push(tenantModule.module_data.title);
    })

    return (
        <div className="pt-5">
            <Container fluid className="mt-5">
                <h3>Tenant Info</h3>
                <div>Tenant Name: <b>{tenant[0]?.name}</b></div>
                <div>Client Code: <b>{tenant[0]?.schema_name}</b></div>
                {products?.map((product) => {
                    return (
                        <Card fluid className="shadow my-5 p-3">
                            <h5>{product?.title}</h5>

                            <Row className="row-cols-4">
                                {modules?.filter((module) => (module.code & product.code) === product.code).map((module, index) => {
                                    return (
                                        <Form.Group className="">
                                            <Form.Check
                                                defaultChecked={tenantProductsList.includes(product?.title) && tenantModulesList.includes(module?.title)}
                                                type="checkbox"
                                                label={module.title} />
                                        </Form.Group>
                                    );
                                })}
                            </Row>
                        </Card>
                    )
                })}
                <div className="d-flex justify-content-center my-5">
                    <Button className="w-25">SAVE</Button>
                </div>
            </Container>
        </div>
    );
}

export default TenantInfo;