import React from "react";
import { FullPageTable } from "components";

const CashCollectionSummary = (params) => {

    return (
        <FullPageTable
            // form="https://nca.margytech.in/api/form/578"
            searchForm="https://nca.margytech.in/api/form/662"
            url="/frontdesk/opdadmission/opdtransaction/"
            tableName="cashcollectionsummary"
            // baseSearchParam="?admission_type=OPD_ADMISSION"
        />
    )
}

export default CashCollectionSummary;