import React from "react";
import { FullPageTable } from "components";

const WorkOrderApproval = (params) => {
  return (
    <FullPageTable
      form="https://nca.margytech.in/api/form/358"
      searchForm="https://nca.margytech.in/api/form/701"
      url="/workorder/workorder/"
      tableName="workorderapproval"
      tableTitle="workorderapproval"
      formTitle="Approve Work Order"
      modalFullscreen={true}
      baseSearchParam="?status=NEW"
    />
  );
};

export default WorkOrderApproval;
