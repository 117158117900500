import React from "react";
import { CustomPage } from "components";

const SubDepartmentMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/102"
            searchForm="https://nca.margytech.in/api/form/561"
            url="/master/subdepartment/"
            baseSearchParam=""
            tableName="subdepartment"
            tableTitle="Sub Department"
            createFormButtonText="Add Sub Department"
            formTitle="Sub Department Master"
            modalFullscreen={false}
            addPermissions={['his:master:subdepartment:add']}
        />
    )
}

export default SubDepartmentMaster;