import axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { store, persistor } from "store";

const notify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    default:
      toast.error(msg);
  }
};

////////////////////////// USERS - Action Creator //////////////////////////

export const login = (data) => {
  return {
    type: "LOGIN",
    payload: data,
  };
};

export const setClient = (data) => {
    return {
      type: "SET_CLIENT",
      payload: data,
    };
  };

export const logout = () => {
  // alert();
  return {
    type: "LOGOUT",
    payload: null,
  };
};

////////////////////////// TEMPLATES - Action Creator //////////////////////////

export const currentTemplate = (type, data) => {
  switch(type) {
    case "ADMISSION_CARD":
      return {
        type: "CURRENT_ADMISSION_CARD_TEMPLATE",
        payload: data,
      };
    case "INVOICE":
      return {
        type: "CURRENT_INVOICE_TEMPLATE",
        payload: data,
      };
    case "REPORT":
      return {
        type: "CURRENT_REPORT_TEMPLATE",
        payload: data,
      };
  }
};


////////////////////////// USERS - Actions //////////////////////////

export const loggingIn = async (data, client) => {
    try {
        const response = await axios.post(`client/` + client + `/user/login/`, data, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        console.log("Response", response);
        if (response.status === 200) {
            return response.data;
        }
    } catch (err) {
        console.log("Error", err);
        notify("Error in login! Try again.", "error");
    }
};
  
export const loggingOut = (data) => {
    return async (dispatch) => {
        try {
        const response = await axios.post(`/user/logout/`, data, {
            headers: {
            "Content-Type": "application/json",
            },
        });
        console.log("Response for logout:", response);
        if (response.status === 204) {
            notify("You are successfully logged out.", "success");
            dispatch(logout());
        }
        } catch (err) {
        console.log("Error", err);
        dispatch(logout());
        persistor.purge();
        }
    };
};