import React, { useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { FullPageTable } from "components";
import * as actions from "actions/CommonActions";

const InventoryGoodsInward = (params) => {
  const form = useRef(null);

  const handlePoClose = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    let data = {};
    let info = [];
    for (var value of formData.keys()) {
      if (formData.get(value) === "on") {
        let id = value.substring(6, value.length);
        info.push({ id: id});
      }
      data["po_close"] = info;
    }
    const response = await actions.customAction("/purchaseorder/poclose/", "", "PO Close", "create", data);
  };

  return (
    <>
      <Form ref={form} onSubmit={(e) => handlePoClose(e)}>
        <div className="d-flex justify-content-end me-5">
          <Button type="submit">Close PO</Button>
        </div>
        <FullPageTable
          form="https://nca.margytech.in/api/form/451"
          searchForm="https://nca.margytech.in/api/form/692"
          url="/purchaseorder/purchaseorder/"
          baseSearchParam=""
          tableName="goodsinward"
          tableTitle="Goods Inward"
          formTitle="Goods Inward"
          modalFullscreen={true}
        />
      </Form>
    </>
  );
};

export default InventoryGoodsInward;
