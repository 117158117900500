import React from "react";
import { CustomPage } from "components";

const UserMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/149"
            searchForm="https://nca.margytech.in/api/form/670"
            url="/user/"
            baseSearchParam=""
            tableName="user"
            tableTitle="User"
            createFormButtonText="Add User"
            formTitle="User Master"
            modalFullscreen={false}
        />
    )
}

export default UserMaster;