import React from "react";
import { FullPageTable } from "components";
import { Outlet, useParams } from "react-router-dom";

const InventoryIndentSummary = (params) => {
    const { requestId } = useParams();

    if (requestId) {
        return <Outlet />;
    }

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/735"
            url = "/purchaseorder/materialrequest/"
            tableName="inventoryindentsummary"
            tableTitle="indent summary"
            baseSearchParam="?request_to_dept__department_code=INVENTORY&&status!=REQUESTED,REJECTED"
            // baseSearchParam="?request_to_dept__department_code=INVENTORY&material_request_status=APPROVED,PARTIALLY_DISPATCHED,DISPATCHED,PARTIALLY_DELIVERED"
        />
    )
}

export default InventoryIndentSummary;