import React from "react";
import { CustomPage } from "components";

const PatientDischarge = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/120"
            searchForm="https://nca.margytech.in/api/form/589"
            url="/nursing/patientdischarge/"
            baseSearchParam=""
            tableName="patientdischarge"
            tableTitle="Patient Discharge"
            createFormButtonText="Add New Discharge"
            formTitle="Patient Discharge"
            modalFullscreen={false}
            addPermissions={['his:inpatient:patientdischarge:add']}
        />
    )
}

export default PatientDischarge;