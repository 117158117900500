import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Nursing = () => {

    return (
        <Outlet />
    );
}

export default Nursing;