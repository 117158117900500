import { useEffect, useRef, useState } from "react"
import * as actions from "actions/CommonActions";
import { Accordion, Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import DataTable from "react-data-table-component";

const SpecialReportEntry =  () => {
    const { testprofileId, status } = useParams();
    const [test, setTest] = useState(null)
    const [testDeptId, setTestDeptId] = useState("")
    const [data, setData] = useState([]);
    const [specialReport, setSpecialReport] = useState([])
    const [customTemplate, setCustomTemplate] = useState([])
    const [currentSplReport, setCurrentSplReport] = useState("")
    const [currentReportTemplate, setCurrentReportTemplate] = useState("")
    const [submitDisable, setSubmitDisable] = useState(false)
    const form = useRef()
    const navigate = useNavigate();

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    console.log("Params", params);
    const param1 = params.length > 0 ? params[0] : "";
    const param2 = params.length > 0 ? params[1] : "";
    const param3 = params.length > 0 ? params[2] : "";
    const param4 = params.length > 0 ? params[3] : "";

    
    const fetchTestData = async (searchParam) => {
        const response = await actions.customAction('/samplemanagement/sampletestprofile/', `?id=${testprofileId}` + searchParam, "Test", "loadMany", null)
        if(response){
            setTest(response[0]);
        }
        console.log("TEST", response[0]);

    }
    
    const fetchSpecialReport = async () => {
      // if(test?.special_report_data){
      //   setSpecialReport(test?.special_report_data)
      // } else {
        const response = await actions.customAction('/master/specialreport/', `?report_department=${testDeptId}`, "Special Report", "loadMany", null)
        setSpecialReport(response)
      // }
    }

    const fetchCustomTemplate = async (filter) => {
      const response = await actions.customAction("/template/", `?department__type_description=${filter}`, "Report Templates", "loadMany", null)
      if (response){
        setCustomTemplate(response)
      }
    }
    
    
    useEffect(()=>{
      if(test){
        setTestDeptId(test?.test_data?.department)
      }
    }, [test])
    
    useEffect(()=> {
      if(testDeptId){
        fetchSpecialReport()
      }
    }, [testDeptId])
    
    const selectedReport = specialReport.find((splReport) => splReport.id === currentSplReport)
    useEffect(()=> {
      if(currentSplReport){
        if(selectedReport){
          setData(selectedReport?.special_report_mapping)
        }
      }
    }, [currentSplReport])
    
    useEffect(()=> {
      if(selectedReport?.report_department){
        fetchCustomTemplate(selectedReport?.report_department_data.type_description)
      }
    }, [currentSplReport])

    useEffect(() => {
      if(test?.special_report_fields.length > 0){
        setData(test?.special_report_fields)
      }
    }, [test])
    
    useEffect(() => {
      if (status, testprofileId) {
          if (status === "UPDATE") {
            fetchTestData("&special_report_status=CREATED");
          } else if (status === "AUTHORIZE") {
              fetchTestData("&special_report_status=UPDATED");
          } else if (status === "APPROVE") {
              fetchTestData("&special_report_status=AUTHORIZED");
          }
      }
  }, [status, testprofileId]);

    console.log("SPLReportMap", data)
    console.log("ID", testDeptId)
    console.log("SELECTEDREP", selectedReport)
    console.log("SPLREPORTID", currentSplReport) 
    console.log("ReportTEmplate", customTemplate) 
    console.log("SelectedReportTEmplate", currentReportTemplate) 

    // const createSpecialReportEntry = async (e) => {
    //   e.preventDefault();
    //   const formData = new FormData(form.current);
    //   const jsonObj = {};
      
    //   jsonObj.special_report_name = selectedReport?.report_type_name
    //   jsonObj.sample_testprofile_data = test;
    //   const response = await actions.customAction(`/samplemanagement/sampletestprofile/${testprofileId}/`, "", "Special Report Entry", "update", jsonObj);
    //   if (response) {
    //     navigate(-1);
    //   }
    // }

    const updateSpecialReportEntry = async (e) => {
      e.preventDefault();
      const jsonObj = {};
      jsonObj.id = test?.id
      jsonObj.status = status
      jsonObj.sample_testprofile_data = test;
      jsonObj.special_report = currentSplReport
      jsonObj.custom_template = currentReportTemplate
      const filtered_report_fields = data.map((item) => {
        return {
          id: item.id,
          item_type: item.item_type,
          observation: item.observation,
          visibility: item.visibility,
        }
      })
      const formData = new FormData(form.current)
      formData.append('data', JSON.stringify(jsonObj))
      formData.append('report_fields', JSON.stringify(filtered_report_fields))
      const filtered_images = data?.map((item) => {
        if(item.image_special_report){
          return `image_${item.id}`
        }
      })
      console.log(filtered_images)
      for(let i=0; i < data?.length; i++){
        if(data[i]?.special_report_image){
          console.log(`image_${data[i].id}`)
          formData.append(`image_${data[i].id}`, data[i].special_report_image)
        }
      }
      console.log(formData)
      const response = await actions.customAction(`/samplemanagement/sampletestprofile/updatespecialentries/`, "", "New Entries", "file", formData)
      if (response) {
          setSubmitDisable(true)
        }
    }


    const customStyles = {
        rows: {
          style: {
            minHeight: "72px",
          },
        },
        headCells: {
          style: {
            fontWeight: "bold",
            padding: "15px",
            border: "1px solid #eee",
            color: "#fff",
            borderBottom: "1px solid #999",
            backgroundColor: "#006699",
          },
        },
        cells: {
          style: {
            borderLeft: "1px solid #eee",
            borderRight: "1px solid #eee",
            minHeight: "50px",
          },
        },
      };
    
      let columns = [
        {
          name: "Item Type",
          selector: null,
          cell: (row, index) => (
            <div className="p-1">
              <Form.Group>
                <Form.Control
                  name='item_type'
                  size="sm"
                  id={row.id}
                  readOnly
                  disabled={row.visibility === "HIDDEN" ? true : false}
                  defaultValue={data ? data[index]?.item_type : ""}
                  onChange={(e) => {
                    let newData = [...data];
                    // console.log("NEW_DATA_ITEM_TYPE",newData)
                    newData[index].item_type = e.target.value;
                    setData(newData)
                  }}
                />
              </Form.Group>
            </div>
          )
        },
        // {
        //   name: "Sequence",
        //   selector: null,
        //   cell: (row, index) => (
        //     <div className="p-1">
        //       <Form.Group>
        //         <Form.Control
        //           type="number"
        //           name='sequence'
        //           size="sm"
        //           id={row.id}
        //           defaultValue={data[index]?.sequence}
        //           onChange={(e) => {
        //             let newData = [...data];
        //             newData[index].sequence = e.target.value;
        //             setData(newData)
        //           }}
        //         />
        //       </Form.Group>
        //     </div>
        //   ),
          
        // },
        {
          name: "Observation",
          selector: null,
          cell: (row, index) => (
            <div className="p-1">
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name='observation'
                  size="sm"
                  id={row.id}
                  disabled={row.visibility === "HIDDEN" ? true : false}
                  defaultValue={data[index]?.observation}
                  onChange={(e) => {
                    let newData = [...data];
                    newData[index].observation = e.target.value;
                    setData(newData)
                  }}
                />
              </Form.Group>
            </div>
          ),
        },
        {
          name: "Image Upload",
          selector: null,
          cell: (row, index) => (
              <>
                {data[index]?.is_image_type ? <div className="p-1">
                  <input type="file" class="form-control" 
                  name="special_report_image" 
                  id="inputGroupFile01"
                  disabled={row.visibility === "HIDDEN" ? true : false}
                  // defaultValue={data[index]?.image_special_report}
                  onChange={(e) => {
                    let newData = [...data];
                    newData[index].special_report_image = e.target.files[0];
                    setData(newData)
                  }}/>
                </div> : data[index]?.image_special_report ? <p className="text-success">Image Uploaded!</p> : ""}
              </>
          )
        },
        {
          name: "Visibility",
          selector: null,
          cell: (row, index) => (
            <div className="p-1">
              <Form.Group>
                <Form.Select
                  name='visibility'
                  size="sm"
                  id={row.id}
                  disabled={row.visibility === "HIDDEN" ? true : false}
                  defaultValue={data[index]?.visibility}
                  onChange={(e) => {
                    let newData = [...data];
                    newData[index].visibility = e.target.value;
                    setData(newData)
                  }}
                >
                  <option value="VISIBLE">Visible</option>
                  <option selected={row.visibility === "HIDDEN" ? true : false} value="HIDDEN">Hidden</option>
                </Form.Select>
              </Form.Group>
            </div>
          ),
          
        },
        // {
        //   name: "Group Header",
        //   selector: null,
        //   cell: (row, index) => (
        //     <div className="p-1">
        //       <Form.Group>
        //         <Form.Control
        //           name='group_header'
        //           size="sm"
        //           id={row.id}Healthy
        //           defaultValue={data[index]?.group_header}
        //           onChange={(e) => {
        //             let newData = [...data];
        //             newData[index].group_header = e.target.value;
        //             setData(newData)
        //           }}
        //         />
        //       </Form.Group>
        //     </div>
        //   ),
          
        // },
        // {
        //   name: "Is Image type?",
        //   selector: null,
        //   cell: (row, index) => (
        //     <Form.Check
        //       name='is_image_type'
        //       size="sm"
        //       id={row.id}
        //       defaultChecked={data[index]?.is_image_type}
        //       onChange={(e) => {
        //         let newData = [...data];
        //         newData[index].is_image_type = e.target.value;
        //         setData(newData)
        //       }}
        //     />
        //   )
        // },
        // {
        //   name: "Pick Predefined Values?",
        //   selector: null,
        //   cell: (row, index) => (
        //     <Form.Check
        //       name='pick_predefined_values'
        //       size="sm"
        //       id={row.id}
        //       defaultChecked={data[index]?.pick_predefined_values}
        //       onChange={(e) => {
        //         let newData = [...data];
        //         newData[index].pick_predefined_values = e.target.value;
        //         setData(newData)
        //       }}
        //     />
        //   )
        // },
        // {
        //   name: "Isolates No.",
        //   selector: null,
        //   cell: (row, index) => (
        //     <Form.Group>
        //       <Form.Control
        //         name='isolates_no'
        //         size="sm"
        //         id={row.id}
        //         defaultValue={data[index]?.isolates_no}
        //         onChange={(e) => {
        //           let newData = [...data];
        //           newData[index].isolates_no = e.target.value;
        //           setData(newData)
        //         }}
        //       />
        //     </Form.Group>
        //   )
        // },
    ]
    
    return(
        <Container fluid>
            <Form ref={form} onSubmit={(e) => {
                updateSpecialReportEntry(e)
              }
            }>
                <Container className="card-form">
                    <Alert variant="secondary">
                      {/* <span className="mx-auto">Patient Name: {test?.patient_name}</span><span>Visit ID: {test?.opd_data.visit_id}</span><span>Barcode: {test?.sample_data.barcode}</span> */}
                      <Row className="mb-3 mt-3">
                        <Col className="sm-4">
                          <Form.Label>Patient Name</Form.Label>
                          <Form.Group>
                            <Form.Control type="text" defaultValue={test?.patient_name} readOnly/>
                          </Form.Group>
                        </Col>
                        <Col className="sm-4">
                          <Form.Label>Visit ID / Admission No</Form.Label>
                          <Form.Group>
                            <Form.Control type="text" defaultValue={test?.opd_data ? test?.opd_data.visit_id : test?.ipd_data ? test?.ipd_data.admission_no: test?.ed_data.admission_no} readOnly/>
                          </Form.Group>
                        </Col>
                        <Col className="sm-4">
                          <Form.Label>Barcode</Form.Label>
                          <Form.Group>
                            <Form.Control type="text" defaultValue={test?.sample_data.barcode} readOnly/>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Alert>
                    <div>
                      <h3 className="text-primary">Test Name: {test?.test_data.name}</h3>
                    </div>
                    <Row className="mb-3 mt-4">
                        <Col className="sm-6">
                        <Form.Label>Select Special Report</Form.Label>
                        <Form.Select name="report_type_name" disabled={status !== "UPDATE" ? true : false} onChange={(e) => {
                            setData([])
                            setCurrentSplReport(e.target.value)
                            }}>
                            <option value="" selected disabled>--Special Report Template--</option>
                            {specialReport?.map((item) => (<option value={item.id}>{item.report_type_name}</option>))}
                        </Form.Select>
                        </Col>
                        <Col className="sm-6">
                        <Form.Label>Select Reporting Template</Form.Label>
                        <Form.Select name="custom_template" disabled={status !== "UPDATE" ? true : false} onChange={(e) => {
                            setCurrentReportTemplate(e.target.value)
                            }}>
                            <option value="" selected disabled>--Special Report Template--</option>
                            {customTemplate?.map((item) => (<option key={item.id} value={item.id}>{item.template_type}</option>))}
                        </Form.Select>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-5">
                    {data && <DataTable data={data} columns={columns} customStyles={customStyles} />}
                </Container>
                <div className={`${status === "UPDATE" ? "d-flex justify-content-center" : "d-none"}`}>
                    <Button type="submit" disabled={submitDisable} className="mx-2">
                        Update Entries
                    </Button>
                    <Button as={Link} to={`/${param1}/${param2}/${param3}/${testprofileId}/AUTHORIZE/`}>
                        Proceed to Authorize
                    </Button>
                </div>
                <div className={`${status === "AUTHORIZE" ? "d-flex justify-content-center" : "d-none"}`}>
                    <Button type="submit" className="mx-2">
                        Authorize Entries
                    </Button>
                    <Button as={Link} to={`/${param1}/${param2}/${param3}/${testprofileId}/APPROVE/`}>
                        Proceed to Approve
                    </Button>
                </div>
                <div className={`${status === "APPROVE" ? "d-flex justify-content-center" : "d-none"}`}>
                    <Button type="submit">
                        Approve
                    </Button>
                </div>
                {/* <div className="d-flex flex-row-reverse">
                    <Button type="submit">Save Results</Button>
                </div>
                {test?.special_report_status === "CREATED" ? <div className="mb-3 mt-3 d-flex justify-content-center">
                 <Button name="authorize" type="submit">Submit For Authorize</Button>
                </div> 
                : test?.special_report_status === "AUTHORIZE" ? <div className="mb-3 mt-3 d-flex justify-content-center">
                <Button name="approve" type="submit">Submit For Approval</Button>
               </div>
                : test?.special_report_status === "APPROVE" ? <div className="mb-3 mt-3 d-flex justify-content-center">
                <Button name="report" type="submit">Generate Report</Button>
               </div> : ''} */}
            </Form>
        </Container>
    )
}

export default SpecialReportEntry