import React from "react";
import { CustomPage } from "components";

const PharmacyVendorMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/341"
            searchForm="https://nca.margytech.in/api/form/548"
            url="/master/vendor/"
            baseSearchParam="?vendor_type=MEDICAL"
            tableName="vendor"
            tableTitle="Vendor"
            createFormButtonText="Add New Vendor"
            formTitle="Vendor Master"
            modalFullscreen={true}
        />
    )
}

export default PharmacyVendorMaster;