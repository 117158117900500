import React from "react";
import { FullPageTable } from "components";

const NursingConsole = (params) => {

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/115"
            url="/frontdesk/ipdadmission/"
            tableName="nursingconsole"
            tableTitle="Patient Info"
            formTitle=""
            modalFullscreen={true}
            baseSearchParam="?status=CONFIRMED"
        />
    )
}

export default NursingConsole;