import React from "react";
import { CustomPage } from "components";
import { useParams, Outlet } from "react-router-dom";

const Table = () => {
    const { tableId } = useParams();

    if (tableId) {
        return (
            <Outlet />
        );
    }

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/675"
            searchForm="https://nca.margytech.in/api/form/674"
            url="/table/"
            baseSearchParam=""
            tableName="table"
            tableTitle="Table"
            createFormButtonText="Add Table"
            formTitle="Table Master"
            modalFullscreen={false}
        />
    )
}

export default Table;