import React, { useState, useRef, useEffect } from "react";
import { Container, Card, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as actions from "actions/PublicAuthActions";
import { useDispatch, useSelector } from "react-redux";


const Product = (props) => {
    const { client } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadTenantProducts(client));
        dispatch(actions.loadTenantModules(client));
    }, []);

    const tenantProducts = useSelector((state) => state.publicAuthReducer.tenantproducts);
    const tenantModules = useSelector((state) => state.publicAuthReducer.tenantmodules);

    if (tenantProducts?.length === 1) {
        navigate(`/${tenantProducts[0].product_data?.route}`)
    }

    return (
        <Container fluid className="mt-5 pt-5">
            <Row className="row-cols-3">
                {tenantProducts?.map((tenantproduct) => {
                    return (
                        <Card
                            as={Link}
                            to={`/${tenantproduct?.product_data?.route}`}
                            className="mx-5 my-3 d-flex justify-content-center align-items-center cursor-pointer shadow"
                            key={tenantproduct.id}
                            style={styles.cardShadow}
                        >
                            {tenantproduct?.product_data?.title}
                        </Card>
                    );
                })}
            </Row>
        </Container>
    );
}

const styles = {
    cardShadow: {
        width: "250px",
        height: "250px",
        textDecoration: "none",
    }
}

export default Product;