import React from "react";
import { CustomPage } from "components";

const CabinSchedule = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/70"
            searchForm="https://nca.margytech.in/api/form/584"
            url="/frontdesk/cabinschedule/"
            baseSearchParam=""
            tableName="cabinschedule"
            tableTitle="Cabin Schedule"
            createFormButtonText="Add New Cabin Schedule"
            formTitle="Cabin Schedule"
            modalFullscreen={false}
            addPermissions={['his:frontdesk:cabinschedule:add']}
        />
    )
}

export default CabinSchedule;