import React, { useEffect, useState } from "react";
import { FullPageTable } from "components";
import { useParams } from "react-router-dom";
import * as actions from "actions/CommonActions";

const BillingDetails = () => {
    const { id } = useParams();
    const [name, setName] = useState(null);

    const fetchName = async () => {
        if (id === "Total") {
            setName("Total")
        } else if (id === "Walk-In") {
            setName("Walk-In")
        } else {
            const response = await actions.customAction(`/master/client/${id}/`, "", "Client Info", "loadMany", null)
            if (response) {
                setName(response.name);
            }
        }
    }

    useEffect(() => {
        fetchName();
    }, [])

    return (
        <div>
            {name &&
                <div className="my-2">
                    <div className="d-flex justify-content-center align-items-center">Billing Summary for:<h3 className="ms-2">{name}</h3></div>
                </div>
            }
            <FullPageTable
                form=""
                searchForm="https://nca.margytech.in/api/form/148"
                url={`/frontdesk/opdadmission/${id}/billing_details`}
                tableName="billingdetail"
                tableTitle="Billing Details"
            />
        </div>
    )
}

export default BillingDetails;