import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Image } from "react-bootstrap";
import * as actions from "actions/CommonActions";
import { Outlet, useLocation, Link } from 'react-router-dom';

const QrMain = () => {
    // const [qrs, setQrs] = useState(null);
    const [qr, setQr] = useState(null);
    const form = useRef(null);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param2 = params.length > 1 ? params[1] : "";

    // useEffect(async () => {
    //     const response = await actions.loadQr();
    //     setQrs(response);
    // }, [])

    const handleQrGeneration = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        const response = await actions.createQr(formData);
        if (response) {
            alert("Successfull!");
            setQr(response)
        }
    };

    if (param2) {
        return (
            <Outlet />
        );
    }

    return (
        <div className="m-5">
            <Form className="d-flex flex-column justify-content-center" ref={form} onSubmit={(e) => handleQrGeneration(e)}>
                <Form.Group className="mt-3 mb-5">
                    <Form.Label>
                        Name
                    </Form.Label>
                    <Form.Control
                        required
                        name="name"
                        type="text"
                        placeholder="Enter Link here"
                    />
                </Form.Group>
                <Button className="w-25" type="submit">
                    Create QR
                </Button>
            </Form>
            <div>
                <div>{qr?.name}</div>
                <Image src={qr?.qr_code} />
            </div>
            {/* <div className="d-flex flex-column justify-content-center">
                {qrs?.map((qr, index) => {
                    return (
                        <Link key={qr?.id} to={`/qr/` + qr?.id}>
                            <div>{index + 1}. {qr.name}</div>
                        </Link>
                    );
                })}
            </div> */}
        </div>
    )
}

export default QrMain;