import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap';
import * as actions from "actions/CommonActions";
import DataTable from 'react-data-table-component';
import axios from 'axios';

const CustomListDropdown = (props) => {
    const { poItems, setPoItems, isOpen, setIsOpen } = props;
    const [items, setItems] = useState([])
    const [vendorItems, setVendorItems] = useState([])
    const [currentItem, setCurrentItem] = useState(null);

    const columns = [
        {
            name: "Item Code",
            selector: row => row.item_data?.code
        },
        {
            name: "Item Name",
            selector: row => row.item_data?.name
        },
        {
            name: "Vendor Name",
            selector: row => row.vendor_data?.name
        },
        {
            name: "Master Rate",
            selector: row => row.item_data?.patient_rate
        },
        {
            name: "Agreed Rate",
            selector: row => row.agreed_rate
        },
        {
            name: "Delivery Schedule",
            selector: row => row.delivery_schedule
        },
        {
            name: 'Action',
            button: true,
            cell: (row) => <Button onClick={() => {
                setPoItems(prevArr => [...prevArr, row]
                )
                setIsOpen(false);
            }}>Add</Button>,
        }

    ]

    const fetchItemData = async () => {
        const response = await actions.customAction("/master/item/", "?item_type__type_description=MEDICAL", "Items", "loadMany", null)
        if (response) {
            setItems(response);
        }
    }

    const fetchVendorItemData = async () => {
        const response = await actions.customAction("/master/vendoritemmapping/", "?item__id=" + currentItem, "Vendor Items", "loadMany", null)
        if (response) {
            console.log(response)
            setVendorItems(response);
            // setIsOpen(false);
        }
    }

    useEffect(() => {
        fetchItemData();
    }, [])

    useEffect(() => {
        if (currentItem) {
            fetchVendorItemData();
        }
    }, [currentItem])

    return (
        <>
            <Form.Select name='material' aria-label="Default select example" required onChange={(e) => {
                setCurrentItem(e.target.value);
                setIsOpen(true);
            }}>
                <option>Select Item</option>
                {items && items?.map((item) => (
                    <option value={item.id}>
                        {item.name}
                    </option>
                ))}
            </Form.Select>
            <Modal
                show={isOpen}
                onHide={() => setIsOpen(false)}
                onExited={() => setIsOpen(false)}
                dialogClassName="my-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {vendorItems && <DataTable data={vendorItems} columns={columns} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setIsOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomListDropdown