import React, { useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { FullPageTable } from "components";
import * as actions from "actions/CommonActions";

const OTApproval = () => {
    const form = useRef(null);
    const [submitType, setSubmitType] = useState("");

    const handleApproveReject = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        let data = { submit_type: submitType }
        let info = [];
        for (var value of formData.keys()) {
            if (formData.get(value) === "on") {
                let id = value.substring(6, value.length);
                info.push({ id: id, doctor_comment: formData.get("comment_" + id) });
            }
            data["ot_approval"] = info;
        }
        const response = await actions.customAction("/operationtheatre/otapproval/", "", "OT Approval", "create", data);
    };

    return (
        <>
            <Form ref={form} onSubmit={(e) => handleApproveReject(e)}>
                <div className="d-flex justify-content-end">
                    <Button
                        type="submit"
                        onClick={() => setSubmitType("approve")}
                    >
                        Approve
                    </Button>
                    <Button
                        type="submit"
                        onClick={() => setSubmitType("reject")}
                        className="mx-2"
                    >
                        Reject
                    </Button>
                </div>
                <FullPageTable
                    searchForm="https://nca.margytech.in/api/form/685"
                    url="/operationtheatre/otschedule/"
                    tableName="otapproval"
                    tableTitle="OT Approval"
                    baseSearchParam=""
                />
            </Form>
        </>
    )
}

export default OTApproval;