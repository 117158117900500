import React from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";

const CollectedSample = () => {
    const { opdIpdAdmissionId } = useParams();

    if (opdIpdAdmissionId) {
        return <Outlet />;
    }

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/655"
            url="/frontdesk/opdipddata/"
            tableName="collectedsamples"
            modalFullscreen={true}
            baseSearchParam=""
        />
    )
}

export default CollectedSample;