import React from "react";
import { FullPageTable } from "components";

const DailyBillingSummary = (params) => {

    return (
        <FullPageTable
            // form="https://nca.margytech.in/api/form/578"
            // searchForm=""
            // url="/frontdesk/opdadmission/"
            tableName="dailybillingsummary"
            // baseSearchParam="?admission_type=OPD_ADMISSION"
        />
    )
}

export default DailyBillingSummary;