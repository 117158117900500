import React from "react";
import { CustomPage } from "components";

const SurgeryMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/156"
            searchForm="https://nca.margytech.in/api/form/559"
            url="/master/surgery/"
            baseSearchParam=""
            tableName="surgery"
            tableTitle="Surgery"
            createFormButtonText="Add New Surgery"
            formTitle="Surgery Master"
            modalFullscreen={false}
            addPermissions={['his:master:surgery:add']}
        />
    )
}

export default SurgeryMaster;