import React from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";

const BedTransferSummary = () => {
    const { ipdadmissionId } = useParams();

    if (ipdadmissionId) {
        return <Outlet />;
    }

    return (
        <FullPageTable
            form="https://nca.margytech.in/api/form/116"
            searchForm="https://nca.margytech.in/api/form/587"
            url="/frontdesk/ipdadmission/"
            baseSearchParam=""
            tableName="bedtransfer"
            tableTitle="Bed Transfer"
            formTitle="Bed Transfer"
            modalFullscreen={false}
        />
    )
}

export default BedTransferSummary;