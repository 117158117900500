import React, {
  useState,
  useRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  Button,
  Container,
  Modal,
  Spinner,
  Form as BootstrapForm,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import * as actions from "actions/CommonActions";
import * as authActions from "actions/PublicAuthActions";
import { useDispatch, useSelector } from "react-redux";
import "core-ui/components/CustomTable.scss";
import { AiOutlineClose } from "react-icons/ai";
import { EmailPill, PdfPreview, Attachment } from "components";
import moment from "moment";

const CustomClientSummaryTable = forwardRef(
  (
    {
      tableName,
      tableTitle,
      url,
      baseSearchParam = "",
      searchURL = "",
    },
    ref
  ) => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pending, setPending] = useState(true);
    const [largestData, setLargestData] = useState(null);
    const [allCounts, setAllCounts] = useState([]);
    const dispatch = useDispatch();

    const fetchRecords = async () => {
      setPending(true);
      const response = await actions.customAction(
        url + baseSearchParam,
        searchURL,
        tableTitle,
        "loadMany",
        null
      );
      console.log(response);
      setData(response);
      setPending(false);
    };

    useEffect(() => {
      fetchRecords();
    }, []);

    useEffect(() => {
      fetchRecords();
    }, [searchURL, baseSearchParam]);

    useEffect(() => {
      if (data.length > 0) {
        data.map((res) => setAllCounts(allCounts => [...allCounts, res.count]))
      }
    }, [data])

    useEffect(() => {
      if (allCounts.length > 0) {
        const largest = data.filter((d) => d.count === Math.max(...allCounts));
        setLargestData(largest[0])
      }
    }, [allCounts])

    const createColumns = () => {
      let cols = [
        {
          name: "Client",
          selector: row => row.client,
          sortable: true,
        }
      ];
      if (largestData) {
        Object.keys(largestData).map((key) => {
          if (key !== 'count' && key !== 'client') {
            console.log(key)
            let field = {
              name: key,
              selector: row => row[key],
              sortable: true
            }
            cols.push(field);
          }
        })
        setColumns(cols);
        setAllCounts([])
        setLargestData(null)
      }
    };

    useEffect(() => {
      createColumns();
    }, [largestData, data]);

    const customStyles = {
      rows: {
        style: {
          minHeight: "72px",
        },
      },
      headCells: {
        style: {
          fontWeight: "bold",
          padding: "15px",
          border: "1px solid #eee",
          color: "#fff",
          borderBottom: "1px solid #999",
          backgroundColor: "#006699",
        },
      },
      cells: {
        style: {
          borderLeft: "1px solid #eee",
          borderRight: "1px solid #eee",
          minHeight: "50px",
        },
      },
    };

    return (
      <>
        <Container className="p-0 py-3" fluid>
          <div className="shadow">
            {columns && data && <DataTable
              columns={columns}
              data={data}
              dense
              responsive
              pagination
              customStyles={customStyles}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              persistTableHead={pending ? false : true}
              progressPending={pending}
              noDataComponent={
                <div className="py-5">
                  <p className="my-5">No data available.</p>
                </div>
              }
              progressComponent={
                <div className="py-5">
                  <Spinner
                    className="my-5"
                    animation="border"
                    variant="primary"
                  />
                </div>
              }
            />}
          </div>
        </Container>
      </>
    );
  }
);

const styles = {
  tableContainer: {
    border: "1px solid #ccc",
  },
};

export default CustomClientSummaryTable;
