import React, { useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { FullPageTable } from "components";
import * as actions from "actions/CommonActions";
import { toast } from "react-toastify";

const ClientLedger = () => {
    const form = useRef(null);
    const [response, setResponse] = useState(true)

    const handleGenerateLedger = async (e) => {
        e.preventDefault();
        setResponse(false)
        const response = await actions.customAction("/account/clientledger/generate_ledger/", "", "Client Ledger", "load", null);
        if (response) {
            toast.success("Ledger generated successfully!");
        }
        setResponse(response)
    };

    return (
        <>
            <Form ref={form} onSubmit={(e) => handleGenerateLedger(e)}>
                <div className="d-flex justify-content-end me-3">
                    <Button type="submit">
                        Generate Client Ledger
                    </Button>
                </div>
            </Form>
            {response ?
                <FullPageTable
                    searchForm="https://nca.margytech.in/api/form/666"
                    url="/account/clientledger/"
                    tableName="clientledger"
                    baseSearchParam=""
                    tableTitle="Client Ledger"
                />
                :
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: "50vh" }}
                >
                    <Spinner />
                </div>
            }
        </>
    )
}

export default ClientLedger;