import React from "react";
import { FullPageTable } from "components";

const WorkOrderRaise = (params) => {

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/701"
            url = "/workorder/workorder/"
            tableName="workorderraise"
            tableTitle="workorderraise"
            formTitle="Approve Work Order"
            modalFullscreen={true}
            baseSearchParam="?wo_status=HO_ACCOUNTS_APPROVAL,RAISED"
        />
    )
}

export default WorkOrderRaise;