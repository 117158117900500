import React from "react";
import { CustomPage } from "components";

const DietMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/597"
            searchForm="https://nca.margytech.in/api/form/598"
            url="/master/diet/"
            baseSearchParam=""
            tableName="diet"
            tableTitle="Diet"
            createFormButtonText="Add Diet"
            formTitle="Diet Master"
            modalFullscreen={false}
            addPermissions={['his:master:diet:add']}
        />
    )
}

export default DietMaster;