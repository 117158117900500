import React, { useState, useRef, forwardRef, useEffect, useImperativeHandle } from "react";
import { Button, Container, Modal, Spinner, Form as BootstrapForm } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import * as actions from "actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "core-ui/components/CustomTable.scss";
import { AiOutlineClose } from "react-icons/ai";

const SampleAccessionTable = forwardRef(({ url, baseSearchParam, form }, ref) => {
    const [pending, setPending] = useState(false);
    const [data, setData] = useState([]);
    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const rejectSample = async (e, status, id) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        let result = {};
        for (var value of formData.keys()) {
            console.log(value, formData.get(value));
            if (formData.get(value) === "on") {
                let id = value.substring(7, value.length);
                result = {
                    id: id,
                    status: status,
                    reason_for_rejection: formData.get("reason_for_rejection_" + id),
                }
            }
        }
        const response = await actions.customAction(url + id + "/", "", "Test Samples", "partialUpdate", result);
        if (response) {
            fetchRecords();
        }
    };

    const fetchRecords = async () => {
        setPending(true);
        const response = await actions.customAction(url + baseSearchParam, "", "Test Samples", "loadMany", null);
        let result = [];
        let profiles = [];
        response.map((item) => {
            if (item.profile) {
                if (!profiles.includes(item.profile_data.id)) {
                    result.push(item);
                    profiles.push(item.profile_data.id);
                }
            } else {
                result.push(item);
            }
            console.log(item);
        })
        setData(result);
        setPending(false);
    };

    useImperativeHandle(ref, () => ({
        updateTable() {
          fetchRecords();
        },
      }));
  

    useEffect(() => {
        if (url) {
            fetchRecords();
        }
    }, [url, baseSearchParam]);

    console.log(data);

    let columns = [
        {
            id: "test_code",
            name: 'Test/Profile Code',
            selector: row => row.profile ? row.profile_data?.code : row.test_data?.code,
            sortable: true,
            cell: null,
            button: false,
            allowOverflow: false,
            wrap: true,
        },
        {
            id: "test_name",
            name: 'Test/Profile Name',
            selector: row => row.profile ? row.profile_data?.name : row.test_data?.name,
            sortable: true,
            cell: null,
            button: false,
            allowOverflow: false,
            wrap: true,
        },
        {
            id: "reason_for_rejection",
            name: 'Reason for Rejection',
            selector: null,
            sortable: false,
            cell: (row, index) => {
                return (
                    <BootstrapForm.Group>
                        <BootstrapForm.Control
                            name={`reason_for_rejection_${row.id}`}
                            size="sm"
                            id={row.id}
                            defaultValue={row.reason_for_rejection ? row.reason_for_rejection : ""}
                        />
                    </BootstrapForm.Group>
                )
            },
            button: false,
            allowOverflow: true,
            wrap: true,
        },
        {
            id: "reject",
            name: 'Reject',
            selector: null,
            sortable: false,
            cell: (row, index) => {
                return (
                    <Button size="sm" className="fa fa-close my-1 btn-table-action btn-danger" onClick={(e) => rejectSample(e, "REJECTED", row.id)} id={row.id} />
                )
            },
            button: true,
            allowOverflow: true,
            wrap: true,
        },
        {
            id: "select",
            name: 'Select',
            selector: row => row.test_data?.name,
            sortable: false,
            cell: (row) => {
                return (<BootstrapForm.Group>
                    <BootstrapForm.Check
                        type="checkbox"
                        name={`select_${row.id}`}
                        label=""
                        id={row.id}
                        defaultChecked={false}
                    />
                </BootstrapForm.Group>)
            },
            button: true,
            allowOverflow: true,
            wrap: true,
        },
    ]

    const customStyles = {
        rows: {
            style: {
                minHeight: "72px",
            },
        },
        headCells: {
            style: {
                fontWeight: "bold",
                padding: "15px",
                border: "1px solid #eee",
                color: "#fff",
                borderBottom: "1px solid #999",
                backgroundColor: "#006699",
            },
        },
        cells: {
            style: {
                borderLeft: "1px solid #eee",
                borderRight: "1px solid #eee",
                minHeight: "50px",
            },
        },
    };

    return (
        <>
            <Container className="p-0 py-3">
                <div className="shadow">
                    {data && <DataTable
                        columns={columns}
                        data={data}
                        dense
                        responsive
                        pagination
                        customStyles={customStyles}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        persistTableHead={pending ? false : true}
                        progressPending={pending}
                        noDataComponent={
                            <div className="py-5">
                                <p className="my-5">No data available.</p>
                            </div>
                        }
                        progressComponent={
                            <div className="py-5">
                                <Spinner
                                    className="my-5"
                                    animation="border"
                                    variant="primary"
                                />
                            </div>
                        }
                    />}
                </div>
            </Container>
        </>
    );
}
);
const styles = {
    tableContainer: {
        border: "1px solid #ccc",
    },
};

export default SampleAccessionTable;