import React from "react";
import { FullPageTable } from "components";

const DeathSummary = () => {

    return (
        <FullPageTable
            form="https://nca.margytech.in/api/form/73"
            searchForm="https://nca.margytech.in/api/form/586"
            url="/emergency/patientdischarge/"
            baseSearchParam="?discharge_type__type_description=EXPIRED"
            tableName="eddeathsummary"
            tableTitle="Death Summary"
            formTitle="Death Summary"
            modalFullscreen={false}
            addPermissions={['his:edpatient:deathsummary:add']}
        />
    )
}

export default DeathSummary;