import React, { useState, useRef, useEffect } from "react";
import { Outlet, useLocation, Link } from 'react-router-dom';
import { Card, Row, Col, Container, Form as BForm } from 'react-bootstrap'
import ModuleDatePicker from "../Common/ModuleItem/ModuleDatePicker";
import ModuleCard from "pages/Common/ModuleItem/ModuleCard";
import { MdAssignment, MdGroup, MdAssignmentTurnedIn, MdAssignmentLate } from "react-icons/md";
import * as actions from "actions/CommonActions";
import moment from "moment";


const RisDashboard = () => {
    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";
    const param2 = params.length > 1 ? params[1] : "";

    const [data, setData] = useState(null);

    const today = new Date();
    const [startDateTime, setStartDateTime] = useState(moment(today.setHours(0, 0, 0, 0)).format("YYYY-MM-DDTHH:mm:ssZ"));
    const [endDateTime, setEndDateTime] = useState(new Date());

    const fetchData = async () => {
        const response = await actions.loadDashboard("ris", "dashboard", startDateTime, endDateTime);
        if (response) {
            setData(response);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (param2) {
        return (
            <Outlet />
        );
    }

    return (
        <Container fluid className="p-5">
            <Row>
                <Col sm={12}>
                <ModuleDatePicker startDateTime={startDateTime} endDateTime={endDateTime} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} fetchData={fetchData}/>
                    <Row>
                        <ModuleCard size={3}
                            cardTitle="Total Patients"
                            cardContentTitle1={"OPD"}
                            cardContent1={<b>{data?.patient_from_opd?.open + data?.patient_from_opd?.completed}</b>}
                            cardContentTitle2={"IPD"}
                            cardContent2={<b>{data?.patient_from_ipd?.on_bed + data?.patient_from_ipd?.discharged}</b>}
                            cardContentTitle3={"Total"}
                            cardContent3={<b>{data?.total_patients?.opd + data?.total_patients?.ipd}</b>}
                            type="3"
                            iconBoxColor="#b0d9fd"
                            icon={<MdGroup color="#bsbsbs" size={30} />}
                        />
                        <ModuleCard size={3}
                            cardTitle="Total Studies"
                            cardContentTitle1={"OPD"}
                            cardContent1={<b>{data?.study_from_opd?.open}</b>}
                            cardContentTitle2={"IPD"}
                            cardContent2={<b>{data?.study_from_ipd?.open}</b>}
                            cardContentTitle3={"Total"}
                            cardContent3={<b>{data?.study_from_opd?.open + data?.study_from_ipd?.open}</b>}
                            type="3"
                            iconBoxColor="#b0d9fd"
                            icon={<MdAssignment color="#bsbsbs" size={30} />}
                        />
                        <ModuleCard size={3}
                            cardTitle="Pending Studies"
                            cardContentTitle1={"OPD"}
                            cardContent1={<b>{data?.study_from_opd?.pending}</b>}
                            cardContentTitle2={"IPD"}
                            cardContent2={<b>{data?.study_from_ipd?.pending}</b>}
                            cardContentTitle3={"Total"}
                            cardContent3={<b>{data?.study_from_opd?.pending + data?.study_from_ipd?.pending}</b>}
                            type="3"
                            iconBoxColor="#b0d9fd"
                            icon={<MdAssignmentLate color="#bsbsbs" size={30} />}
                        />
                        <ModuleCard size={3}
                            cardTitle="Reported Studies"
                            cardContentTitle1={"OPD"}
                            cardContent1={<b>{data?.study_from_opd?.reported}</b>}
                            cardContentTitle2={"IPD"}
                            cardContent2={<b>{data?.study_from_ipd?.reported}</b>}
                            cardContentTitle3={"Total"}
                            cardContent3={<b>{data?.study_from_opd?.reported + data?.study_from_ipd?.reported}</b>}
                            type="3"
                            iconBoxColor="#b0d9fd"
                            icon={<MdAssignmentTurnedIn color="#bsbsbs" size={30} />}
                        />
                    </Row>
                </Col>
                
            </Row>
        </Container>
    ); 
}

export default RisDashboard;