import React from "react";
import { FullPageTable } from "components";

const IPDPatientSummary = (params) => {

    return (
        <FullPageTable
            form="https://nca.margytech.in/api/form/580"
            searchForm="https://nca.margytech.in/api/form/682"
            url="/frontdesk/ipdadmission/"
            tableName="ipdadmission"
            tableTitle="IPD Admission"
            formTitle="IPD Admission"
            modalFullscreen={true}
            baseSearchParam=""
        />
    )
}

export default IPDPatientSummary;