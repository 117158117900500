import React from "react";
import { Container, Button, Modal, Row, Col } from "react-bootstrap";
import { FullPageTable } from "components";

const RisMedicalStockTransferInward = () => {

    return (
        <>
            <h4 className="d-flex justify-content-center">Inward</h4>
            <FullPageTable
                form="https://nca.margytech.in/api/form/732"
                searchForm="https://nca.margytech.in/api/form/743"
                url="/purchaseorder/stocktransfer/"
                baseSearchParam="?transfer_to_depts=Radiology"
                tableName="stocktransferinward"
                tableTitle="Stock Transfer"
                createFormButtonText="Generate Stock Transfer"
                formTitle="Generate Stock Transfer"
                modalFullscreen={true}
            />
        </>
    );
};

export default RisMedicalStockTransferInward;