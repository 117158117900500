import React from "react";
import { FullPageTable } from "components";

const PurchaseOrderHoManager = (params) => {
  return (
    <FullPageTable
    form="https://nca.margytech.in/api/form/436"
    searchForm="https://nca.margytech.in/api/form/691"
      url="/purchaseorder/purchaseorder/"
      tableName="pohomanagerapproval"
      tableTitle="HO manager approval"
      formTitle="Approve Purchase Order"
      modalFullscreen={true}
      baseSearchParam="?status=APPROVED&department__department_code=PHARMACY"
    />
  );
};

export default PurchaseOrderHoManager;
