import React from "react";
import { FullPageTable } from "components";

const InventoryDepartmentStock = (params) => {

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/694"
            url = "/purchaseorder/stock/"
            tableName="pharmacystock"
            tableTitle="Stock summary"
            baseSearchParam="?departments_nonmedical=Inventory"
        />
    )
}

export default InventoryDepartmentStock;