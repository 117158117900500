import React, { useEffect, useState } from "react";
import { CustomPage, CustomPageNew } from "components";
import { useSelector } from "react-redux";

const LisReferredDoctorMaster = () => {
    const currentlocation = useSelector((state) => state.adminReducer.currentLocation)
    const user = useSelector((state) => state.authReducer.user)
    const setting = useSelector((state) => state.adminReducer.setting);
    const multiLocation = setting.location_enabled


    return (
        <>
            {!currentlocation && user.is_admin ? <CustomPage
                // formSet={formSet}
                form="https://nca.margytech.in/api/form/382"
                searchForm="https://nca.margytech.in/api/form/553"
                url="/master/referreddoctor/"
                baseSearchParam=""
                tableName="referreddoctor"
                tableTitle="Referred Doctor"
                createFormButtonText="Add Referred Doctor"
                formTitle="Referred Doctor Master"
                modalFullscreen={true}
                addPermissions={['lis:master:referreddoctor:add']}
                
            /> : <CustomPage
            // formSet={formSet}
                    form="https://nca.margytech.in/api/form/382"
                    searchForm="https://nca.margytech.in/api/form/553"
                    url="/master/referreddoctor/"
                    baseSearchParam={`?location__id=${currentlocation}`}
                    tableName="referreddoctor"
                    tableTitle="Referred Doctor"
                    createFormButtonText="Add Referred Doctor"
                    formTitle="Referred Doctor Master"
                    modalFullscreen={true}
                    addPermissions={['lis:master:referreddoctor:add']}
                    
            />}
        </>
    )
}

export default LisReferredDoctorMaster;