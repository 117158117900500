import React, { useState, useRef, useEffect } from "react";
import { Container, Button, Modal, Row, Col } from "react-bootstrap";
import { Form } from "react-formio";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as actions from "actions/CommonActions";
import { CustomClientSummaryTable } from "components";
import axios from "axios";
import { AiOutlineClose, AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { dropdownUrl } from "utilities";

const CustomClientSummaryPage = (params) => {
    const { searchForm, url, baseSearchParam, tableTitle} = params;
    const [modal, setModal] = useState(false);
    const [filterForm, setFilterForm] = useState(null);
    const [filterParams, setFilterParams] = useState("");
    const [submissionData, setSubmissionData] = useState("");
    const [fold, setFold] = useState(true);

    // Refs
    const customTableRef = useRef()
    const filterFormRef = useRef()

    useEffect(() => {
        if (filterFormRef) {
            console.log(filterFormRef)
        }
    }, [filterFormRef])

    const fetchFilterForm = async () => {
        setFilterForm(await axios.get(searchForm));
    }

    useEffect(() => {
        fetchFilterForm();
    }, []);

    const filterItems = (e) => {
        const keys = Object.keys(e.data);
        let x = "";
        if (baseSearchParam === "") {
            x = "?";
        }
        else {
            x = "&";
        }
        keys.map((key) => {
            if (e.data[key] && key !== 'submit') {
                x = x + key + "=" + e.data[key] + "&";
            }
        })
        setFilterParams(x);
    }

    return (
        <Container fluid className="mt-2 overflow-hidden">
            <Row>
                {searchForm &&
                    <Col sm={3} className={`${fold ? "d-none" : "d-flex"}`}>
                        {filterForm?.data &&
                            <Form
                                ref={filterFormRef}
                                form={dropdownUrl(filterForm?.data)}
                                onSubmit={(e) => { setSubmissionData(e); filterItems(e); }}
                                submission={submissionData}
                            />
                        }
                    </Col>
                }
                <Col sm={(searchForm && !fold) ? 9 : 12}>
                    {!fold && <div className=""><Button onClick={() => setFold(true)}><AiOutlineMenuFold size={30} /></Button></div>}
                    {fold && <div className=""><Button onClick={() => setFold(false)}><AiOutlineMenuUnfold size={30} /></Button></div>}
                    <CustomClientSummaryTable
                        ref={customTableRef}
                        tableTitle={tableTitle}
                        url={url}
                        baseSearchParam={baseSearchParam}
                        searchURL={filterParams}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default CustomClientSummaryPage;