import React from "react";
import { FullPageTable } from "components";

const EmergencyConsole = (params) => {

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/115"
            url="/frontdesk/edadmission/"
            tableName="emergencyconsole"
            tableTitle="Patient Info"
            formTitle=""
            modalFullscreen={true}
            baseSearchParam="?is_archive=False"
        />
    )
}

export default EmergencyConsole;