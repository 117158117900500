import axios from "axios";
import { toast } from "react-toastify";
import { store, persistor } from "store";

const notify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    default:
      toast.error(msg);
  }
};

////////////////////////// USERS - Action Creator //////////////////////////

export const login = (data) => {
  return {
    type: "LOGIN",
    payload: data,
  };
};

export const logout = () => {
  // alert();
  return {
    type: "LOGOUT",
    payload: null,
  };
};

////////////////////////// TENANTS - Actions Creator //////////////////////////

export const tenantsLoad = (data) => {
  // alert();
  return {
    type: "LIST_TENANTS",
    payload: data,
  };
};

////////////////////////// PRODUCTS - Actions Creator //////////////////////////

export const productsLoad = (data) => {
  return {
    type: "LIST_PRODUCTS",
    payload: data,
  };
};

export const modulesLoad = (data) => {
  return {
    type: "LIST_MODULES",
    payload: data,
  };
};

////////////////////////// PRODUCTS - Actions Creator //////////////////////////

export const tenantProductsLoad = (data) => {
  return {
    type: "LIST_TENANT_PRODUCTS",
    payload: data,
  };
};

export const tenantModulesLoad = (data) => {
  return {
    type: "LIST_TENANT_MODULES",
    payload: data,
  };
};

export const permissionsLoad = (data) => {
  return {
    type: "LOAD_PERMISSIONS",
    payload: data
  };
}

////////////////////////// TEMPLATES - Action Creator //////////////////////////

export const currentTemplate = (type, data) => {
  switch (type) {
    case "ADMISSION_CARD":
      return {
        type: "CURRENT_ADMISSION_CARD_TEMPLATE",
        payload: data,
      };
    case "INVOICE":
      return {
        type: "CURRENT_INVOICE_TEMPLATE",
        payload: data,
      };
    case "REPORT":
      return {
        type: "CURRENT_REPORT_TEMPLATE",
        payload: data,
      };
  }
};


////////////////////////// USERS - Actions //////////////////////////

export const loggingIn = async (data) => {
  try {
    const response = await axios.post(`/user/login/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("Error in login! Try again.", "error");
  }
};

export const loggingOut = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/user/logout/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response for logout:", response);
      if (response.status === 204) {
        notify("You are successfully logged out.", "success");
        dispatch(logout());
      }
    } catch (err) {
      console.log("Error", err);
      dispatch(logout());
      persistor.purge();
    }
  };
};

////////////////////////// TENANTS - Actions //////////////////////////

export const loadTenants = async () => {
  try {
    const response = await axios.get(`/tenant/?public=false`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Tenants fetched successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("There was a problem loading Tenants!", "error");
  }
};

////////////////////////// PRODUCTS - Actions //////////////////////////

export const loadProducts = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/product/product/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response", response);
      if (response.status === 200) {
        dispatch(productsLoad(response.data));
        // notify("Products fetched successfully!", "success");
      }
    } catch (err) {
      console.log("Error", err);
      notify("There was a problem loading Products!", "error");
    }
  }
};

export const loadModules = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/product/module/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response", response);
      if (response.status === 200) {
        dispatch(modulesLoad(response.data));
      }
    } catch (err) {
      console.log("Error", err);
      notify("There was a problem loading Modules!", "error");
    }
  }
};

////////////////////////// TENANT PRODUCTS - Actions //////////////////////////

export const loadTenantProducts = (client) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/tenantproduct/tenantproduct/?tenant__schema_name=` + client, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response", response);
      if (response.status === 200) {
        dispatch(tenantProductsLoad(response.data));
      }
    } catch (err) {
      console.log("Error", err);
      notify("There was a problem loading Tenant Products!", "error");
    }
  }
};

export const loadTenantModules = (client) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/tenantproduct/tenantmodule/?tenant__schema_name=` + client, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response", response);
      if (response.status === 200) {
        dispatch(tenantModulesLoad(response.data));
      }
    } catch (err) {
      console.log("Error", err);
      notify("There was a problem loading Tenant Modules!", "error");
    }
  }
};

export const loadPermissions = (user) => {
  return async(dispatch) => {
    try{
      const response = await axios.get(`/user/${user.id}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if(response.status === 200){
        dispatch(permissionsLoad(response.data));
      }
    } catch(err){
      console.log("Error", err);
    }
  }
}