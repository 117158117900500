import React, { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Form } from "react-formio";
import { useNavigate } from "react-router-dom";
import * as actions from "actions/CommonActions";
import axios from "axios";
import { dropdownUrl } from "utilities";
import { useSelector } from "react-redux";
import fileDownload from "js-file-download";

const FullPageForm = (params) => {
    const { submission, form, url, tableTitle, navigateTo, patch, fileDownload } = params;
    const navigate = useNavigate();
    const [itemForm, setItemForm] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const currentLocation = useSelector((state) => state.adminReducer.currentLocation)


    const fetchForm = async () => {
        setItemForm(await axios.get(form));
    }

    // const fetchMetaTagTypeForm = async () => {
    //     setTagTypeForm(await axios.get("https://nca.margytech.in/api/form/528"))
    // }

    useEffect(() => {
        fetchForm();
        // fetchMetaTagTypeForm();
    }, []);

    const createItem = async (e) => {
        const response = await actions.customAction(url, "", tableTitle, "create", e.data);
        if (response) {
            navigate(navigateTo);
        }
    }

    const partialUpdateItem = async (e) => {
        const response = await actions.customAction(url + e.data['id'] + "/", "", tableTitle, "partialUpdate", e.data);
        if (response) {
            navigate(navigateTo);
        }
      };

    const donwloadFile = async (e) => {
        const response = await actions.customAction(
            url,
            "",
            tableTitle,
            "download",
            e.data
          );
        if (response) {
            navigate(navigateTo);
        }
    }

    console.log("SUBMISSION", submission)
    // const updateItem = async (e) => {
    //     const response = await actions.customAction(url + currentItem.id + "/", "", tableTitle, "update", e.data);
    // }

    return (
        <Container fluid className="mt-5 px-5 overflow-hidden">
            {itemForm?.data &&
                <Form
                    submission={{ 'data': submission }}
                    form={dropdownUrl(itemForm?.data, null, process.env.REACT_APP_LOCATION_SET ? currentLocation : null)}
                    onSubmit={(e) => {
                        if (patch) {
                            partialUpdateItem(e);
                        } else if ((fileDownload && e.data.save_print) || (fileDownload && e.data.download && !e.data.sendEmail && !e.data.sendWhatsApp)){
                            donwloadFile(e);
                        // } else if (fileDownload && e.data.download && !e.data.sendEmail && !e.data.sendWhatsApp){
                        //     donwloadFile(e);
                        }else{
                            createItem(e)
                        }
                    }}
                    onChange={(e) => console.log(e)}
                />
            }
        </Container>
    );
}

export default FullPageForm;