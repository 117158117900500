import React from "react";
import { CustomPage } from "components";

const PharmacyInwardWithoutPO = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/739"
            searchForm="https://nca.margytech.in/api/form/740"
            url="/purchaseorder/goodinward/"
            baseSearchParam="?vendors_type=MEDICAL"
            tableName="pharmacyinwardwithoutpo"
            tableTitle="Inward Without Purchase Order"
            createFormButtonText="Add New Good Inward"
            formTitle="Inward Without PO"
            modalFullscreen={true}
        />
    );
};

export default PharmacyInwardWithoutPO;
