import React, { useEffect, useState } from "react";
import { FullPageForm } from "components";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import * as actions from "actions/CommonActions";

const SampleRegistration = () => {
    const { patientId } = useParams();
    const [patient, setPatient] = useState(null);
    const currentLocation = useSelector((state) => state.adminReducer.currentLocation)
    const [multiLocation, setMultiLocation] = useState(false)
    const setting = useSelector((state) => state.adminReducer.setting);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchPreviousSampleRegistration = async () => {
        const filter = "?patient__id=" + patientId + "&admission_type=SAMPLE_REGISTRATION"
        const registration_response = await actions.customAction("/frontdesk/opdadmission/", filter , "Registration", "loadMany", null);
        let bal = 0;
        if (registration_response) {
            registration_response.map((res) => {
                bal += res.balance_amount;
            })
            if (bal > 0){
                alert(`Please Pay your previous balances:${bal}`);
            }
        }
    }

    const fetchPatient = async () => {
        const response = await actions.customAction("/frontdesk/patient/" + patientId + "/", "", "Patient", "load", null);
        if (response) {
            setPatient({
                uhid: response.uhid,
                patient_name: response.first_name + " " + response.middle_name + " " + response.last_name,
                mobile: response.mobile,
                patient: response.id,
                gender: response.gender,
            });
        }
    }

    useEffect(() => {
        if (patientId) {
            fetchPatient();
        }
    }, [patientId]);

    useEffect(() => {
        if (patient) {
            fetchPreviousSampleRegistration();
        }
    }, [patient]);

    console.log(patient);

    return (
        <>
            {patient && !currentLocation ? <FullPageForm
                submission={patient}
                // form="https://nca.margytech.in/api/form/556"
                form="https://nca.margytech.in/api/form/517"
                url="/frontdesk/opdadmission/"
                tableTitle="Sample Registration"
                navigateTo={`/${param1}/frontdesk/patient`}
                patch={false}
            /> :
            patient && <FullPageForm
                submission={patient}
                // form="https://nca.margytech.in/api/form/556"
                form="https://nca.margytech.in/api/form/201"
                url="/frontdesk/opdadmission/"
                tableTitle="Sample Registration"
                navigateTo={`/${param1}/frontdesk/patient`}
                patch={false}
            />}
        </>
    )
}

export default SampleRegistration;