import React from "react";
import { CustomPage } from "components";
import { Button } from "react-bootstrap";
import axios from "axios";
import * as actions from "actions/CommonActions";

const BloodBankPatientRegister = () => {

    return (
        <>
        <CustomPage
            form="https://nca.margytech.in/api/form/731"
            searchForm="https://nca.margytech.in/api/form/531"
            url="/frontdesk/patient/"
            baseSearchParam=""
            tableName="bloodbankpatient"
            tableTitle="Patient"
            createFormButtonText="Register Patient"
            formTitle="Patient Registration"
            modalFullscreen={true}
            addPermissions={['bloodbank:patient:register:add']}
        />
        </>
    )
}

export default BloodBankPatientRegister;