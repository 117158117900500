import React from "react";
import { CustomPage, FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";

const IPDReceipt = () => {
    const { ipdId } = useParams();

    if (ipdId) {
        return <Outlet />;
    }

    return (
        <FullPageTable
            // form="https://nca.margytech.in/api/form/615"
            searchForm="https://nca.margytech.in/api/form/687"
            url="/frontdesk/ipdreceipt/"
            tableName="ipdreceipt"
            tableTitle="IPD Receipt"
            formTitle=""
            modalFullscreen={true}
            baseSearchParam=""
        />
    )
}

export default IPDReceipt;