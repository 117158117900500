import React, { useState, useEffect } from "react";
import { CustomPageNew, FullPageForm, FullPageTable } from "components";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const PatientInfo = () => {
    const { ipdadmissionId } = useParams();
    const [ipdAdmission, setIpdAdmission] = useState(null);
    const [preData, setPreData] = useState(null);
    const [bedChargePreData, setBedChargePreData] = useState(null);

    const [physicalExam, setPhysicalExam] = useState(null);
    const [physicalExamFlag, setPhysicalExamFlag] = useState(false);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchIpdAdmission = async () => {
        const response = await actions.customAction("/frontdesk/ipdadmission/" + ipdadmissionId + "/", "", "Patient", "load", null);
        if (response) {
            setIpdAdmission(response);
            setPreData({ ipd: response.id, uhid: response.uhid });
            setBedChargePreData({
                ipd: response.id,
                uhid: response.uhid,
                bed: response.bed,
                ward: response.ward,
                ward_name: response.ward_data?.name,
                bed_code: response.bed_data?.bed_code,
                bed_number: response.bed_data?.bed_number,
                patient_rate: response.bed_data?.patient_rate
            });
        }
    }

    const fetchPhysicalExam = async () => {
        const response = await actions.customAction("/nursing/physicalexam/", "?ipd__id=" + ipdadmissionId, "Physical Exam", "load", null);
        if (response) {
            if (response[0]?.id) {
                setPhysicalExamFlag(true);
                setPhysicalExam(response[0]);
            } else {
                setPhysicalExam({ ipd: ipdadmissionId });
            }
        }
    }

    useEffect(() => {
        if (ipdadmissionId) {
            fetchIpdAdmission();
        }
    }, [ipdadmissionId]);

    useEffect(() => {
        if (ipdadmissionId && preData) {
            fetchPhysicalExam();
        }
    }, [ipdadmissionId, preData]);

    console.log(ipdAdmission);

    return (
        <>
            <div className="m-5 d-flex justify-content-between">
                <div className="d-flex flex-column">
                    <b>Admission No:</b>
                    <h5>{ipdAdmission?.admission_no}</h5>
                </div>
                <div className="d-flex flex-column">
                    <b>UHID:</b>
                    <h5>{ipdAdmission?.uhid}</h5>
                </div>
                <div className="d-flex flex-column">
                    <b>Patient Name:</b>
                    <h5>{ipdAdmission?.patient_name}/{ipdAdmission?.patient_data?.age} {ipdAdmission?.patient_data?.age_type}/{ipdAdmission?.patient_data?.gender}</h5>
                </div>
            </div>
            <Tabs className="mx-4">
                <Tab eventKey="instruction" title="Instructions">
                    <h4 className="mt-2 mx-5">Instructions</h4>
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/616"
                        searchForm=""
                        url="/nursing/instruction/"
                        baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                        tableName="instruction"
                        tableTitle="Instruction"
                        formTitle="Instruction"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                    <h4 className="mt-5 mx-5">Diagnosis</h4>
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/619"
                        searchForm=""
                        url="/nursing/diagnosis/"
                        baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                        tableName="diagnosis"
                        tableTitle="Diagnosis"
                        formTitle="Diagnosis"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="vital" title="Vitals">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/617"
                        searchForm=""
                        url="/nursing/vital/"
                        baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                        tableName="vital"
                        tableTitle="Vital"
                        formTitle="Vital"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="advice" title="Investigation Advice">
                    <Row>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/618"
                                searchForm=""
                                url="/nursing/labadvice/"
                                baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                                tableName="labadvice"
                                tableTitle="Lab Advice"
                                formTitle="Lab Advice"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/646"
                                searchForm=""
                                url="/nursing/radiologyadvice/"
                                baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                                tableName="radiologyadvice"
                                tableTitle="Radiology Advice"
                                formTitle="Radiology Advice"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="medicine" title="Medicine">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/620"
                        searchForm=""
                        url="/nursing/medicine/"
                        baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                        tableName="medicine"
                        tableTitle="Medicine"
                        formTitle="Medicine"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="inputoutput" title="Input/Output">
                    <Row>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/621"
                                searchForm=""
                                url="/nursing/input/"
                                baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                                tableName="input"
                                tableTitle="Input"
                                formTitle="Input"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/622"
                                searchForm=""
                                url="/nursing/output/"
                                baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                                tableName="output"
                                tableTitle="Output"
                                formTitle="Output"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="diet" title="Diet">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/623"
                        searchForm=""
                        url="/nursing/patientdiet/"
                        baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                        tableName="patientdiet"
                        tableTitle="Patient Diet"
                        formTitle="Patient Diet"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="indweller" title="Indwellers">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/625"
                        searchForm=""
                        url="/nursing/indweller/"
                        baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                        tableName="indweller"
                        tableTitle="Indweller"
                        formTitle="Indweller"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="service" title="Services/Packages">
                    <Row>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/627"
                                searchForm=""
                                url="/nursing/patientservice/"
                                baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                                tableName="patientservice"
                                tableTitle="Service"
                                formTitle="Service"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/705"
                                searchForm=""
                                url="/nursing/patientpackage/"
                                baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                                tableName="patientpackage"
                                tableTitle="Package"
                                formTitle="Package"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="drvisiting" title="Doctor Visits">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/626"
                        searchForm=""
                        url="/nursing/doctorvisit/"
                        baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                        tableName="doctorvisit"
                        tableTitle="Doctor Visit"
                        formTitle="Doctor Visit"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="physicalexam" title="Physical Exam">
                    {preData && physicalExam && <FullPageForm
                        submission={physicalExam}
                        form="https://nca.margytech.in/api/form/745"
                        url="/nursing/physicalexam/"
                        tableTitle="Physical Exam"
                        navigateTo={`/${param1}/inpatient/patientinfo/${ipdAdmission.id}`}
                        patch={physicalExamFlag}
                    />}
                </Tab>
                <Tab eventKey="otschedule" title="OT Schedule">
                    {preData && <FullPageTable
                        searchForm=""
                        url="/operationtheatre/otschedule/"
                        tableName="patientotschedule"
                        tableTitle="OT Approval"
                        baseSearchParam={`?ipd__id=${ipdAdmission.id}&ot_schedule_status=APPROVED`}
                    />}
                </Tab>
                <Tab eventKey="bedcharges" title="Bed Charges">
                    {bedChargePreData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/707"
                        searchForm=""
                        url="/nursing/bedcharge/"
                        baseSearchParam={`?ipd__id=${ipdAdmission.id}`}
                        tableName="bedcharge"
                        tableTitle="Bed Charges"
                        formTitle="Bed Charges"
                        modalFullscreen={false}
                        preData={bedChargePreData}
                    />}
                </Tab>
            </Tabs>
        </>
    )
}

export default PatientInfo;