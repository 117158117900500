import React, { useEffect, useState } from "react";
import { CustomPage } from "components";
import { useDispatch, useSelector } from "react-redux";

const LocationMaster = () => {
    const [multiLocation, setMultiLocation] = useState(false)
    const setting = useSelector((state) => state.adminReducer.setting);

    useEffect(() =>{
        if(setting){
            setMultiLocation(setting.location_enabled)
        }
    }, [setting])

    return (
        <>
            {multiLocation && <CustomPage
                form="https://nca.margytech.in/api/form/604"
                searchForm="https://nca.margytech.in/api/form/605"
                url="/user/location/"
                baseSearchParam=""
                tableName="location"
                tableTitle="Location"
                createFormButtonText="Add Location"
                formTitle="Location Master"
                modalFullscreen={false}
                addPermissions={['lis:master:location:add']}
            />}
        </>
    )
}

export default LocationMaster;