import React from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";

const OutlabBillingSummary = (params) => {
    const { id } = useParams();
    
    if (id) {
        return <Outlet />;
    }

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/669"
            url="/samplemanagement/sampletestprofile/outlab_summary/"
            tableName="outlabbillingsummary"
            tableTitle="Outlab Summary"
            baseSearchParam=""
        />
    )
}

export default OutlabBillingSummary;