import React from "react";
import { CustomPage } from "components";

const ClientMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/174"
            searchForm="https://nca.margytech.in/api/form/537"
            url="/master/client/"
            baseSearchParam=""
            tableName="client"
            tableTitle="Client"
            createFormButtonText="Add Client"
            formTitle="Client Master"
            modalFullscreen={true}
            addPermissions={['lis:opmaster:client:add']}
        />
    )
}

export default ClientMaster;