// import axios from "axios";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";

const axios = require("axios").default;

export const notify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    default:
      toast.error(msg);
  }
};

////////////////////////// QR - Actions //////////////////////////

export const loadQr = async (qrId) => {
  try {
    const response = await axios.get("/qr/" + qrId + "/");
    console.log("Response", response);
    if (response.status === 200) {
      notify("QR fetched successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
  }
};

export const loadQrs = async () => {
  try {
    const response = await axios.get(`/qr/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      notify("QRs fetched successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("There was a problem loading QRs!", "error");
  }
};

export const createQr = async (data) => {
  try {
    const response = await axios.post(`/qr/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status) {
      notify("Your QR has been created!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    alert("QR could not be created!");
  }
};

////////////////////////// BARCODE - Actions //////////////////////////

export const createBarcode = async (data) => {
  try {
    const response = await axios.post(`/barcode/barcode_pdf/`, data,
      {
        responseType: "blob",
      }
    );
    console.log("FILE DOWNLOAD RESPONSE:", response);
    fileDownload(response.data, "barcode.pdf");
  } catch (err) {
    console.log("Error", err);
    alert("Barcodes could not be created!");
  }
};

////////////////////////// COMMON - Actions //////////////////////////

export const performAction = async (tabelName, tableTitle, mode, data, id, initialApi, searchURL) => {
  switch (mode) {
    case "load":
      try {
        const response = await axios.get(initialApi + `/` + tabelName + `/` + id, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Response", response);
        if (response.status === 200) {
          // notify("Patient loaded successfully!", "success");
          return response.data;
        }
      } catch (err) {
        console.log("Error", err);
        notify("There was a problem loading " + tableTitle + "!", "error");
      }
      break;
    case "loadMany":
      try {
        const response = await axios.get(initialApi + `/` + tabelName + `/` + searchURL, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Response", response);
        if (response.status === 200) {
          // notify("Patients loaded successfully!", "success");
          return response.data;
        }
      } catch (err) {
        console.log("Error", err);
        notify("There was a problem loading " + tableTitle + "s!", "error");
      }
      break;
    case "create":
      try {
        const response = await axios.post(initialApi + `/` + tabelName + `/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Response", response);
        if (response.status) {
          notify(tableTitle + " has been created!", "success");
          return response.data;
        }
      } catch (err) {
        console.log("Error", err);
        notify(tableTitle + " could not be created!", "error");
      }
      break;
    case "update":
      try {
        const response = await axios.put(initialApi + `/` + tabelName + `/` + id + `/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Response", response);
        if (response.status) {
          notify(tableTitle + " has been updated!", "success");
          return response.data;
        }
      } catch (err) {
        console.log("Error", err);
        notify(tableTitle + " could not be updated!", "error");
      }
      break;
    case "delete":
      try {
        const response = await axios.delete(initialApi + `/` + tabelName + `/` + id);
        notify(tableTitle + " deleted!", "success");
        return response.status;
      } catch (err) {
        console.log("Error", err);
        notify(tableTitle + " could not be deleted!", "error");
      }
      break;
    case "download":
      try {
        const response = await axios.post(initialApi + `/` + tabelName + `/` + id + `/pdfDownload/`, data,
          {
            responseType: "blob",
          });
        console.log("FILE DOWNLOAD RESPONSE:", response);
        fileDownload(response.data, "card.pdf");
      } catch (err) {
        console.log(err);
      }
      break;
    default:
      break;
  }
}

export const customAction = async (url, searchURL, tableTitle, mode, data) => {
  switch (mode) {
    case "load":
      try {
        const response = await axios.get(url + searchURL, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Response", response);
        if (response.status === 200) {
          // notify("Patient loaded successfully!", "success");
          return response.data;
        }
      } catch (err) {
        console.log("Error", err);
        notify("There was a problem loading " + tableTitle + "!", "error");
      }
      break;
    case "loadMany":
      try {
        const response = await axios.get(url + searchURL, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Response", response);
        if (response.status === 200) {
          // notify("Patients loaded successfully!", "success");
          return response.data;
        }
      } catch (err) {
        console.log("Error", err);
        notify("There was a problem loading " + tableTitle + "s!", "error");
      }
      break;
    case "create":
      try {
        const response = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Response", response);
        if (response.status) {
          notify(tableTitle + " has been created!", "success");
          return response.data;
        }
      } catch (err) {
        console.log("Error", err);
        notify(tableTitle + " could not be created!", "error");
      }
      break;
    case "update":
      try {
        const response = await axios.put(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("RESPONSE", response);
        if (response.status == 200) {
          notify(tableTitle + " has been updated!", "success");
          return response.data;
        }
      } catch (err) {
        console.log("Error", err);
        notify(tableTitle + " could not be updated!", "error");
      }
      break;
    case "partialUpdate":
      try {
        const response = await axios.patch(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Response", response);
        if (response.status) {
          notify(tableTitle + " has been updated!", "success");
          return response.data;
        }
      } catch (err) {
        console.log("Error", err);
        notify(tableTitle + " could not be updated!", "error");
      }
      break;
    case "delete":
      try {
        const response = await axios.delete(url);
        // const response = await axios.delete(initialApi + `/` + tabelName + `/` + id);
        notify(tableTitle + " deleted!", "success");
        return response.status;
      } catch (err) {
        console.log("Error", err);
        notify(tableTitle + " could not be deleted!", "error");
      }
      break;
    case "download":
      try {
        const response = await axios.post(url, data,
          // const response = await axios.post(initialApi + `/` + tabelName + `/` + id + `/pdfDownload/`, data,
          {
            responseType: "blob",
          });
        console.log("FILE DOWNLOAD RESPONSE:", response);
        fileDownload(response.data, "file.pdf");
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
      break;
    case "file":
      try {
        const response = await axios.post(url, data,
          {headers: {
            "Content-Type": "multipart/form-data",
          }
        })
        if (response.status) {
          notify(tableTitle + " has been created!", "success");
          return response.data;
        }
      } catch (err) {
        console.log("Error", err);
        notify(tableTitle + " could not be created!", "error");
      }
      break;
    default:
      break;
  }
}

export const send_email = async (data) => {
  try {
    const response = await axios.post(`/send_email/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
    if(response.status === 200){
      notify("Email Sent Successfully!", "success");
      return response.data;
    }
  } catch(err) {
    console.log("Error", err);
    notify("There was a problem sending the email, please try again!", "error");
  }
}

export const send_wa_msg = async (data) => {
  try {
    const response = await axios.post(`/send_wa_message/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
    if(response.status === 200){
      notify("Message Sent Successfully!", "success");
      return response.data;
    }
  } catch(err) {
    console.log("Error", err);
    notify("There was a problem sending the message, please try again!", "error");
  }
}

export const loadTableFields = async (tableName) => {
  try {
    const response = await axios.get(`/tablefield/?table__uniquename=` + tableName + `&status=ACTIVE`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Patient loaded successfully!", "success");
      return response.data;
    }
  } catch (err) {
    console.log("Error", err);
    notify("There was a problem loading Table Fields!", "error");
  }
};


///////////////////////////////// PDF ACTIONS /////////////////////////
export const templateToPDF = async (data) => {
  try {
    const response = await axios.post(data.url, data, {
      responseType: "blob",
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      // fileDownload(response.data, "template.html");
      if (data.preview) {
        return response.data;
      } else {
        fileDownload(response.data, "template.pdf");
      }
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("Template could not be downloaded!", "warning");
  }
};

export const templateToPDFData = async (data) => {
  try {
    const response = await axios.post(data.url, data, {
      responseType: "blob",
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      // fileDownload(response.data, "template.html");
      return response.data;
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("Template could not be fetched!", "warning");
  }
};

export const fetchTemplateData = async (url) => {
  // try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      // fileDownload(response.data, "template.html");
      return response.data;
    }
  //   console.log("FILE DOWNLOAD RESPONSE:", response);
  // } catch (err) {
  //   console.log("Error", err);
  //   notify("Template could not be fetched!", "warning");
  // }
};


export const fetchMetaDataTags = async (metaDataType) => {
  try {
    const response = await axios.get("/metadata/metatagtype/?metatag__tag_code=" + metaDataType, {}, {
      responseType: "blob",
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      // fileDownload(response.data, "template.html");
      return response.data;
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("Template could not be downloaded!", "warning");
  }
}

export const exportDownload = async () => {
  try {
    const response = await axios.post("/frontdesk/patient/export/", {}, {
      responseType: "blob",
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      fileDownload(response.data, "template.xlsx");
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("File could not be downloaded!", "warning");
  }
}

export const importData = async () => {
  try {
    const response = await axios.post("/frontdesk/patient/import_data/", {}, {
      responseType: "blob",
    });
    console.log("Response", response);
    if (response.status === 200) {
      // notify("Template fetched successfully!", "success");
      // fileDownload(response.data, "template.xlsx");
    }
    console.log("FILE DOWNLOAD RESPONSE:", response);
  } catch (err) {
    console.log("Error", err);
    notify("Import is not executed!", "warning");
  }
}

// customLink
export const customLink = async (method, url, action, id, successMsg, errorMsg) => {
  switch (method.toUpperCase()) {
    case "POST":
      try {
        const response = await axios.post(url + id + action);
        if (response.status == 200) {
          notify(successMsg, "success")
        } else {
          notify(errorMsg, "error");
        }
      } catch (err) {
        notify(errorMsg, "error");
      }
      break;
    case "PUT":
      try {
        const response = await axios.put(url + id + action);
        if (response.status == 200) {
          notify(successMsg, "success")
        } else {
          notify(errorMsg, "error");
        }
      } catch (err) {
        notify(errorMsg, "error");
      }
      break;
    default:
      try {
        const response = await axios.get(url + id + action);
        if (response.status == 200) {
          notify(successMsg, "success")
        } else {
          notify(errorMsg, "error");
        }
      } catch (err) {
        notify(errorMsg, "error");
      }
      break;
  }
}

export const loadDashboard = async (product, module, startDateTime, endDateTime) => {
  const response = await axios.post('/dashboard/', { product: product, module: module, startDateTime: startDateTime, endDateTime: endDateTime });
  if (response.status == 200) {
    return response.data;
  }
} 