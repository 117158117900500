import React from "react";
import { FullPageTable } from "components";

const RejectionSummary = (params) => {

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/654"
            url="/samplemanagement/sampletestprofile/"
            baseSearchParam={`?status=REJECTED`}
            tableName="samplerejection"
            tableTitle=""
            formTitle=""
            modalFullscreen={true}
        />
    )
}

export default RejectionSummary;