import React from "react";
import { CustomPage } from "components";

const ProjectMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/538"
            searchForm="https://nca.margytech.in/api/form/181"
            url="/master/project/"
            baseSearchParam=""
            tableName="project"
            tableTitle="Project"
            createFormButtonText="Add Project"
            formTitle="Project Master"
            modalFullscreen={false}
            addPermissions={['lis:opmaster:project:add']}
        />
    )
}

export default ProjectMaster;