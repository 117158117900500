import React, { useState } from "react";
import { useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import * as actions from "actions/CommonActions";
import DataTable from "react-data-table-component";
import CustomListDropdown from "./Utils/CustomListDropdown";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select'

const PharmacyBilling = (params) => {
    const form = useRef(null)
    const [isOpen, setIsOpen] = useState(false);
    const [patient, setPatient] = useState(null)
    const [currentPatientId, setCurrentPatientId] = useState("")
    const [currentPatient, setCurrentPatient] = useState(null)
    const [billingItems, setBillingItems] = useState([])
    const navigate = useNavigate();

    const initialValues = {
      discount_type: "",
      discount: 0,
      cash: 0,
      upi: 0,
      debit_card: 0,
      credit_card: 0,
      cheque: 0,
      e_wallet: 0,
      net_banking: 0
    };
    const [values, setValues] = useState(initialValues);
    let grossAmount = 0
    let total_tax = 0
    let total_tax_amount = 0

    billingItems.forEach(item => {
      console.log(item)
      const item_amount = item.item_data?.patient_rate * item.selected_quantity
      total_tax = item.item_data?.gst + item.item_data?.vat
      total_tax_amount += item_amount * total_tax * 0.01
      grossAmount += item_amount
    })
    let salesAmount = grossAmount + total_tax_amount;

    if(values.discount_type === "AMOUNT" && values.discount >= 0){
      salesAmount = salesAmount - values.discount
    }else if(values.discount_type === "PERCENTAGE" && values.discount >= 0){
      let discount = salesAmount * values.discount * 0.01
      salesAmount = salesAmount - discount
    }
    let balancePayable = salesAmount - values.cash - values.upi - values.debit_card - values.credit_card - values.cheque - values.e_wallet - values.net_banking;

    const customStyles = {
      rows: {
        style: {
          minHeight: "72px",
        },
      },
      headCells: {
        style: {
          fontWeight: "bold",
          padding: "15px",
          border: "1px solid #eee",
          color: "#fff",
          borderBottom: "1px solid #999",
          backgroundColor: "#006699",
        },
      },
      cells: {
        style: {
          borderLeft: "1px solid #eee",
          borderRight: "1px solid #eee",
          minHeight: "50px",
        },
      },
    };

    const columns = [
      {
        name: "Item Code",
        selector: row => row.item_data?.code
      },
      {
        name: "Item Name",
        selector: row => row.item_data?.name
      },
      {
        name: "Batch",
        selector: row => row.batch
      },
      {
        name: "Item Quantity",
        selector: row => row.selected_quantity,
      },
      {
        name: "Patient Rate",
        selector: row => row.item_data?.patient_rate,
      },
      {
        name: "VAT",
        selector: row => row.item_data?.vat
      },
      {
        name: "GST",
        selector: row => row.item_data?.gst
      },
      {
        name: "Action",
        selector: null,
        cell: (row, index) => <Button className="btn-danger fa fa-trash" onClick={() => {
            const newData = billingItems.filter((item, indexElem) => index != indexElem)
            setBillingItems(newData)
        }}></Button>,
        button: true,
      }
    ]

    const patientData = async () => {
      const response = await actions.customAction("/frontdesk/patient", "", "Patients", "loadMany", null)
      if(response){
        setPatient(response)
      }
    }

    const patientOptions = patient?.map((elem) => {
      return {value: elem.id, label: `${elem.first_name} ${elem.last_name}`}
    })

    useEffect(() =>{
      if(currentPatientId){
        setCurrentPatient(patient?.find((elem) => elem.id === currentPatientId))
      }
    }, [currentPatientId, patient, currentPatient])

    useEffect(()=>{
      patientData()
    }, [])

    const handleInputChange = (e) => {
      const { name, value } = e.target;
  
      setValues({
        ...values,
        [name]: value,
      });
    };

    const handleSubmit = async (e) =>{
      e.preventDefault()
      const formData = new FormData(form.current);
      let object = {};
      formData.forEach((value, key) => object[key] = value);
      object["billing_items"] = billingItems;
      const response = await actions.customAction("/pharmacy/pharmacybilling/", "", "Pharmacy bill", "create", object);
      if (response) {
        navigate(`/pharmacy/billing/summary/`)
      }
    }

    return (
            <>
              <Form className="px-5" ref={form} onSubmit={(e) => handleSubmit(e)}>
                <Container fluid className="card-form">
                  <h2 className="d-flex justify-content-center">Pharmacy Billing</h2>
                  <hr/>
                  <Row className="mb-3 mt-4">
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>UHID</Form.Label>
                        <Form.Control
                          name="uhid"
                          type="text"
                          value={currentPatient?.uhid}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Patient Name</Form.Label>
                        <Form.Control
                          name="patient_name"
                          type="text"
                          value={`${currentPatient?.first_name ? currentPatient?.first_name : ""} ${currentPatient?.last_name ? currentPatient?.last_name : ""}`}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Tariff</Form.Label>
                        <Form.Control name="tariff" type="text" disabled/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3 mt-4">
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Visit Type</Form.Label>
                        <Form.Select name="visit_type" type="date">
                            <option>Select visit type</option>
                            <option value="OPD">OPD</option>
                            <option value="IPD">IPD</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                    <Form.Group>
                        <Form.Label>Patient</Form.Label>
                        <Select name="patient" options={patientOptions} onChange={(e) => {
                          setCurrentPatientId(e.value)
                        }
                        }/>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Billing Mode</Form.Label>
                        <Form.Select name="billing_mode" type="text">
                            <option>Select Billing type</option>
                            <option value="CASH">CASH</option>
                            <option value="CREDIT">CREDIT</option>
                            <option value="CARDSALE_ONLINE">CARDSALE/ONLINE</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
                <Container fluid className="card-form mt-5">
                  <h2 className="">Items</h2>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Control name="total_tax" value={total_tax} hidden/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <CustomListDropdown setBillingItems={setBillingItems} isOpen={isOpen} setIsOpen={setIsOpen} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3 mt-4">
                    <DataTable data={billingItems} columns={columns} customStyles={customStyles} />
                  </Row>
                  <hr/>
                  <Row className="mb-3 mt-4">
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Gross Amount</Form.Label>
                        <Form.Control name="gross_amount" type="number" value={grossAmount} readOnly/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Discount Type</Form.Label>
                        <Form.Select name="discount_type" type="text" value={values.discount_type} onChange={handleInputChange}>
                            <option value="">Select Discount type</option>
                            <option value="AMOUNT">AMOUNT</option>
                            <option value="PERCENTAGE">PERCENTAGE</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Discount</Form.Label>
                        <Form.Control name="discount" type="number" value={values.discount} onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Discount Remark</Form.Label>
                        <Form.Control name="discount_remarks" type="textarea"/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>CGST Tax Amount</Form.Label>
                        <Form.Control name="cgst_tax_amount" type="number" value={total_tax_amount/2} readOnly/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3 mt-4">
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Sales Amount</Form.Label>
                        <Form.Control name="sales_amount" type="number"  value={salesAmount} readOnly/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Cash</Form.Label>
                        <Form.Control name="cash" type="number" value={values.cash} onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>UPI</Form.Label>
                        <Form.Control name="upi" type="text" value={values.upi} onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>UPI Transaction ID</Form.Label>
                        <Form.Control name="upi_transaction_id" type="text"/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>SGST Tax Amount</Form.Label>
                        <Form.Control name="sgst_tax_amount" type="number" value={total_tax_amount/2} readOnly/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3 mt-4">
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Debit Card</Form.Label>
                        <Form.Control name="debit_card" type="number" value={values.debit_card} onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Credit Card</Form.Label>
                        <Form.Control name="credit_card" type="number" value={values.credit_card} onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Card Number</Form.Label>
                        <Form.Control name="card_number" type="number"/>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Bank Name</Form.Label>
                        <Form.Control name="bank_name" type="text"/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Balance Payable</Form.Label>
                        <Form.Control name="balance_payable" type="number" value={balancePayable} readOnly/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3 mt-4">
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Cheque</Form.Label>
                        <Form.Control name="cheque" type="number" value={values.cheque} onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Cheque No.</Form.Label>
                        <Form.Control name="cheque_no" type="number"/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>E-Wallet</Form.Label>
                        <Form.Control name="e_wallet" type="number" value={values.e_wallet} onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Wallet Name</Form.Label>
                        <Form.Control name="wallet_name" type="text"/>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group>
                        <Form.Label>Wallet ID</Form.Label>
                        <Form.Control name="wallet_id" type="text"/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3 mt-4">
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Transfer Type</Form.Label>
                        <Form.Select name="transfer_type" type="text">
                          <option value="">Select Transfer Type</option>
                          <option value="NEFT">NEFT</option>
                          <option value="RTGS">RTGS</option>
                          <option value="IMPS">IMPS</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Net Banking</Form.Label>
                        <Form.Control name="net_banking" type="number" value={values.net_banking} onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Ref No / Trans No</Form.Label>
                        <Form.Control name="transaction_no" type="number"/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3 mt-4">
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Swipe Charges Debit Card</Form.Label>
                        <Form.Control name="swipe_charges_debit_card" type="number" readOnly/>
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Swipe Charges Credit Card</Form.Label>
                        <Form.Control name="swipe_charges_credit_card" type="number" readOnly/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center align-items-center mt-5">
                    <Button type="submit">Save & Close</Button>
                  </div>
                </Container>
            </Form>
        </>
    )
}

export default PharmacyBilling;