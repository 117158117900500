import React, { useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { FullPageTable } from "components";
import * as actions from "actions/CommonActions";
import { toast } from "react-toastify";

const GenerateClientInvoice = (params) => {
    const form = useRef(null);
    const [response, setResponse] = useState(true)

    const handleGenerateInvoice = async (e) => {
        e.preventDefault();
        setResponse(false)
        const response = await actions.customAction("/account/clientinvoice/generate_invoice/", "", "Client Invoices", "load", null);
        if (response) {
            toast.success("Invoices generated successfully!");
        }
        setResponse(response)
    };

    return (
        <>
            <Form ref={form} onSubmit={(e) => handleGenerateInvoice(e)}>
                <div className="d-flex justify-content-end me-3">
                    <Button type="submit">
                        Generate Invoices
                    </Button>
                </div>
            </Form>
            {response ?
                <FullPageTable
                    searchForm="https://nca.margytech.in/api/form/667"
                    url="/account/clientinvoice/"
                    tableName="generateclientinvoice"
                    tableTitle="Invoice"
                    baseSearchParam=""
                />
                :
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: "50vh" }}
                >
                    <Spinner />
                </div>
            }
        </>
    )
}

export default GenerateClientInvoice;