import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const EmailPill = ({email, removeEmail}) => {
    return (
        <div className="d-flex bg-primary mx-1 my-1 px-2" style={{borderRadius: 5, float: 'left'}}>
            <div className="text-white">
                <small>{email}</small>
            </div>
            <div>
            <AiOutlineClose
              size={15}
              color="#fff"
              onClick={removeEmail}
            />
            </div>
        </div>
    );
}

export default EmailPill;