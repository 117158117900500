import React, { useState, useRef, useEffect } from "react";
import { Outlet, useLocation, Link } from 'react-router-dom';
import { Card, Row, Col, Container, Form as BForm } from 'react-bootstrap'
import ModuleDatePicker from '../Common/ModuleItem/ModuleDatePicker'
import { MdGroup } from "react-icons/md";
import * as actions from "actions/CommonActions";
import ModuleCard from "pages/Common/ModuleItem/ModuleCard";
import moment from "moment";
import { GiDrippingTube, GiTestTubes } from "react-icons/gi";
import { RiTestTubeFill } from "react-icons/ri";
import { FaCheck, FaClock, FaPrescription, FaPrescriptionBottle, FaTimes } from "react-icons/fa";

const LisDashboard = () => {
    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";
    const param2 = params.length > 1 ? params[1] : "";

    const [data, setData] = useState(null);

    const today = new Date();
    const [startDateTime, setStartDateTime] = useState(moment(today.setHours(0, 0, 0, 0)).format("YYYY-MM-DDTHH:mm:ssZ"));
    const [endDateTime, setEndDateTime] = useState(new Date());

    const fetchData = async () => {
        const response = await actions.loadDashboard("lis", "dashboard", startDateTime, endDateTime);
        if (response) {
            setData(response);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (param2) {
        return (
            <Outlet />
        );
    }

    return (
        <Container fluid className="p-5">
            <Row>
                <Col sm={12}>
                <ModuleDatePicker startDateTime={startDateTime} endDateTime={endDateTime} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} fetchData={fetchData}/>
                <Row>
                        <ModuleCard size={3}
                            cardTitle="Total Tests"
                            cardContentTitle1={"Tests"}
                            type="2"
                            iconBoxColor="#b0d9fd"
                            cardContent1={<b>{data?.test?.total}</b>}
                            icon={<RiTestTubeFill color="#bsbsbs" size={30} />}
                        />
                        <ModuleCard size={3}
                            cardTitle="Total Samples"
                            cardContent1={<b>{data?.sample?.total}</b>}
                            cardContentTitle1={"Samples"}
                            type="2"
                            iconBoxColor="#b0d9fd"
                            icon={<FaPrescriptionBottle color="#bsbsbs" size={30} />}
                        />
                        <ModuleCard size={3}
                            cardTitle="Reported"
                            cardContent1={<b>{data?.test?.reported}</b>}
                            cardContentTitle1={"Reported Samples"}
                            type="2"
                            iconBoxColor="#b0d9fd"
                            icon={<FaCheck color="#bsbsbs" size={30} />}
                        />
                        <ModuleCard size={3}
                            cardTitle="Pending"
                            cardContent1={<b>{data?.test?.pending}</b>}
                            cardContentTitle1={"Pending Samples"}
                            type="2"
                            iconBoxColor="#b0d9fd"
                            icon={<FaClock color="#bsbsbs" size={30} />}
                        />
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default LisDashboard;