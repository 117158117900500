import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Form, Spinner } from "react-bootstrap";
import { FullPageTable, CustomTable, SampleAccessionTable } from "components";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const SampleAccession = () => {
    const { opdIpdAdmissionId } = useParams();
    const [opdIpdData, setOpdIpdData] = useState(null);
    const [samples, setSamples] = useState([]);
    const [currentSample, setCurrentSample] = useState(null);
    const [currentSampleData, setCurrentSampleData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [print, setPrint] = useState(false)
    const form = useRef(null);
    const sampleAccessionRef = useRef()

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";
    const param2 = params.length > 0 ? params[1] : "";
    const param3 = params.length > 0 ? params[2] : "";
    const param4 = params.length > 0 ? params[3] : "";

    const fetchOpdIpdAdmission = async () => {
        if (param4 === "opd") {
            const response = await actions.customAction("/frontdesk/opdadmission/" + opdIpdAdmissionId + "/", "", "Samples", "load", null);
            if (response) {
                setOpdIpdData(response);
            }
        } else if (param4 === "ipd") {
            const response = await actions.customAction("/frontdesk/ipdadmission/" + opdIpdAdmissionId + "/", "", "Samples", "load", null);
            if (response) {
                setOpdIpdData(response);
            }
        } else if (param4 === "ed") {
            const response = await actions.customAction("/frontdesk/edadmission/" + opdIpdAdmissionId + "/", "", "Samples", "load", null);
            if (response) {
                setOpdIpdData(response);
            }
        }
    }

    const fetchSamples = async () => {
        if (param4 === "opd") {
            const response = await actions.customAction("/samplemanagement/sample/", "?opd=" + opdIpdAdmissionId + "&status=COLLECTED", "Samples", "loadMany", null);
            if (response) {
                //////// Needs Improvement
                let arr = [];
                for (let i = 0; i < response.length; i++) {
                    const result = await fetchSampleTestProfile(response[i].id);
                    console.log("RESULT:", result);
                    if (result.length > 0) {
                        arr.push(response[i]);
                    }
                }
                setSamples(arr);
                if (arr.length > 0) {
                    setCurrentSample(arr[0]?.id);
                }
            }
        } else if (param4 === "ipd") {
            const response = await actions.customAction("/samplemanagement/sample/", "?ipd=" + opdIpdAdmissionId + "&status=COLLECTED", "Samples", "loadMany", null);
            console.log("Response", response);
            if (response) {
                let arr = [];
                for (let i = 0; i < response.length; i++) {
                    const result = await fetchSampleTestProfile(response[i].id);
                    console.log("RESULT:", result);
                    if (result.length > 0) {
                        arr.push(response[i]);
                    }
                }
                setSamples(arr);
                if (arr.length > 0) {
                    setCurrentSample(arr[0]?.id);
                }
            }
        } else if (param4 === "ed") {
            const response = await actions.customAction("/samplemanagement/sample/", "?ed=" + opdIpdAdmissionId + "&status=COLLECTED", "Samples", "loadMany", null);
            console.log("Response", response);
            if (response) {
                let arr = [];
                for (let i = 0; i < response.length; i++) {
                    const result = await fetchSampleTestProfile(response[i].id);
                    console.log("RESULT:", result);
                    if (result.length > 0) {
                        arr.push(response[i]);
                    }
                }
                setSamples(arr);
                if (arr.length > 0) {
                    setCurrentSample(arr[0]?.id);
                }
            }
        }
        setLoading(false);
    }

    const fetchSampleTestProfile = async (thisSample) => {
        const response = await actions.customAction("/samplemanagement/sampletestprofile/", "?sample=" + thisSample + "&status=PENDING", "Sample Tests", "loadMany", null);
        return response;
    }

    useEffect(() => {
        if (opdIpdAdmissionId && param4) {
            fetchOpdIpdAdmission();
            fetchSamples();
        }
    }, [opdIpdAdmissionId]);

    useEffect(() => {
        if (currentSample) {
            setCurrentSampleData(samples.filter((sample) => sample.id === currentSample));
        }
    }, [currentSample])

    const handleSampleAccession = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        let data = {};
        data[param4] = opdIpdAdmissionId;
        let info = [];
        for (var value of formData.keys()) {
            if (formData.get(value) === "on") {
                let id = value.substring(7, value.length);
                info.push({ id: id });
            }
            data["sample_test_profile"] = info;
        }
        data['print_barcode'] = print
        if(print){
            const response = await actions.customAction(
                "/samplemanagement/sampletestprofile/confirmaccession/",
                "",
                "Samples",
                "download",
                data
              );
              if (response) {
                setPrint(false)
                sampleAccessionRef.current.updateTable()
              }
        } else {
            const response = await actions.customAction("/samplemanagement/sampletestprofile/confirmaccession/", "", "Sample Accession", "create", data);
            if (response) {
                sampleAccessionRef.current.updateTable();
            }
        }
    };

    console.log(currentSampleData);
    if (loading) {
        return (
            <Container className="w-100 h-100 d-flex justify-content-center align-items-center py-5">
                <Spinner
                    className="my-5"
                    animation="border"
                    variant="primary"
                />
            </Container>
        );
    }

    return (
        <Container className="mt-3">
            <div className="mx-1 d-flex flex-column">
                <div className="py-2">Visit Id / Admission Id: <b>{opdIpdData?.visit_id || (opdIpdData?.admission_id ? (opdIpdData?.admission_id + " (IPD)") : null)}</b></div>
                <div className="py-2">Patient Details: <b>{opdIpdData?.uhid}</b> / <b>{opdIpdData?.patient_name}</b></div>
                <div className="py-2">Age: <b>{opdIpdData?.patient_data?.age} {opdIpdData?.patient_data?.age_type}</b></div>
                <div className="py-2">Referred By: <b>{opdIpdData?.referred_doctor_data?.name}</b></div>
            </div>
            <Form className="mx-1 py-2" ref={form} onSubmit={(e) => handleSampleAccession(e)}>
                <div className="d-flex">
                    <Form.Group className="me-5">
                        <Form.Label>Available Vials:</Form.Label>
                        <Form.Select name="specimen_type" onChange={(e) => setCurrentSample(e.target.value)}>
                            {samples?.map((sample) => {
                                return <option id={sample.id} value={sample.id}>{sample.specimen_type}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>BarCode:</Form.Label>
                        {currentSampleData && <Form.Control disabled type="text" value={currentSampleData[0]?.barcode} />}
                    </Form.Group>
                </div>
                <div className="my-3 d-flex justify-content-center">
                    <Button type="submit">Accept Sample</Button>
                    <Button
                        type="submit"
                        className="mx-2"
                        name="print_barcode"
                        value="true"
                        onClick={() => setPrint(true)}
                    >
                        Accept Sample & Print Barcode
                    </Button>
                </div>
                <div className="mt-4 mx-1"><b>Tests Requested</b></div>
                {currentSample && <SampleAccessionTable
                    ref={sampleAccessionRef}
                    url="/samplemanagement/sampletestprofile/"
                    baseSearchParam={`?sample=${currentSample}&status=PENDING`}
                    form={form}
                />}
                {/* {currentSample && <CustomTable
                    ref={customTableRef}
                    url={`/samplemanagement/sampletestprofile/`}
                    baseSearchParam={`?sample=${currentSample}&status=PENDING`}
                    tableName="sampletestprofileaccession"
                    tableTitle="Test Samples"
                    searchURL=""
                    setForm=""
                />} */}
            </Form>
        </Container >
    )
}

export default SampleAccession;