import { FullPageTable } from "components";

const SpecialReportList = () =>{

    return(
        <FullPageTable
            // form="https://nca.margytech.in/api/form/477"
            searchForm="https://nca.margytech.in/api/form/734"
            url="/samplemanagement/sampletestprofile/"
            baseSearchParam="?status=RESULT&test__reporting_style__type_description=DESCRIPTIVE"
            tableName="specialreportentry"
            tableTitle="Special Report Entry"
            // createFormButtonText="Add Special Report"
            // formTitle="Special Reports Master"
            modalFullscreen={true}
            addPermissions={[]}
        />
    )
}

export default SpecialReportList;
