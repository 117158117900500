import React from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";

const RISListReport = (params) => {
    const { patientId } = useParams();

    if (patientId) {
        return <Outlet />;
    }

    return (
        <FullPageTable
            // form="https://nca.margytech.in/api/form/727"
            searchForm="https://nca.margytech.in/api/form/742"
            url="/studymanagement/generatereport/liststudy/"
            tableName="rispatientstudylist"
            tableTitle="Generate"
            // formTitle="Generate Radiology Report"
            // modalFullscreen={true}
            baseSearchParam=""
            searchURL=""
            setForm=""
        />
    )
}

export default RISListReport;