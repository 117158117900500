import React, { useState, useRef, useEffect } from "react";
import { Button, Container, Modal, Form, Spinner } from "react-bootstrap";
import { CustomTable, CustomPage } from "components";
import { useParams, Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineClose } from "react-icons/ai";
import * as actions from "actions/CommonActions";

const TableField = () => {
    const { tableId } = useParams();
    const [table, setTable] = useState(null);

    const fetchTableInfo = async () => {
        const response = await actions.customAction("/table/" + tableId + "/", "", "Table", "load", null);
        console.log(response);
        if (response) {
            setTable(response);
        }
    }
    console.log(table);

    useEffect(() => {
        fetchTableInfo();
    }, [tableId])

    return (
        <Container>
            <h3 className="mt-5 pt-5 text-black">TableFields - {table?.name}</h3>
            <CustomPage
                form="https://nca.margytech.in/api/form/676"
                searchForm=""
                url="/tablefield/"
                baseSearchParam={`?table__id=` + tableId}
                tableName="tablefield"
                tableTitle="Table Field"
                createFormButtonText="Add Table Field"
                formTitle="Table Field Master"
                modalFullscreen={false}
            />
        </Container>
    );
}

export default TableField;