import React from "react";
import { CustomPage } from "components";

const ModalityMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/319"
            // searchForm="https://nca.margytech.in/api/form/547"
            url="/master/modality/"
            baseSearchParam=""
            tableName="modality"
            tableTitle="Modality"
            createFormButtonText="Add Modality"
            formTitle="Modality Master"
            modalFullscreen={true}
        />
    )
}

export default ModalityMaster;