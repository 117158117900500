import React from "react";
import { CustomPage } from "components";

const DesignationMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/216"
            searchForm="https://nca.margytech.in/api/form/560"
            url="/master/designation/"
            baseSearchParam=""
            tableName="designation"
            tableTitle="Designation"
            createFormButtonText="Add Designation"
            formTitle="Designation Master"
            modalFullscreen={false}
            addPermissions={['his:master:designation:add']}
        />
    )
}

export default DesignationMaster;