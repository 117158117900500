import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

const FrontDesk = () => {
    return (
        <Outlet 
        
        form="https://nca.margytech.in/api/form/507"
        searchForm="https://nca.margytech.in/api/form/531"
        url="/frontdesk/patient/"
        baseSearchParam=""
        tableName="patient"
        tableTitle="Patient"
        addPermissions={['his:frontdesk:patient']}
        />
    );
}

export default FrontDesk;