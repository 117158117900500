import logo from "./logo.svg";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Breakpoint } from "react-socks";
import { ToastContainer } from "react-toastify";

import * as authActions from "actions/PublicAuthActions";

import {
  Sidebar,
  NavBar,
  NavComponentbar,
  NavBreadcrumbBar,
  Footer,
  NoAccess,
  AccessControl,
} from "components";

import SuperUser from "./pages/SuperUser/SuperUser";
import SuperUserLogin from "./pages/SuperUser/Auth/SuperUserLogin";
import TenantMain from "./pages/SuperUser/Tenant/TenantMain";
import TenantInfo from "./pages/SuperUser/Tenant/TenantInfo";

import Login from "./pages/Login/Login";

import Settings from "./pages/Settings/Settings";
import SelectTemplate from "./pages/Settings/Template";
import FormData from "./pages/Settings/FormData";

import Product from "./pages/Product/Product";

import Table from "./pages/Table/Table";
import TableField from "./pages/Table/TableField";
import CreateTableField from "./pages/Table/CreateTableField";
import UpdateTableField from "./pages/Table/UpdateTableField";

import Template from "./pages/Template/Template";
import QrMain from "./pages/Qr/QrMain";
import BarcodeMain from "./pages/Barcode/BarcodeMain";

import HisDashboard from "./pages/His/HisDashboard";

import FrontDesk from "./pages/His/FrontDesk/FrontDesk";
import SearchPatient from "./pages/His/FrontDesk/SearchPatient";
import PatientRegistration from "./pages/His/FrontDesk/PatientRegistration";
import OPDAdmission from "./pages/His/FrontDesk/OPDAdmission";
import EmergencyOPDAdmission from "./pages/His/FrontDesk/EmergencyOPDAdmission";
import IPDAdmission from "./pages/His/FrontDesk/IPDAdmission";
import Appointment from "./pages/His/FrontDesk/Appointment";
import PatientAppointment from "./pages/His/FrontDesk/PatientAppointment";
import RegistrationSummary from "./pages/His/FrontDesk/RegistrationSummary";
import CostEstimation from "./pages/His/FrontDesk/CostEstimation";
import LeaveSchedule from "./pages/His/FrontDesk/LeaveSchedule";
import CabinSchedule from "./pages/His/FrontDesk/CabinSchedule";

import HisMaster from "./pages/His/HisMaster";
import UserMaster from "./pages/MetaData/User";
import DietMaster from "./pages/His/Master/Diet";
import WardMaster from "./pages/His/Master/Ward";
import BedMaster from "./pages/His/Master/Bed";
import CabinMaster from "./pages/His/Master/Cabin";
import CashCounterMaster from "./pages/His/Master/CashCounter";
import ConsultingServiceMaster from "./pages/His/Master/ConsultingService";
import DoctorMaster from "./pages/His/Master/Doctor";
import DepartmentMaster from "./pages/His/Master/Department";
import SubDepartmentMaster from "./pages/His/Master/SubDepartment";
import DesignationMaster from "./pages/His/Master/Designation";
import SurgeryMaster from "./pages/His/Master/Surgery";
import InsuranceMaster from "./pages/His/Master/Insurance";
import InsuranceSpecial from "./pages/His/Master/InsuranceSpecial";
import TariffMaster from "./pages/His/Master/Tariff";
import KitMaster from "./pages/His/Master/Kit";
import PackageMaster from "./pages/His/Master/Package";
import ReferredDoctorMaster from "./pages/His/Master/ReferredDoctor";
import EmployeeMaster from "./pages/His/Master/Employee";
import OTMaster from "./pages/His/Master/OT";
import DiscountMaster from "./pages/His/Master/Discount";
import HMOMaster from "./pages/His/Master/HMO";


import Nursing from "./pages/His/Nursing/Nursing";
import IPDPatientSummary from "./pages/His/Nursing/IPDPatientSummary";
import NursingRoaster from "./pages/His/Nursing/NursingRoaster";
import PatientAcceptance from "./pages/His/Nursing/PatientAcceptance";
import NursingConsole from "./pages/His/Nursing/NursingConsole";
import PatientInfo from "./pages/His/Nursing/PatientInfo";
import BedTransferSummary from "./pages/His/Nursing/BedTransferSummary";
import BedTransfer from "./pages/His/Nursing/BedTransfer";
import PatientDischarge from "./pages/His/Nursing/PatientDischarge";
import DeathSummary from "./pages/His/Nursing/DeathSummary";
import DischargeTemplate from "./pages/His/Nursing/DischargeTemplate";
import PromissoryNote from "./pages/His/Nursing/PromissoryNote";

import Doctor from "./pages/His/Doctor/Doctor";
import OPDConsultation from "./pages/His/Doctor/OPDConsultation";
import DoctorNursingConsole from "./pages/His/Doctor/DoctorNursingConsole";
import OPDPatientInfo from "./pages/His/Doctor/OPDPatientInfo";

import Emergency from "./pages/His/Emergency/Emergency";
import EDPatientSummary from "./pages/His/Emergency/EDPatientSummary";
import EmergencyConsole from "./pages/His/Emergency/EmergencyConsole";
import EDPatientInfo from "./pages/His/Emergency/PatientInfo";
import EDPatientDischarge from "./pages/His/Emergency/PatientDischarge";
import EDDeathSummary from "./pages/His/Emergency/DeathSummary";

import OT from "./pages/His/OT/OT";
import OTSchedule from "./pages/His/OT/OTSchedule";
import OTApproval from "./pages/His/OT/OTApproval";
import PostOPNotes from "./pages/His/OT/PostOPNotes";

import Billing from "./pages/His/Billing/Billing";
import OPDBilling from "./pages/His/Billing/OPDBilling";
import OPDBillingInfo from "./pages/His/Billing/OPDBillingInfo";
import IPDBilling from "./pages/His/Billing/IPDBilling";
import IPDBillingInfo from "./pages/His/Billing/IPDBillingInfo";
import IPDReceipt from "./pages/His/Billing/IPDReceipt";

// His Non Medical Stock
import HisNonMedicalStock from "pages/His/NonMedicalStock/HisNonMedicalStock";
import HisDepartmentStock from "pages/His/NonMedicalStock/HisDepartmentStock";
import HisMaterialRequest from "pages/His/NonMedicalStock/HisMaterialRequest";
import HisStockConsumption from "pages/His/NonMedicalStock/HisStockConsumption";
import HisStockTransfer from "pages/His/NonMedicalStock/HisStockTransfer";
import HisItemExpiry from "pages/His/NonMedicalStock/HisItemExpiry";

// His Medical Stock
import HisMedicalStock from "pages/His/MedicalStock/HisMedicalStock";
import HisMedicalDepartmentStock from "pages/His/MedicalStock/HisMedicalDepartmentStock";
import HisMedicalMaterialRequest from "pages/His/MedicalStock/HisMedicalMaterialRequest";
import HisMedicalStockConsumption from "pages/His/MedicalStock/HisMedicalStockConsumption";
import HisMedicalStockTransfer from "pages/His/MedicalStock/HisMedicalStockTransfer";
import HisMedicalItemExpiry from "pages/His/MedicalStock/HisMedicalItemExpiry";

// common master
import TemplateMaster from "./pages/Common/Master/Template";
import LisReferredDoctorMaster from "./pages/Lis/Master/ReferredDoctor";

import LisDashboard from "./pages/Lis/LisDashboard";

import FrontDeskLis from "./pages/Lis/FrontDesk/FrontDeskLis";
import SearchPatientLis from "./pages/Lis/FrontDesk/SearchPatientLis";
import SampleRegistration from "./pages/Lis/FrontDesk/SampleRegistration";
import SampleRegistrationSummary from "./pages/Lis/FrontDesk/SampleRegistrationSummary";
import RegistrationPayment from "./pages/Lis/FrontDesk/RegistrationPayment";
import RejectionSummary from "./pages/Lis/FrontDesk/RejectionSummary";
import TestProfileCostEstimation from "./pages/Lis/FrontDesk/TestProfileCostEstimation";

import SampleManagement from "./pages/Lis/SampleManagement/SampleManagement";
import PendingSample from "./pages/Lis/SampleManagement/PendingSample";
import SampleCollection from "./pages/Lis/SampleManagement/SampleCollection";
import CollectedSample from "./pages/Lis/SampleManagement/CollectedSample";
import SampleAccession from "./pages/Lis/SampleManagement/SampleAccession";

import ManageReporting from "./pages/Lis/ManageReporting/ManageReporting";
import OutlabSelection from "./pages/Lis/ManageReporting/OutlabSelection";
import OutlabUpload from "./pages/Lis/ManageReporting/OutlabUpload";
import ResultEntry from "./pages/Lis/ManageReporting/ResultEntry";
import ResultEntryDepartment from "./pages/Lis/ManageReporting/ResultEntryDepartment";
import ResultEntryTest from "./pages/Lis/ManageReporting/ResultEntryTest";
import ResultEntryProfile from "./pages/Lis/ManageReporting/ResultEntryProfile";
import PrintReport from "./pages/Lis/ManageReporting/PrintReport";

//LIS MEDICAL/NON MEDICAL STOck
import LisNonMedicalStock from "pages/Lis/NonMedicalStock/LisNonMedicalStock";
import LisMedicalItemExpiry from "pages/Lis/MedicalStock/LisMedicalItemExpiry";
import LisMedicalStockTransfer from "pages/Lis/MedicalStock/LisMedicalStockTransfer";
import LisMedicalStockConsumption from "pages/Lis/MedicalStock/LisMedicalStockConsumption";
import LisMedicalMaterialRequest from "pages/Lis/MedicalStock/LisMedicalMaterialRequest";
import LisMedicalDepartmentStock from "pages/Lis/MedicalStock/LisMedicalDepartmentStock";
import LisMedicalStock from "pages/Lis/MedicalStock/LisMedicalStock";
import LisItemExpiry from "pages/Lis/NonMedicalStock/LisItemExpiry";
import LisStockTransfer from "pages/Lis/NonMedicalStock/LisStockTransfer";
import LisStockConsumption from "pages/Lis/NonMedicalStock/LisStockConsumption";
import LisMaterialRequest from "pages/Lis/NonMedicalStock/LisMaterialRequest";
import LisDepartmentStock from "pages/Lis/NonMedicalStock/LisDepartmentStock";

import LisMaster from "./pages/Lis/LisMaster";
import TestMaster from "./pages/Lis/Master/Test";
import ProfileMaster from "./pages/Lis/Master/Profile";
import SpecialReportMaster from "./pages/Lis/Master/SpecialReport";
import FormulaMaster from "./pages/Lis/Master/Formula";
import CentreMaster from "./pages/Lis/Master/Centre";
// import CentreSpecialMaster from "./pages/Lis/Master/CentreSpecial";
// import CentreBulkSpecialMaster from "./pages/Lis/Master/CentreBulkSpecial";
import ClientMaster from "./pages/Lis/Master/Client";
import ClientSpecial from "./pages/Lis/Master/ClientSpecial";
import CenterSpecial from "./pages/Lis/Master/CenterSpecial";
import OutlabSpecial from "./pages/Lis/Master/OutlabSpecial";
import ProjectMaster from "./pages/Lis/Master/Project";
import ProjectSpecial from "./pages/Lis/Master/ProjectSpecial";
// import ProjectBulkRateMaster from "./pages/Lis/Master/ProjectBulkRate";
import AnalyzerMaster from "./pages/Lis/Master/Analyzer";
import BarcodeManager from "./pages/Lis/Master/BarcodeManager";
import AssetMaster from "./pages/Lis/Master/Asset";
import OutlabMaster from "./pages/Lis/Master/Outlab";
import QualityControlMaster from "./pages/Lis/Master/QualityControl";
import FilmContrastMaster from "./pages/Lis/Master/FilmContrast";

import Account from "./pages/Lis/Account/Account";
import BillingSummary from "./pages/Lis/Account/BillingSummary";
import BillingDetails from "./pages/Lis/Account/BillingDetails";
import ExpenseEntry from "./pages/Lis/Account/ExpenseEntry";
import ClientLedger from "./pages/Lis/Account/ClientLedger";
import ClientBillingSummary from "./pages/Lis/Account/ClientBillingSummary";
import DailyBillingSummary from "./pages/Lis/Account/DailyBillingSummary";
import CashCollectionSummary from "./pages/Lis/Account/CashCollectionSummary";
import GenerateClientInvoice from "./pages/Lis/Account/GenerateClientInvoice";
import AccountReceivable from "./pages/Lis/Account/AccountReceivable";
import OutlabBillingSummary from "./pages/Lis/Account/OutlabBillingSummary";
import OutlabBillingDetails from "./pages/Lis/Account/OutlabBillingDetails";

import RisDashboard from "./pages/Ris/RisDashboard";
import RisMaster from "./pages/Ris/RisMaster";
import StudyMaster from "./pages/Ris/Master/Study";
import ModalityMaster from "./pages/Ris/Master/Modality";
import RisReferredDoctorMaster from "pages/Ris/Master/ReferredDoctor";

import InventoryDashboard from "./pages/Inventory/InventoryDashboard";

import PharmacyDashboard from "./pages/Pharmacy/PharmacyDashboard";

import CommonMaster from "./pages/Common/Master/Master";
import ItemMaster from "./pages/Common/Master/Item";
import Pharmacy from "./pages/Pharmacy/PharmacyBilling/Billing";
import PharmacyBilling from "./pages/Pharmacy/PharmacyBilling/PharmacyBilling";
import PharmacySummary from "./pages/Pharmacy/PharmacyBilling/PharmacySummary";

import MetaData from "pages/MetaData/MetaData";
import MetaDataTag from "pages/MetaData/MetaDataTag";
import MetaDataTagType from "pages/MetaData/MetaDataTagType";

import UserRole from "pages/UserRole/UserRole";
import RolePermissions from "pages/UserRole/RolePermissions";



// RIS
import SearchPatientRis from "pages/Ris/Frontdesk/SearchPatientLis";
import StudyRegistration from "pages/Ris/Frontdesk/StudyRegistration";
import StudyRegistrationSummary from "pages/Ris/Frontdesk/StudyRegistrationSummary";
import WorkListSummary from "pages/Ris/Frontdesk/WorkListSummary";
import RadiologyConfirmation from "pages/Ris/Frontdesk/RadiologyConfirmation";
import RisRejectionSummary from "pages/Ris/Frontdesk/RejectionSummary";

import RISManageReporting from "pages/Ris/ManageReporting/ManageReporting";
import RISListReport from "pages/Ris/ManageReporting/ListReport";
import RISGenerateReport from "pages/Ris/ManageReporting/GenerateReport";
import RISPrintReport from "pages/Ris/ManageReporting/PrintReport";

import RisNonMedicalStock from "pages/Ris/NonMedicalStock/RisNonMedicalStock";
import RisDepartmentStock from "pages/Ris/NonMedicalStock/RisDepartmentStock";
import RisMaterialRequest from "pages/Ris/NonMedicalStock/RisMaterialRequest";
import RisStockConsumption from "pages/Ris/NonMedicalStock/RisStockConsumption";
import RisStockTransfer from "pages/Ris/NonMedicalStock/RisStockTransfer";
import RisItemExpiry from "pages/Ris/NonMedicalStock/RisItemExpiry";
import RisMedicalStock from "pages/Ris/MedicalStock/RisMedicalStock";
import RisMedicalDepartmentStock from "pages/Ris/MedicalStock/RisMedicalDepartmentStock";
import RisMedicalMaterialRequest from "pages/Ris/MedicalStock/RisMedicalMaterialRequest";
import RisMedicalStockConsumption from "pages/Ris/MedicalStock/RisMedicalStockConsumption";
import RisMedicalStockTransfer from "pages/Ris/MedicalStock/RisMedicalStockTransfer";
import RisMedicalItemExpiry from "pages/Ris/MedicalStock/RisMedicalItemExpiry";

// Common
import ModuleDashboard from "pages/Common/Containers/ModuleDashboard";
import VendorMaster from "pages/Pharmacy/Master/Vendor";
import PharmacyDeptBill from "pages/Pharmacy/PharmacyBilling/PharmacyDeptBill";
import PharmacyPOGenerationMain from "pages/Pharmacy/ProductOrder/PharmacyPOGenerationMain";
import PharmacyPOGeneration from "pages/Pharmacy/ProductOrder/PharmacyPOGeneration";
import PharmacyPOApproval from "pages/Pharmacy/ProductOrder/PharmacyPOApproval";
import PharmacyPORaise from "pages/Pharmacy/ProductOrder/PharmacyPORaise";
import PharmacyPurchaseOrder from "pages/Pharmacy/ProductOrder/PharmacyPurchaseOrder";
import PharmacyItemMaster from "pages/Pharmacy/Master/Item";
import PharmacyVendorMaster from "pages/Pharmacy/Master/Vendor";

// Pharmacy work order
import WorkOrder from "pages/Pharmacy/WorkOrder/WorkOrder";
import WorkOrderGeneration from "pages/Pharmacy/WorkOrder/WorkOrderGeneration";
import WorkOrderApproval from "pages/Pharmacy/WorkOrder/WorkOrderApproval";
import HoManagerApproval from "pages/Pharmacy/WorkOrder/HoManagerApproval";
import HoAccountsApproval from "pages/Pharmacy/WorkOrder/HoAccountsApproval";
import WorkOrderRaise from "pages/Pharmacy/WorkOrder/WorkOrderRaise";
import ItemSpecialRate from "pages/Pharmacy/Master/ItemSpecialRate";
import PurchaseOrderHoManager from "pages/Pharmacy/ProductOrder/PurchaseOrderHoManager";
import PurchaseOrderHoAccounts from "pages/Pharmacy/ProductOrder/PurchaseOrderHoAccounts";

// Pharmacy Good Inwards
import GoodInward from "pages/Pharmacy/GoodInward/GoodInward";
import PharmacyGoodInward from "pages/Pharmacy/GoodInward/PharmacyGoodInward";
import PharmacyGoodInwardCreate from "pages/Pharmacy/GoodInward/PharmacyGoodInwardCreate";
import PharmacyInwardDetails from "pages/Pharmacy/GoodInward/PharmacyInwardDetails";
import PharmacyInwardWithoutPO from "pages/Pharmacy/GoodInward/PharmacyInwardWithoutPO";

// Pharmacy Medical Stock
import PharmacyMedicalStock from "pages/Pharmacy/MedicalStock/PharmacyMedicalStock";
import PharmacyStockSummary from "pages/Pharmacy/MedicalStock/PharmacyStockSummary";
import PharmacyIndentSummary from "pages/Pharmacy/MedicalStock/PharmacyIndentSummary";
import PharmacyDispatchConfirmation from "pages/Pharmacy/MedicalStock/PharmacyDispatchConfirmation";
import PharmacyReturnFromDept from "pages/Pharmacy/MedicalStock/PharmacyReturnFromDept";
import PharmacyReturnToVendorMain from "pages/Pharmacy/MedicalStock/PharmacyReturnToVendorMain";
import PharmacyReturnToVendor from "pages/Pharmacy/MedicalStock/PharmacyReturnToVendor";
import PharmacyItemExpiry from "pages/Pharmacy/MedicalStock/PharmacyItemExpiry";

// Pharmacy Non Medical Stock
import PharmacyNonMedicalStock from "pages/Pharmacy/NonMedicalStock/PharmacyNonMedicalStock";
import PharmacyDepartmentStock from "pages/Pharmacy/NonMedicalStock/PharmacyDepartmentStock";
import PharmacyMaterialRequest from "pages/Pharmacy/NonMedicalStock/PharmacyMaterialRequest";
import PharmacyStockConsumption from "pages/Pharmacy/NonMedicalStock/PharmacyStockConsumption";
import PharmacyStockTransfer from "pages/Pharmacy/NonMedicalStock/PharmacyStockTransfer";

// Inventory Stock
import InventoryStock from "pages/Inventory/Stock/InventoryStock";
import InventoryIndentSummary from "pages/Inventory/Stock/InventoryIndentSummary";
import InventoryDepartmentStock from "pages/Inventory/Stock/InventoryDepartmentStock";
import InventoryStockTransfer from "pages/Inventory/Stock/InventoryStockTransfer";
import InventoryReturnToVendor from "pages/Inventory/Stock/InventoryReturnToVendor";
import InventoryStockConsumption from "pages/Inventory/Stock/InventoryStockConsumption";
import InventoryItemExpiry from "pages/Inventory/Stock/InventoryItemExpiry";

import InventoryItemMaster from "pages/Inventory/Master/Item";
import InventoryVendorMaster from "pages/Inventory/Master/Vendor";
import InventoryGoodsInward from "pages/Inventory/GoodsInward/InventoryGoodsInward";
import InventoryInwardDetails from "pages/Inventory/GoodsInward/InventoryInwardDetail";
import InventoryInwardWithoutPO from "pages/Inventory/GoodsInward/InventoryInwardWithoutPO";
import GoodsInwardInventory from "pages/Inventory/GoodsInward/GoodsInwardInventory";
import InventoryProductOrder from "pages/Inventory/ProductOrder/InventoryProductOrder";
import InventoryPOGeneration from "pages/Inventory/ProductOrder/InventoryPOGeneration";
import InventoryPOApproval from "pages/Inventory/ProductOrder/InventoryPOApproval";
import InventoryPORaise from "pages/Inventory/ProductOrder/InventoryPORaise";

import InventoryPOGenerationMain from "pages/Inventory/ProductOrder/InventoryPOGenerationMain";
import InventoryPurchaseOrderHoManager from "pages/Inventory/ProductOrder/InventoryPurchaseOrderHoManager";
import InventoryPurchaseOrderHoAccounts from "pages/Inventory/ProductOrder/InventoryPurchaseOrderHoAccounts";
import InventoryWorkOrderGeneration from "pages/Inventory/WorkOrder/InventoryWorkOrderGeneration";
import InventoryWorkOrderApproval from "pages/Inventory/WorkOrder/InventoryWorkOrderApproval";
import InventoryHoManagerApproval from "pages/Inventory/WorkOrder/InventoryHoManagerApproval";
import InventoryHoAccountsApproval from "pages/Inventory/WorkOrder/InventoryHoAccountsApproval";
import InventoryWorkOrderRaise from "pages/Inventory/WorkOrder/InventoryWorkOrderRaise";
import InventoryVendorSpecialRate from "pages/Inventory/Master/InventoryItemSpecialRate";
import VendorSpecialRate from "pages/Pharmacy/Master/ItemSpecialRate";


import InventoryGoodInwardCreate from "pages/Inventory/GoodsInward/InventoryGoodInwardCreate";
import InventoryDispatchConfirmation from "pages/Inventory/Stock/InventoryDispatchConfirmation";
import InventoryReturnToVendorMain from "pages/Inventory/Stock/InventoryReturnToVendorMain";
import SpecialReportGeneration from "pages/Lis/Master/SpecialReportGeneration";
import EDBilling from "pages/His/Billing/EDBilling";
import EDBillingInfo from "pages/His/Billing/EDBillingInfo";
import EDReceipt from "pages/His/Billing/EDReceipt";
import LocationMaster from "pages/Lis/Master/location";
import SpecialReportEntry from "pages/Lis/ManageReporting/SpecialReportEntry";
import SpecialReportList from "pages/Lis/ManageReporting/SpecialReportList";
import BloodBankDashboard from "pages/Bloodbank/BloodBankDashboard";
import BloodBankPatientRegister from "pages/Bloodbank/BloodBankTransfusion/BloodBankPatient";



const Routers = () => {
  const [multiLocation, setMultiLocation] = useState(false)
  const client = useSelector((state) => state.authReducer.client);
  const token = useSelector((state) => state.authReducer.token);
  const user = useSelector((state) => state.authReducer.user);
  const setting = useSelector((state) => state.adminReducer.setting)
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const isAdmin = useSelector((state) => state.authReducer.user.is_admin);

  const dispatch = useDispatch();
  const userPermissions = useSelector(
    (state) => state.publicAuthReducer.permissions
  );

  if (client && token) {
    axios.defaults.baseURL =
      process.env.REACT_APP_BASE_URL + `client/` + client;
  } else {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  }

  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  useEffect(() => {
    if (setting) {
      setMultiLocation(setting.location_enabled)
    }
    dispatch(authActions.loadPermissions(user));
  }, [user, setting]);

  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="d-flex">
        {/* <Sidebar isAdmin={isAdmin} /> */}
        <div
          className="d-flex flex-column flex-grow-1 overflow-hidden"
          style={styles.parentContainer}
        >
          <div>
            <NavBar client={client} />
            <NavComponentbar
              client={client}
              userPermissions={userPermissions}
            />
            <NavBreadcrumbBar />
          </div>
          <div className="pb-5 overflow-auto">
            <Routes>
              {!client && isAdmin && (
                <Route path="superadmin" element={<SuperUser />}>
                  <Route path="" element={<TenantMain />} />
                  <Route path=":tenantId" element={<TenantInfo />} />
                </Route>
              )}
              <Route path="superadmin" element={<SuperUserLogin />} />
              {client && isAuthenticated && (
                <>
                  <Route path="" element={<Product client={client} />} />
                  <Route path="qr" element={<QrMain />} />
                  <Route path="barcode" element={<BarcodeMain />} />

                  {/* HIS */}
                  <Route
                    path="his"
                    element={
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={["his:dashboard:view"]}
                        renderNoAccess={() => (
                          <NoAccess permissionNeeded="his:dashboard:view" />
                        )}
                      >
                        <HisDashboard />
                      </AccessControl>
                    }
                  >
                    <Route path="frontdesk" element={<FrontDesk />}>
                      <Route
                        index
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:frontdesk:dashboard:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:frontdesk:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="his" module="frontdesk" />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="patient"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:frontdesk:patient:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:frontdesk:patient:view" />
                            )}
                          >
                            <SearchPatient />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="patientregistration"
                        element={<PatientRegistration />}
                      >
                        <Route
                          path=":appointmentId"
                          element={<PatientRegistration />}
                        />
                      </Route>
                      <Route path="opdadmission" element={<OPDAdmission />}>
                        <Route path=":patientId" element={<OPDAdmission />} />
                      </Route>
                      <Route
                        path="emergeopdadmission"
                        element={<EmergencyOPDAdmission />}
                      >
                        <Route
                          path=":patientId"
                          element={<EmergencyOPDAdmission />}
                        />
                      </Route>
                      <Route path="ipdadmission" element={<IPDAdmission />}>
                        <Route path=":patientId" element={<IPDAdmission />} />
                      </Route>
                      <Route path="appointment" element={<Appointment />}>
                        <Route path=":patientId" element={<Appointment />} />
                      </Route>
                      <Route
                        path="summary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:frontdesk:summary:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:frontdesk:summary:view" />
                            )}
                          >
                            <RegistrationSummary />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="costestimation"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:frontdesk:costestimation:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:frontdesk:costestimation:view" />
                            )}
                          >
                            <CostEstimation />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="scheduler"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:frontdesk:scheduler:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:frontdesk:scheduler:view" />
                            )}
                          >
                            <PatientAppointment />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="leaveschedule"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:frontdesk:leaveschedule:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:frontdesk:leaveschedule:view" />
                            )}
                          >
                            <LeaveSchedule />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="cabinschedule"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:frontdesk:cabinschedule:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:frontdesk:cabinschedule:view" />
                            )}
                          >
                            <CabinSchedule />
                          </AccessControl>
                        }
                      />
                    </Route>
                    <Route path="master" element={<HisMaster />}>
                      <Route
                        index
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:diet:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:diet:view" />
                            )}
                          >
                            <DietMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="diet"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:diet:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:diet:view" />
                            )}
                          >
                            <DietMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="ward"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:ward:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:ward:view " />
                            )}
                          >
                            <WardMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="bed"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:bed:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:bed:view" />
                            )}
                          >
                            <BedMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="cabin"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:cabin:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:cabin:view" />
                            )}
                          >
                            <CabinMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="cashcounter"
                        element={<CashCounterMaster />}
                      />
                      <Route
                        path="service"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:service:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:service:view" />
                            )}
                          >
                            <ConsultingServiceMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="doctor"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:doctor:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:doctor:view" />
                            )}
                          >
                            <DoctorMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="department"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:department:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:department:view" />
                            )}
                          >
                            <DepartmentMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="subdepartment"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:master:subdepartment:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:subdepartment:view" />
                            )}
                          >
                            <SubDepartmentMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="designation"
                        element={<DesignationMaster />}
                      />
                      <Route
                        path="surgery"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:surgery:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:surgery:view" />
                            )}
                          >
                            <SurgeryMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="project"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:project:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:project:view" />
                            )}
                          >
                            <ProjectMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="projectspecial"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:master:projectspecial:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:projectspecial:view" />
                            )}
                          >
                            <ProjectSpecial />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="insurance"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:insurance:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:insurance:view" />
                            )}
                          >
                            <InsuranceMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="insurancespecial"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:master:insurancespecial:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:insurancespecial:view" />
                            )}
                          >
                            <InsuranceSpecial />
                          </AccessControl>
                        }
                      />
                      <Route path="kit" element={<KitMaster />} />
                      {/* <Route
                        path="package"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:package:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:package:view" />
                            )}
                          >
                            <PackageMaster />
                          </AccessControl>
                        }
                      /> */}
                      <Route
                        path="referreddoctor"
                        element={<ReferredDoctorMaster />}
                      />
                      <Route
                        path="employee"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:employee:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:employee:view" />
                            )}
                          >
                            <EmployeeMaster />
                          </AccessControl>
                        }
                      />
                      <Route path="operationtheatre" element={<OTMaster />} />
                      <Route
                        path="discount"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:discount:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:discount:view" />
                            )}
                          >
                            <DiscountMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="hmo"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:hmo:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:hmo:view" />
                            )}
                          >
                            <HMOMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="template"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:master:template:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:master:template:view" />
                            )}
                          >
                            <TemplateMaster />
                          </AccessControl>
                        }
                      />
                    </Route>
                    <Route path="metadata" element={<MetaData />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:metadata:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:metadata:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="his" module="metadata" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="user"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:metadata:user:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:metadata:user:view" />
                            )}
                          >
                            <UserMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="metatag"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:metadata:metatag:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:metadata:metatag:view" />
                            )}
                          >
                            <MetaDataTag />
                          </AccessControl>
                        }
                      >
                        <Route
                          path=":metaTagId"
                          element={<MetaDataTagType />}
                        />
                      </Route>
                      <Route
                        path="userrole"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:metadata:userrole:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:metadata:userrole:view" />
                            )}
                          >
                            <UserRole />
                          </AccessControl>
                        }
                      >
                        <Route path=":id" element={<RolePermissions />} />
                      </Route>
                      <Route
                        path="table"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:metadata:table:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:metadata:table:view" />
                            )}
                          >
                            <Table />
                          </AccessControl>
                        }
                      >
                        <Route path=":tableId" element={<TableField />}></Route>
                      </Route>
                    </Route>
                    <Route path="inpatient" element={<Nursing />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:inpatient:dashboard:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:inpatient:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="his" module="inpatient" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="patientsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:inpatient:patientsummary:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:inpatient:patientsummary:view" />
                            )}
                          >
                            <IPDPatientSummary />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="roaster"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:inpatient:roaster:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:inpatient:roaster:view" />
                            )}
                          >
                            <NursingRoaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="patientacceptance"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:inpatient:patientacceptance:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:inpatient:patientacceptance:view" />
                            )}
                          >
                            <PatientAcceptance />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="nursingconsole"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:inpatient:nursingconsole:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:inpatient:nursingconsole:view" />
                            )}
                          >
                            <NursingConsole />
                          </AccessControl>
                        }
                      />
                      <Route path="patientinfo" element={<PatientInfo />}>
                        <Route
                          path=":ipdadmissionId"
                          element={<PatientInfo />}
                        />
                      </Route>
                      <Route
                        path="bedtransfer"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:inpatient:bedtransfer:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:inpatient:bedtransfer:view" />
                            )}
                          >
                            <BedTransferSummary />
                          </AccessControl>
                        }
                      >
                        <Route
                          path=":ipdadmissionId"
                          element={<BedTransfer />}
                        />
                      </Route>
                      <Route
                        path="patientdischarge"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:inpatient:patientdischarge:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:inpatient:patientdischarge:view" />
                            )}
                          >
                            <PatientDischarge />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="dischargetemplate"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:inpatient:dischargetemplate:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:inpatient:dischargetemplate:view" />
                            )}
                          >
                            <DischargeTemplate />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="promissorynote"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:inpatient:promissorynote:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:inpatient:promissorynote:view" />
                            )}
                          >
                            <PromissoryNote />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="deathsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:inpatient:deathsummary:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:inpatient:deathsummary:view" />
                            )}
                          >
                            <DeathSummary />
                          </AccessControl>
                        }
                      />
                    </Route>
                    <Route path="outpatient" element={<Doctor />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:outpatient:dashboard:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:outpatient:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard
                              product="his"
                              module="outpatient"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="opdconsultation"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:outpatient:opdconsultation:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:outpatient:opdconsultation:view" />
                            )}
                          >
                            <OPDConsultation />
                          </AccessControl>
                        }
                      />
                      <Route path="opdpatientinfo" element={<OPDPatientInfo />}>
                        <Route
                          path=":opdadmissionId"
                          element={<OPDPatientInfo />}
                        />
                      </Route>
                      <Route
                        path="nursingconsole"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:outpatient:nursingconsole:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:outpatient:nursingconsole:view" />
                            )}
                          >
                            <DoctorNursingConsole />
                          </AccessControl>
                        }
                      />
                      <Route path="ipdpatientinfo" element={<PatientInfo />}>
                        <Route
                          path=":ipdadmissionId"
                          element={<PatientInfo />}
                        />
                      </Route>
                    </Route>
                    <Route path="edpatient" element={<Emergency />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:edpatient:dashboard:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:edpatient:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="his" module="edpatient" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="patientsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:edpatient:patientsummary:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:edpatient:patientsummary:view" />
                            )}
                          >
                            <EDPatientSummary />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="emergencyconsole"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:edpatient:emergencyconsole:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:edpatient:emergencyconsole:view" />
                            )}
                          >
                            <EmergencyConsole />
                          </AccessControl>
                        }
                      />
                      <Route path="patientinfo" element={<EDPatientInfo />}>
                        <Route
                          path=":edadmissionId"
                          element={<EDPatientInfo />}
                        />
                      </Route>
                      <Route
                        path="patientdischarge"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:edpatient:patientdischarge:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:edpatient:patientdischarge:view" />
                            )}
                          >
                            <EDPatientDischarge />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="deathsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:edpatient:deathsummary:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:edpatient:deathsummary:view" />
                            )}
                          >
                            <EDDeathSummary />
                          </AccessControl>
                        }
                      />
                    </Route>
                    <Route path="billing" element={<Billing />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:billing:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:billing:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="his" module="billing" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="opdbilling"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:billing:opdbilling:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:billing:opdbilling:view" />
                            )}
                          >
                            <OPDBilling />
                          </AccessControl>
                        }
                      >
                        <Route path=":billId" element={<OPDBillingInfo />} />
                      </Route>
                      <Route
                        path="ipdbilling"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:billing:ipdbilling:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:billing:ipdbilling:view" />
                            )}
                          >
                            <IPDBilling />
                          </AccessControl>
                        }
                      >
                        <Route path=":ipdId" element={<IPDBillingInfo />} />
                      </Route>
                      <Route
                        path="ipdreceipt"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:billing:ipdreceipt:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:billing:ipdreceipt:view" />
                            )}
                          >
                            <IPDReceipt />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="edbilling"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:billing:edbilling:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:billing:edbilling:view" />
                            )}
                          >
                            <EDBilling />
                          </AccessControl>
                        }
                      >
                        <Route path=":edId" element={<EDBillingInfo />} />
                      </Route>
                      <Route
                        path="edreceipt"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:billing:edreceipt:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:billing:edreceipt:view" />
                            )}
                          >
                            <EDReceipt />
                          </AccessControl>
                        }
                      />
                    </Route>
                    <Route path="operationtheatre" element={<OT />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:operationtheatre:dashboard:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:operationtheatre:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard
                              product="his"
                              module="operationtheatre"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="otschedule"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:operationtheatre:otschedule:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:operationtheatre:otschedule:view" />
                            )}
                          >
                            <OTSchedule />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="otapproval"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:operationtheatre:otapproval:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:operationtheatre:otapproval:view" />
                            )}
                          >
                            <OTApproval />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="postopnotes"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "his:operationtheatre:postopnotes:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:operationtheatre:postopnotes:view" />
                            )}
                          >
                            <PostOPNotes />
                          </AccessControl>
                        }
                      />
                    </Route>
                    <Route
                      path="nonmedicalstock"
                      element={<HisNonMedicalStock />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:nonmedicalstock:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:nonmedicalstock:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="his"
                              module="nonmedicalstock"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="deptstock"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:nonmedicalstock:deptstock:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:nonmedicalstock:deptstock:view" />
                            )} >
                            <HisDepartmentStock />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="request"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:nonmedicalstock:request:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:nonmedicalstock:request:view" />
                            )} >
                            <HisMaterialRequest />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="consumption"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:nonmedicalstock:consumption:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:nonmedicalstock:consumption:view" />
                            )} >
                            <HisStockConsumption />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="transfer"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:nonmedicalstock:transfer:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:nonmedicalstock:transfer:view" />
                            )} >
                            <HisStockTransfer />
                          </AccessControl>
                        }
                      />
                      {/* <Route path="expiry"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:nonmedicalstock:expiry:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:nonmedicalstock:expiry:view" />
                            )} >
                            <HisItemExpiry />
                          </AccessControl>
                        } /> */}
                    </Route>
                    <Route
                      path="medicalstock"
                      element={<HisMedicalStock />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:medicalstock:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:medicalstock:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="his"
                              module="medicalstock"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="deptstock"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:medicalstock:deptstock:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:medicalstock:deptstock:view" />
                            )} >
                            <HisMedicalDepartmentStock />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="request"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:medicalstock:request:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:medicalstock:request:view" />
                            )} >
                            <HisMedicalMaterialRequest />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="consumption"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:medicalstock:consumption:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:medicalstock:consumption:view" />
                            )} >
                            <HisMedicalStockConsumption />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="transfer"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:medicalstock:transfer:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:medicalstock:transfer:view" />
                            )} >
                            <HisMedicalStockTransfer />
                          </AccessControl>
                        }
                      />
                      <Route path="expiry"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["his:medicalstock:expiry:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="his:medicalstock:expiry:view" />
                            )} >
                            <HisMedicalItemExpiry />
                          </AccessControl>
                        } />
                    </Route>
                    <Route
                      path="setting"
                      element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["his:setting:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="his:setting:view" />
                          )}
                        >
                          <Settings />
                        </AccessControl>
                      }
                    />
                    {/* <Route path="template" element={<SelectTemplate />} />
                                            <Route path="formdata" element={<FormData />} /> */}
                    {/* </Route> */}
                  </Route>

                  {/* LIS */}
                  <Route
                    path="lis"
                    element={
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={["lis:dashboard:view"]}
                        renderNoAccess={() => (
                          <NoAccess permissionNeeded="lis:dashboard:view" />
                        )}
                      >
                        <LisDashboard />
                      </AccessControl>
                    }
                  >
                    <Route path="frontdesk" element={<FrontDeskLis />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:frontdesk:dashboard:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:frontdesk:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="lis" module="frontdesk" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="patient"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:frontdesk:patient:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:frontdesk:patient:view" />
                            )}
                          >
                            <SearchPatientLis />
                          </AccessControl>
                        }
                      />
                      <Route path="sample" element={<SampleRegistration />}>
                        <Route
                          path=":patientId"
                          element={<SampleRegistration />}
                        />
                      </Route>
                      <Route
                        path="summary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:frontdesk:summary:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:frontdesk:summary:view" />
                            )}
                          >
                            <SampleRegistrationSummary />
                          </AccessControl>
                        }
                      >
                        <Route
                          path=":opdId"
                          element={<RegistrationPayment />}
                        />
                        <Route
                          path=":opdId/payment"
                          element={<RegistrationPayment />}
                        />
                      </Route>
                      <Route
                        path="costestimation"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:frontdesk:costestimation:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:frontdesk:costestimation:view" />
                            )}
                          >
                            <TestProfileCostEstimation />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="rejectionsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:frontdesk:rejectionsummary:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:frontdesk:rejectionsummary:view" />
                            )}
                          >
                            <RejectionSummary />
                          </AccessControl>
                        }
                      />
                    </Route>
                    <Route path="sample" element={<SampleManagement />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:sample:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:sample:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="lis" module="sample" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route path="collection" element={<PendingSample />}>
                        <Route
                          path="opd/:opdIpdAdmissionId"
                          element={
                            <AccessControl
                              userPermissions={userPermissions}
                              allowedPermissions={[
                                "lis:sample:collection:view",
                              ]}
                              renderNoAccess={() => (
                                <NoAccess permissionNeeded="lis:sample:collection:view" />
                              )}
                            >
                              <SampleCollection />
                            </AccessControl>
                          }
                        />
                        <Route
                          path="ipd/:opdIpdAdmissionId"
                          element={
                            <AccessControl
                              userPermissions={userPermissions}
                              allowedPermissions={[
                                "lis:sample:collection:view",
                              ]}
                              renderNoAccess={() => (
                                <NoAccess permissionNeeded="lis:sample:collection:view" />
                              )}
                            >
                              <SampleCollection />
                            </AccessControl>
                          }
                        />
                        <Route
                          path="ed/:opdIpdAdmissionId"
                          element={
                            <AccessControl
                              userPermissions={userPermissions}
                              allowedPermissions={[
                                "lis:sample:collection:view",
                              ]}
                              renderNoAccess={() => (
                                <NoAccess permissionNeeded="lis:sample:collection:view" />
                              )}
                            >
                              <SampleCollection />
                            </AccessControl>
                          }
                        />
                      </Route>
                      <Route
                        path="accession"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:sample:accession:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:sample:accession:view" />
                            )}
                          >
                            <CollectedSample />
                          </AccessControl>
                        }
                      >
                        <Route
                          path="opd/:opdIpdAdmissionId"
                          element={<SampleAccession />}
                        />
                        <Route
                          path="ipd/:opdIpdAdmissionId"
                          element={<SampleAccession />}
                        />
                        <Route
                          path="ed/:opdIpdAdmissionId"
                          element={<SampleAccession />}
                        />
                      </Route>
                    </Route>
                    <Route path="result" element={<ManageReporting />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:result:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:result:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="lis" module="result" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="outlabselection"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:result:outlabselection:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:result:outlabselection:view" />
                            )}
                          >
                            <OutlabSelection />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="outlabupload"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:result:outlabupload:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:result:outlabupload:view" />
                            )}
                          >
                            <OutlabUpload />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="resultentry"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:result:resultentry:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:result:resultentry:view" />
                            )}
                          >
                            <ResultEntry />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="report"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:result:report:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:result:report:view" />
                            )}
                          >
                            <PrintReport />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="resultentry/department/:opdipd/:opd/:id/:status"
                        element={<ResultEntryDepartment />}
                      />
                      <Route
                        path="resultentry/test/:opdipd/:opd/:id/:status"
                        element={<ResultEntryTest />}
                      />
                      <Route
                        path="resultentry/profile/:opdipd/:opd/:id/:status"
                        element={<ResultEntryProfile />}
                      />
                      <Route
                        path="specialreport"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:result:specialreportlist:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:result:specialreportlist:view" />
                            )}
                          >
                            <SpecialReportList />
                          </AccessControl>
                        }
                      />
                      <Route path="specialreportentry/:testprofileId/:status" element={<SpecialReportEntry />} />
                      {/* <Route path=":testId" element={<SpecialReportEntry/>}/>
                      </Route> */}
                    </Route>
                    <Route path="master" element={<LisMaster />}>
                      <Route
                        path=""
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:master:test:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:master:test:view" />
                            )}
                          >
                            <TestMaster />
                          </AccessControl>
                        }
                      />
                      {multiLocation && <Route
                        path="location"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:master:location:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:master:location:view" />
                            )}
                          >
                            <LocationMaster />
                          </AccessControl>
                        }
                      />}
                      <Route
                        path="test"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:master:test:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:master:test:view" />
                            )}
                          >
                            <TestMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="profile"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:master:profile:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:master:profile:view" />
                            )}
                          >
                            <ProfileMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="analyzer"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:master:analyzer:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:master:analyzer:view" />
                            )}
                          >
                            <AnalyzerMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="specialreport"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:master:specialreport:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:master:specialreport:view" />
                            )}
                          >
                            <SpecialReportMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="specialreport/:specialReportId"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:master:specialreport:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:master:specialreport:view" />
                            )}
                          >
                            <SpecialReportGeneration />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="referreddoctor"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:master:referreddoctor:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:master:referreddoctor:view" />
                            )}
                          >
                            <LisReferredDoctorMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="formula"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:master:formula:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:master:formula:view" />
                            )}
                          >
                            <FormulaMaster />
                          </AccessControl>
                        }
                      />
                      {/* <Route path="employee" element={<EmployeeMaster />} />
                        <Route path="project" element={<ProjectMaster />} />
                        <Route path="barcode" element={<BarcodeManager />} />
                        <Route path="asset" element={<AssetMaster />} />
                        <Route path="control" element={<QualityControlMaster />} />
                        <Route path="filmcontract" element={<FilmContrastMaster />} /> */}
                      <Route
                        path="template"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:master:template:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:master:template:view" />
                            )}
                          >
                            <TemplateMaster />
                          </AccessControl>
                        }
                      />
                    </Route>
                    <Route path="opmaster" element={<LisMaster />}>
                      <Route
                        path=""
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:opmaster:referreddoctor:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:referreddoctor:view" />
                            )}
                          >
                            <ReferredDoctorMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="referreddoctor"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:opmaster:referreddoctor:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:referreddoctor:view" />
                            )}
                          >
                            <ReferredDoctorMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="client"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:opmaster:client:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:client:view" />
                            )}
                          >
                            <ClientMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="clientspecialrate"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:opmaster:clientspecialrate:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:clientspecialrate:view" />
                            )}
                          >
                            <ClientSpecial />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="clientbulkrates"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:opmaster:clientbulkrates:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:clientbulkrates:view" />
                            )}
                          >
                            <CentreMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="centre"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:opmaster:centre:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:centre:view" />
                            )}
                          >
                            <CentreMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="centerspecialrate"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:opmaster:centerspecialrate:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:centerspecialrate:view" />
                            )}
                          >
                            <CenterSpecial />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="outlabspecialrate"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:opmaster:outlabspecialrate:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:outlabspecialrate:view" />
                            )}
                          >
                            <OutlabSpecial />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="barcode"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:opmaster:barcode:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:barcode:view" />
                            )}
                          >
                            <BarcodeManager />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="employee"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:opmaster:employee:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:employee:view" />
                            )}
                          >
                            <EmployeeMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="project"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:opmaster:project:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:project:view" />
                            )}
                          >
                            <ProjectMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="projectspecial"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:opmaster:projectspecial:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:projectspecial:view" />
                            )}
                          >
                            <ProjectSpecial />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="outlab"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:opmaster:outlab:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:opmaster:outlab:view" />
                            )}
                          >
                            <OutlabMaster />
                          </AccessControl>
                        }
                      />
                      {/* <Route path="test" element={<TestMaster />} />
                                            <Route path="employee" element={<EmployeeMaster />} />
                                            <Route path="profile" element={<ProfileMaster />} />
                                            <Route path="specialreport" element={<SpecialReportMaster />} />
                                            <Route path="centre" element={<CentreMaster />} />
                                            <Route path="client" element={<ClientMaster />} />
                                            <Route path="project" element={<ProjectMaster />} />
                                            <Route path="analyzer" element={<AnalyzerMaster />} />
                                            <Route path="barcode" element={<BarcodeManager />} />
                                            <Route path="asset" element={<AssetMaster />} />
                                            <Route path="outlab" element={<OutlabMaster />} />
                                            <Route path="control" element={<QualityControlMaster />} />
                                            <Route path="filmcontract" element={<FilmContrastMaster />} />
                                            <Route path="template" element={<TemplateMaster />} /> */}
                    </Route>
                    {/* <Route path="mis" elemen={<Mis />}>
                                            <Route path="print_reports" element={<PrintReports />} />
                                        </Route> */}
                    <Route
                      path="setting"
                      element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["lis:setting:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="lis:setting:view" />
                          )}
                        >
                          <Settings />
                        </AccessControl>
                      }
                    >
                      <Route path="template" element={<SelectTemplate />} />
                    </Route>
                    <Route path="account" element={<Account />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:account:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:account:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="lis" module="account" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="billingsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:account:billingsummary:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:account:billingsummary:view" />
                            )}
                          >
                            <BillingSummary />
                          </AccessControl>
                        }
                      >
                        <Route path=":id" element={<BillingDetails />} />
                      </Route>
                      <Route
                        path="expenseentry"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:account:expenseentry:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:account:expenseentry:view" />
                            )}
                          >
                            <ExpenseEntry />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="clientledger"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:account:clientledger:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:account:clientledger:view" />
                            )}
                          >
                            <ClientLedger />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="clientbillingsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:account:clientbillingsummary:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:account:clientbillingsummary:view" />
                            )}
                          >
                            <ClientBillingSummary />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="dailybillingsummary"
                        element={<DailyBillingSummary />}
                      />
                      <Route
                        path="cashcollectionsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:account:cashcollectionsummary:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:account:cashcollectionsummary:view" />
                            )}
                          >
                            <CashCollectionSummary />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="generateclientinvoice"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:account:generateclientinvoice:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:account:generateclientinvoice:view" />
                            )}
                          >
                            <GenerateClientInvoice />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="accountreceivable"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:account:accountreceivable:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:account:accountreceivable:view" />
                            )}
                          >
                            <AccountReceivable />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="outlabbillingsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "lis:account:outlabbillingsummary:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:account:outlabbillingsummary:view" />
                            )}
                          >
                            <OutlabBillingSummary />
                          </AccessControl>
                        }
                      >
                        <Route path=":id" element={<OutlabBillingDetails />} />
                      </Route>
                    </Route>

                    <Route
                      path="nonmedicalstock"
                      element={<LisNonMedicalStock />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:nonmedicalstock:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:nonmedicalstock:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="lis"
                              module="nonmedicalstock"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="deptstock"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:nonmedicalstock:deptstock:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:nonmedicalstock:deptstock:view" />
                            )} >
                            <LisDepartmentStock />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="request"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:nonmedicalstock:request:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:nonmedicalstock:request:view" />
                            )} >
                            <LisMaterialRequest />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="consumption"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:nonmedicalstock:consumption:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:nonmedicalstock:consumption:view" />
                            )} >
                            <LisStockConsumption />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="transfer"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:nonmedicalstock:transfer:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:nonmedicalstock:transfer:view" />
                            )} >
                            <LisStockTransfer />
                          </AccessControl>
                        }
                      />
                      {/* <Route path="expiry"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:nonmedicalstock:expiry:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:nonmedicalstock:expiry:view" />
                            )} >
                            <LisItemExpiry />
                          </AccessControl>
                        } /> */}
                    </Route>
                    <Route
                      path="medicalstock"
                      element={<LisMedicalStock />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:medicalstock:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:medicalstock:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="lis"
                              module="medicalstock"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="deptstock"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:medicalstock:deptstock:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:medicalstock:deptstock:view" />
                            )} >
                            <LisMedicalDepartmentStock />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="request"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:medicalstock:request:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:medicalstock:request:view" />
                            )} >
                            <LisMedicalMaterialRequest />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="consumption"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:medicalstock:consumption:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:medicalstock:consumption:view" />
                            )} >
                            <LisMedicalStockConsumption />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="transfer"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:medicalstock:transfer:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:medicalstock:transfer:view" />
                            )} >
                            <LisMedicalStockTransfer />
                          </AccessControl>
                        }
                      />
                      <Route path="expiry"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:medicalstock:expiry:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:medicalstock:expiry:view" />
                            )} >
                            <LisMedicalItemExpiry />
                          </AccessControl>
                        } />
                    </Route>
                    <Route path="metadata" element={<MetaData />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:metadata:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:metadata:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="lis" module="metadata" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="user"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:metadata:user:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:metadata:user:view" />
                            )}
                          >
                            <UserMaster />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="metatag"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:metadata:metatag:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:metadata:metatag:view" />
                            )}
                          >
                            <MetaDataTag />
                          </AccessControl>
                        }
                      >
                        <Route
                          path=":metaTagId"
                          element={<MetaDataTagType />}
                        />
                      </Route>
                      <Route
                        path="userrole"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:metadata:userrole:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:metadata:userrole:view" />
                            )}
                          >
                            <UserRole />
                          </AccessControl>
                        }
                      >
                        <Route path=":id" element={<RolePermissions />} />
                      </Route>
                      <Route
                        path="table"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["lis:metadata:table:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="lis:metadata:table:view" />
                            )}
                          >
                            <Table />
                          </AccessControl>
                        }
                      >
                        <Route path=":tableId" element={<TableField />}>
                          {/* <Route path="createtablefield" element={<CreateTableField />}>
                                                <Route path=":tableFieldId" element={<UpdateTableField />} />
                                            </Route> */}
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  {/* RIS ROUTERS */}
                  <Route path="ris" element={
                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={["ris:dashboard:view"]}
                      renderNoAccess={() => (
                        <NoAccess permissionNeeded="ris:dashboard:view" />
                      )}
                    >
                      <RisDashboard />
                    </AccessControl>
                  }>
                    <Route path="frontdesk" element={<FrontDesk />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:frontdesk:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:frontdesk:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="ris" module="frontdesk" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route index path="patient" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:frontdesk:patient:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:frontdesk:patient:view" />
                          )} >
                          <SearchPatientRis />
                        </AccessControl>

                      } />
                      <Route path="study" element={<StudyRegistration />}>
                        <Route
                          path=":patientId"
                          element={<StudyRegistration />}
                        />
                      </Route>
                      <Route
                        path="summary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:frontdesk:summary:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:frontdesk:summary:view" />
                            )}
                          >
                            <StudyRegistrationSummary />
                          </AccessControl>
                        }
                      >
                        <Route
                          path=":opdId"
                          element={<RegistrationPayment />}
                        />
                        <Route
                          path=":opdId/payment"
                          element={<RegistrationPayment />}
                        />
                      </Route>

                      {/* <Route
                        index
                        path="worklist"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:frontdesk:worklist:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:frontdesk:worklist:view" />
                            )} >
                            <WorkListSummary />
                          </AccessControl>

                        }
                      />
                      <Route
                        index
                        path="confirmation"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:frontdesk:confirmation:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:frontdesk:confirmation:view" />
                            )} >
                            <RadiologyConfirmation />
                          </AccessControl>

                        }
                      />
                      <Route
                        index
                        path="rejectionsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:frontdesk:rejectionsummary:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:frontdesk:rejectionsummary:view" />
                            )} >
                            <RisRejectionSummary />
                          </AccessControl>

                        }
                      /> */}
                    </Route>

                    <Route path="result" element={<RISManageReporting />}>
                      <Route element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:result:dashboard:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:result:dashboard:view" />
                          )} >
                          <ModuleDashboard product="ris" module="result" />
                        </AccessControl>
                      } index />

                      <Route path="study" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:result:studylist:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:result:studylist:view" />
                          )} >
                          <RISListReport />
                        </AccessControl>
                      }>
                        <Route
                          path=":patientId"
                          element={<RISGenerateReport />}
                        />
                        <Route
                          path=":patientId/generate"
                          element={<RISGenerateReport />}
                        />
                      </Route>

                      <Route path="print" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:result:print:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:result:print:view" />
                          )} >
                          <RISPrintReport />
                        </AccessControl>
                      } />
                      {/* <Route path="study" element={<StudyMaster />} /> */}
                    </Route>

                    <Route path="master" element={<RisMaster />}>
                      <Route element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:master:dashboard:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:master:dashboard:view" />
                          )} >
                          <ModuleDashboard />
                        </AccessControl>

                      } index />
                      <Route path="study" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:master:study:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:master:study:view" />
                          )} >
                          <StudyMaster />
                        </AccessControl>

                      } />
                      <Route path="modality" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:master:study:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:master:study:view" />
                          )} >
                          <ModalityMaster />
                        </AccessControl>

                      } />

                      <Route path="referreddoctor" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:master:referreddoctor:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:master:referreddoctor:view" />
                          )} >
                          <RisReferredDoctorMaster />
                        </AccessControl>

                      } />
                      <Route path="template" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:master:template:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:master:template:view" />
                          )} >
                          <TemplateMaster />
                        </AccessControl>
                      } />
                    </Route>

                    <Route
                      path="nonmedicalstock"
                      element={<RisNonMedicalStock />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:nonmedicalstock:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:nonmedicalstock:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="ris"
                              module="nonmedicalstock"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="deptstock"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:nonmedicalstock:deptstock:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:nonmedicalstock:deptstock:view" />
                            )} >
                            <RisDepartmentStock />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="request"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:nonmedicalstock:request:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:nonmedicalstock:request:view" />
                            )} >
                            <RisMaterialRequest />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="consumption"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:nonmedicalstock:consumption:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:nonmedicalstock:consumption:view" />
                            )} >
                            <RisStockConsumption />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="transfer"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:nonmedicalstock:transfer:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:nonmedicalstock:transfer:view" />
                            )} >
                            <RisStockTransfer />
                          </AccessControl>
                        }
                      />
                      {/* <Route path="expiry"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:nonmedicalstock:expiry:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:nonmedicalstock:expiry:view" />
                            )} >
                            <RisItemExpiry />
                          </AccessControl>
                        } /> */}
                    </Route>
                    <Route
                      path="medicalstock"
                      element={<RisMedicalStock />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:medicalstock:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:medicalstock:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="ris"
                              module="medicalstock"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="deptstock"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:medicalstock:deptstock:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:medicalstock:deptstock:view" />
                            )} >
                            <RisMedicalDepartmentStock />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="request"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:medicalstock:request:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:medicalstock:request:view" />
                            )} >
                            <RisMedicalMaterialRequest />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="consumption"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:medicalstock:consumption:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:medicalstock:consumption:view" />
                            )} >
                            <RisMedicalStockConsumption />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="transfer"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:medicalstock:transfer:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:medicalstock:transfer:view" />
                            )} >
                            <RisMedicalStockTransfer />
                          </AccessControl>
                        }
                      />
                      <Route path="expiry"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:medicalstock:expiry:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:medicalstock:expiry:view" />
                            )} >
                            <RisMedicalItemExpiry />
                          </AccessControl>
                        } />
                    </Route>
                    <Route path="setting" element={
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={["ris:setting:view"]}
                        renderNoAccess={() => (
                          <NoAccess permissionNeeded="ris:setting:view" />
                        )} >
                        <Settings />
                      </AccessControl>

                    }>
                      {/* <Route path="template" element={<SelectTemplate />} /> */}
                    </Route>
                    <Route path="metadata" element={

                      <MetaData />

                    }>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["ris:metadata:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="ris:metadata:dashboard:view" />
                            )} >
                            <ModuleDashboard product={"ris"} module="metadata" />
                          </AccessControl>

                        }
                        index
                      />
                      <Route path="user" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:metadata:user:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:metadata:user:view" />
                          )} >
                          <UserMaster />
                        </AccessControl>

                      } />
                      <Route path="metatag" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:metadata:metatag:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:metadata:metatag:view" />
                          )} >
                          <MetaDataTag />
                        </AccessControl>

                      }>
                        <Route
                          path=":metaTagId"
                          element={<MetaDataTagType />}
                        />
                      </Route>
                      <Route path="userrole" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:metadata:userrole:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:metadata:userrole:view" />
                          )} >
                          <UserRole />
                        </AccessControl>

                      } />
                      <Route path="table" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["ris:metadata:table:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="ris:metadata:table:view" />
                          )} >
                          <Table />
                        </AccessControl>

                      }>
                        <Route path=":tableId" element={<TableField />}>
                          {/* <Route path="createtablefield" element={<CreateTableField />}>
                                                <Route path=":tableFieldId" element={<UpdateTableField />} />
                                            </Route> */}
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  {/* INVENTORY */}
                  <Route path="inventory" element={
                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={["inventory:dashboard:view"]}
                      renderNoAccess={() => (
                        <NoAccess permissionNeeded="inventory:dashboard:view" />
                      )} >
                      <InventoryDashboard />
                    </AccessControl>
                  }>
                    <Route path="master" element={<CommonMaster />}>
                      <Route path="item" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:master:item:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:master:item:view" />
                          )} >
                          <InventoryItemMaster />
                        </AccessControl>

                      } />
                      <Route path="vendor" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:master:vendor:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:master:vendor:view" />
                          )} >
                          <InventoryVendorMaster />
                        </AccessControl>
                      } />
                      <Route path="itemsplrate" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:master:itemsplrate:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:master:itemsplrate:view" />
                          )} >
                          <InventoryVendorSpecialRate />
                        </AccessControl>
                      } />

                    </Route>
                    <Route path="order" element={<InventoryProductOrder />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:order:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:order:dashboard:view" />
                            )} >
                            <ModuleDashboard product="inventory" module="order" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="generation"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:order:generation:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:order:generation:view" />
                            )} >
                            <InventoryPOGenerationMain />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="pogeneration"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:order:generation:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:order:generation:view" />
                            )} >
                            <InventoryPOGeneration />
                          </AccessControl>

                        }
                      >
                        <Route
                          path=":poId"
                          element={
                            <AccessControl
                              userPermissions={userPermissions}
                              allowedPermissions={["inventory:order:generation:view"]}
                              renderNoAccess={() => (
                                <NoAccess permissionNeeded="inventory:order:generation:view" />
                              )} >
                              <InventoryPOGeneration />
                            </AccessControl>
                          }
                        />
                      </Route>
                      <Route path="approval" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:order:approval:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:order:approval:view" />
                          )} >
                          <InventoryPOApproval />
                        </AccessControl>

                      } />
                      <Route
                        path="homanager"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:order:homanager:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:order:homanager:view" />
                            )} >
                            <InventoryPurchaseOrderHoManager />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="hoaccounts"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:order:hoaccounts:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:order:hoaccounts:view" />
                            )} >
                            <InventoryPurchaseOrderHoAccounts />
                          </AccessControl>

                        }
                      />
                      <Route path="raise" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:order:hoaccounts:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:order:hoaccounts:view" />
                          )} >
                          <InventoryPORaise />
                        </AccessControl>
                      } />
                    </Route>
                    <Route path="workorder" element={<WorkOrder />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:workorder:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:workorder:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="inventory"
                              module="workorder"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="generation"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:workorder:generation:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:workorder:generation:view" />
                            )} >
                            <InventoryWorkOrderGeneration />
                          </AccessControl>
                        }
                      />
                      <Route path="approval" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:workorder:approval:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:workorder:approval:view" />
                          )} >
                          <InventoryWorkOrderApproval />
                        </AccessControl>
                      } />
                      <Route path="homanager" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:workorder:homanager:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:workorder:homanager:view" />
                          )} >
                          <InventoryHoManagerApproval />
                        </AccessControl>
                      } />
                      <Route
                        path="hoaccounts"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:workorder:hoaccounts:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:workorder:hoaccounts:view" />
                            )} >
                            <InventoryHoAccountsApproval />
                          </AccessControl>
                        }
                      />
                      <Route path="raise" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:workorder:raise:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:workorder:raise:view" />
                          )} >
                          <InventoryWorkOrderRaise />
                        </AccessControl>
                      } />
                    </Route>
                    <Route path="inward" element={<GoodsInwardInventory />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:inward:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:inward:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="inventory"
                              module="inward"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route path="goods" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:inward:goods:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:inward:goods:view" />
                          )} >
                          <InventoryGoodsInward />
                        </AccessControl>
                      } />
                      <Route path="goods/:poId" element={<InventoryGoodInwardCreate />} />
                      <Route
                        path="detail"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:inward:detail:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:inward:detail:view" />
                            )} >
                            <InventoryInwardDetails />
                          </AccessControl>

                        }
                      />
                      <Route
                        path="Inwardwithoutpo"
                        element={<InventoryInwardWithoutPO />}
                      />
                    </Route>
                    <Route path="stock" element={<InventoryStock />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:stock:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:stock:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="inventory"
                              module="stock"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="indentsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:stock:indentsummary:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:stock:indentsummary:view" />
                            )} >
                            <InventoryIndentSummary />
                          </AccessControl>
                        }
                      >
                        <Route
                          path=":requestId"
                          element={
                            <AccessControl
                              userPermissions={userPermissions}
                              allowedPermissions={["inventory:stock:indentsummary:view"]}
                              renderNoAccess={() => (
                                <NoAccess permissionNeeded="inventory:stock:indentsummary:view" />
                              )} >
                              <InventoryDispatchConfirmation />
                            </AccessControl>
                          }
                        />
                      </Route>

                      <Route
                        path="deptstock"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:stock:deptstock:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:stock:deptstock:view" />
                            )} >
                            <InventoryDepartmentStock />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="transfer"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:stock:transfer:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:stock:transfer:view" />
                            )} >
                            <InventoryStockTransfer />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="returntovendor"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:stock:returntovendor:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:stock:returntovendor:view" />
                            )} >
                            <InventoryReturnToVendorMain />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="returntovendorform"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:stock:returntovendor:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:stock:returntovendor:view" />
                            )} >
                            <InventoryReturnToVendor />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="consumption"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:stock:consumption:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:stock:consumption:view" />
                            )} >
                            <InventoryStockConsumption />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="expiry"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:stock:expiry:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:stock:expiry:view" />
                            )} >
                            <InventoryItemExpiry />
                          </AccessControl>
                        }
                      />
                    </Route>
                    <Route path="metadata" element={<MetaData />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["inventory:metadata:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="inventory:metadata:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="inventory"
                              module="metadata"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route path="user" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:metadata:user:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:metadata:user:view" />
                          )} >
                          <UserMaster />
                        </AccessControl>

                      } />
                      <Route path="metatag" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:metadata:metatag:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:metadata:metatag:view" />
                          )} >
                          <MetaDataTag />
                        </AccessControl>

                      }>
                        <Route
                          path=":metaTagId"
                          element={<MetaDataTagType />}
                        />
                      </Route>
                      <Route path="userrole" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:metadata:userrole:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:metadata:userrole:view" />
                          )} >
                          <UserRole />
                        </AccessControl>} />
                      <Route path="table" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["inventory:metadata:table:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:metadata:table:view" />
                          )} >
                          <Table />
                        </AccessControl>
                      }>
                        <Route path=":tableId" element={<TableField />}>
                          {/* <Route path="createtablefield" element={<CreateTableField />}>
                                                <Route path=":tableFieldId" element={<UpdateTableField />} />
                                            </Route> */}
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  {/* PHARMACY */}
                  <Route path="pharmacy" element={
                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={["pharmacy:dashboard:view"]}
                      renderNoAccess={() => (
                        <NoAccess permissionNeeded="pharmacy:dashboard:view" />
                      )} >
                      <PharmacyDashboard />
                    </AccessControl>
                  }>
                    <Route path="billing" element={<Billing />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:billing:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:billing:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="pharmacy"
                              module="billing"
                            />
                          </AccessControl>

                        }
                        index
                      />
                      <Route path="bill" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:billing:bill:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:billing:bill:view" />
                          )} >
                          <PharmacyBilling />
                        </AccessControl>
                      } />
                      <Route path="summary" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:billing:summary:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:billing:summary:view" />
                          )} >
                          <PharmacySummary />
                        </AccessControl>
                      } />
                      <Route path="deptbill" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:pharmacy:deptbill:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:pharmacy:deptbill:view" />
                          )} >
                          <PharmacyDeptBill />
                        </AccessControl>
                      } />
                    </Route>
                    <Route path="master" element={
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={["pharmacy:master:dashboard:view"]}
                        renderNoAccess={() => (
                          <NoAccess permissionNeeded="pharmacy:master:dashboard:view" />
                        )} >
                        <CommonMaster />
                      </AccessControl>

                    }>
                      <Route path="item" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:master:item:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:master:item:view" />
                          )} >
                          <PharmacyItemMaster />
                        </AccessControl>

                      } />
                      <Route path="vendor" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:master:vendor:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:master:vendor:view" />
                          )} >
                          <PharmacyVendorMaster />
                        </AccessControl>

                      } />
                      <Route path="itemsplrate" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:master:itemsplrate:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:master:itemsplrate:view" />
                          )} >
                          <VendorSpecialRate />
                        </AccessControl>
                      } />
                    </Route>
                    <Route path="order" element={<PharmacyPurchaseOrder />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:order:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:order:dashboard:view" />
                            )} >
                            <ModuleDashboard product="pharmacy" module="order" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="generation"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:order:generation:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:order:generation:view" />
                            )} >
                            <PharmacyPOGenerationMain />
                          </AccessControl>

                        }
                      />
                      <Route
                        path="pogeneration"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:order:generation:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:order:generation:view" />
                            )} >
                            <PharmacyPOGeneration />
                          </AccessControl>

                        }
                      >
                        <Route
                          path=":poId"
                          element={
                            <AccessControl
                              userPermissions={userPermissions}
                              allowedPermissions={["pharmacy:order:generation:view"]}
                              renderNoAccess={() => (
                                <NoAccess permissionNeeded="pharmacy:order:generation:view" />
                              )} >
                              <PharmacyPOGeneration />
                            </AccessControl>

                          }
                        />
                      </Route>
                      <Route path="approval" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:order:approval:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:order:approval:view" />
                          )} >
                          <PharmacyPOApproval />
                        </AccessControl>

                      } />
                      <Route
                        path="homanager"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:order:homanager:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:order:homanager:view" />
                            )} >
                            <PurchaseOrderHoManager />
                          </AccessControl>

                        }
                      />
                      <Route
                        path="hoaccounts"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:order:hoaccounts:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:order:hoaccounts:view" />
                            )} >
                            <PurchaseOrderHoAccounts />
                          </AccessControl>
                        }

                      />
                      <Route path="raise" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:order:hoaccounts:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:order:hoaccounts:view" />
                          )} >
                          <PharmacyPORaise />
                        </AccessControl>
                      } />
                    </Route>
                    <Route path="workorder" element={< WorkOrder />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:workorder:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:workorder:dashboard:view" />
                            )} >
                            <ModuleDashboard product="pharmacy" module="workorder" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="generation"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:workorder:generation:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:workorder:generation:view" />
                            )} >
                            <WorkOrderGeneration />
                          </AccessControl>

                        }
                      />
                      <Route path="approval" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:workorder:approval:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:workorder:approval:view" />
                          )} >
                          <WorkOrderApproval />
                        </AccessControl>

                      } />
                      <Route path="homanager" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:workorder:homanager:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:workorder:homanager:view" />
                          )} >
                          <HoManagerApproval />
                        </AccessControl>

                      } />
                      <Route
                        path="hoaccounts"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:workorder:hoaccounts:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:workorder:hoaccounts:view" />
                            )} >
                            <HoAccountsApproval />
                          </AccessControl>

                        }
                      />
                      <Route path="raise" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:workorder:raise:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:workorder:raise:view" />
                          )} >
                          <WorkOrderRaise />
                        </AccessControl>

                      } />
                      {/* <Route path="approval" element={<PharmacyPOApproval />} />
                      <Route path="raise" element={<PharmacyPORaise />} /> */}
                    </Route>
                    <Route path="inward" element={<GoodInward />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:inward:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:inward:dashboard:view" />
                            )} >
                            <ModuleDashboard product="pharmacy" module="inward" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route path="goods" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:inward:goods:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:inward:goods:view" />
                          )} >
                          <PharmacyGoodInward />
                        </AccessControl>
                      } />
                      <Route path="goods/:poId" element={<PharmacyGoodInwardCreate />} />
                      <Route
                        path="detail"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:inward:detail:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:inward:detail:view" />
                            )} >
                            <PharmacyInwardDetails />
                          </AccessControl>

                        }
                      />
                      <Route
                        path="Inwardwithoutpo"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:inward:inwardwithoutpo:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:inward:inwardwithoutpo:view" />
                            )} >
                            <PharmacyInwardWithoutPO />
                          </AccessControl>

                        }
                      />
                    </Route>
                    <Route path="medicalstock" element={<PharmacyMedicalStock />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:medicalstock:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:medicalstock:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="pharmacy"
                              module="medicalstock"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="stocksummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:medicalstock:stocksummary:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:medicalstock:stocksummary:view" />
                            )} >
                            <PharmacyStockSummary />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="indentsummary"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:medicalstock:indentsummary:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:medicalstock:indentsummary:view" />
                            )} >
                            <PharmacyIndentSummary />
                          </AccessControl>
                        }
                      >
                        <Route
                          path=":requestId"
                          element={
                            <AccessControl
                              userPermissions={userPermissions}
                              allowedPermissions={["pharmacy:medicalstock:indentsummary:view"]}
                              renderNoAccess={() => (
                                <NoAccess permissionNeeded="pharmacy:medicalstock:indentsummary:view" />
                              )} >
                              <PharmacyDispatchConfirmation />
                            </AccessControl>
                          }
                        />
                      </Route>
                      <Route
                        path="returnfromdept"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:medicalstock:returnfromdept:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:medicalstock:returnfromdept:view" />
                            )} >
                            <PharmacyReturnFromDept />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="returntovendor"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:medicalstock:returntovendor:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:medicalstock:returntovendor:view" />
                            )} >
                            <PharmacyReturnToVendorMain />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="returntovendorform"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:medicalstock:returntovendor:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:medicalstock:returntovendor:view" />
                            )} >
                            <PharmacyReturnToVendor />
                          </AccessControl>
                        }
                      />
                      <Route path="expiry"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:medicalstock:expiry:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:medicalstock:expiry:view" />
                            )} >
                            <PharmacyItemExpiry />
                          </AccessControl>
                        } />
                    </Route>
                    <Route
                      path="nonmedicalstock"
                      element={<PharmacyNonMedicalStock />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:nonmedicalstock:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:nonmedicalstock:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="pharmacy"
                              module="nonmedicalstock"
                            />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="deptstock"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:nonmedicalstock:deptstock:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:nonmedicalstock:deptstock:view" />
                            )} >
                            <PharmacyDepartmentStock />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="request"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:nonmedicalstock:request:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:nonmedicalstock:request:view" />
                            )} >
                            <PharmacyMaterialRequest />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="consumption"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:nonmedicalstock:consumption:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:nonmedicalstock:consumption:view" />
                            )} >
                            <PharmacyStockConsumption />
                          </AccessControl>
                        }
                      />
                      <Route
                        path="transfer"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:nonmedicalstock:transfer:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:nonmedicalstock:transfer:view" />
                            )} >
                            <PharmacyStockTransfer />
                          </AccessControl>
                        }
                      />
                    </Route>
                    <Route path="metadata" element={<MetaData />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["pharmacy:metadata:dashboard:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="pharmacy:metadata:dashboard:view" />
                            )} >
                            <ModuleDashboard
                              product="pharmacy"
                              module="metadata"
                            />
                          </AccessControl>

                        }
                        index
                      />
                      <Route path="user" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:metadata:user:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:metadata:user:view" />
                          )} >
                          <UserMaster />
                        </AccessControl>

                      } />
                      <Route path="metatag" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:metadata:metatag:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:metadata:metatag:view" />
                          )} >
                          <MetaDataTag />
                        </AccessControl>
                      }>
                        <Route
                          path=":metaTagId"
                          element={<MetaDataTagType />}
                        />
                      </Route>
                      <Route path="userrole" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:metadata:userrole:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:metadata:userrole:view" />
                          )} >
                          <UserRole />
                        </AccessControl>
                      } />
                      <Route path="table" element={
                        <AccessControl
                          userPermissions={userPermissions}
                          allowedPermissions={["pharmacy:metadata:table:view"]}
                          renderNoAccess={() => (
                            <NoAccess permissionNeeded="pharmacy:metadata:table:view" />
                          )} >
                          <Table />
                        </AccessControl>

                      }>
                        <Route path=":tableId" element={<TableField />}>
                          {/* <Route path="createtablefield" element={<CreateTableField />}>
                                  <Route path=":tableFieldId" element={<UpdateTableField />} />
                              </Route> */}
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    path="bloodbank"
                    element={
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={["bloodbank:dashboard:view"]}
                        renderNoAccess={() => (
                          <NoAccess permissionNeeded="bloodbank:dashboard:view" />
                        )}
                      >
                        <BloodBankDashboard />
                      </AccessControl>
                    }
                  >
                    <Route path="patient" element={<FrontDeskLis />}>
                      <Route
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[
                              "bloodbank:dashboard:view",
                            ]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="bloodbank:dashboard:view" />
                            )}
                          >
                            <ModuleDashboard product="bloodbank" module="register" />
                          </AccessControl>
                        }
                        index
                      />
                      <Route
                        path="register"
                        element={
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={["bloodbank:patient:register:view"]}
                            renderNoAccess={() => (
                              <NoAccess permissionNeeded="bloodbank:patient:register:view" />
                            )}
                          >
                            <BloodBankPatientRegister />
                          </AccessControl>
                        }
                      />
                      <Route path="transfusion" element={<SampleRegistration />}>
                        <Route
                          path=":patientId"
                          element={<SampleRegistration />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </>
              )}

              <Route path="" element={<Login />} />
            </Routes>
          </div>
          <Footer />
        </div>
        {/* </>)} */}
      </div>
    </Router>
  );
};

const styles = {
  parentContainer: {
    height: "100vh",
    backgroundColor: "#F1F3FA",
  },
};

export default Routers;