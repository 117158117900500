import React from "react";
import { CustomPage } from "components";

const OTMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/602"
            searchForm="https://nca.margytech.in/api/form/603"
            url="/master/operationtheatre/"
            baseSearchParam=""
            tableName="operationtheatre"
            tableTitle="OT"
            createFormButtonText="Add OT"
            formTitle="OT Master"
            modalFullscreen={false}
            addPermissions={['his:master:operationtheatre:add']}
        />
    )
}

export default OTMaster;