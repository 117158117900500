import React, { useRef, useState } from "react";
import { FullPageTable } from "components";
import { Form, Button } from "react-bootstrap";
import * as actions from "actions/CommonActions";

const ProjectSpecial = () => {
    const form = useRef(null);
    const fullRef = useRef(null);

    const submitRates = async (e) => {
        e.preventDefault();
        console.log("hello");
        const formData = new FormData(form.current);
        let info = [];
        for (let i of formData.keys()) {
            const value = formData.get(i);
            console.log(i);
            console.log(value);
            if (i.startsWith("comment_")) {
                let id = i.substring(8, i.length);
                if (!isNaN(parseFloat(value))) {
                    info.push({ id: id, agreed_rate: value });
                }
            }
        }
        const response = await actions.customAction(`/master/projectspecialrates/${formData.get("data[project]")}/`, "", "Project Special Rates", "update", info);
        console.log(response);
        if (response) {
            fullRef.current.updateTable();
        }
    }


    return (
        <Form ref={form} onSubmit={(e) => submitRates(e)}>
            <div>
                <div className="d-flex justify-content-end">
                    <Button
                        type="submit"
                    >
                        Save Rates
                    </Button>
                </div>
                <FullPageTable
                    fullRef={fullRef}
                    form="https://nca.margytech.in/api/form/448"
                    searchForm="https://nca.margytech.in/api/form/664"
                    url="/master/projectspecialrates/"
                    baseSearchParam=""
                    tableName="projectspecial"
                    tableTitle="Project"
                    createFormButtonText="Add Project"
                    formTitle="Project Master"
                    modalFullscreen={true}
                />
            </div>
        </Form>
    )
}

export default ProjectSpecial;