import React from "react";
import { CustomPage } from "components";

const PharmacyStockConsumption = (params) => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/275"
            searchForm="https://nca.margytech.in/api/form/699"
            url="/purchaseorder/stockconsumption/"
            tableName="pharmacystockconsumption"
            tableTitle="Stock consumption"
            createFormButtonText="Stock Consumption"
            baseSearchParam="?departments_nonmedical=Pharmacy"
            modalFullscreen={true}

        />
    )
}

export default PharmacyStockConsumption;