import { Outlet } from "react-router-dom";

const InventoryStock = () => {

    return (
        <Outlet />
    );
}

export default InventoryStock;
