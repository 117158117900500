import React, { useEffect, useState } from "react";
import { FullPageForm } from "components";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const PatientRegistration = () => {
    const { appointmentId } = useParams();
    const [appointment, setAppointment] = useState(null);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchAppointment = async () => {
        const response = await actions.customAction("/frontdesk/appointment/" + appointmentId + "/", "", "Appointment", "load", null);
        if (response) {
            setAppointment({
                appointment_id: response.id,
                first_name: response.patient_name,
                mobile: response.mobile,
            });
        }
    }

    useEffect(() => {
        if (appointmentId) {
            fetchAppointment();
        }
    }, [appointmentId]);

    console.log(appointment);

    return (
        <>
            {appointment && <FullPageForm
                submission={appointment}
                form="https://nca.margytech.in/api/form/507"
                url="/frontdesk/patient/"
                tableTitle="Patient Registration"
                navigateTo={`/${param1}/frontdesk/patient`}
                patch={false}
            />}
        </>
    )
}

export default PatientRegistration;