import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const Attachment = ({attachment, removeAttachment}) => {
    return (
        <div className="d-flex m-1 px-2 flex-row justify-content-between border" style={{borderRadius: 5}}>
            <div>
                <small>{attachment.title}</small>
                
            </div>
            <div>
            <AiOutlineClose
              size={15}
              onClick={removeAttachment}
            />
            </div>
        </div>
    );
}

export default Attachment;