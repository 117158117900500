import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { FullPageTable, CustomTable } from "components";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as actions from "actions/CommonActions";

const SampleCollection = () => {
    const { opdIpdAdmissionId } = useParams();
    const [opdIpdData, setOpdIpdData] = useState(null);
    const [filter, setFilter] = useState(null);
    const [print, setPrint] = useState(false);
    const [url, setUrl] = useState(null);
    const form = useRef(null);
    const customTableRef = useRef()

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";
    const param2 = params.length > 0 ? params[1] : "";
    const param3 = params.length > 0 ? params[2] : "";
    const param4 = params.length > 0 ? params[3] : "";

    const fetchOpdIpdAdmission = async () => {
        if (param4 === "opd") {
            const response = await actions.customAction("/frontdesk/opdadmission/" + opdIpdAdmissionId + "/", "", "Samples", "load", null);
            if (response) {
                setOpdIpdData(response);
                setFilter(`?opd=${opdIpdAdmissionId}&status=NOT_COLLECTED`)
                setUrl(`/frontdesk/opdadmission/${opdIpdAdmissionId}/testprofiles/`)
            }
        } else if (param4 === "ipd") {
            const response = await actions.customAction("/frontdesk/ipdadmission/" + opdIpdAdmissionId + "/", "", "Samples", "load", null);
            if (response) {
                setOpdIpdData(response);
                setFilter(`?ipd=${opdIpdAdmissionId}&status=NOT_COLLECTED`)
                setUrl(`/frontdesk/ipdadmission/${opdIpdAdmissionId}/testprofiles/`)
            }
        } else if (param4 === "ed") {
            const response = await actions.customAction("/frontdesk/edadmission/" + opdIpdAdmissionId + "/", "", "Samples", "load", null);
            if (response) {
                setOpdIpdData(response);
                setFilter(`?ed=${opdIpdAdmissionId}&status=NOT_COLLECTED`)
                setUrl(`/frontdesk/edadmission/${opdIpdAdmissionId}/testprofiles/`)
            }
        }
    }

    useEffect(() => {
        if (opdIpdAdmissionId && param4) {
            fetchOpdIpdAdmission();
        }
    }, [opdIpdAdmissionId]);


    const handleSampleCollection = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        let data = {};
        data[param4] = opdIpdAdmissionId;
        let info = [];
        for (var value of formData.keys()) {
            if (formData.get(value) === "on") {
                let id = value.substring(6, value.length);
                info.push({ id: id, barcode: formData.get("comment_" + id) });
            }
            data["samples"] = info;
        }
        console.log(form.current)
        data['print_barcode'] = print;

        if (print) {
            const response = await actions.customAction(
                "/samplemanagement/sample/confirmsamples/",
                "",
                "Samples",
                "download",
                data
              );
              if (response) {
                setPrint(false)
              }
        }else{
            const response = await actions.customAction("/samplemanagement/sample/confirmsamples/", "", "Samples", "create", data);
        }
        customTableRef.current.updateTable();
        // console.log("AAAAAAAAAAAAAAAAAAAAAAA", response)
    }
        // const donwloadFile = async (e) => {
           
        // if(response){
        //     const templateToPDF = async (data) => {
        //         try {
        //           const response = await axios.post('/samplemanagement/sample/confirmsamples/', data, {
        //             responseType: "blob",
        //           });
        //           console.log("Response", response);
        //           if (response.status === 200) {
        //             // notify("Template fetched successfully!", "success");
        //             // fileDownload(response.data, "template.html");
        //             if (data.preview) {
        //               return response.data;
        //             } else {
        //               fileDownload(response.data, "template.pdf");
        //             }
        //           }
        //           console.log("FILE DOWNLOAD RESPONSE:", response);
        //         } catch (err) {
        //           console.log("Error", err);
        //           notify("Template could not be downloaded!", "warning");
        //         }
        //       };
        // }
    // };

    return (
        <Container className="mt-3">
            <div className="mx-1 d-flex justify-content-between">
                <span>Patient Name: <b>{opdIpdData?.patient_name}</b></span>
                <span>Referred By: <b>{opdIpdData?.referred_doctor_data?.name}</b></span>
                <span>Registered On: <b>{opdIpdData?.created_on}</b></span>
            </div>
            <Form ref={form} onSubmit={(e) => handleSampleCollection(e)}>
                {opdIpdData && filter && <CustomTable
                    ref={customTableRef}
                    tableName="sample"
                    tableTitle="Sample"
                    url="/samplemanagement/sample/"
                    baseSearchParam={filter}
                    searchURL=""
                    setForm=""
                />}
                <div className="my-3 d-flex justify-content-center">
                    <Button type="submit">Confirm Collection</Button>
                    <Button
                        type="submit"
                        className="mx-2"
                        name="print_barcode"
                        value="true"
                        onClick={() => setPrint(true)}
                    >Print Barcode</Button>
                </div>
                <div className="mt-4 mx-1"><b>Tests Requested</b></div>
                {opdIpdData && url && <CustomTable
                    url={url}
                    baseSearchParam=""
                    tableName="sampletestprofile"
                    tableTitle=""
                    searchURL=""
                    setForm=""
                />}
            </Form>
        </Container >
    )
}

export default SampleCollection;