import React from "react";
import { CustomPage } from "components";

const BedMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/93"
            searchForm="https://nca.margytech.in/api/form/567"
            url="/master/bed/"
            baseSearchParam=""
            tableName="bed"
            tableTitle="Bed"
            createFormButtonText="Add Bed"
            formTitle="Bed Master"
            modalFullscreen={false}
            addPermissions={['his:master:bed:add']}
        />
    )
}

export default BedMaster;