import React, { useEffect } from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";

const OutlabSelection = () => {

    return (
        <FullPageTable
            form="https://nca.margytech.in/api/form/638"
            searchForm="https://nca.margytech.in/api/form/656"
            url="/samplemanagement/sampletestprofile/"
            tableName="sampleoutlab"
            tableTitle="Send to Outlab"
            formTitle="Send to Outlab"
            modalFullscreen={false}
            baseSearchParam={`?status=ACCESSED&lab_status=OUTLAB`}
            searchURL=""
            setForm=""
            testId={true}
        />
    )
}

export default OutlabSelection;
