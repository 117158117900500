import React from "react";
import { CustomPage, FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";

const EDBilling = () => {
    const { edId } = useParams();

    if (edId) {
        return <Outlet />;
    }

    return (
        <FullPageTable
            // form="https://nca.margytech.in/api/form/615"
            searchForm="https://nca.margytech.in/api/form/687"
            url="/frontdesk/edadmission/"
            tableName="edbill"
            tableTitle="ED Billing"
            formTitle=""
            modalFullscreen={true}
            baseSearchParam="?is_archive=False"
        />
    )
}

export default EDBilling;