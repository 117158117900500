import React from "react";
import { FullPageTable } from "components";

const PharmacyPOApproval = (params) => {
  return (
    <FullPageTable
      form="https://nca.margytech.in/api/form/436"
      searchForm="https://nca.margytech.in/api/form/691"
      url="/purchaseorder/purchaseorder/"
      tableName="pharmacypoapproval"
      tableTitle="purchaseorderapproval"
      formTitle="Approve Purchase Order"
      modalFullscreen={true}
      baseSearchParam="?status=NEW&department__department_code=PHARMACY"
    />
  );
};

export default PharmacyPOApproval;
