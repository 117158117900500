import React from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const RISPrintReport = (params) => {
    // const { patientId } = useParams();

    // if (patientId) {
    //     return <Outlet />;
    // }

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/742"
            url="/studymanagement/studyreport/printreport/"
            tableName="risprintreport"
            tableTitle="Report"
            modalFullscreen={true}
            baseSearchParam=""
        />
    )
}

export default RISPrintReport;