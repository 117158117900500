import React from "react";
import { CustomPage } from "components";

const PostOPNotes = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/613"
            searchForm="https://nca.margytech.in/api/form/686"
            url="/operationtheatre/postopnotes/"
            baseSearchParam=""
            tableName="postopnotes"
            tableTitle="Post OP Notes"
            createFormButtonText="Add Note"
            formTitle="Post OP Notes"
            modalFullscreen={true}
            addPermissions={['his:operationtheatre:postopnotes:add']}
        />
    )
}

export default PostOPNotes;