import React, { useEffect } from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";

const PendingSample = () => {
    const { opdIpdAdmissionId } = useParams();

    if (opdIpdAdmissionId) {
        return <Outlet />;
    }

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/653"
            url="/frontdesk/opdipddata/"
            baseSearchParam=""
            tableName="pendingsamples"
            modalFullscreen={true}
        />
    )
}

export default PendingSample;