import React, { useState, useRef, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { FullPageForm } from "components";
import * as actions from "actions/CommonActions";
import { useLocation, useParams } from "react-router-dom";

const InventoryGoodInwardCreate = () => {
  const { poId } = useParams();
  const [purchaseOrder, setPurchaseOrder] = useState(null);

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const fetchPurchaseOrder = async () => {
    const response = await actions.customAction("/purchaseorder/purchaseorder/" + poId + "/", "", "Purchase Order", "load", null);
    if (response) {
      setPurchaseOrder({
        purchase_order: response.id,
        vendor: response.vendor,
        po_number: response.po_number,
        vendor_name: response.vendor_data?.name,
        purchase_order_items: response.purchase_order_items,
        department: "INVENTORY",
    });
    }
  }

  useEffect(() => {
    if (poId) {
      fetchPurchaseOrder();
    }
  }, [poId]);

  return (
    <>
      {purchaseOrder && <FullPageForm
        submission={purchaseOrder}
        form="https://nca.margytech.in/api/form/451"
        searchForm=""
        url="/purchaseorder/goodinward/"
        baseSearchParam=""
        tableName="goodsinward"
        tableTitle="Goods Inward"
        formTitle="Goods Inward"
        modalFullscreen={true}
        navigateTo={`/${param1}/inward/goods`}
        patch={false}
      />}
    </>
  );
};

export default InventoryGoodInwardCreate;
