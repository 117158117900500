import React, { useRef, useState } from "react";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import { useNavigate } from "react-router-dom";
import AccessControlHidden from "components/Access/AccessControl/AccessControlHidden";

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import {FiChevronDown} from "react-icons/fi"

const ReactMenu = ({ item, index, userPermissions, subNavigations, isActive }) => {

    const ref = useRef(null);
    const [isOpen, setOpen] = useState();

    const navigate = useNavigate();

    return (
        <>
            <div ref={ref} className="btn no-border-radius" style={{ borderBottom: isActive ? "4px solid #01437A" : 0 }} onPointerEnter={() => setOpen(true)} onPointerLeave={() => setOpen(false)} >
                <div
                    className="text-center fw-bold text-primary cursor-pointer text-nowrap"
                    style={{ fontSize: 15 }}
                >
                    <span>{item.title}</span><span className={`${subNavigations[index]?.length != 0 ? "ms-1" : "d-none"}`}><FiChevronDown/></span>
                </div>
            </div>
            {subNavigations[index]?.length > 0 &&
                <ControlledMenu
                    state={isOpen ? 'open' : 'closed'}
                    anchorRef={ref}
                    onPointerEnter={() => setOpen(true)}
                    onPointerLeave={() => setOpen(false)}
                    onClose={() => setOpen(false)}
                >
                    {subNavigations[index]?.map((element, subIndex) => {
                        return (
                            <AccessControlHidden
                                key={subIndex}
                                userPermissions={userPermissions}
                                allowedPermissions={element.permissions}
                            >
                                <MenuItem onClick={() => { navigate("/" + element.navigateTo); navigate(0); }}>
                                    {element.title}
                                </MenuItem>
                            </AccessControlHidden>
                        );
                    })}
                </ControlledMenu>
            }
        </>
    );
}

export default ReactMenu;