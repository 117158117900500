import React, { useState, useRef, useEffect } from "react";
import { Container, Navbar, Nav, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ProfileDropdown } from "components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "core-ui/components/NavBar.scss";
import moment from "moment";
import * as actions from "actions/PublicAuthActions";


const NavBar = (props) => {
    const { client } = props;
    const [profileDropdown, setProfileDropDown] = useState(false);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item != "");
    const param1 = params.length > 0 ? params[0] : "";

    const user = useSelector((state) => state.authReducer.user);
    const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);

    const [currentTime, setCurrentTime] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'));
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        const x = setInterval(() => {
            setCurrentTime(moment().format('MMMM Do YYYY, h:mm:ss a'));
        }, 1000)

        return () => clearInterval(x)
    }, [])

    useEffect(() => {
        dispatch(actions.loadTenantProducts(client));
    }, [client])

    const tenantProducts = useSelector((state) => state.publicAuthReducer.tenantproducts);

    return (
        <Navbar className="navbar-background" fixed="top">
            <Container fluid className="w-100">
                <Navbar.Brand

                    href={param1 === "superadmin" ? "/superadmin" : (param1 === "admin" ? "/admin" : "/")}
                    className="text-white">
                    {/* {client ? client : "Margy Tech."} */}
                    {client ? <img
                        src="https://i.ibb.co/ypQnf8z/enic.png"
                        // src="http://margytech.in/assets/img/Logo.jpg"
                        width="200"
                        height="40"
                        className="d-inline-block align-top"
                        alt="Enic"

                    /> : "Margy Tech"}
                </Navbar.Brand>
                <div className="d-flex me-auto">
                    {tenantProducts?.map((tenantproduct, index) => {
                        return (
                            <div
                                key={index}
                                className="btn border-0 mx-2 fw-bold"
                                style={param1 == tenantproduct?.product_data.slug ? { borderBottom: 0, backgroundColor: "#b0d9fd", width: 120 } : {borderBottom: 0, backgroundColor: "#226094", width: 120}}
                                // style={param1 === tenantproduct?.product_data.slug ? { borderBottom: 0, backgroundColor: "#2db0c2", width: 120 } : {borderBottom: 0, backgroundColor: "#226094", width: 120}}
                                onClick={() => navigate(`/${tenantproduct?.product_data?.route}`)}>
                                <div
                                    className={param1 == tenantproduct?.product_data.slug ? "text-center text-white cursor-pointer text-enic-blue" : "text-center text-white cursor-pointer"}
                                    style={{ fontSize: 15 }}
                                >
                                    {tenantproduct?.product_data?.title}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="text-white d-flex ms-auto mx-3">
                    <span className="fw-bold">
                        {currentTime}
                    </span>
                </div>
                <Nav.Link
                    className={`${isAuthenticated ? 'position-relative text-white main-nav-links' : "d-none"
                        }`}
                    onMouseEnter={() => {

                        setProfileDropDown(true)

                    }}
                    onMouseLeave={() => {
                        setTimeout(() => {
                            setProfileDropDown(false);
                        }, 2000)
                    }}
                    style={{ textDecoration: "none" }}
                >
                    <div className="d-flex justify-content-center align-items-center">
                        <Card className="cursor-pointer shadow" style={{ width: "40px", height: "40px", borderRadius: "20px" }}></Card>
                        <div className="mx-3">{user?.username}</div>
                    </div>
                    <ProfileDropdown profileDropdown={profileDropdown} />
                </Nav.Link>
            </Container>
        </Navbar>
    );
}

export default NavBar;