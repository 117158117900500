import React from "react";
import { CustomPage } from "components";
import { useParams, Outlet } from "react-router-dom";

const OPDBilling = () => {
    const { billId } = useParams();

    if (billId) {
        return <Outlet />;
    }

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/512"
            searchForm="https://nca.margytech.in/api/form/635"
            url="/frontdesk/opdadmission/opdreceipt/"
            baseSearchParam=""
            tableName="opdbill"
            tableTitle="OPD Billing"
            createFormButtonText="Add New Payment"
            formTitle="New Payment"
            modalFullscreen={true}
            addPermissions={['his:billing:opdbilling:add']}
        />
    )
}

export default OPDBilling;