import React, { useState, useRef, useEffect, forwardRef } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { Form } from "react-formio";
import * as actions from "actions/CommonActions";
import { CustomTable } from "components";
import axios from "axios";
import { AiOutlineClose, AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { dropdownUrl } from "utilities";
import { useSelector } from "react-redux";

const FullPageTable = forwardRef((params, fullRef) => {
    const { form, searchForm, url, baseSearchParam, tableName, tableTitle, formTitle, modalFullscreen, testId } = params;
    const [modal, setModal] = useState(false);
    const [itemForm, setItemForm] = useState(null);
    const [filterForm, setFilterForm] = useState(null);
    const [filterParams, setFilterParams] = useState("");
    const [currentItem, setCurrentItem] = useState(null);
    const [submissionData, setSubmissionData] = useState([]);
    const [fold, setFold] = useState(true);
    const customTableRef = useRef();
    const currentLocation = useSelector((state) => state.adminReducer.currentLocation)

    useEffect(()=> {
        if(customTableRef){
            fullRef = customTableRef;
        }
    }, [])

    const fetchForm = async () => {
        setItemForm(await axios.get(form));
    }

    const fetchFilterForm = async () => {
        setFilterForm(await axios.get(searchForm));
    }

    const updateItem = async (e) => {
        const response = await actions.customAction(url + currentItem.id + "/", "", tableTitle, "update", e.data);
        console.log(response)
        if (response) {
            setModal(false);
            customTableRef.current.updateTable();
        }
    }

    useEffect(() => {
        fetchForm();
        fetchFilterForm();
    }, []);

    const filterItems = (e) => {
        const keys = Object.keys(e.data);
        console.log(keys);
        let x = "";
        if (baseSearchParam === "") {
            x = "?";
        }
        else {
            x = "&";
        }
        keys.map((key) => {
            if (e.data[key] && key !== 'submit') {
                x = x + key + "=" + e.data[key] + "&";
            }
        })
        setFilterParams(x);
    }

    console.log(filterParams);

    return (
        <Container fluid className="my-5">
            <Row>
                {searchForm &&
                    <Col sm={3} className={`${fold ? "d-none" : "d-flex"}`}>
                        {filterForm?.data &&
                            <Form
                                form={dropdownUrl(filterForm?.data, null, currentLocation)}
                                onSubmit={(e) => { console.log(e); setSubmissionData(e); filterItems(e); }}
                                submission={submissionData}
                                onChange={(e) => console.log("FilterData:", e)}

                            />
                        }
                    </Col>
                }
                <Col sm={(searchForm && !fold) ? 9 : 12}>
                    {!fold && <div className=""><Button onClick={() => setFold(true)}><AiOutlineMenuFold size={30} /></Button></div>}
                    {fold && <div className=""><Button onClick={() => setFold(false)}><AiOutlineMenuUnfold size={30} /></Button></div>}
                    <CustomTable
                        ref={customTableRef}
                        tableName={tableName}
                        tableTitle={tableTitle}
                        url={url}
                        baseSearchParam={baseSearchParam}
                        searchURL={filterParams}
                        setForm={setItemForm}
                        setModal={setModal}
                        setUpdateData={setCurrentItem}
                    />
                </Col>
            </Row>
            <Modal
                show={modal}
                onHide={() => setModal(false)}
                centered
                onExited={() => {
                    setModal(false);
                }}
                size="lg"
                fullscreen={modalFullscreen}
            >
                <Modal.Header className="d-flex flex-row justify-content-center">
                    <h2>{formTitle}</h2>
                </Modal.Header>
                <div className="removeButtonRight position-absolute cursor-pointer">
                    <AiOutlineClose size={20} onClick={() => setModal(false)} />
                </div>
                <Modal.Body>
                    {itemForm?.data &&
                        <Form
                            submission={{ 'data': currentItem }}
                            form={dropdownUrl(itemForm?.data, testId ? currentItem : null, currentLocation)}
                            onSubmit={(e) => {
                                updateItem(e);
                            }}
                            onChange={(e) => console.log(e)}
                        />
                    }
                </Modal.Body>
            </Modal>
        </Container>
    );
});

export default FullPageTable;