import React from "react";
import { FullPageTable } from "components";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const SpecialReportMaster = () => {

    return (
        <>
        <div className="d-flex justify-content-end align-items-end mx-4 py-2">
            <Button as={Link} to={`/lis/master/specialreport/new`}>Add New</Button>
        </div>
        <FullPageTable
            // form="https://nca.margytech.in/api/form/477"
            searchForm="https://nca.margytech.in/api/form/535"
            url="/master/specialreport/"
            baseSearchParam=""
            tableName="specialreport"
            tableTitle="Special Report"
            // createFormButtonText="Add Special Report"
            formTitle="Special Reports Master"
            modalFullscreen={true}
            addPermissions={[]}
        />
        </>
    )
}

export default SpecialReportMaster;