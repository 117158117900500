import React from "react";
import { CustomPage, FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";

const IPDBilling = () => {
    const { ipdId } = useParams();

    if (ipdId) {
        return <Outlet />;
    }

    return (
        <FullPageTable
            // form="https://nca.margytech.in/api/form/615"
            searchForm="https://nca.margytech.in/api/form/733"
            url="/frontdesk/ipdadmission/"
            tableName="ipdbill"
            tableTitle="IPD Billing"
            formTitle=""
            modalFullscreen={true}
            baseSearchParam=""
        />
        // <CustomPage
        //     // form="https://nca.margytech.in/api/form/511"
        //     form="https://nca.margytech.in/api/form/615"
        //     searchForm="https://nca.margytech.in/api/form/687"
        //     url="/bill/ipdreceipt/"
        //     baseSearchParam=""
        //     tableName="ipdbill"
        //     tableTitle="IPD Billing"
        //     createFormButtonText="Add New Payment"
        //     formTitle="New Payment"
        //     modalFullscreen={true}
        // />
    )
}

export default IPDBilling;