import React, { useEffect, useState } from "react";
import { FullPageForm } from "components";

const TestProfileCostEstimation = () => {

    return (
        <FullPageForm
            form="https://nca.margytech.in/api/form/184"
            url="frontdesk/costestimationemail/"
            tableTitle="PDF File"
            fileDownload={true}
            patch={false}
        />
    )
}

export default TestProfileCostEstimation;