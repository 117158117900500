import React, { useEffect, useRef, useState } from "react";
import { FullPageTable } from "components";
import { Form, Button } from "react-bootstrap";
import * as actions from "actions/CommonActions";
import { useSelector } from "react-redux";

const ClientSpecial = () => {
    const form = useRef(null);
    const [submitType, setSubmitType] = useState("");
    const currentlocation = useSelector((state) => state.adminReducer.currentLocation)
    const user = useSelector((state) => state.authReducer.user)
    const [multiLocation, setMultiLocation] = useState(false)
    const setting = useSelector((state) => state.adminReducer.setting);

    const submitRates = async (e) => {
        e.preventDefault();
        console.log("hello");
        const formData = new FormData(form.current);
        let info = [];
        for (let i of formData.keys()) {
            const value = formData.get(i);
            console.log(i);
            console.log(value);
            if (i.startsWith("comment_")) {
                let id = i.substring(8, i.length);
                if (!isNaN(parseFloat(value))) {
                    info.push({ id: id, agreed_rate: value });
                }
            }
        }
        let response
        if(!currentlocation && user.is_admin){
            response = await actions.customAction(`/master/clientspecialrates/${formData.get("data[client]")}/`, "", "Client Special Rates", "update", info);
        } else{
            response = await actions.customAction(`/master/clientlocationspecialrates/${formData.get("data[client]")}/`, "", "Client Special Rates", "update", info)
        }
        if (response.status == 201) {
            form.current.updateTable();
        }
    }

    return (
        <Form ref={form} onSubmit={(e) => submitRates(e)}>
            <div>
                <div className="d-flex justify-content-end">
                    <Button
                        type="submit"
                    >
                        Save Rates
                    </Button>
                </div>
                {!currentlocation && user.is_admin ? <FullPageTable
                    form="https://nca.margytech.in/api/form/174"
                    searchForm="https://nca.margytech.in/api/form/648"
                    url="/master/clientspecialrates/"
                    baseSearchParam=""
                    tableName="clientspecialrates"
                    tableTitle="Client Special Rates"
                    createFormButtonText=""
                    formTitle="Client Master"
                    modalFullscreen={true}
                /> :
                <FullPageTable
                    form="https://nca.margytech.in/api/form/174"
                    searchForm="https://nca.margytech.in/api/form/648"
                    url="/master/clientlocationspecialrates/"
                    baseSearchParam=""
                    tableName="clientlocationspecialrates"
                    tableTitle="Client Special Rates"
                    createFormButtonText=""
                    formTitle="Client Master"
                    modalFullscreen={true}
                />}
            </div>
        </Form>
    )
}

export default ClientSpecial;