import React from "react";
import { CustomPage } from "components";

const InventoryVendorMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/341"
            searchForm="https://nca.margytech.in/api/form/548"
            url="/master/vendor/"
            baseSearchParam="?vendor_type=NON_MEDICAL"
            tableName="vendor"
            tableTitle="Inventory Vendor"
            createFormButtonText="Add New Vendor"
            formTitle="Vendor Master"
            modalFullscreen={true}
            addPermissions={[]}

        />
    )
}

export default InventoryVendorMaster;