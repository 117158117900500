const initialState = {
    patients: [],
};
  
export const userReducer = (state = initialState, action) => {
    console.log(action);
    switch (action.type) {
    //   case "LOAD_ALL_CATEGORIES":
    //     state.categories = action.payload;
    //     break;
        default:
        console.log("State", state);
    }
    return state;
};