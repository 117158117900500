const initialState = {
    setting: null,
    currentLocation: ""
};

export const adminReducer = (state = initialState, action) => {
    console.log(action);
    switch (action.type) {
        case "SET_SETTING":
            console.log("STATE:", state);
            state.setting = action.payload;
            break;
        case "SET_LOCATION":
            console.log("STATE:", state);
            state.currentLocation = action.payload;
            break;
        case "CLEAR_LOCATION":
            console.log("STATE:", state);
            state.currentLocation = '';
            break;
        default:
            console.log("State", state);
    }
    return state;
};