import React from "react";
import { CustomPage } from "components";

const InventoryInwardWithoutPO = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/741"
            searchForm="https://nca.margytech.in/api/form/740"
            url="/purchaseorder/goodinward/"
            baseSearchParam="?vendors_type=NON_MEDICAL"
            tableName="pharmacyinwardwithoutpo"
            tableTitle="Inward Without Purchase Order"
            createFormButtonText="Add New Good Inward"
            formTitle="Inward Without PO"
            modalFullscreen={true}
        />
    );
};

export default InventoryInwardWithoutPO;