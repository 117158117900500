import React from "react";
import { FullPageTable } from "components";

const RegistrationSummary = (params) => {

    return (
        <FullPageTable
            form="https://nca.margytech.in/api/form/578"
            searchForm="https://nca.margytech.in/api/form/681"
            url="/frontdesk/opdadmission/"
            tableName="opdadmission"
            tableTitle="OPD Admission"
            formTitle="OPD Admission"
            modalFullscreen={true}
            baseSearchParam="?admission_type=OPD_ADMISSION"
        />
    )
}

export default RegistrationSummary;