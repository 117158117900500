import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";
import * as actions from "actions/CommonActions";

const BillingSummary = (params) => {
    const { id } = useParams();
    const [summary, setSummary] = useState(null);

    const fetchSummary = async () => {
        const response = await actions.customAction("/frontdesk/opdadmission/billing_summary/", "", "Billing Summary", "load", null)
        if (response) {
            setSummary(response.filter((res) => res.id === "Total")[0]);
        }
    }

    useEffect(() => {
        fetchSummary();
    }, [])

    console.log('SUMMARY', summary);

    if (id) {
        return <Outlet />;
    }

    return (
        <div>
            <Row className="p-0">
                <Col sm={10}>
                    <FullPageTable
                        searchForm="https://nca.margytech.in/api/form/661"
                        url="/frontdesk/opdadmission/billing_summary/"
                        tableName="billingsummary"
                        tableTitle="Billing Summary"
                        baseSearchParam=""
                    />
                </Col>
                {summary && <Col sm={2} className="ps-0 mt-5 d-flex flex-column">
                    <table>
                        <tr>
                            <td><h5>Total Patients</h5></td>
                            <td><h5>: <b>{summary.total_patient_count}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Tests</h5></td>
                            <td><h5>: <b>{summary.total_test_count}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Gross</h5></td>
                            <td><h5>: <b>{summary.total_gross}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Discount</h5></td>
                            <td><h5>: <b>{summary.total_discount}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Other Charges</h5></td>
                            <td><h5>: <b>{summary.total_other_charges}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Net</h5></td>
                            <td><h5>: <b>{summary.total_bill_amount}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Paid Amount</h5></td>
                            <td><h5>: <b>{summary.total_paid_amount}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Balance Amount</h5></td>
                            <td><h5>: <b>{summary.total_balance_amount}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Previous Amount Received</h5></td>
                            <td><h5>: <b>{summary.total_previous_amount}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Refund</h5></td>
                            <td><h5>: <b>{summary.total_refund}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Cash</h5></td>
                            <td><h5>: <b>{summary.total_cash}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Cheque</h5></td>
                            <td><h5>: <b>{summary.total_cheque}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Credit/Debit Card</h5></td>
                            <td><h5>: <b>{summary.total_credit_debit_card}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total BHIM</h5></td>
                            <td><h5>: <b>{summary.total_bhim}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total NEFT</h5></td>
                            <td><h5>: <b>{summary.total_neft}</b></h5></td>
                        </tr>
                        <tr>
                            <td><h5>Total Online Transfer</h5></td>
                            <td><h5>: <b>{summary.total_online_transfer}</b></h5></td>
                        </tr>

                    </table>
                </Col>}
            </Row>
        </div>
    )
}

export default BillingSummary;