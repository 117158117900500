import React, { useState } from 'react';

import { Document, Page, pdfjs } from "react-pdf";
import { CKEditor } from "ckeditor4-react";
import { Button, Container, Modal } from 'react-bootstrap';
import {IoCloudDownloadSharp} from 'react-icons/io5';

const PdfPreview = ({ visible, setVisible, pdfData, downloadRecord }) => {
    const [numPages, setNumPages] = useState(0);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <Container>
            <Modal
                show={visible}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onClose={() => setVisible(false)}
                onHide={() => setVisible(false)}
                fullscreen={true}
                onExited={() => {
                    setVisible(false);
                }}
            >
                <Modal.Header
                    closeButton
                    className="d-flex justify-content-center w-100"
                >
                    <Modal.Title className="d-flex justify-content-center w-100">
                        PDF Preview
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-end py-2'>
                        <Button onClick={(e) => downloadRecord(e)}><IoCloudDownloadSharp size="20"/> Download</Button>
                    </div>
                    <div
                        className="d-flex justify-content-center align-items-center py-3 h-100vh"
                        style={{ backgroundColor: "#999" }}
                    >
                        <Document
                            file={pdfData}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {[...Array(numPages).keys()].map((item, index) => {
                                return <Page key={index + 1} className="my-3" pageNumber={index + 1} />;
                            })}
                        </Document>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={() => console.log("console")}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        </Container>
    );
}

export default PdfPreview;