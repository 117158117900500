import React, { useRef, useEffect, useState } from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap';
import * as actions from "actions/CommonActions";
import DataTable from 'react-data-table-component';
import axios from 'axios';

const CustomListDropdownStockReturn = (props) => {
    const { returnStocks, setReturnStocks, isOpen, setIsOpen } = props;
    const [items, setItems] = useState(null)
    const [currentItem, setCurrentItem] = useState(null);
    const [stockItems, setStockItems] = useState([])
    const itemForm = useRef(null);

    const columns = [
        {
            name: "Item Code",
            selector: row => row.item_data?.code
        },
        {
            name: "Item Name",
            selector: row => row.item_data?.name
        },
        {
            name: "Vendor Name",
            selector: row => row.purchase_order_item_data?.vendor_data?.name
        },
        {
            name: "Batch No.",
            selector: row => row.batch
        },
        {
            name: "Expiry Date",
            selector: row => row.expiry_date
        },
        {
            name: "Stock Item Quantity",
            selector: row => row.quantity
        },
        {
            name: "Select Return Quantity",
            selector: null,
            cell: (row) => <Form.Group>
                <Form.Control
                    name={`selected_quantity_${row.id}`}
                    size="sm"
                    id={row.id}
                />
            </Form.Group>,
            button: true,
        },
        {
            name: "Select",
            selector: null,
            cell: (row) => <Form.Group>
                <Form.Check
                    type="checkbox"
                    name={`check_${row.id}`}
                    label=""
                    id={row.id}
                />
            </Form.Group>,
            button: true,
        },
    ]

    const fetchItems = async () => {
        const response = await actions.customAction("/master/item/", "?item_type__type_description=MEDICAL", "Items", "loadMany", null)
        if (response) {
            setItems(response);
        }
    }

    const fetchItemStock = async () => {
        const response = await actions.customAction("/purchaseorder/stock/", "?department__department_code=PHARMACY&item__id=" + currentItem, "Stocks", "loadMany", null)
        console.log(response);
        if (response) {
            setStockItems(response);
        }
    }

    useEffect(() => {
        fetchItems();
    }, [])

    useEffect(() => {
        if (currentItem) {
            fetchItemStock();
        }
    }, [currentItem])

    const addItems = (e) => {
        e.preventDefault();
        const formData = new FormData(itemForm.current);
        let info = {};
        let data = [];
        for (var value of formData.keys()) {
            console.log(value);
            if (formData.get(value) === "on") {
                let id = value.substring(6, value.length);
                info = { ...stockItems.find((item) => item.id === id) };
                info["selected_quantity"] = formData.get("selected_quantity_" + id);
                data.push(info);
            }
        }
        setReturnStocks(prevArr => [...prevArr, ...data]);
        setIsOpen(false);
    };

    return (
        <>
            <Form.Select name='material' value={currentItem} aria-label="Default select example" onChange={(e) => {
                setCurrentItem(e.target.value);
                setIsOpen(true);
            }}>
                <option selected value="">Select Item</option>
                {items && items?.map((item) => (
                    <option value={item.id}>
                        {item.name}
                    </option>
                ))}
            </Form.Select>
            <Modal
                show={isOpen}
                onHide={() => {
                    setIsOpen(false)
                    setCurrentItem("")
                }}
                onExited={() => {
                    setIsOpen(false)
                    setCurrentItem("")
                }}
                dialogClassName="my-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Available Items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form ref={itemForm}>
                        {stockItems && <DataTable data={stockItems} columns={columns} />}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="primary" onClick={(e) => addItems(e)}>
                        Add
                    </Button>
                    <Button variant="primary" onClick={() => setIsOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomListDropdownStockReturn
