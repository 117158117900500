import React, { useEffect, useState } from "react";
import {Container, Spinner} from 'react-bootstrap';


const AccessControlHidden = (props) => {
    
    const {children, userPermissions=[], allowedPermissions = []} = props;
    const [loading, setLoading] = useState(true);
    const [permitted, setPermitted] = useState(false);
    
    useEffect(()=> {
        // console.log(userPermissions);
        // console.log(allowedPermissions);
        if(allowedPermissions.length == 0){
            setPermitted(true);
        } else {
            for(let i in allowedPermissions){
                if(userPermissions.find(item => item == allowedPermissions[i])){
                    setPermitted(true);
                    break;
                }
            }
        }
        setLoading(false);
    }, [userPermissions]);

    if(loading){
        return (
            <></>
        );
    }

    if(!permitted){
        return <></>;
    }

    return (
        <>
            {children}
        </>
    );
}

export default AccessControlHidden;