import React from "react";
import { CustomPage } from "components";

const StudyMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/588"
            searchForm="https://nca.margytech.in/api/form/737"
            url="/master/study/"
            baseSearchParam=""
            tableName="study"
            tableTitle="Study"
            createFormButtonText="Add Study"
            formTitle="Study Master"
            modalFullscreen={true}
        />
    )
}

export default StudyMaster;