import React, { useState, useEffect } from "react";
import { CustomPageNew, FullPageForm, FullPageTable } from "components";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const PatientInfo = () => {
    const { edadmissionId } = useParams();
    const [edAdmission, setEdAdmission] = useState(null);
    const [preData, setPreData] = useState(null);
    const [bedChargePreData, setBedChargePreData] = useState(null);

    const [physicalExam, setPhysicalExam] = useState(null);
    const [physicalExamFlag, setPhysicalExamFlag] = useState(false);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchPreviousEDRecord = async () => {
        const filter = "?id=" + edadmissionId
        const previous_ed_responses = await actions.customAction("/frontdesk/edadmission/", filter , "ED Record", "loadMany", null);
        if (previous_ed_responses) {
            previous_ed_responses.map((res) => {
                let nowDate = new Date().getTime()
                const ed_admission_date = new Date(res.admission_date).getTime()
                let hours = Math.abs(ed_admission_date - nowDate) / 36e5;
                if(hours > 8){
                    alert(`This Patient is in Emergency Ward for more than 8 Hours. Total Time in ED Ward - ${Math.floor(hours)} Hours`)
                }
                // console.log("ED_ADMISSION: ", res)
                // console.log("ED_ADMISSION_DATE: ", ed_admission_date, nowDate, hours)
            })
        }
    }

    const fetchEdAdmission = async () => {
        const response = await actions.customAction("/frontdesk/edadmission/" + edadmissionId + "/", "", "Patient", "load", null);
        if (response) {
            setEdAdmission(response);
            setPreData({ ed: response.id, uhid: response.uhid });
            setBedChargePreData({
                ed: response.id,
                uhid: response.uhid,
                bed: response.bed,
                ward: response.ward,
                ward_name: response.ward_data?.name,
                bed_code: response.bed_data?.bed_code,
                bed_number: response.bed_data?.bed_number,
                patient_rate: response.bed_data?.patient_rate
            });
        }
    }

    // const fetchPhysicalExam = async () => {
    //     const response = await actions.customAction("/emergency/physicalexam/", "?ed__id=" + edadmissionId, "Physical Exam", "load", null);
    //     if (response) {
    //         if (response[0]?.id) {
    //             setPhysicalExamFlag(true);
    //             setPhysicalExam(response[0]);
    //         } else {
    //             setPhysicalExam({ ipd: edadmissionId });
    //         }
    //     }
    // }

    useEffect(() => {
        if (edadmissionId) {
            fetchEdAdmission();
        }
    }, [edadmissionId]);

    useEffect(() => {
        if (edAdmission) {
            fetchPreviousEDRecord();
        }
    }, [edAdmission]);


    // useEffect(() => {
    //     if (edadmissionId && preData) {
    //         fetchPhysicalExam();
    //     }
    // }, [edadmissionId, preData]);

    console.log("EdAdmission", edAdmission);

    return (
        <>
            <div className="m-5 d-flex justify-content-between">
                <div className="d-flex flex-column">
                    <b>Admission No:</b>
                    <h5>{edAdmission?.admission_no}</h5>
                </div>
                <div className="d-flex flex-column">
                    <b>UHID:</b>
                    <h5>{edAdmission?.uhid}</h5>
                </div>
                <div className="d-flex flex-column">
                    <b>Patient Name:</b>
                    <h5>{edAdmission?.patient_name}/{edAdmission?.patient_data?.age} {edAdmission?.patient_data?.age_type}/{edAdmission?.patient_data?.gender}</h5>
                </div>
            </div>
            <Tabs className="mx-4">
                <Tab eventKey="instruction" title="Instructions">
                    <h4 className="mt-2 mx-5">Instructions</h4>
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/616"
                        searchForm=""
                        url="/emergency/instruction/"
                        baseSearchParam={`?ed__id=${edAdmission?.id}&is_archive=False`}
                        tableName="edinstruction"
                        tableTitle="Instruction"
                        formTitle="Instruction"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                    <h4 className="mt-5 mx-5">Diagnosis</h4>
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/619"
                        searchForm=""
                        url="/emergency/diagnosis/"
                        baseSearchParam={`?ed__id=${edAdmission?.id}&is_archive=False`}
                        tableName="eddiagnosis"
                        tableTitle="Diagnosis"
                        formTitle="Diagnosis"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="vital" title="Vitals">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/617"
                        searchForm=""
                        url="/emergency/vital/"
                        baseSearchParam={`?ed__id=${edAdmission.id}&is_archive=False`}
                        tableName="edvital"
                        tableTitle="Vital"
                        formTitle="Vital"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="advice" title="Investigation Advice">
                    <Row>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/618"
                                searchForm=""
                                url="/emergency/labadvice/"
                                baseSearchParam={`?ed__id=${edAdmission.id}&is_archive=False`}
                                tableName="edlabadvice"
                                tableTitle="Lab Advice"
                                formTitle="Lab Advice"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/646"
                                searchForm=""
                                url="/emergency/radiologyadvice/"
                                baseSearchParam={`?ed__id=${edAdmission.id}&is_archive=False`}
                                tableName="edradiologyadvice"
                                tableTitle="Radiology Advice"
                                formTitle="Radiology Advice"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="medicine" title="Medicine">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/713"
                        searchForm=""
                        url="/emergency/medicine/"
                        baseSearchParam={`?ed__id=${edAdmission.id}&is_archive=False`}
                        tableName="edmedicine"
                        tableTitle="Medicine"
                        formTitle="Medicine"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="inputoutput" title="Input/Output">
                    <Row>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/621"
                                searchForm=""
                                url="/emergency/input/"
                                baseSearchParam={`?ed__id=${edAdmission.id}&is_archive=False`}
                                tableName="edinput"
                                tableTitle="Input"
                                formTitle="Input"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                        <Col sm={6}>
                            {preData && <CustomPageNew
                                form="https://nca.margytech.in/api/form/622"
                                searchForm=""
                                url="/emergency/output/"
                                baseSearchParam={`?ed__id=${edAdmission.id}&is_archive=False`}
                                tableName="edoutput"
                                tableTitle="Output"
                                formTitle="Output"
                                modalFullscreen={false}
                                preData={preData}
                            />}
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="indweller" title="Indwellers">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/625"
                        searchForm=""
                        url="/emergency/indweller/"
                        baseSearchParam={`?ed__id=${edAdmission.id}&is_archive=False`}
                        tableName="edindweller"
                        tableTitle="Indweller"
                        formTitle="Indweller"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="service" title="Services">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/627"
                        searchForm=""
                        url="/emergency/patientservice/"
                        baseSearchParam={`?ed__id=${edAdmission.id}&is_archive=False`}
                        tableName="edpatientservice"
                        tableTitle="Service"
                        formTitle="Service"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                <Tab eventKey="drvisiting" title="Doctor Visits">
                    {preData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/626"
                        searchForm=""
                        url="/emergency/doctorvisit/"
                        baseSearchParam={`?ed__id=${edAdmission.id}&is_archive=False`}
                        tableName="eddoctorvisit"
                        tableTitle="Doctor Visit"
                        formTitle="Doctor Visit"
                        modalFullscreen={false}
                        preData={preData}
                    />}
                </Tab>
                {/* <Tab eventKey="physicalexam" title="Physical Exam">
                    {preData && physicalExam && <FullPageForm
                        submission={physicalExam}
                        form="https://nca.margytech.in/api/form/632"
                        url="/emergency/physicalexam/"
                        tableTitle="Physical Exam"
                        navigateTo={`/${param1}/edpatient/patientinfo/${edAdmission.id}`}
                        patch={physicalExamFlag}
                    />}
                </Tab> */}
                <Tab eventKey="otschedule" title="OT Schedule">
                    {preData && <FullPageTable
                        searchForm=""
                        url="/operationtheatre/otschedule/"
                        tableName="edotschedule"
                        tableTitle="OT Approval"
                        baseSearchParam={`?ed__id=${edAdmission.id}&ot_schedule_status=APPROVED`}
                    />}
                </Tab>
                <Tab eventKey="bedcharges" title="Bed Charges">
                    {bedChargePreData && <CustomPageNew
                        form="https://nca.margytech.in/api/form/707"
                        searchForm=""
                        url="/emergency/bedcharge/"
                        baseSearchParam={`?ed__id=${edAdmission.id}`}
                        tableName="edbedcharge"
                        tableTitle="Bed Charges"
                        formTitle="Bed Charges"
                        modalFullscreen={false}
                        preData={bedChargePreData}
                    />}
                </Tab>
                <Tab eventKey="transfertoipd" title="Transfer">
                    {preData && <FullPageForm
                        submission={edAdmission} 
                        form="https://nca.margytech.in/api/form/709"
                        url={`/frontdesk/edadmission/${edAdmission.id}/transfertoipd/`}
                        tableTitle="Transfer to IPD"
                        navigateTo={`/${param1}/inpatient/nursingconsole`}
                        patch={false}
                    />}
                </Tab>
            </Tabs>
        </>
    )
}

export default PatientInfo;