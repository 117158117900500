import React from "react";
import { FullPageTable } from "components";

const HisDepartmentStock = (params) => {

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/698"
            url = "/purchaseorder/stock/"
            tableName="pharmacystock"
            tableTitle="Department stock"
            baseSearchParam="?departments=OPD,IPD,OT,Emergency&item__item_type__type_description=NON-MEDICAL"
        />
    )
}

export default HisDepartmentStock;