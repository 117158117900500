import React from "react";
import { CustomPage } from "components";

const LisMaterialRequest = (params) => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/277"
            searchForm="https://nca.margytech.in/api/form/697"
            url="/purchaseorder/materialrequest/"
            baseSearchParam="?request_from_dept__name=Lab"
            tableName="materialrequest"
            tableTitle="material request"
            createFormButtonText="Material Request"
            formTitle="Material Request"
            modalFullscreen={true}

        />
    )
}

export default LisMaterialRequest;