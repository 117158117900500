import React, { useState, useRef, useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import { CustomPage } from "components";

const MetaDataTag = () => {
    const { metaTagId } = useParams();

    if (metaTagId) {
        return (
            <Outlet />
        );
    }

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/408"
            searchForm="https://nca.margytech.in/api/form/672"
            url="/metadata/metatag/"
            baseSearchParam=""
            tableName="metatag"
            tableTitle="Meta Data Tag"
            createFormButtonText="Add Meta Data Tag"
            formTitle="Meta Data Tag"
            modalFullscreen={false}
        />
    );
}

export default MetaDataTag;