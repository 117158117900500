import React, { useState, useRef, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Container, Form as BForm, Button, Row, Col } from "react-bootstrap";
import { CustomTable, CustomPage } from "components";
import * as adminactions from "actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";

const Settings = () => {
  // return <Outlet/>
  const dispatch = useDispatch()
  const [offline, setOffline] = useState(false);
  const [multiLocation, setMultiLocation] = useState(false);
  const [disabled, setDisabled] = useState(false)
  // const [setting, setSetting] = useState();
  const [tls, setTLS] = useState(false);
  const [sending, setSending] = useState(false);
  const setting = useSelector((state) => state.adminReducer.setting);

  const settingFormRef = useRef();

  useEffect(() => {
    if (setting) {
      setTLS(setting.smtp_tls);
      setOffline(setting.use_offline);
      setMultiLocation(setting.location_enabled);
      setDisabled(setting.location_enabled)
    }
  }, [setting]);

  const handleSettingUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData(settingFormRef.current);
    formData.set('smtp_tls', tls);
    formData.set('use_offline', offline);
    formData.set('location_enabled', multiLocation);
    dispatch(adminactions.createOrUpdateSetting(formData));
  }

  const sendTestMail = async () => {
    setSending(true);
    const response = await adminactions.sendEmail();
    setSending(false);
  }

  return (
    <Container>
      <Container className="border p-4 mb-5 shadow">
        <h4>Prefixes and Suffixes</h4>
        <CustomPage
          form="https://nca.margytech.in/api/form/636"
          searchForm="https://nca.margytech.in/api/form/637"
          url="setting/prefixsuffix/"
          baseSearchParam=""
          tableName="prefixsuffix"
          tableTitle="Prefixes and Suffixes"
          createFormButtonText="Add New PrefixSuffix"
          formTitle="Prefix Suffix"
          modalFullscreen={false}
        />
      </Container>
      <BForm
        onSubmit={(e) => handleSettingUpdate(e)}
        ref={settingFormRef}
      >
        <Container className="border p-4 mb-5 shadow">
          <h4>NCA Settings</h4>
          <div className="d-flex">
            <BForm.Group className="mb-3" controlId="formBasicEmail">
              <BForm.Label>Tenant URL</BForm.Label>
              <BForm.Control name="nca_settings" size="sm" type="text" placeholder="Tenant URL" defaultValue={setting ? setting.nca_settings : ""} />
              <BForm.Text className="text-muted">
                Keep this empty for common forms.
              </BForm.Text>
            </BForm.Group>
            <div className="d-flex align-items-center">
              <BForm.Group className="mx-2 ms-5" controlId="formBasicCheckbox">
                <BForm.Check
                  type="checkbox"
                  label="Use Offline"
                  name="use_offline"
                  checked={offline} onChange={(e) => setOffline(e.target.checked)}
                />
                <BForm.Text className="text-muted">
                  Click on this to use NCA forms offline.
                </BForm.Text>
              </BForm.Group>
              {offline && (
                <>
                  <Button
                    className="mx-2"
                    size="sm"
                    variant="success"
                    type="button"
                  >
                    Fetch Data
                  </Button>
                  <Button
                    className="mx-2"
                    size="sm"
                    variant="success"
                    type="button"
                  >
                    Update Data
                  </Button>
                  <Button
                    className="mx-2"
                    size="sm"
                    variant="danger"
                    type="button"
                  >
                    Flush Data
                  </Button>
                </>
              )}
            </div>
          </div>
        </Container>
        <Container className="border p-4 mb-5 shadow">
          <Row>
            <Col sm={4}>
              <h4>SMTP</h4>
              <div style={{ width: 300 }}>
                <BForm.Group className="mb-3" controlId="formBasicCheckbox">
                  <BForm.Check type="checkbox" label="Use TLS" name="smtp_tls" checked={tls} onChange={(e) => setTLS(e.target.checked)} />
                </BForm.Group>
                <BForm.Group className="mb-3" controlId="formBasicEmail">
                  <BForm.Label>HOST</BForm.Label>
                  <BForm.Control
                    size="sm"
                    type="text"
                    name="smtp_host"
                    placeholder="Enter host"
                    defaultValue={setting ? setting.smtp_host : ""}
                  />
                </BForm.Group>
                <BForm.Group className="mb-3" controlId="formBasicEmail">
                  <BForm.Label>PORT</BForm.Label>
                  <BForm.Control
                    size="sm"
                    type="text"
                    name="smtp_port"
                    placeholder="Enter port"
                    defaultValue={setting ? setting.smtp_port : ""}
                  />
                </BForm.Group>
                <BForm.Group className="mb-3" controlId="formBasicEmail">
                  <BForm.Label>HOST_USER</BForm.Label>
                  <BForm.Control
                    size="sm"
                    type="text"
                    name="smtp_user"
                    placeholder="Enter host user"
                    defaultValue={setting ? setting.smtp_user : ""}
                  />
                </BForm.Group>
                <BForm.Group className="mb-3" controlId="formBasicPassword">
                  <BForm.Label>HOST_PASSWORD</BForm.Label>
                  <BForm.Control
                    size="sm"
                    type="password"
                    name="smtp_password"
                    placeholder="Enter host password"
                    defaultValue={setting ? setting.smtp_password : ""}
                  />
                </BForm.Group>
                <Button variant="success" size="sm" onClick={() => sendTestMail()}>Test Mail</Button>
              </div>
            </Col>
            <Col sm={4}>
              <h4>WhatsApp Integration</h4>
              <div style={{ width: 300 }}>
                <BForm.Group className="mb-3" controlId="formBasicEmail">
                  <BForm.Label>INSTANCE ID</BForm.Label>
                  <BForm.Control
                    size="sm"
                    type="text"
                    name="whatsapp_integration"
                    placeholder="Enter Instance ID"
                    defaultValue={setting ? setting.whatsapp_integration : ""}
                  />
                </BForm.Group>
              </div>
            </Col>
            <Col sm={4}>
              <h4>License Activation</h4>
              <div style={{ width: 300 }}>
                <BForm.Group className="mb-3" controlId="formBasicEmail">
                  <BForm.Label>LICENSE KEY</BForm.Label>
                  <BForm.Control
                    size="sm"
                    type="text"
                    name="license_activation"
                    placeholder="License Key"
                    defaultValue={setting ? setting.license_activation : ""}
                  />
                </BForm.Group>
                <BForm.Text className="text-muted">
                  License activated on: {setting ? setting.license_activated_on : ""}
                </BForm.Text>
                <br />
                <BForm.Text className="text-muted">
                  License renewed on: {setting ? setting.license_renewed_on : ""}
                </BForm.Text>
                <br />
                <BForm.Text className="text-muted">
                  License will expire on: {setting ? setting.license_expires_on : ""}
                </BForm.Text>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="border p-4 mb-5 shadow">
          <h4>Multi-Location Settings</h4>
          <div className="d-flex">
            <div className="d-flex align-items-center">
              {/* {!disabled ? */}
              <BForm.Group className="" controlId="formBasicCheckbox">
                <BForm.Check
                  type="checkbox"
                  label="Enable Multi Location"
                  name="location_enabled"
                  // disabled={disabled}
                  checked={multiLocation} onChange={(e) => setMultiLocation(e.target.checked)}
                />
                <BForm.Text className="text-muted">
                  Click on this to enable multi-location.
                </BForm.Text>
              </BForm.Group>
              {/* :
              <BForm.Text className="">
                multi-location is enabled
              </BForm.Text>} */}
            </div>
          </div>
        </Container>
        <div className="text-end my-5">
          <Button variant="success" type="submit">
            Save Settings
          </Button>
        </div>
      </BForm>
    </Container>
  );
};

export default Settings;
