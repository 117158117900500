import React, {useEffect, useState} from "react";
import {Container} from 'react-bootstrap';

const NoAccess = ({permissionNeeded='his:dashboard'}) => {
    
    switch(permissionNeeded){
        case "his:dashboard":
        default:
            return (
                <Container>
                    You do not have access for this page.
                </Container>
            );
    }
}

export default NoAccess;