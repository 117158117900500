import { useEffect, useRef, useState } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import DataTable from "react-data-table-component"
import * as actions from "actions/CommonActions";
import { useNavigate, useParams } from "react-router-dom";

const SpecialReportGeneration = () => {

  const { specialReportId } = useParams();

  const navigate = useNavigate();

  const [specialTemplate, setSpecialTemplate] = useState();
  const [data, setData] = useState([]);
  const [department, setDepartment] = useState([])

  useEffect(() => {
    console.log(data);
  }, [data])

  const formRef = useRef();


  //DUMMY DATA TO TEST DATATABLE FIELDS

  const [templates, setTemplates] = useState([]);

  const [customTemplates, setCustomTemplates] = useState([]);

  const fetchCustomTemplates = async () => {
    // const templateFilter = 
    const response = await actions.customAction("/template/", "?template_type__startswith=SPECIAL_REPORT_", "", "loadMany", null);
    setCustomTemplates(response);
  }

  const fetchSpecialTemplate = async () => {
    if (specialReportId !== "new") {
      const response = await actions.customAction("/master/specialreport/" + specialReportId + "/", "", "Special Report", "load", null)
      setSpecialTemplate(response);
      setData(response.special_report_mapping);
      console.log("DATA_SPL_REPORT", response.special_report_mapping)
    } else {
      setData([{ item_type: null, sequence: null, observation: null, additional_info: null }])
    }
  }

  const fetchDepartmentList = async () => {
    const response = await actions.customAction("/metadata/metatagtype/", "?metatag__tag_code=TEST_DEPARTMENT", "Meta tag Department", "load", null)
    setDepartment(response)
  }

  const updateSpecialTemplate = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    console.log(formData.get("report_type_name"));
    const jsonObj = {};
    jsonObj.id = specialReportId;
    jsonObj.report_type_name = formData.get("report_type_name");
    jsonObj.report_department = formData.get("report_department");
    jsonObj.footer = formData.get("footer");
    jsonObj.special_report_mapping = data;
    console.log(jsonObj);
    const response = await actions.customAction("/master/specialreport/" + specialReportId + "/", "", "Special Report", "update", jsonObj);
    if (response) {
      setSpecialTemplate(response);
      navigate(-1);
    }
  }

  const createSpecialTemplate = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const jsonObj = {};
    jsonObj.report_type_name = formData.get("report_type_name")
    jsonObj.report_department = formData.get("report_department");
    jsonObj.footer = formData.get("footer");
    jsonObj.special_report_mapping = data;
    const response = await actions.customAction("/master/specialreport/", "", "Special Report", "create", jsonObj);
    if (response) {
      console.log(response)
      navigate(-1);
    }
  }

  useEffect(() => {
    fetchCustomTemplates();
    fetchSpecialTemplate();
    fetchDepartmentList()
  }, []);

  const DeleteButton = ({ row, index, settingData }) => {
    return (
      <Button size="sm" onClick={() => {
        const newData = data.filter((item, indexElem) => index != indexElem)
        settingData(newData)
      }}>Delete</Button>
    );
  }

  const AddNewButton = ({ row, index, settingData }) => {
    return (
      <Button size="sm" onClick={() => {
        if (data.length == 0) {
          alert();
          settingData([{ item_type: null, sequence: null, observation: null, additional_info: null }]);
        } else {
          let newData = [...data];
          newData.splice(index + 1, 0, { item_type: null, sequence: null, observation: null, additional_info: null });
          console.log(newData);
          settingData(newData)
        }
      }}>+ Add</Button>
    );
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        padding: "15px",
        border: "1px solid #eee",
        color: "#fff",
        borderBottom: "1px solid #999",
        backgroundColor: "#006699",
      },
    },
    cells: {
      style: {
        borderLeft: "1px solid #eee",
        borderRight: "1px solid #eee",
        minHeight: "50px",
      },
    },
  };

  let columns = [
    {
      name: "Item Type",
      selector: null,
      cell: (row, index) => (
        <div className="p-1">
          <Form.Group>
            <Form.Control
              name='item_type'
              size="sm"
              id={row.id}
              defaultValue={data[index]?.item_type}
              onChange={(e) => {
                let newData = [...data];
                newData[index].item_type = e.target.value;
                setData(newData)
              }}
            />
          </Form.Group>
        </div>
      )
    },
    {
      name: "Sequence",
      selector: null,
      cell: (row, index) => (
        <div className="p-1">
          <Form.Group>
            <Form.Control
              type="number"
              name='sequence'
              size="sm"
              id={row.id}
              defaultValue={data[index]?.sequence}
              onChange={(e) => {
                let newData = [...data];
                newData[index].sequence = e.target.value;
                setData(newData)
              }}
            />
          </Form.Group>
        </div>
      ),
      
    },
    {
      name: "Observation",
      selector: null,
      cell: (row, index) => (
        <div className="p-1">
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              name='observation'
              size="sm"
              id={row.id}
              defaultValue={data[index]?.observation}
              onChange={(e) => {
                let newData = [...data];
                newData[index].observation = e.target.value;
                setData(newData)
              }}
            />
          </Form.Group>
        </div>
      ),
      
    },
    {
      name: "Additional Info",
      selector: null,
      cell: (row, index) => (
        <div className="p-1">
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              name='additional_info'
              size="sm"
              id={row.id}
              defaultValue={data[index]?.additional_info}
              onChange={(e) => {
                let newData = [...data];
                newData[index].additional_info = e.target.value;
                setData(newData)
              }}
            />
          </Form.Group>
        </div>
      ),
      
    },
    {
      name: "Group Header",
      selector: null,
      cell: (row, index) => (
        <div className="p-1">
          <Form.Group>
            <Form.Control
              name='group_header'
              size="sm"
              id={row.id}
              defaultValue={data[index]?.group_header}
              onChange={(e) => {
                let newData = [...data];
                newData[index].group_header = e.target.value;
                setData(newData)
              }}
            />
          </Form.Group>
        </div>
      ),
      
    },
    // {
    //   name: "Units",
    //   selector: null,
    //   cell: (row, index) => (
    //     <div className="p-1">
    //       <Form.Group>
    //         <Form.Control
    //           type="number"
    //           name='unit'
    //           size="sm"
    //           id={row.id}
    //           defaultValue={data[index]?.unit}
    //           onChange={(e) => {
    //             let newData = [...data];
    //             newData[index].unit = e.target.value;
    //             setData(newData)
    //           }}
    //         />
    //       </Form.Group>
    //     </div>
    //   ),
    //   button: true,
    // },
    // {
    //   name: "Referral Ranges",
    //   selector: null,
    //   cell: (row, index) => (
    //     <div className="p-1">
    //       <Form.Group>
    //         <Form.Control
    //           type="number"
    //           name='referral_ranges'
    //           size="sm"
    //           id={row.id}
    //           defaultValue={data[index]?.referral_ranges}
    //           onChange={(e) => {
    //             let newData = [...data];
    //             newData[index].referral_ranges = e.target.value;
    //             setData(newData)
    //           }}
    //         />
    //       </Form.Group>
    //     </div>
    //   ),
    //   button: true,
    // },
    // {
    //   name: "Low Range value",
    //   selector: null,
    //   cell: (row, index) => (
    //     <div className="p-1">
    //       <Form.Group>
    //         <Form.Control
    //           type="number"
    //           name='low_range_value'
    //           size="sm"
    //           id={row.id}
    //           defaultValue={data[index]?.low_range_value}
    //           onChange={(e) => {
    //             let newData = [...data];
    //             newData[index].low_range_value = e.target.value;
    //             setData(newData)
    //           }}
    //         />
    //       </Form.Group>
    //     </div>
    //   ),
    //   button: true,
    // },
    // {
    //   name: "High Range value",
    //   selector: null,
    //   cell: (row, index) => (
    //     <div className="p-1">
    //       <Form.Group>
    //         <Form.Control
    //           type="number"
    //           name='high_range_value'
    //           size="sm"
    //           id={row.id}
    //           defaultValue={data[index]?.high_range_value}
    //           onChange={(e) => {
    //             let newData = [...data];
    //             newData[index].high_range_value = e.target.value;
    //             setData(newData)
    //           }}
    //         />
    //       </Form.Group>
    //     </div>
    //   ),
    //   button: true,
    // },
    {
      name: "Is Image type?",
      selector: null,
      cell: (row, index) => (
        <Form.Check
          name='is_image_type'
          size="sm"
          id={row.id}
          defaultChecked={data[index]?.is_image_type}
          onChange={(e) => {
            let newData = [...data];
            newData[index].is_image_type = e.target.value;
            setData(newData)
          }}
        />
      )
    },
    // {
    //   name: "Pick Predefined Values?",
    //   selector: null,
    //   cell: (row, index) => (
    //     <Form.Check
    //       name='pick_predefined_values'
    //       size="sm"
    //       id={row.id}
    //       defaultChecked={data[index]?.pick_predefined_values}
    //       onChange={(e) => {
    //         let newData = [...data];
    //         newData[index].pick_predefined_values = e.target.value;
    //         setData(newData)
    //       }}
    //     />
    //   )
    // },
    // {
    //   name: "Option to choose Data?",
    //   selector: null,
    //   cell: (row, index) => (
    //     <Form.Check
    //       name='option_to_choose_data'
    //       size="sm"
    //       id={row.id}
    //       defaultChecked={data[index]?.option_to_choose_data}
    //       onChange={(e) => {
    //         let newData = [...data];
    //         newData[index].option_to_choose_data = e.target.value;
    //         setData(newData)
    //       }}
    //     />
    //   ),
    //   button: true,
    // },
    // {
    //   name: "Is Second Results Required?",
    //   selector: null,
    //   cell: (row, index) => (
    //     <Form.Check
    //       name='is_second_results_required'
    //       size="sm"
    //       id={row.id}
    //       defaultChecked={data[index]?.is_second_results_required}
    //       onChange={(e) => {
    //         let newData = [...data];
    //         newData[index].is_second_results_required = e.target.value;
    //         setData(newData)
    //       }}
    //     />
    //   ),
    //   button: true,
    // },
    // {
    //   name: "Is Antibiotic Results ?",
    //   selector: null,
    //   cell: (row, index) => (
    //     <Form.Check
    //       name='is_antibiotic_results'
    //       size="sm"
    //       id={row.id}
    //       defaultChecked={data[index]?.is_antibiotic_results}
    //       onChange={(e) => {
    //         let newData = [...data];
    //         newData[index].is_antibiotic_results = e.target.value;
    //         setData(newData)
    //       }}
    //     />
    //   ),
    //   button: true,
    // },
    {
      name: "Isolates No.",
      selector: null,
      cell: (row, index) => (
        <Form.Group>
          <Form.Control
            name='isolates_no'
            size="sm"
            id={row.id}
            defaultValue={data[index]?.isolates_no}
            onChange={(e) => {
              let newData = [...data];
              newData[index].isolates_no = e.target.value;
              setData(newData)
            }}
          />
        </Form.Group>
      )
    },
    {
      name: "Action",
      selector: null,
      cell: (row, index) => <DeleteButton row={row} index={index} settingData={setData} />,
      button: true,
    },
    {
      name: "Action",
      selector: null,
      cell: (row, index) => <AddNewButton row={row} index={index} settingData={setData} />,
      button: true,
    }
  ]


  return (
    <Container fluid>
      <Form ref={formRef} onSubmit={(e) => {
        if (specialTemplate) {
          updateSpecialTemplate(e);
        } else {
          createSpecialTemplate(e);
        }
      }}>
        <Container className="card-form">
          <Row className="mb-3 mt-4">
            <Col sm="4">
              <Form.Group>
                <Form.Label>Report Type Name</Form.Label>
                <Form.Control
                  name="report_type_name"
                  type="text"
                  defaultValue={specialTemplate ? specialTemplate.report_type_name : ""}
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <Form.Label>Department</Form.Label>
                {/* <CustomListDropDown poItems={poItems} setPoItems={setPoItems} isOpen={isOpen} setIsOpen={setIsOpen} /> */}
                <Form.Select
                  name="report_department"
                  aria-label="Default select example"
                  defaultValue={specialTemplate ? specialTemplate.report_department_data.type_description : ""}
                >
                  <option value="">Select Department</option>
                  {department?.map((item, index) => {
                    return <option value={item.id} selected={item.id == specialTemplate?.report_department ? true : false}>{item.type_description}</option>
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Footer</Form.Label>
                <Form.Control name="footer" as="textarea" defaultValue={specialTemplate ? specialTemplate.footer : ""} />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5">
          {data && <DataTable data={data} columns={columns} customStyles={customStyles} />}
        </Container>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Button type="submit">{`${specialReportId == "new" ? "Add" : "Update"} Template`}</Button>
        </div>
      </Form>
    </Container>
  )
}

export default SpecialReportGeneration