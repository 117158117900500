import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Button, Spinner, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import { notify } from "actions/AdminActions";

const RolePermissions = () => {

    const [pending, setPending] = useState(false);
    const [assignedPermissions, setAssignedPermissions] = useState([]);
    const [assignedPermPending, setAssignedPermPending] = useState(true);
    const [data, setData] = useState([]);
    const [sortedData, setSortedData] = useState([]);
    const [roleData, setRoleData] = useState(null);
    const { id } = useParams();

    const fetchAssignedPermissions = async () => {
        const response = await axios.get('/user/role/' + id);
        setAssignedPermissions(response.data.permissions);
        setAssignedPermPending(false);
        setRoleData(response.data);
    }

    const capitalize = (string) => {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return ""
    }

    const fetchAllPermissions = async () => {
        const response = await axios.get('/user/rolepermissions/');
        if (response.status == 200) {
            const x = response.data;
            let obj = new Object();
            for (let i in x) {
                if ((x[i].product + x[i].module + x[i].submodule) in obj) {
                    obj[(x[i].product + x[i].module + x[i].submodule)].push(x[i]);
                } else {
                    obj[(x[i].product + x[i].module + x[i].submodule)] = [x[i]];
                }
            }

            setData(response.data);
            console.log(obj);

            setPending(false);
        }
    }

    const submitPermissions = async () => {
        const role = {...roleData, permissions: assignedPermissions, permissions_data: null, permissions_array: []}
        const response = await axios.put(`/user/role/${id}/`, role);
        if (response.status == 200) {
            notify("Permissions updated successfully!", "success");
        } else {
            notify("Permissions could not be updated!", "error");
        }
    }

    useEffect(() => {
        if (!assignedPermPending) {
            fetchAllPermissions();
        }
    }, [assignedPermPending])

    useEffect(() => {
        fetchAssignedPermissions();
    }, [])

    const columns = [
        {
            name: 'Product',
            selector: row => (row.product).toUpperCase(),
            sortable: true,
        },
        {
            name: 'Module',
            selector: row => capitalize(row.module),
            sortable: true,
        },
        {
            name: 'Submodule',
            selector: row => capitalize(row.submodule),
            sortable: true,
        },
        {
            name: 'Permission',
            sortable: true,
            cell: (row) => {
                return (
                    <div>{capitalize(row.permission)}</div>
                );
            }
        },
        {
            sortable: true,
            cell: (row) => {
                return (
                    <Form.Check
                        type={"checkbox"}
                        id={`${row.id}`}
                        defaultChecked={assignedPermissions.find(element => element.id == row.id)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setAssignedPermissions([...assignedPermissions, row.id])
                            } else {
                                setAssignedPermissions(assignedPermissions.filter(element => element != row.id))
                            }
                        }}
                    />
                );
            }
        }
        // {
        //     cell: (row) => <Button onClick={() => navigate(row?.id)}>View</Button>,
        //     button: true,
        //     allowOverflow: true,
        // },
    ]

    return (
        <Container>
            <h4>Permissions</h4>
            <h4>Role: {roleData?.role}</h4>
            <div className="d-flex justify-content-end py-3">

                <Button type="button" onClick={() => {
                    submitPermissions();
                }}>
                    Update Permissions
                </Button>
            </div>
            <DataTable
                columns={columns}
                data={data}
                dense
                responsive
                pagination={false}
                //   customStyles={customStyles}
                //   paginationRowsPerPageOptions={[10, 25, 50, 1000]}
                //   conditionalRowStyles={otScheduleStyle}
                persistTableHead={pending ? false : true}
                progressPending={pending}
                noDataComponent={
                    <div className="py-5">
                        <p className="my-5">No data available.</p>
                    </div>
                }
                progressComponent={
                    <div className="py-5">
                        <Spinner
                            className="my-5"
                            animation="border"
                            variant="primary"
                        />
                    </div>
                }
                key={row => row.id}
            />
        </Container>
    );
}

export default RolePermissions;