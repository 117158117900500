import React from "react";
import { CustomPage } from "components";

const HMOMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/708"
            searchForm="https://nca.margytech.in/api/form/714"
            url="/master/hmo/"
            baseSearchParam=""
            tableName="hmo"
            tableTitle="hmo"
            createFormButtonText="Add HMO"
            formTitle="HMO Master"
            modalFullscreen={false}
            addPermissions={['his:master:hmo:add']}
        />
    )
}

export default HMOMaster;