import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import './Sidebar.scss';

const Sidebar = (props) => {
    const { isAdmin } = props
    const [open, setOpen] = useState(true);
    const [his, setHis] = useState(true);
    const [lab, setLab] = useState(false);
    const [inventory, setInventory] = useState(false);
    const [pharmacy, setPharmacy] = useState(false);

    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 text-black" style={open ? styles.openSideBar : styles.closeSideBar}>
            <div className={`${open ? "d-block" : "d-none"}`}>
                <Link to={`/`} className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-black text-decoration-none">
                    <span className="fs-4">Margy</span>
                </Link>
                <hr />
                <div className="d-flex justify-content-end">
                    <AiOutlineMenu onClick={() => setOpen(false)} />
                </div>
                <hr />
                <ul className="list-unstyled ps-0">
                    {isAdmin &&
                        <li className="mb-1">
                            <Button as={Link} to={`/admin/table/`} className="btn align-items-center rounded collapsed text-white w-100">
                                Tables
                            </Button>
                        </li>
                    }
                    {!isAdmin &&
                        <div>
                            <li className="mb-1">
                                <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="true">
                                    Dashboard
                                </Button>
                                <div className="collapse show" id="dashboard-collapse">
                                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                        <li>
                                            <Link
                                                to={`his`}
                                                className="link-dark rounded text-black cursor-pointer"
                                                onClick={() => {
                                                    setHis(true);
                                                    setLab(false);
                                                    setInventory(false);
                                                    setPharmacy(false);
                                                }}
                                            >HIS Dashboard</Link></li>
                                        <li>
                                            <Link
                                                to={`lab`}
                                                className="link-dark rounded text-black cursor-pointer"
                                                onClick={() => {
                                                    setLab(true);
                                                    setHis(false);
                                                    setInventory(false);
                                                    setPharmacy(false);
                                                }}
                                            >Lab Dashboard</Link></li>
                                        <li>
                                            <Link
                                                to={`inventory`}
                                                className="link-dark rounded text-black cursor-pointer"
                                                onClick={() => {
                                                    setInventory(true);
                                                    setHis(false);
                                                    setLab(false);
                                                    setPharmacy(false);
                                                }}
                                            >Inventory Dashboard</Link></li>
                                        <li>
                                            <Link
                                                to={`pharmacy`}
                                                className="link-dark rounded text-black cursor-pointer"
                                                onClick={() => {
                                                    setPharmacy(true);
                                                    setHis(false);
                                                    setLab(false);
                                                    setInventory(false);
                                                }}
                                            >Pharmacy Dashboard</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <div className={`${his ? "d-block" : "d-none"}`}>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#his-front-desk-collapse" aria-expanded="false">
                                        Front Desk
                                    </Button>
                                    <div className="collapse" id="his-front-desk-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`his/frontdesk/searchpatient`} className="link-dark rounded text-black">Register Patients</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Patient Appointment</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Cost Estimation</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#his-billing-collapse" aria-expanded="false">
                                        Billing
                                    </Button>
                                    <div className="collapse" id="his-billing-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">Patient OPD Bill</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Patient IPD Bill</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Advance Receipt</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#his-ipd-collapse" aria-expanded="false">
                                        IPD
                                    </Button>
                                    <div className="collapse" id="his-ipd-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">IPD Admission</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Consulting Dr Visit</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Consulting Dr Payment</Link></li>
                                        </ul>
                                    </div>
                                </li>
                            </div>
                            <div className={`${lab ? "d-block" : "d-none"}`}>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#front-desk-collapse" aria-expanded="false">
                                        Front Desk
                                    </Button>
                                    <div className="collapse" id="front-desk-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">New</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Processed</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Shipped</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Returned</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#billing-collapse" aria-expanded="false">
                                        Billing
                                    </Button>
                                    <div className="collapse" id="billing-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">New</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Processed</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Shipped</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Returned</Link></li>
                                        </ul>
                                    </div>
                                </li>
                            </div>
                            <div className={`${inventory ? "d-block" : "d-none"}`}>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#front-desk-collapse" aria-expanded="false">
                                        Front Desk
                                    </Button>
                                    <div className="collapse" id="front-desk-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">New</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Processed</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Shipped</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Returned</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#billing-collapse" aria-expanded="false">
                                        Billing
                                    </Button>
                                    <div className="collapse" id="billing-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">New</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Processed</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Shipped</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Returned</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#ot-collapse" aria-expanded="false">
                                        OT
                                    </Button>
                                    <div className="collapse" id="ot-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">New</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Processed</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Shipped</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Returned</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#pharmacy-collapse" aria-expanded="false">
                                        Pharmacy
                                    </Button>
                                    <div className="collapse" id="pharmacy-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">New</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Processed</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Shipped</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Returned</Link></li>
                                        </ul>
                                    </div>
                                </li>
                            </div>
                            <div className={`${pharmacy ? "d-block" : "d-none"}`}>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#front-desk-collapse" aria-expanded="false">
                                        Front Desk
                                    </Button>
                                    <div className="collapse" id="front-desk-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">New</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Processed</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Shipped</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Returned</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#billing-collapse" aria-expanded="false">
                                        Billing
                                    </Button>
                                    <div className="collapse" id="billing-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">New</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Processed</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Shipped</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Returned</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mb-1">
                                    <Button className="btn btn-toggle align-items-center rounded collapsed text-black w-100" data-bs-toggle="collapse" data-bs-target="#ot-collapse" aria-expanded="false">
                                        OT
                                    </Button>
                                    <div className="collapse" id="ot-collapse">
                                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                            <li><Link to={`/`} className="link-dark rounded text-black">New</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Processed</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Shipped</Link></li>
                                            <li><Link to={`/`} className="link-dark rounded text-black">Returned</Link></li>
                                        </ul>
                                    </div>
                                </li>
                            </div>
                        </div>
                    }
                    {/* {isAdmin && */}
                        <li className="mb-1">
                            <Button as={Link} to={`/settings/`} className="btn align-items-center rounded collapsed text-white w-100">
                                Settings
                            </Button>
                        </li>
                    {/* } */}
                </ul>
            </div>
            <div className={`${open ? "d-none" : "d-block"}`}>
                <Link to={`/`} className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-black text-decoration-none">
                    <span className="fs-4">M</span>
                </Link>
                <hr />
                <div className="d-flex justify-content-center">
                    <AiOutlineMenu onClick={() => setOpen(true)} />
                </div>
                <hr />
                <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
                    <li className="nav-item">
                        <Link to={`/`} className="nav-link active py-3 border-bottom" aria-current="page" title="Home" data-bs-toggle="tooltip" data-bs-placement="right">
                            H
                        </Link>
                    </li>
                    <li>
                        <Link to={`/`} className="nav-link py-3 border-bottom" title="Dashboard" data-bs-toggle="tooltip" data-bs-placement="right">
                            D
                        </Link>
                    </li>
                    <li>
                        <Link to={`/`} className="nav-link py-3 border-bottom" title="Orders" data-bs-toggle="tooltip" data-bs-placement="right">
                            O
                        </Link>
                    </li>
                    <li>
                        <Link to={`/`} className="nav-link py-3 border-bottom" title="Products" data-bs-toggle="tooltip" data-bs-placement="right">
                            P
                        </Link>
                    </li>
                    <li>
                        <Link to={`/`} className="nav-link py-3 border-bottom" title="Customers" data-bs-toggle="tooltip" data-bs-placement="right">
                            C
                        </Link>
                    </li>
                </ul>
            </div>
            <hr />
        </div >
    );
}

// var myCollapsible = document.getElementById('collapse')
// myCollapsible.addEventListener('hidden.bs.collapse', function () {
//     // do something...
// });

/* global bootstrap: false */
(function () {
    'use strict'
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.forEach(function (tooltipTriggerEl) {
        new bootstrap.Tooltip(tooltipTriggerEl)
    })
})()

const styles = {
    openSideBar: {
        width: "280px"
    },
    closeSideBar: {
        width: "80px"
    }
}
export default Sidebar;