import React from "react";
import { FullPageTable } from "components";

const PrintReport = (params) => {

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/660"
            url="/samplemanagement/sampletestprofile/printreport/"
            tableName="printreport"
            tableTitle="Report"
            baseSearchParam=""
            // baseSearchParam={`?status=RESULT&lab_status=INLAB`}
            searchURL=""
            setForm=""
            testId={true}
        />
    )
}

export default PrintReport;