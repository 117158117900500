import React, { useEffect, useState } from "react";
import { FullPageForm } from "components";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const BedTransfer = () => {
    const { ipdadmissionId } = useParams();
    const [ipdAdmission, setIpdAdmission] = useState(null);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchIpdAdmission = async () => {
        const response = await actions.customAction("/frontdesk/ipdadmission/" + ipdadmissionId + "/", "", "Patient", "load", null);
        if (response) {
            setIpdAdmission({
                id: response.id,
                uhid: response.uhid,
                patient_name: response.patient_name,
                current_ward: response.ward_data.name,
                current_bed: response.bed_data.bed_code,
            });
        }
    }

    useEffect(() => {
        if (ipdadmissionId) {
            fetchIpdAdmission();
        }
    }, [ipdadmissionId]);

    console.log(ipdAdmission);

    return (
        <>
            {ipdAdmission && <FullPageForm
                submission={ipdAdmission}
                form="https://nca.margytech.in/api/form/116"
                url="/frontdesk/ipdadmission/"
                tableTitle="Bed Transfer"
                navigateTo={`/${param1}/inpatient/bedtransfer`}
                patch={true}
            />}
        </>
    )
}

export default BedTransfer;