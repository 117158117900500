import React from "react";
import { Container, Button, Modal, Row, Col } from "react-bootstrap";
import { CustomPage } from "components";

const HisStockTransferOutward = () => {

    return (
        <>
            <h4 className="d-flex justify-content-center">Outward</h4>
            <CustomPage
                form="https://nca.margytech.in/api/form/310"
                searchForm="https://nca.margytech.in/api/form/744"
                url="/purchaseorder/stocktransfer/"
                baseSearchParam="?transfer_to_depts=Pharmacy,Inventory"
                tableName="stocktransferoutward"
                tableTitle="Stock Transfer"
                createFormButtonText="Generate Stock Transfer"
                formTitle="Generate Stock Transfer"
                modalFullscreen={true}
            />
        </>
    );
};

export default HisStockTransferOutward;