import React, { useRef, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import * as actions from "actions/CommonActions";
import DataTable from 'react-data-table-component';
import Select from 'react-select'

const CustomListDropdown = (props) => {
    const { setBillingItems, isOpen, setIsOpen } = props;
    const [stockItems, setStockItems] = useState([])
    const [items, setItems] = useState([])
    const [currentItem, setCurrentItem] = useState(null);
    const itemForm = useRef(null);

    const columns = [
        {
            name: "Item Code",
            selector: row => row.item_data?.code
        },
        {
            name: "Item Name",
            selector: row => row.item_data?.name
        },
        {
            name: "Batch No.",
            selector: row => row.batch
        },
        {
            name: "Expiry Date",
            selector: row => row.expiry_date
        },
        {
            name: "Stock Item Quantity",
            selector: row => row.quantity
        },
        {
            name: "Select Quantity",
            selector: null,
            cell: (row) => <Form.Group>
                <Form.Control
                    name={`selected_quantity_${row.id}`}
                    size="sm"
                    id={row.id}
                    defaultValue={row.agreed_rate}
                />
            </Form.Group>,
            button: true,
        },
        {
            name: "Select",
            selector: null,
            cell: (row) => <Form.Group>
                <Form.Check
                    type="checkbox"
                    name={`check_${row.id}`}
                    label=""
                    id={row.id}
                />
            </Form.Group>,
            button: true,
        },
    ]

    const fetchMasterItemData = async () => {
        const response = await actions.customAction("/master/item/", `?item_type__type_description=MEDICAL`, "Stock Items", "loadMany", null)
        if(response){
            setItems(response)
        }
    }

    const itemOptions = items?.map((item) => {
        return {value: item.id, label: item.name}
    })

    const fetchStockItemData = async () => {
        const response = await actions.customAction("/purchaseorder/stock/", "?item=" + currentItem + "&department__department_code=PHARMACY", "Stock Items", "loadMany", null)
        if (response) {
            setStockItems(response);
        }

    }

    useEffect(() => {
        fetchMasterItemData()
    }, [])

    useEffect(() => {
        if(currentItem){
            fetchStockItemData();
        }
    }, [currentItem])

    const addItems = (e) => {
        e.preventDefault();
        const formData = new FormData(itemForm.current);
        let info = {};
        let data = [];
        for (var value of formData.keys()) {
            console.log(value);
            if (formData.get(value) === "on") {
                let id = value.substring(6, value.length);
                info = {...stockItems.find((item) => item.id === id)};
                info["selected_quantity"] = formData.get("selected_quantity_" + id);
                data.push(info);
            }
        }
        setBillingItems(prevArray => [...prevArray, ...data])
        setIsOpen(false);
    };

    return (
        <>
            <Select options={itemOptions} onChange={(e) => {
                setCurrentItem(e.value);
                setIsOpen(true);
            }} />
            <Modal
                show={isOpen}
                onHide={() => {
                    setIsOpen(false)
                    setCurrentItem("")
                }}
                onExited={() => {
                    setIsOpen(false)
                    setCurrentItem("")
                }}
                dialogClassName="my-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Available Items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form ref={itemForm}>
                        {stockItems && <DataTable data={stockItems} columns={columns} />}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="primary" onClick={(e) => addItems(e)}>
                        Add
                    </Button>
                    <Button variant="primary" onClick={() => setIsOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomListDropdown
