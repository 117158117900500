import React, { useEffect, useState } from "react";
import { FullPageForm } from "components";

const CostEstimation = () => {

    return (
        <>
            <FullPageForm
                form="https://nca.margytech.in/api/form/89"
                url="frontdesk/costestimationemail/"
                tableTitle="PDF File"
                patch={false}
                fileDownload={true}
            />
        </>
    )
}

export default CostEstimation;