import React from "react";
import { CustomPage } from "components";

const BarcodeManager = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/235"
            searchForm="https://nca.margytech.in/api/form/542"
            url="/master/barcodemanager/"
            baseSearchParam=""
            tableName="barcodemanager"
            tableTitle="Barcode Manager"
            createFormButtonText="Add Barcode"
            formTitle="Barcode Manager"
            modalFullscreen={false}
            addPermissions={['lis:opmaster:barcode:add']}
        />
    )
}

export default BarcodeManager;