import React from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const StudyRegistrationSummary = () => {
    const { opdId } = useParams();
    const currentLocation = useSelector((state) => state.adminReducer.currentLocation)

    if (opdId) {
        return <Outlet />;
    }

    return (
        <>
            { !currentLocation && !process.env.REACT_APP_LOCATION_SET? <FullPageTable
                form="https://nca.margytech.in/api/form/717"
                searchForm="https://nca.margytech.in/api/form/738"
                url="/frontdesk/opdadmission/"
                tableName="studyregistration"
                tableTitle="Study Registration"
                formTitle="Study Registration"
                modalFullscreen={true}
                baseSearchParam="?admission_type=STUDY_REGISTRATION"
            /> :
            <FullPageTable
                submission={ {currentLocation: currentLocation} }
                form="https://nca.margytech.in/api/form/726"
                searchForm="https://nca.margytech.in/api/form/738"
                url="/frontdesk/opdadmission/"
                tableName="studyregistration"
                tableTitle="Study Registration"
                formTitle="Study Registration"
                modalFullscreen={true}
                baseSearchParam="?admission_type=STUDY_REGISTRATION"
            />
            }
        </>
    )
}

export default StudyRegistrationSummary;