import React, { useState } from "react";
import { Button } from "react-bootstrap";
import LisMedicalStockTransferOutward from "./LisMedicalStockTransferOutward";
import LisMedicalStockTransferInward from "./LisMedicalStockTransferInward";

const LisMedicalStockTransfer = () => {
    const [isOutward, setIsOutward] = useState(true)

    return (
        <div>
            <Button
                onClick={() => setIsOutward(true)}
                className="mx-3"
            >
                Outward
            </Button>
            <Button
                onClick={() => setIsOutward(false)}
            >
                Inward
            </Button>
            <div className={`${isOutward ? "d-block" : "d-none"}`}>
                <LisMedicalStockTransferOutward />
            </div>
            <div className={`${!isOutward ? "d-block" : "d-none"}`}>
                <LisMedicalStockTransferInward />
            </div>
        </div>
    );
};

export default LisMedicalStockTransfer;