import React from "react";
import { CustomPage } from "components";

const DoctorMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/131"
            searchForm="https://nca.margytech.in/api/form/563"
            url="/master/doctor/"
            baseSearchParam=""
            tableName="doctor"
            tableTitle="Doctor"
            createFormButtonText="Add Doctor"
            formTitle="Doctor Master"
            modalFullscreen={true}
            addPermissions={['his:master:doctor:add']}
        />
    )
}

export default DoctorMaster;