import { Outlet } from "react-router-dom";

const PharmacyNonMedicalStock = () => {

    return (
        <Outlet />
    );
}

export default PharmacyNonMedicalStock;
