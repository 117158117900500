import React, { useRef, useState } from "react";
import { FullPageTable } from "components";
import { Form, Button } from "react-bootstrap";
import * as actions from "actions/CommonActions";

const CenterSpecial = () => {
    const form = useRef(null);

    const submitRates = async (e) => {
        e.preventDefault();
        console.log("hello");
        const formData = new FormData(form.current);
        let info = [];
        for (let i of formData.keys()) {
            const value = formData.get(i);
            console.log(i);
            console.log(value);
            if (i.startsWith("comment_")) {
                let id = i.substring(8, i.length);
                if (!isNaN(parseFloat(value))) {
                    info.push({ id: id, agreed_rate: value });
                }
            }
        }
        const response = await actions.customAction(`/master/centerspecialrates/${formData.get("data[center]")}/`, "", "Center Special Rates", "update", info);
        if (response.status == 201) {
            form.current.updateTable();
        }
    }


    return (
        <Form ref={form} onSubmit={(e) => submitRates(e)}>
            <div>
                <div className="d-flex justify-content-end">
                    <Button
                        type="submit"
                    >
                        Save Rates
                    </Button>
                </div>
                <FullPageTable
                    form="https://nca.margytech.in/api/form/448"
                    searchForm="https://nca.margytech.in/api/form/650"
                    url="/master/centerspecialrates/"
                    baseSearchParam=""
                    tableName="centerspecialrates"
                    tableTitle="Center"
                    createFormButtonText="Add Centre"
                    formTitle="Centre Master"
                    modalFullscreen={true}
                />
            </div>
        </Form>
    )
}

export default CenterSpecial;