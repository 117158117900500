import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

const FrontDeskLis = () => {

    return (
        <Outlet />
    );
}

export default FrontDeskLis;