import React, { useState } from "react";
import { Button } from "react-bootstrap";
import LisStockTransferOutward from "./LisStockTransferOutward";
import LisStockTransferInward from "./LisStockTransferInward";

const LisStockTransfer = () => {
    const [isOutward, setIsOutward] = useState(true)

    return (
        <div>
            <Button
                onClick={() => setIsOutward(true)}
                className="mx-3"
            >
                Outward
            </Button>
            <Button
                onClick={() => setIsOutward(false)}
            >
                Inward
            </Button>
            <div className={`${isOutward ? "d-block" : "d-none"}`}>
                <LisStockTransferOutward />
            </div>
            <div className={`${!isOutward ? "d-block" : "d-none"}`}>
                <LisStockTransferInward />
            </div>
        </div>
    );
};

export default LisStockTransfer;