import React from "react";
import { FullPageTable } from "components";

const InventoryInwardDetails = (params) => {

    return (
        <FullPageTable
            form=""
            searchForm="https://nca.margytech.in/api/form/693"
            url="/purchaseorder/goodinward/"
            baseSearchParam=""
            tableName="pharmacyinwarddetail"
            tableTitle="Inward Details"
            formTitle=""
            modalFullscreen={true}
        />
    )
}

export default InventoryInwardDetails;