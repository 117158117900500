import React, { useEffect, useState } from "react";
import { CustomPage } from "components";
import { useSelector } from "react-redux";

const ProfileMaster = () => {
    const currentlocation = useSelector((state) => state.adminReducer.currentLocation)
    const user = useSelector((state) => state.authReducer.user)
    const setting = useSelector((state) => state.adminReducer.setting);
    const multiLocation = setting.location_enabled

    return (
        <>
            {!currentlocation && user.is_admin ? <CustomPage
                form="https://nca.margytech.in/api/form/476"
                searchForm="https://nca.margytech.in/api/form/534"
                url="/master/profile/"
                baseSearchParam=""
                tableName="profile"
                tableTitle="Profile"
                createFormButtonText="Add Profile"
                formTitle="Profile Master"
                modalFullscreen={true}
                addPermissions={['lis:master:profile:add']}
            /> :
            <CustomPage
                form="https://nca.margytech.in/api/form/722"
                searchForm="https://nca.margytech.in/api/form/534"
                url="/master/profilelocationmapping/"
                baseSearchParam={`?location__id=${currentlocation}`}
                tableName="profilelocationmapping"
                tableTitle="Profile Location"
                createFormButtonText="Map Profile Location"
                formTitle="Profile Location Mapping"
                modalFullscreen={true}
                addPermissions={['lis:master:profile:add']}
            /> }
        </>
    )
}

export default ProfileMaster;