import React from "react";
import { FullPageTable } from "components";

const EDPatientSummary = (params) => {

    return (
        <FullPageTable
            form="https://nca.margytech.in/api/form/579"
            searchForm="https://nca.margytech.in/api/form/682"
            url="/frontdesk/edadmission/"
            tableName="edadmission"
            tableTitle="ED Admission"
            formTitle="ED Admission"
            modalFullscreen={true}
            baseSearchParam="?is_archive=False"
        />
    )
}

export default EDPatientSummary;