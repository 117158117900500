const initialState = {
    tenants: [],
    products: [],
    modules: [],
    tenantproducts: [],
    tenantmodules: [],
    permissions: []
};

export const publicAuthReducer = (state = initialState, action) => {
    console.log(action);
    switch (action.type) {
        case "LIST_TENANTS":
            console.log("STATE:", state);
            state.tenants = action.payload;
            break;
        case "LIST_PRODUCTS":
            console.log("STATE:", state);
            state.products = action.payload;
            break;
        case "LIST_MODULES":
            console.log("STATE:", state);
            state.modules = action.payload;
            break;
        case "LIST_TENANT_PRODUCTS":
            console.log("STATE:", state);
            state.tenantproducts = action.payload;
            break;
        case "LIST_TENANT_MODULES":
            console.log("STATE:", state);
            state.tenantmodules = action.payload;
            break;
        case "LOAD_PERMISSIONS":
            state.permissions = action.payload?.role_data?.permissions_array;
            break;
        default:
            console.log("State", state);
    }
    return state;
};