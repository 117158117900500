import React from "react";
import { CustomPage } from "components";

const DiscountMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/529"
            searchForm="https://nca.margytech.in/api/form/568"
            url="/master/discount/"
            baseSearchParam=""
            tableName="discount"
            tableTitle="discount"
            createFormButtonText="Add discount"
            formTitle="Discount Master"
            modalFullscreen={false}
            addPermissions={['his:master:discount:add']}
        />
    )
}

export default DiscountMaster;