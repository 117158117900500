import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ClientMonthwiseBillingSummary from "./ClientMonthwiseBillingSummary";
import ClientYearwiseBillingSummary from "./ClientYearwiseBillingSummary";

const LisMedicalStockTransfer = () => {
    const [isMonthly, setIsMonthly] = useState(true)

    return (
        <div>
            <Button
                onClick={() => setIsMonthly(true)}
                className="mx-3"
                variant={isMonthly ? "primary" : "outline-primary"}
            >
                Monthwise Summary
            </Button>
            <Button
                onClick={() => setIsMonthly(false)}
                variant={isMonthly ? "outline-primary" : "primary"}
            >
                Yearwise Summary
            </Button>
            {isMonthly && <div>
                <ClientMonthwiseBillingSummary />
            </div>}
            {!isMonthly && <div>
                <ClientYearwiseBillingSummary />
            </div>}
        </div>
    );
};

export default LisMedicalStockTransfer;