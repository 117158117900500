import React from "react";
import { CustomPage } from "components";
import { Outlet, useParams } from "react-router-dom";

const UserRole = () => {

    const { id } = useParams();

    if (id) {
        return <Outlet />;
    }

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/169"
            searchForm="https://nca.margytech.in/api/form/671"
            url="/user/role/"
            baseSearchParam=""
            tableName="role"
            tableTitle="User Role"
            createFormButtonText="Add New Role"
            formTitle="User Role"
            modalFullscreen={false}
        />
    )
}

export default UserRole;