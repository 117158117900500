import React from "react";
import { FullPageTable } from "components";

const LisMedicalItemExpiry = (params) => {

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/700"
            url = "/purchaseorder/stock/"
            tableName="pharmacyitemexpiry"
            tableTitle="Item Expiry"
            baseSearchParam="?departments=Lab&item__item_type__type_description=MEDICAL"
        />
    )
}

export default LisMedicalItemExpiry;