import React, { useEffect, useState } from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const SampleRegistrationSummary = () => {
    const { opdId } = useParams();
    const currentLocation = useSelector((state) => state.adminReducer.currentLocation)
    const [multiLocation, setMultiLocation] = useState(false)
    const setting = useSelector((state) => state.adminReducer.setting);

    if (opdId) {
        return <Outlet />;
    }

    return (
        <>
            {!currentLocation ? <FullPageTable
                form="https://nca.margytech.in/api/form/517"
                searchForm="https://nca.margytech.in/api/form/652"
                url="/frontdesk/opdadmission/"
                tableName="sampleregistration"
                tableTitle="Sample Registration"
                formTitle="Sample Registration"
                modalFullscreen={true}
                baseSearchParam="?admission_type=SAMPLE_REGISTRATION"
            /> :
            <FullPageTable
                form="https://nca.margytech.in/api/form/201"
                searchForm="https://nca.margytech.in/api/form/652"
                url="/frontdesk/opdadmission/"
                tableName="sampleregistration"
                tableTitle="Sample Registration"
                formTitle="Sample Registration"
                modalFullscreen={true}
                baseSearchParam="?admission_type=SAMPLE_REGISTRATION"
            />
            }
        </>
    )
}

export default SampleRegistrationSummary;