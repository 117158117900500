import React, { useEffect, useState } from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";
import * as actions from "actions/CommonActions";

const RISGenerateReport = (params) => {
    const { patientId } = useParams();
    const [patientInfo, setPatientInfo] = useState(null);
    const [submission, setSubmission] = useState(null);

    const fetchPatient = async () => {
        const response = await actions.customAction("/frontdesk/opdadmission/?admission_type=STUDY_REGISTRATION", "", "Patient Study Info", "load", null);


        if (response) {
            setPatientInfo(response);
            setSubmission({
                opd: response.id,
                study_report_text: response.study_report_text
            });
        }
    }

    useEffect(() => {
        if (patientId) {
            fetchPatient();
        }
    }, [patientId]);

    return (
        <FullPageTable
            form="https://nca.margytech.in/api/form/727"
            searchForm="https://nca.margytech.in/api/form/660"
            // url="/frontdesk/opdadmission/opdstudy/"
            url="/studymanagement/generatereport/generate/"
            tableName="risgeneratereport"
            tableTitle="Generate"
            formTitle="Generate Radiology Report"
            modalFullscreen={true}
            // baseSearchParam={`?opd__id=${patientId}`}
            baseSearchParam={patientId}
            searchURL=""
            setForm=""
        />
    )
}

export default RISGenerateReport;