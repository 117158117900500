import React from "react";
import { Container, Button, Modal, Row, Col } from "react-bootstrap";
import { CustomClientSummaryPage } from "components";

const ClientYearwiseBillingSummary = () => {

    return (
        <>
            <h4 className="d-flex justify-content-center">Yearly</h4>
            <CustomClientSummaryPage
                searchForm="https://nca.margytech.in/api/form/716"
                url="/account/clientledger/clientsummary/"
                baseSearchParam="?type=yearly"
                tableTitle="Yearly Summary"
            />
        </>
    );
};

export default ClientYearwiseBillingSummary;