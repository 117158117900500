import React, { useState, useRef, useEffect } from "react";
import { Container, Button, Modal, Row, Col } from "react-bootstrap";
import { Form } from "react-formio";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as actions from "actions/CommonActions";
import { CustomTable } from "components";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { dropdownUrl } from "utilities";
import fileDownload from "js-file-download";

const CustomPageNew = (params) => {
    const { form, searchForm, url, baseSearchParam, tableName, tableTitle, formTitle, modalFullscreen, preData, fileDownload } = params;
    const [itemForm, setItemForm] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    // Refs
    const customTableRef = useRef()

    const fetchForm = async () => {
        setItemForm(await axios.get(form));
    }

    useEffect(() => {
        fetchForm();
    }, []);

    const donwloadFile = async (e) => {
        const response = await actions.customAction(
            url,
            "",
            tableTitle,
            "download",
            e.data
          );
    }

    const createItem = async (e) => {
        const response = await actions.customAction(url, "", tableTitle, "create", e.data);
        if (response) {
            customTableRef.current.updateTable();
        }
    }

    // const updateItem = async (e) => {
    //     const response = await actions.customAction(url + currentItem.id + "/", "", tableTitle, "update", e.data);
    //     if (response) {
    //         customTableRef.current.updateTable();
    //         console.log(customTableRef);
    //     }
    // }

    return (
        <Container fluid className="mt-2">
            <div className="mx-4">
                {itemForm?.data &&
                    <Form
                        // submission={{ 'data': currentItem }}
                        submission={{ 'data': preData }}
                        form={dropdownUrl(itemForm?.data)}
                        onSubmit={(e) => {
                            if (fileDownload && e.data.print_prescription ) {
                                donwloadFile(e);
                            } else {
                                createItem(e);
                            }
                        }}
                        onChange={(e) => console.log(e)}
                    />
                }
            </div>
            <CustomTable
                ref={customTableRef}
                tableName={tableName}
                tableTitle={tableTitle}
                url={url}
                baseSearchParam={baseSearchParam}
                setForm={setItemForm}
                setUpdateData={setCurrentItem}
            />
        </Container>
    );
}

export default CustomPageNew;