import React from "react";
import { CustomPage } from "components";

const RisMedicalMaterialRequest = (params) => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/238"
            searchForm="https://nca.margytech.in/api/form/697"
            url="/purchaseorder/materialrequest/"
            baseSearchParam="?departments_medical=Radiology"
            tableName="materialrequest"
            tableTitle="material request"
            createFormButtonText="Material Request"
            formTitle="Material Request"
            modalFullscreen={true}
        />
    )
}

export default RisMedicalMaterialRequest;