import React, { useState, useRef, useEffect } from "react";
import { Container, Card, Image, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import "core-ui/components/NavBar.scss";
import {
    //NEW//
    opdconsultation_icon,
    nursingconsole_icon,
    ipdpatientsummary_icon,
    patientacceptance_icon,
    patientdischarge_icon,
    bedtransfer_icon,
    dischargesummary_icon,
    dischargetemplate_icon,
    otapproval_icon,
    otschedule_icon,
    postopnotes_icon,
    receipt_icon,
    dashboard_icon,
    frontdesk_icon,
    outpatientdept_icon,
    inpatientdept_icon,
    ot_icon,
    billing_icon,
    masters_icon,
    meta_icon,
    settings_icon,
    patientmanagement_icon,
    patientappointment_icon,
    registrationsummary_icon,
    costestimation_icon,
    leaveschedule_icon,
    cabinschedule_icon,
    bed_icon,
    cabin_icon,
    department_icon,
    diet_icon,
    doctor_icon,
    employee_icon,
    hmo_icon,
    insurance_icon,
    insurancerates_icon,
    package_icon,
    project_icon,
    projectrates_icon,
    service_icon,
    subdepartment_icon,
    surgery_icon,
    template_icon,
    ward_icon,
    usermaster_icon,
    userrole_icon,
    tablemaster_icon,
    metadatatag_icon,


    //OLD//
    dashboard,
    frontdesk,
    accounts,
    masters,
    mis,
    opd,
    security,
    settings,
    support,
    sample,
    result,
    patientmanagement,
    scheduler,
    certificate,
    costestimation,
    leaveschedule,
    cabinschedule,
    doctor,
    nurse,
    opddashboard,
    ward,
    bed,
    diet,
    cabin,
    cashcounter,
    department,
    user,
    user_role,
    meta,
    table,
} from "assets";
import { AccessControl, NoAccess, AccessControlHidden } from "components";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "actions/PublicAuthActions";
import ReactMenu from "./ReactMenu";
import { GiLifeBar } from "react-icons/gi";

const NavComponentbar = ({ client, userPermissions }) => {
    const [multiLocation, setMultiLocation] = useState(false);
    // console.log(userPermissions);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const setting = useSelector((state) => state.adminReducer.setting)

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";
    const param2 = params.length > 1 ? params[1] : "";
    const param3 = params.length > 2 ? params[2] : "";
    // const user = useSelector((state) => state.authReducer.user);
    console.log(user);

    // const userPermissions = useSelector(
    //   (state) => state.publicAuthReducer.permissions
    // );

    // console.log(userPermissions);

    useEffect(() => {
        if(setting){
            setMultiLocation(setting.location_enabled);
        }
    }, [setting]);

    // HIS

    const hisNavigation = [
        {
            id: 1,
            navigateTo: `his`,
            label: "",
            icon: dashboard_icon,
            title: "Dashboard",
            permissions: ["his:dashboard:view"],
        },
        {
            id: 2,
            navigateTo: `his/frontdesk`,
            label: "frontdesk",
            icon: frontdesk_icon,
            title: "Front Desk",
            permissions: ["his:frontdesk:dashboard:view"],
        },
        // { id: 3, navigateTo: `his/opd`, label: 'opd', icon: opd, title: "OPD", permissions: [] },
        {
            id: 3,
            navigateTo: `his/outpatient`,
            label: "outpatient",
            icon: outpatientdept_icon,
            title: "Out Patient Dept.",
            permissions: ["his:outpatient:dashboard:view"],
        },
        {
            id: 4,
            navigateTo: `his/inpatient`,
            label: "inpatient",
            icon: inpatientdept_icon,
            title: "In Patient Dept.",
            permissions: ["his:inpatient:dashboard:view"],
        },
        {
            id: 5,
            navigateTo: `his/edpatient`,
            label: "edpatient",
            icon: inpatientdept_icon,
            title: "Emergency Patient Dept.",
            permissions: ["his:edpatient:dashboard:view"],
        },
        {
            id: 6,
            navigateTo: `his/operationtheatre`,
            label: "operationtheatre",
            icon: ot_icon,
            title: "OT",
            permissions: ["his:operationtheatre:dashboard:view"],
        },
        {
            id: 7,
            navigateTo: `his/billing`,
            label: "billing",
            icon: billing_icon,
            title: "Billing",
            permissions: ["his:billing:dashboard:view"],
        },
        {
            id: 8,
            navigateTo: `his/master`,
            label: "master",
            icon: masters_icon,
            title: "Masters",
            permissions: ["his:master:dashboard:view"],
        },
        {
            id: 9,
            navigateTo: `his/medicalstock`,
            label: "medicalstock",
            icon: masters_icon,
            title: "Medical Stock",
            permissions: ["his:medicalstock:dashboard:view"],
        },
        {
            id: 10,
            navigateTo: `his/nonmedicalstock`,
            label: "nonmedicalstock",
            icon: masters_icon,
            title: "Non Medical Stock",
            permissions: ["his:nonmedicalstock:dashboard:view"],
        },
        {
            id: 11,
            navigateTo: `his/setting`,
            label: "setting",
            icon: settings_icon,
            title: "Settings",
            permissions: ["his:setting:view"],
        },
        // { id:11, navigateTo: `his/mis`, label: 'mis', icon: mis, title: "MIS", permissions: [] },
        {
            id: 12,
            navigateTo: `his/metadata`,
            label: "metadata",
            icon: meta_icon,
            title: "Security & Meta",
            permissions: ["his:metadata:dashboard:view"],
        },
        // {
        //   id: 13,
        //   navigateTo: `his/ticket`,
        //   label: "ticket",
        //   icon: support,
        //   title: "Support",
        //   permissions: ['his:ticket:view'],
        // },
    ];

    const hisFrontdeskNavigation = [
        {
            id: 8,
            navigateTo: `his/frontdesk/`,
            label: "",
            icon: dashboard_icon,
            title: "Dashboard",
            permissions: ["his:frontdesk:patient:view"],
        },
        {
            id: 1,
            navigateTo: `his/frontdesk/patient`,
            label: "patient",
            icon: patientmanagement_icon,
            title: "Patient Management",
            permissions: [],
        },
        {
            id: 2,
            navigateTo: `his/frontdesk/scheduler`,
            label: "scheduler",
            icon: patientappointment_icon,
            title: "Patient Appointment",
            permissions: ["his:frontdesk:scheduler:view"],
        },
        // { id: 3, navigateTo: `his/frontdesk/certificate`, label: 'certificate', icon: certificate, title: "Certificate Creation", permissions: [] },
        {
            id: 4,
            navigateTo: `his/frontdesk/summary`,
            label: "summary",
            icon: registrationsummary_icon,
            title: "Registration Summary",
            permissions: ["his:frontdesk:summary:view"],
        },
        {
            id: 5,
            navigateTo: `his/frontdesk/costestimation`,
            label: "costestimation",
            icon: costestimation_icon,
            title: "Cost Estimation",
            permissions: ["his:frontdesk:costestimation:view"],
        },
        {
            id: 6,
            navigateTo: `his/frontdesk/leaveschedule`,
            label: "leaveschedule",
            icon: leaveschedule_icon,
            title: "Leave Schedule",
            permissions: ["his:frontdesk:leaveschedule:view"],
        },
        {
            id: 7,
            navigateTo: `his/frontdesk/cabinschedule`,
            label: "cabinschedule",
            icon: cabinschedule_icon,
            title: "Cabin Schedule",
            permissions: ["his:frontdesk:cabinschedule:view"],
        },
    ];

    const hisInpatientNavigation = [
        {
            id: 9,
            navigateTo: `his/inpatient/`,
            label: "",
            icon: dashboard_icon,
            title: "Dashboard",
            permissions: ["his:inpatient:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `his/inpatient/patientsummary`,
            label: "patientsummary",
            icon: ipdpatientsummary_icon,
            title: "IPD Patient Summary",
            permissions: ["his:inpatient:patientsummary:view"],
        },
        {
            id: 2,
            navigateTo: `his/inpatient/roaster`,
            label: "roaster",
            icon: nursingconsole_icon,
            title: "Nursing Roaster",
            permissions: ["his:inpatient:roaster:view"],
        },
        {
            id: 3,
            navigateTo: `his/inpatient/patientacceptance`,
            label: "patientacceptance",
            icon: patientacceptance_icon,
            title: "Patient Accpetance",
            permissions: ["his:inpatient:patientacceptance:view"],
        },
        {
            id: 4,
            navigateTo: `his/inpatient/nursingconsole`,
            label: "nursingconsole",
            icon: nursingconsole_icon,
            title: "Nursing Console",
            permissions: ["his:inpatient:nursingconsole:view"],
        },
        {
            id: 5,
            navigateTo: `his/inpatient/bedtransfer`,
            label: "bedtransfer",
            icon: bedtransfer_icon,
            title: "Bed Transfer",
            permissions: ["his:inpatient:bedtransfer:view"],
        },
        {
            id: 6,
            navigateTo: `his/inpatient/patientdischarge`,
            label: "patientdischarge",
            icon: patientdischarge_icon,
            title: "Patient Discharge",
            permissions: ["his:inpatient:patientdischarge:view"],
        },
        {
            id: 7,
            navigateTo: `his/inpatient/dischargetemplate`,
            label: "dischargetemplate",
            icon: dischargetemplate_icon,
            title: "Discharge Template",
            permissions: ["his:inpatient:dischargetemplate:view"],
        },
        {
            id: 8,
            navigateTo: `his/inpatient/deathsummary`,
            label: "deathsummary",
            icon: dischargesummary_icon,
            title: "Death Summary",
            permissions: ["his:inpatient:deathsummary:view"],
        },
        {
            id: 9,
            navigateTo: `his/inpatient/promissorynote`,
            label: "promissorynote",
            icon: dischargesummary_icon,
            title: "Promissory Note",
            permissions: ["his:inpatient:promissorynote:view"],
        },
    ];

    const hisOutpatientNavigation = [
        {
            id: 9,
            navigateTo: `his/outpatient/`,
            label: "",
            icon: dashboard_icon,
            title: "Dashboard",
            permissions: ["his:outpatient:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `his/outpatient/opdconsultation`,
            label: "opdconsultation",
            icon: opdconsultation_icon,
            title: "OPD Consultation",
            permissions: ["his:outpatient:opdconsultation:view"],
        },
        {
            id: 2,
            navigateTo: `his/outpatient/nursingconsole`,
            label: "nursingconsole",
            icon: nursingconsole_icon,
            title: "Nursing Console",
            permissions: ["his:outpatient:nursingconsole:view"],
        },
    ];

    const hisEmergencypatientNavigation = [
        {
            id: 0,
            navigateTo: `his/edpatient/`,
            label: "",
            icon: dashboard_icon,
            title: "Dashboard",
            permissions: ["his:edpatient:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `his/edpatient/patientsummary`,
            label: "patientsummary",
            icon: ipdpatientsummary_icon,
            title: "ED Patient Summary",
            permissions: ["his:edpatient:patientsummary:view"],
        },
        {
            id: 2,
            navigateTo: `his/edpatient/emergencyconsole`,
            label: "emergencyconsole",
            icon: nursingconsole_icon,
            title: "Emergency Console",
            permissions: ["his:edpatient:emergencyconsole:view"],
        },
        {
            id: 3,
            navigateTo: `his/edpatient/patientdischarge`,
            label: "patientdischarge",
            icon: patientdischarge_icon,
            title: "Patient Discharge",
            permissions: ["his:edpatient:patientdischarge:view"],
        },
        {
            id: 4,
            navigateTo: `his/edpatient/deathsummary`,
            label: "deathsummary",
            icon: dischargesummary_icon,
            title: "Death Summary",
            permissions: ["his:edpatient:deathsummary:view"],
        },
    ];

    const hisMasterNavigation = [
        {
            id: 1,
            navigateTo: `his/master/diet`,
            label: "diet",
            icon: diet_icon,
            title: "Diet Master",
            permissions: ["his:master:diet:view"],
        },
        {
            id: 2,
            navigateTo: `his/master/ward`,
            label: "ward",
            icon: ward_icon,
            title: "Ward Master",
            permissions: ["his:master:ward:view"],
        },
        {
            id: 3,
            navigateTo: `his/master/bed`,
            label: "bed",
            icon: bed_icon,
            title: "Bed Master",
            permissions: ["his:master:bed:view"],
        },
        {
            id: 4,
            navigateTo: `his/master/cabin`,
            label: "cabin",
            icon: cabin_icon,
            title: "Cabin Master",
            permissions: ["his:master:cabin:view"],
        },
        // { id: 5, navigateTo: `his/master/cashcounter`, label: "cashcounter", icon: cashcounter, title: "Cash Counter Master", permissions: [] },
        {
            id: 7,
            navigateTo: `his/master/department`,
            label: "department",
            icon: department_icon,
            title: "Department Master",
            permissions: ["his:master:department:view"],
        },
        {
            id: 8,
            navigateTo: `his/master/subdepartment`,
            label: "subdepartment",
            icon: subdepartment_icon,
            title: "Sub Department Master",
            permissions: ["his:master:subdepartment:view"],
        },
        {
            id: 6,
            navigateTo: `his/master/service`,
            label: "service",
            icon: service_icon,
            title: "Service Master",
            permissions: ["his:master:service:view"],
        },
        {
            id: 9,
            navigateTo: `his/master/doctor`,
            label: "doctor",
            icon: doctor_icon,
            title: "Doctor Master",
            permissions: ["his:master:doctor:view"],
        },
        // { id: 10, navigateTo: `his/master/designation`, label: "designation", icon: masters, title: "Designation Master", permissions: [] },
        {
            id: 11,
            navigateTo: `his/master/surgery`,
            label: "surgery",
            icon: surgery_icon,
            title: "Surgery Master",
            permissions: ["his:master:surgery:view"],
        },
        {
            id: 12,
            navigateTo: `his/master/project`,
            label: "project",
            icon: project_icon,
            title: "Project Master",
            permissions: ["his:master:project:view"],
        },
        {
            id: 20,
            navigateTo: `his/master/projectspecial`,
            label: "projectspecial",
            icon: projectrates_icon,
            title: "Project Rates Master",
            permissions: ["his:master:projectspecial:view"],
        },
        {
            id: 21,
            navigateTo: `his/master/insurance`,
            label: "insurance",
            icon: insurance_icon,
            title: "Insurance Master",
            permissions: ["his:master:insurance:view"],
        },
        {
            id: 12,
            navigateTo: `his/master/insurancespecial`,
            label: "insurancespecial",
            icon: insurancerates_icon,
            title: "Insurance Rates Master",
            permissions: ["his:master:insurancespecial:view"],
        },
        // {
        //     id: 13,
        //     navigateTo: `his/master/package`,
        //     label: "package",
        //     icon: package_icon,
        //     title: "Package Master",
        //     permissions: ["his:master:package:view"],
        // },
        // { id: 14, navigateTo: `his/master/kit`, label: "kit", icon: masters, title: "Kit Master", permissions: [] },
        {   
            id: 15, 
            navigateTo: `his/master/referreddoctor`, 
            label: "referreddoctor", 
            icon: masters, 
            title: "Referred Doctor Master", 
            permissions: ["his:master:referreddoctor:view"], 
        },
        {
            id: 16,
            navigateTo: `his/master/discount`,
            label: "discount",
            icon: hmo_icon,
            title: "Discount Master",
            permissions: ["his:master:discount:view"],
        },
        {
            id: 17,
            navigateTo: `his/master/hmo`,
            label: "hmo",
            icon: hmo_icon,
            title: "HMO Master",
            permissions: ["his:master:hmo:view"],
        },
        {
            id: 18,
            navigateTo: `his/master/operationtheatre`,
            label: "operationtheatre",
            icon: ot_icon,
            title: "OT Master",
            permissions: ["his:master:operationtheatre:view"],
        },
        {
            id: 19,
            navigateTo: `his/master/employee`,
            label: "employee",
            icon: employee_icon,
            title: "Employee Master",
            permissions: ["his:master:employee:view"],
        },
        {
            id: 20,
            navigateTo: `his/master/template`,
            label: "template",
            icon: template_icon,
            title: "Template Master",
            permissions: ["his:master:template:view"],
        },
    ];

    const hisOtNavigation = [
        {
            id: 9,
            navigateTo: `his/operationtheatre/`,
            label: "",
            icon: dashboard_icon,
            title: "Dashboard",
            permissions: ["his:operationtheatre:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `his/operationtheatre/otschedule`,
            label: "otschedule",
            icon: otschedule_icon,
            title: "OT Schedule",
            permissions: ["his:operationtheatre:otschedule:view"],
        },
        {
            id: 2,
            navigateTo: `his/operationtheatre/otapproval`,
            label: "otapproval",
            icon: otapproval_icon,
            title: "OT Approval",
            permissions: ["his:operationtheatre:otapproval:view"],
        },
        {
            id: 3,
            navigateTo: `his/operationtheatre/postopnotes`,
            label: "postopnotes",
            icon: postopnotes_icon,
            title: "Post OP Notes",
            permissions: ["his:operationtheatre:postopnotes:view"],
        },
    ];

    const hisBillingNavigation = [
        {
            id: 9,
            navigateTo: `his/billing/`,
            label: "",
            icon: dashboard_icon,
            title: "Dashboard",
            permissions: ["his:billing:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `his/billing/opdbilling`,
            label: "opdbilling",
            icon: billing_icon,
            title: "OPD Cash Collection / Billing",
            permissions: ["his:billing:opdbilling:view"],
        },
        {
            id: 2,
            navigateTo: `his/billing/ipdbilling`,
            label: "ipdbilling",
            icon: billing_icon,
            title: "IPD Billing",
            permissions: ["his:billing:ipdbilling:view"],
        },
        {
            id: 3,
            navigateTo: `his/billing/ipdreceipt`,
            label: "ipdreceipt",
            icon: receipt_icon,
            title: "IPD Receipts",
            permissions: ["his:billing:ipdreceipt:view"],
        },
        {
            id: 4,
            navigateTo: `his/billing/edbilling`,
            label: "edbilling",
            icon: billing_icon,
            title: "ED Billing",
            permissions: ["his:billing:edbilling:view"],
        },
        {
            id: 5,
            navigateTo: `his/billing/edreceipt`,
            label: "edreceipt",
            icon: receipt_icon,
            title: "ED Receipts",
            permissions: ["his:billing:edreceipt:view"],
        },
    ];

    const hisMedicalStockNavigation = [
        {
            id: 1,
            navigateTo: `his/medicalstock/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['his:medicalstock:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `his/medicalstock/deptstock`,
            label: "deptstock",
            icon: meta,
            title: "Department Stock",
            permissions: ['his:medicalstock:deptstock:view'],
        },
        {
            id: 3,
            navigateTo: `his/medicalstock/request`,
            label: "request",
            icon: user,
            title: "Material Request",
            permissions: ['his:medicalstock:request:view'],
        },
        {
            id: 4,
            navigateTo: `his/medicalstock/consumption`,
            label: "consumption",
            icon: table,
            title: "Stock Consumption",
            permissions: ['his:medicalstock:consumption:view'],
        },
        {
            id: 5,
            navigateTo: `his/medicalstock/transfer`,
            label: "transfer",
            icon: table,
            title: "Stock Transfer",
            permissions: ['his:medicalstock:transfer:view'],
        },
        {
            id: 6,
            navigateTo: `his/medicalstock/expiry`,
            label: "expiry",
            icon: table,
            title: "Item Expiry",
            permissions: ['his:medicalstock:expiry:view'],
        },
    ];

    const hisNonMedicalStockNavigation = [
        {
            id: 1,
            navigateTo: `his/nonmedicalstock/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['his:nonmedicalstock:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `his/nonmedicalstock/deptstock`,
            label: "deptstock",
            icon: meta,
            title: "Department Stock",
            permissions: ['his:nonmedicalstock:deptstock:view'],
        },
        {
            id: 3,
            navigateTo: `his/nonmedicalstock/request`,
            label: "request",
            icon: user,
            title: "Material Request",
            permissions: ['his:nonmedicalstock:request:view'],
        },
        {
            id: 4,
            navigateTo: `his/nonmedicalstock/consumption`,
            label: "consumption",
            icon: table,
            title: "Stock Consumption",
            permissions: ['his:nonmedicalstock:consumption:view'],
        },
        {
            id: 5,
            navigateTo: `his/nonmedicalstock/transfer`,
            label: "transfer",
            icon: table,
            title: "Stock Transfer",
            permissions: ['his:nonmedicalstock:transfer:view'],
        },
        // {
        //   id: 6,
        //   navigateTo: `his/nonmedicalstock/expiry`,
        //   label: "expiry",
        //   icon: table,
        //   title: "Item Expiry",
        //   permissions: ['his:nonmedicalstock:expiry:view'],
        // },
    ];

    const hisSecurityAndMetaNavigation = [
        {
            id: 9,
            navigateTo: `his/metadata/`,
            label: "",
            icon: dashboard_icon,
            title: "Dashboard",
            permissions: ["his:metadata:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `his/metadata/user`,
            label: "user",
            icon: usermaster_icon,
            title: "User Master",
            permissions: ["his:metadata:user:view"],
        },
        {
            id: 2,
            navigateTo: `his/metadata/userrole`,
            label: "userrole",
            icon: userrole_icon,
            title: "User Role Master",
            permissions: ["his:metadata:userrole:view"],
        },
        {
            id: 3,
            navigateTo: `his/metadata/metatag`,
            label: "metatag",
            icon: metadatatag_icon,
            title: "Meta Data Tag",
            permissions: ["his:metadata:metatag:view"],
        },
        {
            id: 4,
            navigateTo: `his/metadata/table`,
            label: "table",
            icon: tablemaster_icon,
            title: "Table Master",
            permissions: ["his:metadata:table:view"],
        },
    ];

    // LIS

    const lisNavigation = [
        {
            id: 1,
            navigateTo: `lis`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ["lis:dashboard:view"],
        },
        {
            id: 2,
            navigateTo: `lis/frontdesk`,
            label: "frontdesk",
            icon: frontdesk,
            title: "Front Desk",
            permissions: ["lis:frontdesk:dashboard:view"],
        },
        {
            id: 3,
            navigateTo: `lis/sample`,
            label: "sample",
            icon: sample,
            title: "Sample Management",
            permissions: ["lis:sample:dashboard:view"],
        },
        {
            id: 4,
            navigateTo: `lis/result`,
            label: "result",
            icon: result,
            title: "Manage Reporting",
            permissions: ["lis:result:dashboard:view"],
        },
        {
            id: 5,
            navigateTo: `lis/account`,
            label: "account",
            icon: accounts,
            title: "Accounts",
            permissions: ["lis:account:dashboard:view"],
        },
        {
            id: 6,
            navigateTo: `lis/master`,
            label: "master",
            icon: masters,
            title: "Masters",
            permissions: ["lis:master:dashboard:view"],
        },
        {
            id: 6,
            navigateTo: `lis/opmaster`,
            label: "opmaster",
            icon: masters,
            title: "Operations Master",
            permissions: ["lis:opmaster:dashboard:view"],
        },
        {
            id: 9,
            navigateTo: `lis/medicalstock`,
            label: "medicalstock",
            icon: masters,
            title: "Medical Stock",
            permissions: ["lis:medicalstock:dashboard:view"],
        },
        {
            id: 8,
            navigateTo: `lis/nonmedicalstock`,
            label: "nonmedicalstock",
            icon: masters,
            title: "Non Medical Stock",
            permissions: ["lis:nonmedicalstock:dashboard:view"],
        },
        {
            id: 7,
            navigateTo: `lis/setting`,
            label: "setting",
            icon: settings,
            title: "Settings",
            permissions: ["lis:setting:view"],
        },
        // { id: 8, navigateTo: `lis/mis`, label: "mis", icon: mis, title: "MIS", permissions: [] },
        {
            id: 9,
            navigateTo: `lis/metadata`,
            label: "metadata",
            icon: security,
            title: "Security & Meta",
            permissions: ["lis:metadata:dashboard:view"],
        },
        // {
        //   id: 10,
        //   navigateTo: `lis/ticket`,
        //   label: "ticket",
        //   icon: support,
        //   title: "Support",
        //   permissions: ['lis:ticket:view'],
        // },
    ];

    const lisFrontdeskNavigation = [
        {
            id: 5,
            navigateTo: `lis/frontdesk`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ["lis:frontdesk:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `lis/frontdesk/patient`,
            label: "patient",
            icon: patientmanagement,
            title: "Patient Management",
            permissions: ["lis:frontdesk:patient:view"],
        },
        {
            id: 2,
            navigateTo: `lis/frontdesk/summary`,
            label: "summary",
            icon: certificate,
            title: "Registration Summary",
            permissions: ["lis:frontdesk:summary:view"],
        },
        {
            id: 3,
            navigateTo: `lis/frontdesk/rejectionsummary`,
            label: "rejectionsummary",
            icon: certificate,
            title: "Rejection Summary",
            permissions: ["lis:frontdesk:rejectionsummary:view"],
        },
        {
            id: 4,
            navigateTo: `lis/frontdesk/costestimation`,
            label: "costestimation",
            icon: costestimation,
            title: "Cost Estimation",
            permissions: ["lis:frontdesk:costestimation:view"],
        },
    ];

    const lisSampleNavigation = [
        {
            id: 5,
            navigateTo: `lis/sample`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ["lis:sample:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `lis/sample/collection`,
            label: "collection",
            icon: sample,
            title: "Sample Collection",
            permissions: ["lis:sample:collection:view"],
        },
        {
            id: 2,
            navigateTo: `lis/sample/accession`,
            label: "accession",
            icon: sample,
            title: "Sample Accession",
            permissions: ["lis:sample:accession:view"],
        },
    ];

    const lisResultNavigation = [
        {
            id: 6,
            navigateTo: `lis/result`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ["lis:result:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `lis/result/outlabselection`,
            label: "outlabselection",
            icon: result,
            title: "Out Lab selection",
            permissions: ["lis:result:outlabselection:view"],
        },
        {
            id: 2,
            navigateTo: `lis/result/resultentry`,
            label: "resultentry",
            icon: result,
            title: "Result Entry",
            permissions: ["lis:result:resultentry:view"],
        },
        {
            id: 3,
            navigateTo: `lis/result/specialreport`,
            label: "specialreport",
            icon: result,
            title: "Special Report Entry",
            permissions: ["lis:result:specialreportlist:view"],
        },
        {
            id: 4,
            navigateTo: `lis/result/outlabupload`,
            label: "outlabupload",
            icon: result,
            title: "Out Lab Upload",
            permissions: ["lis:result:outlabupload:view"],
        },
        {
            id: 5,
            navigateTo: `lis/result/report`,
            label: "report",
            icon: result,
            title: "Print Report",
            permissions: ["lis:result:report:view"],
        },
    ];

    const lisMasterNavigation = [
        // { id: 1, navigateTo: `lis/master/reporttemplate`, icon: masters, title: "Report Template Master", permissions: [] },
        {
            id: 1,
            navigateTo: `lis/master/location`,
            label: "location",
            icon: masters,
            title: "Location Master",
            permissions: ["lis:master:location:view"],
        },
        {
            id: 2,
            navigateTo: `lis/master/test`,
            label: "test",
            icon: masters,
            title: "Test Master",
            permissions: ["lis:master:test:view"],
        },
        {
            id: 3,
            navigateTo: `lis/master/profile`,
            label: "profile",
            icon: masters,
            title: "Profile Master",
            permissions: ["lis:master:profile:view"],
        },
        {
            id: 13,
            navigateTo: `lis/master/analyzer`,
            label: "analyzer",
            icon: masters,
            title: "Analyzer Master",
            permissions: ["lis:master:analyzer:view"],
        },
        {
            id: 22,
            navigateTo: `lis/master/template`,
            label: "template",
            icon: masters,
            title: "Template Master",
            permissions: ["lis:master:template:view"],
        },
        {
            id: 19,
            navigateTo: `lis/master/formula`,
            label: "formula",
            icon: masters,
            title: "Formula Master",
            permissions: ["lis:master:formula:view"],
        },
        {
            id: 20,
            navigateTo: `lis/master/specialreport`,
            label: "specialreport",
            icon: masters,
            title: "Special Report Master",
            permissions: ["lis:master:specialreport:view"],
        },
        {
            id: 21,
            navigateTo: `lis/master/referreddoctor`,
            label: "referreddoctor",
            icon: doctor,
            title: "Referred Doctor Master",
            permissions: ["lis:master:referreddoctor:view"],
        },
    ];

    if(!multiLocation){
        lisMasterNavigation.shift()
    }

    const lisOpMasterNavigation = [
        // {
        //     id: 1,
        //     navigateTo: `lis/opmaster/referreddoctor`,
        //     label: "referreddoctor",
        //     icon: doctor,
        //     title: "Referred Doctor Master",
        //     permissions: ["lis:opmaster:referreddoctor:view"],
        // },
        {
            id: 2,
            navigateTo: `lis/opmaster/client`,
            label: "client",
            icon: masters,
            title: "Client Master",
            permissions: ["lis:opmaster:client:view"],
        },
        {
            id: 3,
            navigateTo: `lis/opmaster/clientspecialrate`,
            label: "clientspecialrate",
            icon: masters,
            title: "Client Special Rates Master",
            permissions: ["lis:opmaster:clientspecialrate:view"],
        },
        {
            id: 4,
            navigateTo: `lis/opmaster/clientbulkrates`,
            label: "clientbulkrates",
            icon: masters,
            title: "Client Bulk Rates",
            permissions: ["lis:opmaster:clientbulkrates:view"],
        },
        {
            id: 5,
            navigateTo: `lis/opmaster/centre`,
            label: "centre",
            icon: masters,
            title: "CC Master",
            permissions: ["lis:opmaster:centre:view"],
        },
        {
            id: 6,
            navigateTo: `lis/opmaster/centerspecialrate`,
            label: "centerspecialrate",
            icon: masters,
            title: "CC Spl. Rates Master",
            permissions: ["lis:opmaster:centerspecialrate:view"],
        },
        {
            id: 7,
            navigateTo: `lis/opmaster/barcode`,
            label: "barcode",
            icon: masters,
            title: "Barcode Manager",
            permissions: ["lis:opmaster:barcode:view"],
        },
        {
            id: 8,
            navigateTo: `lis/opmaster/employee`,
            label: "employee",
            icon: masters,
            title: "Employee Master",
            permissions: ["lis:opmaster:employee:view"],
        },
        {
            id: 9,
            navigateTo: `lis/opmaster/project`,
            label: "project",
            icon: masters,
            title: "Project Master",
            permissions: ["lis:opmaster:project:view"],
        },
        {
            id: 10,
            navigateTo: `lis/opmaster/projectspecial`,
            label: "projectspecial",
            icon: masters,
            title: "Project Rates Master",
            permissions: ["lis:opmaster:projectspecial:view"],
        },
        {
            id: 11,
            navigateTo: `lis/opmaster/outlab`,
            label: "outlab",
            icon: masters,
            title: "Outlab Master",
            permissions: ["lis:opmaster:outlab:view"],
        },
        {
            id: 12,
            navigateTo: `lis/opmaster/outlabspecialrate`,
            label: "outlabspecialrate",
            icon: masters,
            title: "Outlab Rates Master",
            permissions: ["lis:opmaster:outlabspecialrate:view"],
        },
    ];

    const lisMedicalStockNavigation = [
        {
            id: 1,
            navigateTo: `lis/medicalstock/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['lis:medicalstock:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `lis/medicalstock/deptstock`,
            label: "deptstock",
            icon: meta,
            title: "Department Stock",
            permissions: ['lis:medicalstock:deptstock:view'],
        },
        {
            id: 3,
            navigateTo: `lis/medicalstock/request`,
            label: "request",
            icon: user,
            title: "Material Request",
            permissions: ['lis:medicalstock:request:view'],
        },
        {
            id: 4,
            navigateTo: `lis/medicalstock/consumption`,
            label: "consumption",
            icon: table,
            title: "Stock Consumption",
            permissions: ['lis:medicalstock:consumption:view'],
        },
        {
            id: 5,
            navigateTo: `lis/medicalstock/transfer`,
            label: "transfer",
            icon: table,
            title: "Stock Transfer",
            permissions: ['lis:medicalstock:transfer:view'],
        },
        {
            id: 6,
            navigateTo: `lis/medicalstock/expiry`,
            label: "expiry",
            icon: table,
            title: "Item Expiry",
            permissions: ['lis:medicalstock:expiry:view'],
        },
    ];

    const lisNonMedicalStockNavigation = [
        {
            id: 1,
            navigateTo: `lis/nonmedicalstock/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['lis:nonmedicalstock:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `lis/nonmedicalstock/deptstock`,
            label: "deptstock",
            icon: meta,
            title: "Department Stock",
            permissions: ['lis:nonmedicalstock:deptstock:view'],
        },
        {
            id: 3,
            navigateTo: `lis/nonmedicalstock/request`,
            label: "request",
            icon: user,
            title: "Material Request",
            permissions: ['lis:nonmedicalstock:request:view'],
        },
        {
            id: 4,
            navigateTo: `lis/nonmedicalstock/consumption`,
            label: "consumption",
            icon: table,
            title: "Stock Consumption",
            permissions: ['lis:nonmedicalstock:consumption:view'],
        },
        {
            id: 5,
            navigateTo: `lis/nonmedicalstock/transfer`,
            label: "transfer",
            icon: table,
            title: "Stock Transfer",
            permissions: ['lis:nonmedicalstock:transfer:view'],
        },
        // {
        //   id: 6,
        //   navigateTo: `lis/nonmedicalstock/expiry`,
        //   label: "expiry",
        //   icon: table,
        //   title: "Item Expiry",
        //   permissions: ['lis:nonmedicalstock:expiry:view'],
        // },
    ];


    const lisSecurityAndMetaNavigation = [
        {
            id: 5,
            navigateTo: `lis/metadata`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ["lis:metadata:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `lis/metadata/user`,
            label: "user",
            icon: user,
            title: "User Master",
            permissions: ["lis:metadata:user:view"],
        },
        {
            id: 2,
            navigateTo: `lis/metadata/userrole`,
            label: "userrole",
            icon: user_role,
            title: "User Role Master",
            permissions: ["lis:metadata:userrole:view"],
        },
        {
            id: 3,
            navigateTo: `lis/metadata/metatag`,
            label: "metatag",
            icon: meta,
            title: "Meta Data Tag",
            permissions: ["lis:metadata:metatag:view"],
        },
        {
            id: 4,
            navigateTo: `lis/metadata/table`,
            label: "table",
            icon: table,
            title: "Table Master",
            permissions: ["lis:metadata:table:view"],
        },
    ];

    const lisAccountNavigation = [
        {
            id: 10,
            navigateTo: `lis/account/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ["lis:account:dashboard:view"],
        },
        {
            id: 1,
            navigateTo: `lis/account/billingsummary`,
            label: "billingsummary",
            icon: accounts,
            title: "Billing Summary",
            permissions: ["lis:account:billingsummary:view"],
        },
        {
            id: 2,
            navigateTo: `lis/account/expenseentry`,
            label: "expenseentry",
            icon: accounts,
            title: "Expense Entry",
            permissions: ["lis:account:expenseentry:view"],
        },
        {
            id: 3,
            navigateTo: `lis/account/clientledger`,
            label: "clientledger",
            icon: accounts,
            title: "Client Ledger",
            permissions: ["lis:account:clientledger:view"],
        },
        // { id: 4, navigateTo: `lis/account/dailybillingsummary`, label: "dailybillingsummary", icon: accounts, title: "Daily Billing Summary", permissions: [] },
        {
            id: 5,
            navigateTo: `lis/account/cashcollectionsummary`,
            label: "cashcollectionsummary",
            icon: accounts,
            title: "Cash collection Summary",
            permissions: ["lis:account:cashcollectionsummary:view"],
        },
        {
            id: 6,
            navigateTo: `lis/account/generateclientinvoice`,
            label: "generateclientinvoice",
            icon: accounts,
            title: "Generate Client Invoice",
            permissions: ["lis:account:generateclientinvoice:view"],
        },
        {
            id: 7,
            navigateTo: `lis/account/accountreceivable`,
            label: "accountreceivable",
            icon: accounts,
            title: "Account Receivables",
            permissions: ["lis:account:accountreceivable:view"],
        },
        {
            id: 8,
            navigateTo: `lis/account/clientbillingsummary`,
            label: "clientbillingsummary",
            icon: accounts,
            title: "Client Billing Summary (Yearly / Monthly)",
            permissions: ["lis:account:clientbillingsummary:view"],
        },
        {
            id: 9,
            navigateTo: `lis/account/outlabbillingsummary`,
            label: "outlabbillingsummary",
            icon: accounts,
            title: "Outlab Billing Summary",
            permissions: ["lis:account:outlabbillingsummary:view"],
        },
    ];

    // RIS

    const risNavigation = [
        {
            id: 1,
            navigateTo: `ris`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['ris:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `ris/frontdesk`,
            label: "frontdesk",
            icon: frontdesk,
            title: "Front Desk",
            permissions: ['ris:frontdesk:dashboard:view'],
        },
        {
            id: 3,
            navigateTo: `ris/result`,
            label: "result",
            icon: result,
            title: "Manage Reporting",
            permissions: ['ris:result:dashboard:view'],
        },
        {
            id: 4,
            navigateTo: `ris/master`,
            label: "master",
            icon: masters,
            title: "Masters",
            permissions: ['ris:master:dashboard:view'],
        },
        {
            id: 9,
            navigateTo: `ris/medicalstock`,
            label: "medicalstock",
            icon: masters,
            title: "Medical Stock",
            permissions: ["ris:medicalstock:dashboard:view"],
        },
        {
            id: 8,
            navigateTo: `ris/nonmedicalstock`,
            label: "nonmedicalstock",
            icon: masters,
            title: "Non Medical Stock",
            permissions: ["ris:nonmedicalstock:dashboard:view"],
        },
        {
            id: 5,
            navigateTo: `ris/metadata`,
            label: "metadata",
            icon: security,
            title: "Security & Meta",
            permissions: ['ris:metadata:dashboard:view'],
        },
    ];

    const risFrontdeskNavigation = [
        {
            id: 5,
            navigateTo: `ris/frontdesk`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['ris:frontdesk:dashboard:view'],
        },
        {
            id: 1,
            navigateTo: `ris/frontdesk/patient`,
            label: "patient",
            icon: patientmanagement,
            title: "Patient Management",
            permissions: ['ris:frontdesk:patient:view'],
        },
        {
            id: 1,
            navigateTo: `ris/frontdesk/summary`,
            label: "registrationsummary",
            icon: certificate,
            title: "Registration Summary",
            permissions: ['ris:frontdesk:summary:view'],
        },
        // {
        //     id: 1,
        //     navigateTo: `ris/frontdesk/worklist`,
        //     label: "worklist",
        //     icon: certificate,
        //     title: "Worklist Summary",
        //     permissions: ['ris:frontdesk:worklist:view'],
        // },
        // {
        //     id: 2,
        //     navigateTo: `ris/frontdesk/confirmation`,
        //     label: "confirmation",
        //     icon: certificate,
        //     title: "Radiology Confirmation",
        //     permissions: ['ris:frontdesk:confirmation:view'],
        // },
        // {
        //     id: 3,
        //     navigateTo: `ris/frontdesk/rejectionsummary`,
        //     label: "rejectionsummary",
        //     icon: certificate,
        //     title: "Rejection Summary",
        //     permissions: ['ris:frontdesk:rejectionsummary:view'],
        // },
    ];

    const risResultNavigation = [
        {
            id: 5,
            navigateTo: `ris/result`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['ris:result:dashboard:view'],
        },
        {
            id: 1,
            navigateTo: `ris/result/study`,
            label: "generate",
            icon: result,
            title: "Generate Report",
            permissions: ['ris:result:studylist:view'],
        },
        {
            id: 2,
            navigateTo: `ris/result/print`,
            label: "print",
            icon: certificate,
            title: "Print Report",
            permissions: ['ris:result:print:view'],
        },
    ];

    const risMedicalStockNavigation = [
        {
            id: 1,
            navigateTo: `ris/medicalstock/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['ris:medicalstock:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `ris/medicalstock/deptstock`,
            label: "deptstock",
            icon: meta,
            title: "Department Stock",
            permissions: ['ris:medicalstock:deptstock:view'],
        },
        {
            id: 3,
            navigateTo: `ris/medicalstock/request`,
            label: "request",
            icon: user,
            title: "Material Request",
            permissions: ['ris:medicalstock:request:view'],
        },
        {
            id: 4,
            navigateTo: `ris/medicalstock/consumption`,
            label: "consumption",
            icon: table,
            title: "Stock Consumption",
            permissions: ['ris:medicalstock:consumption:view'],
        },
        {
            id: 5,
            navigateTo: `ris/medicalstock/transfer`,
            label: "transfer",
            icon: table,
            title: "Stock Transfer",
            permissions: ['ris:medicalstock:transfer:view'],
        },
        {
            id: 6,
            navigateTo: `ris/medicalstock/expiry`,
            label: "expiry",
            icon: table,
            title: "Item Expiry",
            permissions: ['ris:medicalstock:expiry:view'],
        },
    ];

    const risNonMedicalStockNavigation = [
        {
            id: 1,
            navigateTo: `ris/nonmedicalstock/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['ris:nonmedicalstock:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `ris/nonmedicalstock/deptstock`,
            label: "deptstock",
            icon: meta,
            title: "Department Stock",
            permissions: ['ris:nonmedicalstock:deptstock:view'],
        },
        {
            id: 3,
            navigateTo: `ris/nonmedicalstock/request`,
            label: "request",
            icon: user,
            title: "Material Request",
            permissions: ['ris:nonmedicalstock:request:view'],
        },
        {
            id: 4,
            navigateTo: `ris/nonmedicalstock/consumption`,
            label: "consumption",
            icon: table,
            title: "Stock Consumption",
            permissions: ['ris:nonmedicalstock:consumption:view'],
        },
        {
            id: 5,
            navigateTo: `ris/nonmedicalstock/transfer`,
            label: "transfer",
            icon: table,
            title: "Stock Transfer",
            permissions: ['ris:nonmedicalstock:transfer:view'],
        },
        // {
        //   id: 6,
        //   navigateTo: `ris/nonmedicalstock/expiry`,
        //   label: "expiry",
        //   icon: table,
        //   title: "Item Expiry",
        //   permissions: ['ris:nonmedicalstock:expiry:view'],
        // },
    ];

    const risSecurityAndMetaNavigation = [
        {
            id: 9,
            navigateTo: `ris/metadata/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['ris:metadata:dashboard:view'],
        },
        {
            id: 1,
            navigateTo: `ris/metadata/user`,
            label: "user",
            icon: user,
            title: "User Master",
            permissions: ['ris:metadata:user:view'],
        },
        {
            id: 2,
            navigateTo: `ris/metadata/userrole`,
            label: "userrole",
            icon: user_role,
            title: "User Role Master",
            permissions: ['ris:metadata:userrole:view'],
        },
        {
            id: 3,
            navigateTo: `ris/metadata/metatag`,
            label: "metatag",
            icon: meta,
            title: "Meta Data Tag",
            permissions: ['ris:metadata:metatag:view'],
        },
        {
            id: 4,
            navigateTo: `ris/metadata/table`,
            label: "table",
            icon: table,
            title: "Table Master",
            permissions: ['ris:metadata:table:view'],
        },
    ];

    const risMasterNavigation = [
        {
            id: 1,
            navigateTo: `ris/master/study`,
            label: "study",
            icon: masters,
            title: "Study Master",
            permissions: ['ris:master:study:view'],
        },
        // {
        //     id: 2,
        //     navigateTo: `ris/master/modality`,
        //     label: "modality",
        //     icon: masters,
        //     title: "Modality Master",
        //     permissions: ['ris:master:modality:view'],
        // },
        {
            id: 3,
            navigateTo: `ris/master/template`,
            label: "template",
            icon: masters,
            title: "Report Template Master",
            permissions: ['ris:master:template:view'],
        },
        {   
            id: 4, 
            navigateTo: `ris/master/referreddoctor`, 
            label: "referreddoctor", 
            icon: masters, 
            title: "Referred Doctor Master", 
            permissions: ["ris:master:referreddoctor:view"], 
        },
    ];

    // PHARMACY
    const pharmacyNavigation = [
        {
            id: 1,
            navigateTo: `pharmacy`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['pharmacy:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `pharmacy/billing`,
            label: "billing",
            icon: frontdesk,
            title: "Pharmacy Billing",
            permissions: ['pharmacy:billing:dashboard:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/order`,
            label: "order",
            icon: doctor,
            title: "Purchase Order",
            permissions: ['pharmacy:order:dashboard:view'],
        },
        {
            id: 4,
            navigateTo: `pharmacy/workorder`,
            label: "workorder",
            icon: doctor,
            title: "Work Order",
            permissions: ['pharmacy:workorder:dashboard:view'],
        },
        {
            id: 5,
            navigateTo: `pharmacy/inward`,
            label: "inward",
            icon: doctor,
            title: "Goods Inward",
            permissions: ['pharmacy:inward:dashboard:view'],
        },
        {
            id: 6,
            navigateTo: `pharmacy/medicalstock`,
            label: "medicalstock",
            icon: doctor,
            title: "Medical Stock",
            permissions: ['pharmacy:medicalstock:dashboard:view'],
        },
        {
            id: 7,
            navigateTo: `pharmacy/nonmedicalstock`,
            label: "nonmedicalstock",
            icon: doctor,
            title: "Non-Medical Stock",
            permissions: ['pharmacy:nonmedicalstock:dashboard:view'],
        },
        {
            id: 8,
            navigateTo: `pharmacy/master`,
            label: "master",
            icon: masters,
            title: "Master",
            permissions: ['pharmacy:master:dashboard:view'],
        },
        {
            id: 9,
            navigateTo: `pharmacy/metadata`,
            label: "metadata",
            icon: security,
            title: "Security & Meta",
            permissions: ['pharmacy:metadata:dashboard:view'],
        },
    ];

    const pharmacyBillingNavigation = [
        {
            id: 1,
            navigateTo: `pharmacy/billing`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['pharmacy:billing:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `pharmacy/billing/bill`,
            label: "bill",
            icon: frontdesk,
            title: "Pharmacy Billing",
            permissions: ['pharmacy:billing:bill:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/billing/summary`,
            label: "summary",
            icon: doctor,
            title: "Billing Summary",
            permissions: ['pharmacy:billing:summary:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/billing/deptbill`,
            label: "deptbill",
            icon: doctor,
            title: "Department Bill",
            permissions: ['pharmacy:billing:deptbill:view'],
        },
    ];

    const pharmacyPONavigation = [
        {
            id: 1,
            navigateTo: `pharmacy/order`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['pharmacy:order:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `pharmacy/order/generation`,
            label: "generation",
            icon: frontdesk,
            title: "PO Generation",
            permissions: ['pharmacy:order:generation:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/order/approval`,
            label: "approval",
            icon: doctor,
            title: "PO Approval",
            permissions: ['pharmacy:order:approval:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/order/homanager`,
            label: "homanager",
            icon: doctor,
            title: "HO Manager Approval",
            permissions: ['pharmacy:order:homanager:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/order/hoaccounts`,
            label: "hoaccounts",
            icon: doctor,
            title: "HO Accounts Approval",
            permissions: ['pharmacy:order:hoaccounts:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/order/raise`,
            label: "raise",
            icon: doctor,
            title: "PO Raise",
            permissions: ['pharmacy:order:raise:view'],
        },
    ];

    const pharmacyWONavigation = [
        {
            id: 1,
            navigateTo: `pharmacy/workorder`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['pharmacy:workorder:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `pharmacy/workorder/generation`,
            label: "generation",
            icon: frontdesk,
            title: "WO Generation",
            permissions: ['pharmacy:workorder:generation:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/workorder/approval`,
            label: "approval",
            icon: doctor,
            title: "WO Approval",
            permissions: ['pharmacy:workorder:approval:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/workorder/homanager`,
            label: "homanager",
            icon: doctor,
            title: "HO Manager",
            permissions: ['pharmacy:workorder:homanager:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/workorder/hoaccounts`,
            label: "hoaccounts",
            icon: doctor,
            title: "HO Accounts",
            permissions: ['pharmacy:workorder:hoaccounts:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/workorder/raise`,
            label: "raise",
            icon: doctor,
            title: "WO Raise",
            permissions: ['pharmacy:workorder:raise:view'],
        },
    ];

    const pharmacyGoodInwardNavigation = [
        {
            id: 1,
            navigateTo: `pharmacy/inward`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['pharmacy:inward:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `pharmacy/inward/goods`,
            label: "goods",
            icon: frontdesk,
            title: "Good Inward",
            permissions: ['pharmacy:inward:goods:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/inward/detail`,
            label: "detail",
            icon: doctor,
            title: "Inward Details",
            permissions: ['pharmacy:inward:detail:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/inward/inwardwithoutpo`,
            label: "inwardwithoutpo",
            icon: doctor,
            title: "Inward Without PO",
            permissions: [],
        },
    ];

    const pharmacyMedicalStockNavigation = [
        {
            id: 1,
            navigateTo: `pharmacy/medicalstock`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['pharmacy:medicalstock:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `pharmacy/medicalstock/stocksummary`,
            label: "stocksummary",
            icon: frontdesk,
            title: "Stock Summary",
            permissions: ['pharmacy:medicalstock:stocksummary:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/medicalstock/indentsummary`,
            label: "indentsummary",
            icon: frontdesk,
            title: "Indent Summary",
            permissions: ['pharmacy:medicalstock:indentsummary:view'],
        },
        {
            id: 4,
            navigateTo: `pharmacy/medicalstock/returnfromdept`,
            label: "returnfromdept",
            icon: doctor,
            title: "Return From Department",
            permissions: ['pharmacy:medicalstock:returnfromdept:view'],
        },
        {
            id: 5,
            navigateTo: `pharmacy/medicalstock/returntovendor`,
            label: "returntovendor",
            icon: doctor,
            title: "Return To Vendor",
            permissions: ['pharmacy:medicalstock:returntovendor:view'],
        },
        {
            id: 6,
            navigateTo: `pharmacy/medicalstock/expiry`,
            label: "expiry",
            icon: table,
            title: "Item Expiry",
            permissions: ['pharmacy:medicalstock:expiry:view'],
        },
    ];

    const pharmacyNonMedicalStockNavigation = [
        {
            id: 1,
            navigateTo: `pharmacy/nonmedicalstock/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['pharmacy:nonmedicalstock:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `pharmacy/nonmedicalstock/deptstock`,
            label: "deptstock",
            icon: meta,
            title: "Department Stock",
            permissions: ['pharmacy:nonmedicalstock:deptstock:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/nonmedicalstock/request`,
            label: "request",
            icon: user,
            title: "Material Request",
            permissions: ['pharmacy:nonmedicalstock:request:view'],
        },
        {
            id: 4,
            navigateTo: `pharmacy/nonmedicalstock/consumption`,
            label: "consumption",
            icon: table,
            title: "Stock Consumption",
            permissions: ['pharmacy:nonmedicalstock:consumption:view'],
        },
        {
            id: 5,
            navigateTo: `pharmacy/nonmedicalstock/transfer`,
            label: "transfer",
            icon: table,
            title: "Stock Transfer",
            permissions: ['pharmacy:nonmedicalstock:transfer:view'],
        },
    ];

    const pharmacySecurityAndMetaNavigation = [
        {
            id: 9,
            navigateTo: `pharmacy/metadata/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['pharmacy:metadata:dashboard:view'],
        },
        {
            id: 1,
            navigateTo: `pharmacy/metadata/user`,
            label: "user",
            icon: user,
            title: "User Master",
            permissions: ['pharmacy:metadata:user:view'],
        },
        {
            id: 2,
            navigateTo: `pharmacy/metadata/userrole`,
            label: "userrole",
            icon: user_role,
            title: "User Role Master",
            permissions: ['pharmacy:metadata:userrole:view'],
        },
        {
            id: 3,
            navigateTo: `pharmacy/metadata/metatag`,
            label: "metatag",
            icon: meta,
            title: "Meta Data Tag",
            permissions: ['pharmacy:metadata:metatag:view'],
        },
        {
            id: 4,
            navigateTo: `pharmacy/metadata/table`,
            label: "table",
            icon: table,
            title: "Table Master",
            permissions: ['pharmacy:metadata:table:view'],
        },
    ];

    const pharmacyMasterNavigation = [
        {
            id: 1,
            navigateTo: `pharmacy/master/item`,
            label: "item",
            icon: masters,
            title: "Item Master",
            permissions: ['pharmacy:master:item:view'],
        },
        {
            id: 2,
            navigateTo: `pharmacy/master/vendor`,
            label: "vendor",
            icon: masters,
            title: "Vendor Master",
            permissions: ['pharmacy:master:vendor:view'],
        },
        {
            id: 2,
            navigateTo: `pharmacy/master/itemsplrate`,
            label: "itemsplrate",
            icon: masters,
            title: "Item Spl Rate",
            permissions: ['pharmacy:master:itemsplrate:view'],
        },
    ];


    //Inventory
    const inventoryNavigation = [
        {
            id: 1,
            navigateTo: `inventory`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['inventory:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `inventory/order`,
            label: "order",
            icon: doctor,
            title: "Product Order",
            permissions: ['inventory:order:dashboard:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/workorder`,
            label: "workorder",
            icon: doctor,
            title: "Work Order",
            permissions: ['inventory:workorder:dashboard:view']
        },
        {
            id: 4,
            navigateTo: `inventory/inward`,
            label: "inward",
            icon: doctor,
            title: "Goods Inward",
            permissions: ['inventory:inward:dashboard:view'],
        },
        {
            id: 5,
            navigateTo: `inventory/stock`,
            label: "stock",
            icon: doctor,
            title: "Stock",
            permissions: ['inventory:stock:dashboard:view'],
        },
        {
            id: 6,
            navigateTo: `inventory/master`,
            label: "master",
            icon: security,
            title: "Master",
            permissions: ['inventory:master:dashboard:view'],
        },
        {
            id: 7,
            navigateTo: `inventory/metadata`,
            label: "metadata",
            icon: security,
            title: "Security & Meta",
            permissions: ['inventory:metadata:dashboard:view'],
        },
    ];

    const inventoryPONavigation = [
        {
            id: 1,
            navigateTo: `inventory/order`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['inventory:order:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `inventory/order/generation`,
            label: "generation",
            icon: frontdesk,
            title: "PO Generation",
            permissions: ['inventory:order:generation:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/order/approval`,
            label: "approval",
            icon: doctor,
            title: "PO Approval",
            permissions: ['inventory:order:approval:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/order/homanager`,
            label: "homanager",
            icon: doctor,
            title: "HO Manager",
            permissions: ['inventory:order:homanager:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/order/hoaccounts`,
            label: "hoaccounts",
            icon: doctor,
            title: "HO Accounts",
            permissions: ['inventory:order:hoaccounts:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/order/raise`,
            label: "raise",
            icon: doctor,
            title: "PO Raise",
            permissions: ['inventory:order:raise:view'],
        },
    ];

    const inventoryWONavigation = [
        {
            id: 1,
            navigateTo: `inventory/workorder`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['inventory:workorder:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `inventory/workorder/generation`,
            label: "generation",
            icon: frontdesk,
            title: "Work Order Generation",
            permissions: ['inventory:workorder:generation:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/workorder/approval`,
            label: "approval",
            icon: doctor,
            title: "WO Approval",
            permissions: ['inventory:workorder:approval:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/workorder/homanager`,
            label: "homanager",
            icon: doctor,
            title: "HO Manager",
            permissions: ['inventory:workorder:homanager:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/workorder/hoaccounts`,
            label: "hoaccounts",
            icon: doctor,
            title: "HO Accounts",
            permissions: ['inventory:workorder:hoaccounts:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/workorder/raise`,
            label: "raise",
            icon: doctor,
            title: "WO Raise",
            permissions: ['inventory:workorder:raise:view'],
        },
    ]

    const inventoryGoodInwardNavigation = [
        {
            id: 1,
            navigateTo: `inventory/inward`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['inventory:inward:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `inventory/inward/goods`,
            label: "goods",
            icon: frontdesk,
            title: "Good Inward",
            permissions: ['inventory:inward:goods:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/inward/detail`,
            label: "detail",
            icon: doctor,
            title: "Inward Details",
            permissions: ['inventory:inward:detail:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/inward/inwardwithoutpo`,
            label: "inwardwithoutpo",
            icon: doctor,
            title: "Inward Without PO",
            permissions: [],
        },
    ];

    const inventoryStockNavigation = [
        {
            id: 1,
            navigateTo: `inventory/stock/`,
            label: "dashboard",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['inventory:stock:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `inventory/stock/indentsummary`,
            label: "indentsummary",
            icon: frontdesk,
            title: "Indent Summary",
            permissions: ['inventory:stock:indentsummary:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/stock/deptstock`,
            label: "deptstock",
            icon: meta,
            title: "Department Stock",
            permissions: ['inventory:stock:deptstock:view'],
        },
        {
            id: 4,
            navigateTo: `inventory/stock/transfer`,
            label: "transfer",
            icon: table,
            title: "Stock Transfer",
            permissions: ['inventory:stock:transfer:view'],
        },
        {
            id: 5,
            navigateTo: `inventory/stock/returntovendor`,
            label: "returntovendor",
            icon: doctor,
            title: "Return To Vendor",
            permissions: ['inventory:stock:returntovendor:view'],
        },
        {
            id: 5,
            navigateTo: `inventory/stock/consumption`,
            label: "consumption",
            icon: table,
            title: "Stock Consumption",
            permissions: ['inventory:stock:consumption:view'],
        },
        {
            id: 7,
            navigateTo: `inventory/stock/expiry`,
            label: "expiry",
            icon: table,
            title: "Item Expiry",
            permissions: ['inventory:stock:expiry:view'],
        },
    ];

    const inventorySecurityAndMetaNavigation = [
        {
            id: 9,
            navigateTo: `inventory/metadata/`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['inventory:metadata:dashboard:view'],
        },
        {
            id: 1,
            navigateTo: `inventory/metadata/user`,
            label: "user",
            icon: user,
            title: "User Master",
            permissions: ['inventory:metadata:user:view'],
        },
        {
            id: 2,
            navigateTo: `inventory/metadata/userrole`,
            label: "userrole",
            icon: user_role,
            title: "User Role Master",
            permissions: ['inventory:metadata:userrole:view'],
        },
        {
            id: 3,
            navigateTo: `inventory/metadata/metatag`,
            label: "metatag",
            icon: meta,
            title: "Meta Data Tag",
            permissions: ['inventory:metadata:metatag:view'],
        },
        {
            id: 4,
            navigateTo: `inventory/metadata/table`,
            label: "table",
            icon: table,
            title: "Table Master",
            permissions: ['inventory:metadata:table:view'],
        },
    ];

    const inventoryMasterNavigation = [
        {
            id: 1,
            navigateTo: `inventory/master/item`,
            label: "item",
            icon: masters,
            title: "Item Master",
            permissions: ['inventory:master:item:view'],
        },
        {
            id: 2,
            navigateTo: `inventory/master/vendor`,
            label: "vendor",
            icon: masters,
            title: "Vendor Master",
            permissions: ['inventory:master:vendor:view'],
        },
        {
            id: 2,
            navigateTo: `inventory/master/itemsplrate`,
            label: "itemsplrate",
            icon: masters,
            title: "Item Spl Rate",
            permissions: ['inventory:master:itemsplrate:view'],
        },
    ];

    //BLOODBANK
    const bloodBankNavigation = [
        {
            id: 1,
            navigateTo: `bloodbank`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['bloodbank:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `bloodbank/patient`,
            label: "patient",
            icon: frontdesk,
            title: "Patient Blood Transfusion",
            permissions: ['bloodbank:patient:dashboard:view'],
        },
        {
            id: 3,
            navigateTo: `bloodbank/component`,
            label: "component",
            icon: result,
            title: "Blood Component Registration",
            permissions: ['bloodbank:component:dashboard:view'],
        },
        {
            id: 4,
            navigateTo: `bloodbank/crossmatching`,
            label: "crossmatching",
            icon: masters,
            title: "Cross Matching",
            permissions: ['bloodbank:crossmatching:dashboard:view'],
        },
        {
            id: 5,
            navigateTo: `bloodbank/management`,
            label: "management",
            icon: security,
            title: "Blood Bank Management",
            permissions: ['bloodbank:management:dashboard:view'],
        },
    ];

    const bloodBankPatientNavigation = [
        {
            id: 1,
            navigateTo: `bloodbank/patient`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['bloodbank:patient:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `bloodbank/patient/register`,
            label: "register",
            icon: frontdesk,
            title: "Patient Registration",
            permissions: ['bloodbank:patient:register:view'],
        },
        {
            id: 3,
            navigateTo: `bloodbank/patient/registrationsummary`,
            label: "registrationsummary",
            icon: frontdesk,
            title: "BloodBank Registration Summary",
            permissions: ['bloodbank:patient:registrationsummary:view'],
        },
    ];

    const bloodBankComponentNavigation = [
        {
            id: 1,
            navigateTo: `bloodbank/component`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['bloodbank:component:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `bloodbank/component/componentregistration`,
            label: "transfusionrequest",
            icon: frontdesk,
            title: "Blood Component Registration",
            permissions: ['bloodbank:component:componentregistration:view'],
        },
        {
            id: 3,
            navigateTo: `bloodbank/component/stockdetails`,
            label: "registrationsummary",
            icon: frontdesk,
            title: "BloodBnak stock Details",
            permissions: ['bloodbank:component:stockdetails:view'],
        },
    ];

    const bloodBankCrossMatchingNavigation = [
        {
            id: 1,
            navigateTo: `bloodbank/crossmatching`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['bloodbank:crossmatching:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `bloodbank/crossmatching/crossmatchbarcode`,
            label: "crossmatchbarcode",
            icon: frontdesk,
            title: "Cross Match Barcode",
            permissions: ['bloodbank:crossmatching:crossmatchbarcode:view'],
        },
        {
            id: 3,
            navigateTo: `bloodbank/crossmatching/resultentry`,
            label: "resultentry",
            icon: frontdesk,
            title: "Result Entry",
            permissions: ['bloodbank:crossmatching:resultentry:view'],
        },
    ];

    const bloodBankManagementNavigation = [
        {
            id: 1,
            navigateTo: `bloodbank/management`,
            label: "",
            icon: dashboard,
            title: "Dashboard",
            permissions: ['bloodbank:management:dashboard:view'],
        },
        {
            id: 2,
            navigateTo: `bloodbank/management/issuereturn`,
            label: "issuereturn",
            icon: frontdesk,
            title: "Blood Issue Return",
            permissions: ['bloodbank:management:issuereturn:view'],
        },
        {
            id: 3,
            navigateTo: `bloodbank/management/bloodtracking`,
            label: "bloodtracking",
            icon: frontdesk,
            title: "Blood Tracking Unit",
            permissions: ['bloodbank:management:bloodtracking:view'],
        },
        {
            id: 4,
            navigateTo: `bloodbank/management/historicalrecord`,
            label: "historicalrecord",
            icon: frontdesk,
            title: "Blood Tracking Unit",
            permissions: ['bloodbank:management:historicalrecord:view'],
        },
        {
            id: 5,
            navigateTo: `bloodbank/management/mis`,
            label: "mis",
            icon: frontdesk,
            title: "Blood Stats MIS",
            permissions: ['bloodbank:management:mis:view'],
        },
        {
            id: 6,
            navigateTo: `bloodbank/management/requestcancellation`,
            label: "mis",
            icon: frontdesk,
            title: "Blood Request Cancellation",
            permissions: ['bloodbank:management:requestcancellation:view'],
        },
        {
            id: 7,
            navigateTo: `bloodbank/management/bloodexpire`,
            label: "bloodexpire",
            icon: frontdesk,
            title: "Blood Expire Details",
            permissions: ['bloodbank:management:bloodexpire:view'],
        },
        {
            id: 8,
            navigateTo: `bloodbank/management/bloodissuedetail`,
            label: "bloodissuedetail",
            icon: frontdesk,
            title: "Blood Issued Details",
            permissions: ['bloodbank:management:bloodissuedetail:view'],
        },
    ];

    return (
        <Container
            fluid
            className={`${param1 && param1 !== "admin" && param1 !== "superadmin"
                ? "mt-5 pt-4"
                : "d-none"
                } p-0 navbar-component-background user-select-none`}
        >
            {/* The follwing nav component is for Modules under HIS*/}
            <div className={`${param1 == "his" ? "d-flex" : "d-none"}`}>
                {hisNavigation.map((his, index) => {
                    return (
                        <AccessControlHidden
                            userPermissions={userPermissions}
                            allowedPermissions={his.permissions}
                        >
                            <div
                                className={`px-1 submodule-block-general ${param2 == his.label ? "submodule-block-active" : ""
                                    }`}
                                onClick={() => navigate(his.navigateTo)}
                            >
                                <ReactMenu
                                    item={his}
                                    index={index}
                                    userPermissions={userPermissions}
                                    subNavigations={[[], hisFrontdeskNavigation, hisOutpatientNavigation, hisInpatientNavigation, hisEmergencypatientNavigation, hisOtNavigation, hisBillingNavigation, hisMasterNavigation, hisMedicalStockNavigation, hisNonMedicalStockNavigation, [], hisSecurityAndMetaNavigation]}
                                    isActive={param2 == his.label}
                                />
                            </div>
                        </AccessControlHidden>
                    );
                })}
            </div>

            <div className={`${param1 == "lis" ? "d-flex" : "d-none"}`}>
                {lisNavigation.map((lis, index) => {
                    return (
                        <AccessControlHidden
                            userPermissions={userPermissions}
                            allowedPermissions={lis.permissions}
                        >
                            <div
                                className={`px-1 submodule-block-general ${param2 == lis.label ? "submodule-block-active" : ""
                                    }`}
                                onClick={() => navigate(lis.navigateTo)}
                            >
                                <ReactMenu
                                    item={lis}
                                    index={index}
                                    userPermissions={userPermissions}
                                    subNavigations={[[], lisFrontdeskNavigation, lisSampleNavigation, lisResultNavigation, lisAccountNavigation, lisMasterNavigation, lisOpMasterNavigation, lisMedicalStockNavigation, lisNonMedicalStockNavigation, [], lisSecurityAndMetaNavigation]}
                                    isActive={param2 == lis.label}
                                />
                            </div>
                        </AccessControlHidden>
                    );
                })}
            </div>

            <div className={`${param1 == "ris" ? "d-flex" : "d-none"}`}>
                {risNavigation.map((ris, index) => {
                    return (
                        <AccessControlHidden
                            userPermissions={userPermissions}
                            allowedPermissions={ris.permissions}
                        >
                            <div
                                className={`px-1 submodule-block-general ${param2 == ris.label ? "submodule-block-active" : ""
                                    }`}
                                onClick={() => navigate(ris.navigateTo)}
                            >
                                <ReactMenu
                                    item={ris}
                                    index={index}
                                    userPermissions={userPermissions}
                                    subNavigations={[[], risFrontdeskNavigation, risResultNavigation, risMasterNavigation, risMedicalStockNavigation, risNonMedicalStockNavigation, risSecurityAndMetaNavigation]}
                                    isActive={param2 == ris.label}
                                />
                            </div>
                        </AccessControlHidden>
                    );
                })}
            </div>

            <div className={`${param1 == "pharmacy" ? "d-flex" : "d-none"}`}>
                {pharmacyNavigation.map((pharmacy, index) => {
                    return (
                        <AccessControlHidden
                            userPermissions={userPermissions}
                            allowedPermissions={pharmacy.permissions}
                        >
                            <div
                                className={`px-1 submodule-block-general ${param2 == pharmacy.label ? "submodule-block-active" : ""
                                    }`}
                                onClick={() => navigate(pharmacy.navigateTo)}
                            >
                                <ReactMenu
                                    item={pharmacy}
                                    index={index}
                                    userPermissions={userPermissions}
                                    subNavigations={[[], pharmacyBillingNavigation, pharmacyPONavigation, pharmacyWONavigation, pharmacyGoodInwardNavigation, pharmacyMedicalStockNavigation, pharmacyNonMedicalStockNavigation, pharmacyMasterNavigation, pharmacySecurityAndMetaNavigation, []]}
                                    isActive={param2 == pharmacy.label}
                                />
                            </div>
                        </AccessControlHidden>
                    );
                })}
            </div>

            <div className={`${param1 == "inventory" ? "d-flex" : "d-none"}`}>
                {inventoryNavigation.map((inventory, index) => {
                    return (
                        <AccessControlHidden
                            userPermissions={userPermissions}
                            allowedPermissions={inventory.permissions}
                        >
                            <div
                                className={`px-1 submodule-block-general ${param2 == inventory.label ? "submodule-block-active" : ""
                                    }`}
                                onClick={() => navigate(inventory.navigateTo)}
                            >
                                <ReactMenu
                                    item={inventory}
                                    index={index}
                                    userPermissions={userPermissions}
                                    subNavigations={[[],inventoryPONavigation, inventoryWONavigation, inventoryGoodInwardNavigation, inventoryStockNavigation, inventoryMasterNavigation, inventorySecurityAndMetaNavigation]}
                                    isActive={param2 == inventory.label}
                                />
                            </div>
                        </AccessControlHidden>
                    );
                })}
            </div>

            <div className={`${param1 == "bloodbank" ? "d-flex" : "d-none"}`}>
                {bloodBankNavigation.map((bloodbank, index) => {
                    return (
                        <AccessControlHidden
                            userPermissions={userPermissions}
                            allowedPermissions={bloodbank.permissions}
                        >
                            <div
                                className={`px-1 submodule-block-general ${param2 == bloodbank.label ? "submodule-block-active" : ""
                                    }`}
                                onClick={() => navigate(bloodbank.navigateTo)}
                            >
                                <ReactMenu
                                    item={bloodbank}
                                    index={index}
                                    userPermissions={userPermissions}
                                    subNavigations={[[],bloodBankPatientNavigation, bloodBankComponentNavigation, bloodBankCrossMatchingNavigation, bloodBankManagementNavigation]}
                                    isActive={param2 == bloodbank.label}
                                />
                            </div>
                        </AccessControlHidden>
                    );
                })}
            </div>
        </Container>
    );
};

export default NavComponentbar;
