import React from "react";
import { CustomPage } from "components";

const AnalyzerMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/232"
            searchForm="https://nca.margytech.in/api/form/541"
            url="/master/analyzer/"
            baseSearchParam=""
            tableName="analyzer"
            tableTitle="Analyzer"
            createFormButtonText="Add Analyzer"
            formTitle="Analyzer Master"
            modalFullscreen={true}
            addPermissions={['lis:master:analyzer:add']}
        />
    )
}

export default AnalyzerMaster;