import React, { useEffect, useState } from "react";
import { FullPageForm } from "components";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const OPDBillingInfo = () => {
    const { billId } = useParams();
    const [billInfo, setBillInfo] = useState(null);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchBillInfo = async () => {
        const response = await actions.customAction("/frontdesk/opdadmission/opdreceipt/" + billId + "/", "", "OPD Admission", "load", null);
        if (response) {
            setBillInfo({
                opd_info: response.opd_data,
            });
        }
    }

    useEffect(() => {
        if (billId) {
            fetchBillInfo();
        }
    }, [billId]);

    console.log(billInfo);

    return (
        <>
            {billInfo && <FullPageForm
                submission={billInfo}
                form="https://nca.margytech.in/api/form/512"
                url="/frontdesk/opdadmission/opdreceipt/"
                tableTitle="OPD Billing"
                navigateTo={`/${param1}/billing/opdbilling`}
                patch={false}
            />}
        </>
    )
}

export default OPDBillingInfo;