import React from "react";
import { CustomPage } from "components";

const DepartmentMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/134"
            searchForm="https://nca.margytech.in/api/form/562"
            url="/user/department/"
            baseSearchParam=""
            tableName="department"
            tableTitle="Department"
            createFormButtonText="Add Department"
            formTitle="Department Master"
            modalFullscreen={false}
            addPermissions={['his:master:department:add']}
        />
    )
}

export default DepartmentMaster;