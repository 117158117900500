import React from "react";
import { CustomPage } from "components";

const NursingRoaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/611"
            searchForm="https://nca.margytech.in/api/form/683"
            url="/nursing/roaster/"
            baseSearchParam=""
            tableName="roaster"
            tableTitle="Roaster"
            createFormButtonText="Add New Roaster"
            formTitle="Nursing Roaster"
            modalFullscreen={false}
            addPermissions={['his:inpatient:roaster:add']}
        />
    )
}

export default NursingRoaster;