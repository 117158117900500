import React from "react";
import { FullPageTable } from "components";
import { useParams, Outlet } from "react-router-dom";

const PharmacyIndentSummary = (params) => {
    const { requestId } = useParams();

    if (requestId) {
        return <Outlet />;
    }

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/735"
            url = "/purchaseorder/materialrequest/"
            tableName="pharmacyindentsummary"
            tableTitle="indent summary"
            baseSearchParam="?request_to_dept__department_code=PHARMACY"
        />
    )
}

export default PharmacyIndentSummary;