import React from "react";
import { CustomPage } from "components";

const KitMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/480"
            searchForm="https://nca.margytech.in/api/form/552"
            url="/master/kit/"
            baseSearchParam=""
            tableName="kit"
            tableTitle="Kit"
            createFormButtonText="Add Kit"
            formTitle="Kit Master"
            modalFullscreen={false}
            addPermissions={['his:frontdesk:kit:add']}
        />
    )
}

export default KitMaster;