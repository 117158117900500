import React, { useState, useRef, useEffect } from "react";
import { Container, Button, Form, Spinner } from "react-bootstrap";
import { useParams, Outlet, Link, useNavigate } from 'react-router-dom';
import * as actions from "actions/PublicAuthActions";
import { useDispatch } from "react-redux";

const SuperUserLogin = () => {
  const form = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(form.current);
    const response = await actions.loggingIn(formData);
    if (response) {
      setIsSubmitting(false);
      dispatch(actions.login(response));
      // navigate(`/his`);
    }
    setIsSubmitting(false);
  }

  return (
    <div className="mt-5 pt-5">
      <Container className="shadow mt-5 p-5" style={{ width: 500 }}>
        <Form
          ref={form}
          className="text-start mx-3"
          onSubmit={(e) => onLogin(e)}
        >
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control name="username" type="text" placeholder="Enter your Username" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control name="password" type="password" placeholder="Enter your Password" />
          </Form.Group>
          <Button type="submit">
            {isSubmitting ? (
              <span>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                LOGGING IN ...
              </span>
            ) : (
              <span className="poppins">LOGIN</span>
            )}
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default SuperUserLogin;