import React, { useState, useRef, forwardRef, useEffect, useImperativeHandle } from "react";
import { Button, Container, Modal, Spinner, Form as BootstrapForm } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import * as actions from "actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "core-ui/components/CustomTable.scss";
import { AiOutlineClose } from "react-icons/ai";

const ResultEntryTable = forwardRef(({ data, opdipd }, ref) => {
    const [pending, setPending] = useState(false);
    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";
    const [newData, setNewData] = useState([]);
    const [formulaData, setformulaData] = useState([]);
    const [formulaTestIds, setFormulaTestIds] = useState([]);
    const [formulaValue, setFormulaValue] = useState(null)
    const [formulaTests, setFormulaTests] = useState([]);

    useEffect(() => {
        if (data.length > 0) {
            console.log(data);
            setNewData(data);
        }
    }, [data])

    useEffect(()=> {
        console.log(newData)
    }, [newData]);

    useEffect(() => {
        for(let i = 0; i < newData.length; i++){
            console.log("DATA", newData[i])
            if(newData[i]?.test_data?.is_calculated === "YES"){
                // fetchFormulaData(newData[i]?.test)
                setFormulaTestIds(prevId => [...prevId, newData[i]?.test])
            }
        }
        return () => {
            setFormulaTestIds([])
        }
    }, [newData])

    const fetchFormulaData = async () => {
        let responseData = []
        for(let i = 0; i < formulaTestIds.length; i++){
            // alert()
            const response = await actions.customAction("/master/formula/", "?target_test=" + formulaTestIds[i], "Formula", "loadMany", null)
            responseData.push(...response)
        }
        if(responseData){
        setformulaData(responseData)
        }
    }

    useEffect(() =>{
        fetchFormulaData()
    }, [formulaTestIds])

    useEffect(() => {
        let x = {}
        let formula
        console.log("FORMULA", formulaData)
        for(let i = 0; i < formulaData?.length; i++){
            console.log("FORMULAinList", formulaData[i])
            // setFormulaTests(formulaData[i].formula_tests)
            for(let j = 0; j < formulaData[i].formula_tests?.length; j++){
                // alert()
                console.log("LENGTH", formulaData[i]?.formula_tests[j])
                const sampleTest = newData?.find(elem => elem.test == formulaData[i]?.formula_tests[j]?.test)
                const formulaSign = formulaData[i]?.formula_tests[j]?.formula_sign
                let individualFormula
                if(formulaData[i]?.formula_tests[j]?.individual_formula){
                    individualFormula = formulaData[i]?.formula_tests[j]?.individual_formula
                    x[formulaSign] = eval(individualFormula.replace(formulaSign, sampleTest.test_value ? sampleTest.test_value : 0))
                }else {
                    x[formulaSign] = parseInt(sampleTest.test_value ? sampleTest.test_value : 0)
                }
            }
            formula = formulaData[i].formula
            const re = new RegExp(Object.keys(x).join("|"),"gi");
            formula = formula?.replace(re, matched => x[matched]);
            console.log("X", x)
            console.log("FINAL_FORMULA", eval(formula));
            formulaData[i].formula_value = eval(formula)

            const b = {id:formulaData[i].target_test.toString(), value: eval(formula) }
            console.log(b)
            let y = formulaTests;
            const c = newData.find(item => (item?.test == b.id))
            const cIndex = newData.findIndex(item => item?.test == b.id);
            if(c){
                y[cIndex] = b.value;
            } 
            
            setFormulaTests(y);
            console.log("YYYYYYYYYYYYYYYYYYYY ", y);
        }
    }, [formulaData])

    useEffect(() => {
        for(let i = 0; i < formulaData?.length; i++){
            setFormulaValue(formulaData[i].formula_value)
        }
        // return () => {
        //     setFormulaValue(null)
        // }
    }, [newData, formulaData])

    if(formulaData){
        console.log("FORMULAValue", formulaValue)
        console.log("FORMULATest", formulaTests)
    }
    console.log("FORMULATestIDS", formulaTestIds)

    let editableKeyToFocus = useRef(null);
    const TestComponent = ({row, index, onClick}) => {
        // return <Button onClick={()=> onClick([])}>test</Button>
        if (row.test_data?.is_calculated === "YES") {
            return (<BootstrapForm.Group>
                <BootstrapForm.Control
                    key={index + 1}
                    name={`test_value_${row.id}`}
                    size="sm"
                    id={row.id}
                    readOnly={row.test_data?.direct_test_entry_restricted == "RESTRICTED"}
                    defaultValue={formulaTests[index]?.toFixed(2)}
                    // onChange={(e) => {
                    //     editableKeyToFocus.current = index + 1;
                    //     let x = [...newData];
                    //     x[index].test_value = e.target.value;
                    //     console.log(x);
                    //     return onClick(x);
                    // }}
                    autoFocus={index+1 === editableKeyToFocus.current}
                    // onChange={(e)=> {onClick([])}}
                />
            </BootstrapForm.Group>)
        }else if (row.test_data?.reporting_style_data.type_code === "QUANTITATIVE") {
            return (<BootstrapForm.Group>
                <BootstrapForm.Control
                    key={index + 1}
                    name={`test_value_${row.id}`}
                    size="sm"
                    id={row.id}
                    defaultValue={row.test_value ? row.test_value : ""}
                    onChange={(e) => {
                        editableKeyToFocus.current = index + 1;
                        let x = [...newData];
                        x[index].test_value = e.target.value;
                        console.log(x);
                        return onClick(x);
                    }}
                    autoFocus={index+1 === editableKeyToFocus.current}
                    // onChange={(e)=> {onClick([])}}
                />
            </BootstrapForm.Group>)
        } else  if (row.test_data?.reporting_style_data.type_code === "QUALITATIVE") {
            return (<BootstrapForm.Group>
                <BootstrapForm.Select
                    name={`test_value_${row.id}`}
                    size="sm"
                    id={row.id}
                    defaultValue={row.test_value}
                >
                    <option value="">SELECT</option>
                    {row.test_data?.special_values.map((special_value) => {
                        return <option value={special_value.special_value}>{special_value.special_value}</option>
                    })}
                </BootstrapForm.Select>
            </BootstrapForm.Group>)
        }
    }

    const getReferralRanges = (row) => {
        console.log(row);
        let age_in_days = 0;
        const age = row.opd ? row.opd_data?.patient_data.age : row.ipd ? row.ipd_data?.patient_data.age : row.ed_data?.patient_data.age;
        const age_type = row.opd ? row.opd_data?.patient_data.age_type : row.ipd ? row.ipd_data?.patient_data.age_type : row.ed_data?.patient_data.age_type;
        if (age_type === "YEARS") {
            age_in_days = parseInt(age) * 365;
        } else if (age_type === "MONTHS") {
            age_in_days = parseInt(age) * 30;
        } else {
            age_in_days = parseInt(age);
        }
        const gender = row.opd ? row.opd_data?.patient_data.gender : row.ipd ? row.ipd_data?.patient_data.gender : row.ed_data?.patient_data.gender;
        const type = row.test_data?.reporting_style_data.type_description;

        if (type === "QUANTITATIVE") {
            const referral_range = row.test_data.ref_ranges.filter(
                (ref_range) =>
                    ref_range.gender === gender || ref_range.gender === "BOTH" &&
                    parseInt(ref_range.day_from) < age_in_days &&
                    parseInt(ref_range.day_to) >= age_in_days
            );
            console.log(referral_range);
            // return referral_range.group;
            return referral_range;
        } else {
            return "Nil";
        }
    }

    let columns = [
        {
            id: "test_name",
            name: 'Test Name',
            selector: row => row.test_data?.name,
            sortable: true,
            cell: null,
            button: false,
            allowOverflow: false,
            wrap: true,
        },
        {
            id: "less_than_greater_than",
            name: '(> or <)',
            selector: null,
            sortable: false,
            cell: (row, index) => {
                return (<BootstrapForm.Group>
                    <BootstrapForm.Control
                        name={`less_than_greater_than_${row.id}`}
                        size="sm"
                        id={row.id}
                        defaultValue={row.less_than_greater_than ? row.less_than_greater_than : ""}
                    />
                </BootstrapForm.Group>)
            },
            button: false,
            allowOverflow: true,
            wrap: true,
        },
        {
            id: "test_value",
            name: 'Test Value',
            selector: null,
            sortable: false,
            cell: (row, index) => <TestComponent row={row} index={index} onClick={setNewData}/>,
            // {
            //     if (row.test_data?.reporting_style_data.type_code === "QUANTITATIVE") {
            //         return (<BootstrapForm.Group>
            //             <BootstrapForm.Control
            //                 name={`test_value_${row.id}`}
            //                 size="sm"
            //                 id={row.id}
            //                 defaultValue={row.test_value ? row.test_value : ""}
            //                 onChange={(e) => {
            //                     let x = newData;
            //                     x[index].test_value = e.target.value;
            //                     x[index].flag = "M";
            //                     console.log(x);
            //                     setNewData(x);
            //                 }}
            //             />
            //         </BootstrapForm.Group>)
            //     } else if (row.test_data?.reporting_style_data.type_code === "QUALITATIVE") {
            //         return (<BootstrapForm.Group>
            //             <BootstrapForm.Select
            //                 name={`test_value_${row.id}`}
            //                 size="sm"
            //                 id={row.id}
            //                 defaultValue={row.test_value}
            //             >
            //                 <option value="">SELECT</option>
            //                 {row.test_data?.special_values.map((special_value) => {
            //                     return <option value={special_value.special_value}>{special_value.special_value}</option>
            //                 })}
            //             </BootstrapForm.Select>
            //         </BootstrapForm.Group>)
            //     }
            // },
            button: false,
            allowOverflow: true,
            wrap: true,
        },
        {
            id: "referral_units",
            name: 'Referral Units',
            selector: row => row.test_data?.reference_unit,
            sortable: true,
            button: false,
            allowOverflow: false,
            wrap: true,
        },
        {
            id: "referral_ranges",
            name: 'Referral Ranges',
            // selector: row => row.test_data?.ref_ranges[0]?.group,
            selector: row => getReferralRanges(row)[0]?.group || "NIL",
            sortable: true,
            button: false,
            allowOverflow: false,
            wrap: true,
        },
        {
            id: "ref_range",
            name: 'Ref Range',
            selector: null,
            sortable: false,
            cell: (row, index) => {
                return (<BootstrapForm.Group>
                    <BootstrapForm.Control
                        name={`ref_range_${row.id}`}
                        size="sm"
                        id={row.id}
                        value={getReferralRanges(row)[0]?.id}
                    />
                </BootstrapForm.Group>)
            },
            button: false,
            allowOverflow: true,
            wrap: true,
            hide: 9999,
        },
        {
            id: "flag",
            name: 'Flag',
            cell: (row, index) => {
                if (row.test_data?.reporting_style_data.type_code === "QUANTITATIVE") {
                    return (
                        <div>
                            {parseFloat(newData[index]?.test_value) > parseFloat(newData[index]?.range_high) ?
                                <b style={{ color: "red" }}>H</b>
                                :
                                (parseFloat(newData[index]?.test_value) < parseFloat(newData[index]?.range_low)?
                                    <b style={{ color: "red" }}>L</b>
                                    :
                                    <b style={{ color: "green" }}>N</b>
                                )
                            }
                        </div>
                    );
                } else if (row.test_data?.reporting_style_data.type_code === "QUALITATIVE") {
                    console.log(newData[index]?.test_value)
                    const this_special_value = row.test_data?.special_values.filter((special_value) => special_value.special_value === newData[index]?.test_value)
                    return (
                        <div>
                            {this_special_value[0]?.is_abnormal ?
                                <b style={{ color: "red" }}>H</b>
                                :
                                <b style={{ color: "green" }}>N</b>
                            }
                        </div>
                    );
                }
            },
            sortable: true,
            button: false,
            allowOverflow: false,
            wrap: true,
        },
        {
            id: "previous_record",
            name: 'P-R',
            selector: row => null,
            sortable: true,
            button: false,
            allowOverflow: false,
            wrap: true,
        },
        {
            id: "repeat_test",
            name: 'Repeat Test',
            selector: null,
            sortable: true,
            button: false,
            allowOverflow: false,
            wrap: true,
        },
        {
            id: "hide",
            name: 'Hide',
            selector: null,
            sortable: false,
            cell: (row) => {
                return (<BootstrapForm.Group>
                    <BootstrapForm.Check
                        type="checkbox"
                        name={`hide_${row.id}`}
                        label=""
                        id={row.id}
                        disabled
                    />
                </BootstrapForm.Group>)
            },
            button: false,
            allowOverflow: true,
            wrap: true,
        },
        {
            id: "select",
            name: 'Select',
            selector: row => row.test_data?.name,
            sortable: false,
            cell: (row) => {
                return (<BootstrapForm.Group>
                    <BootstrapForm.Check
                        type="checkbox"
                        name={`select_${row.id}`}
                        label=""
                        id={row.id}
                        defaultChecked={false}
                    />
                </BootstrapForm.Group>)
            },
            button: false,
            allowOverflow: true,
            wrap: true,
        },
    ]

    const customStyles = {
        rows: {
            style: {
                minHeight: "72px",
            },
        },
        headCells: {
            style: {
                fontWeight: "bold",
                padding: "15px",
                border: "1px solid #eee",
                color: "#fff",
                borderBottom: "1px solid #999",
                backgroundColor: "#006699",
            },
        },
        cells: {
            style: {
                borderLeft: "1px solid #eee",
                borderRight: "1px solid #eee",
                minHeight: "50px",
            },
        },
    };

    //   const otScheduleStyle = [
    //     {
    //       when: (row) => row.ot_schedule_status === "APPROVED",
    //       style: { backgroundColor: "#90ee90" },
    //     },
    //     {
    //       when: (row) => row.ot_schedule_status === "REJECTED",
    //       style: { backgroundColor: "#e53c3c" },
    //     },
    //   ];

    return (
        <>
            <Container className="p-0 py-3">
                <div className="shadow">
                    {newData && <DataTable
                        columns={columns}
                        data={newData}
                        dense
                        responsive
                        // pagination
                        customStyles={customStyles}
                        // paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        // conditionalRowStyles={otScheduleStyle}
                        persistTableHead={pending ? false : true}
                        progressPending={pending}
                        noDataComponent={
                            <div className="py-5">
                                <p className="my-5">No data available.</p>
                            </div>
                        }
                        progressComponent={
                            <div className="py-5">
                                <Spinner
                                    className="my-5"
                                    animation="border"
                                    variant="primary"
                                />
                            </div>
                        }
                    />}
                </div>
            </Container>
        </>
    );
}
);
const styles = {
    tableContainer: {
        border: "1px solid #ccc",
    },
};

export default ResultEntryTable;
