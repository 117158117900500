import React from "react";
import { CustomPage } from "components";

const RisStockConsumption = (params) => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/275"
            searchForm="https://nca.margytech.in/api/form/699"
            url="/purchaseorder/stockconsumption/"
            tableName="pharmacystockconsumption"
            tableTitle="Stock consumption"
            createFormButtonText="Stock Consumption"
            baseSearchParam={"?departments=RADIOLOGY&item__item_type__type_description=NON-MEDICAL"}
            modalFullscreen={true}

        />
    )
}

export default RisStockConsumption;