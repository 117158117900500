import React from "react";
import { Container, Button, Modal, Row, Col } from "react-bootstrap";
import { CustomClientSummaryPage } from "components";

const ClientMonthwiseBillingSummary = () => {

    return (
        <>
            <h4 className="d-flex justify-content-center">Monthly</h4>
            <CustomClientSummaryPage
                searchForm="https://nca.margytech.in/api/form/715"
                url="/account/clientledger/clientsummary/"
                baseSearchParam="?type=monthly"
                tableTitle="Monthly Summary"
            />
        </>
    );
};

export default ClientMonthwiseBillingSummary;