import React, { useEffect, useState } from "react";
import { FullPageForm } from "components";
import { useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const IPDBillingInfo = () => {
    const { ipdId } = useParams();
    const [ipdInfo, setIpdInfo] = useState(null);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchipdInfo = async () => {
        const response = await actions.customAction("/frontdesk/ipdadmission/" + ipdId + "/", "", "IPD Admission", "load", null);
        if (response) {
            // const transactions = await actions.customAction("/frontdesk/ipdreceipt/", "?ipd__id="+ ipdId, "IPD Transactions", "loadMany", null);
            // if (transactions) {
            //     let info = {...response, previous_transactions: transactions}
            //     setIpdInfo(info)
            // } else {
            //     setIpdInfo(response);
            // }
            setIpdInfo(response);
        }
    }

    useEffect(() => {
        if (ipdId) {
            fetchipdInfo();
        }
    }, [ipdId]);

    console.log(ipdInfo);

    return (
        <>
            {ipdInfo && <FullPageForm
                submission={ipdInfo}
                form="https://nca.margytech.in/api/form/615"
                url="/frontdesk/ipdreceipt/"
                tableTitle="IPD Billing"
                navigateTo={`/${param1}/billing/ipdbilling`}
                patch={false}
            />}
        </>
    )
}

export default IPDBillingInfo;