import React from "react";
import { CustomPage } from "components";
import { Button } from "react-bootstrap";
import axios from "axios";
import * as actions from "actions/CommonActions";

const SearchPatient = () => {

    return (
        <>
        {/* <div>
            <Button onClick={async() => {
                await actions.exportDownload();
            }}>Export</Button>
            <Button onClick={async() => {
                await actions.importData();
            }}>Import</Button>
        </div> */}
        <CustomPage
            form="https://nca.margytech.in/api/form/507"
            searchForm="https://nca.margytech.in/api/form/531"
            url="/frontdesk/patient/"
            baseSearchParam=""
            tableName="patient"
            tableTitle="Patient"
            createFormButtonText="Register Patient"
            formTitle="Patient Registration"
            modalFullscreen={true}
            addPermissions={['his:frontdesk:patient:add']}
        />
        </>
    )
}

export default SearchPatient;