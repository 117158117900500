import React, { useEffect, useState } from "react";
import { FullPageForm } from "components";
import { Container } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import * as actions from "actions/CommonActions";

const EmergencyOPDAdmission = () => {
    const { patientId } = useParams();
    const [patient, setPatient] = useState(null);

    const location = useLocation();
    const params = location.pathname.split("/").filter((item) => item !== "");
    const param1 = params.length > 0 ? params[0] : "";

    const fetchPatient = async () => {
        const response = await actions.customAction("/frontdesk/patient/" + patientId + "/", "", "Patient", "load", null);
        if (response) {
            setPatient({
                uhid: response.uhid,
                patient_name: response.first_name + " " + response.middle_name + " " + response.last_name,
                mobile: response.mobile,
                patient: response.id,
                gender: response.gender,
                age: response.age
            });
        }
    }

    useEffect(() => {
        if (patientId) {
            fetchPatient();
        }
    }, [patientId]);

    console.log(patient);

    return (
        <>
            {patient && <FullPageForm
                submission={patient}
                // form="https://nca.margytech.in/api/form/556"
                form="https://nca.margytech.in/api/form/579"
                url="/frontdesk/edadmission/"
                tableTitle="Emergency OPD Data Entry"
                navigateTo={`/${param1}/frontdesk/patient`}
                patch={false}
            />}
        </>
    )
}

export default EmergencyOPDAdmission;