import React from "react";
import { CustomPage } from "components";

const ReferredDoctorMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/382"
            searchForm="https://nca.margytech.in/api/form/553"
            url="/master/referreddoctor/"
            baseSearchParam=""
            tableName="referreddoctor"
            tableTitle="Referred Doctor"
            createFormButtonText="Add Referred Doctor"
            formTitle="Referred Doctor Master"
            modalFullscreen={false}
            addPermissions={['his:master:referreddoctor:add']}
        />
    )
}

export default ReferredDoctorMaster;