import React from "react";
import { CustomPage } from "components";

const PatientAppointment = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/689"
            searchForm="https://nca.margytech.in/api/form/581"
            url="/frontdesk/appointment/"
            baseSearchParam=""
            tableName="appointment"
            tableTitle="Patient Appointment"
            createFormButtonText="Add New Appointment"
            formTitle="Patient Appointment"
            modalFullscreen={true}
            addPermissions={['his:frontdesk:scheduler:add']}
        />
    )
}

export default PatientAppointment;