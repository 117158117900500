import { loadDashboard } from "actions/CommonActions";
import React, { useState, useEffect } from "react";
import { Card, Row, Col, Container, Form as BForm } from "react-bootstrap";
import ModuleCard from "../ModuleItem/ModuleCard";
import ModuleDatePicker from "../ModuleItem/ModuleDatePicker";
import * as actions from "actions/CommonActions";
import { MdArchive, MdAssessment, MdAssignment, MdAssignmentLate, MdAssignmentTurnedIn, MdBatteryCharging90, MdBed, MdBuild, MdBuildCircle, MdCheck, MdCrop75, MdGroup, MdLockClock, MdNotes, MdOutlineAccountBalanceWallet, MdOutlineArchive, MdOutlinePaid, MdOutlineReceipt, MdReceipt, MdSick, MdStopCircle, MdTextSnippet } from "react-icons/md";
import { FaArchive, FaBed, FaBox, FaBoxOpen, FaBriefcaseMedical, FaCheck, FaClipboard, FaClipboardCheck, FaClipboardList, FaClock, FaCreditCard, FaMoneyBillWave, FaPercent, FaPercentage, FaReceipt, FaTags, FaTimes, FaUserCheck, FaUserClock, FaUserInjured, FaUserTag, FaUserTimes } from "react-icons/fa";
import { RiBillFill, RiHeartPulseFill, RiPulseFill, RiRefund2Fill, RiRefund2Line, RiRefundFill, RiTestTubeFill, RiWalkFill } from "react-icons/ri";
import { GiBed, GiMedicalDrip, GiMedicalPack, GiPulse } from "react-icons/gi";
import { RiUserSettingsFill } from "react-icons/ri";
import moment from "moment";
import { IoPulse } from "react-icons/io5";
import { FiPercent } from "react-icons/fi";

const ModuleDashboard = ({ product, module }) => {
  const [data, setData] = useState(null);

  const today = new Date();
  const [startDateTime, setStartDateTime] = useState(
    moment(today.setHours(0, 0, 0, 0)).format("YYYY-MM-DDTHH:mm:ssZ")
  );
  const [endDateTime, setEndDateTime] = useState(
    moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
  );

  const fetchData = async () => {
    const response = await actions.loadDashboard(
      product,
      module,
      startDateTime,
      endDateTime
    );
    if (response) {
      setData(response);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  // const fetchData = (product, module) => {
  //     const response = await actions.loadDashboard(product, module);
  //     if(response){
  //         setData(response);
  //     }
  // }

  switch (product) {
    case "his":
      switch (module) {
        case "frontdesk":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total Patients"
                      type="2"
                      cardContentTitle1={"OPD"}
                      cardContent1={<b>{data?.total_patients?.opd}</b>}
                      cardContentTitle2={"IPD"}
                      cardContent2={<b>{data?.total_patients?.ipd}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="OPD"
                      type="2"
                      cardContentTitle1={"Open"}
                      cardContent1={<b>{data?.patient_from_opd?.open}</b>}
                      cardContentTitle2={"Completed"}
                      cardContent2={<b>{data?.patient_from_opd?.completed}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserInjured color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="IPD"
                      type="2"
                      cardContentTitle1={"On Bed"}
                      cardContent1={<b>{data?.patient_from_ipd?.on_bed}</b>}
                      cardContentTitle2={"Discharged"}
                      cardContent2={<b>{data?.patient_from_ipd?.discharged}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBed color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "outpatient":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total OPD Patients"
                      type="2"
                      cardContentTitle1={"Patients"}
                      cardContent1={<b>{data?.opd_data?.total_patients}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserInjured color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="OPD Open"
                      type="stack"
                      cardContentTitle1={"Open"}
                      cardContent1={<b>{data?.opd_data?.open}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserInjured color="#bsbsbs" size={30} />}
                      stackicon={<FaClock color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="OPD Completed"
                      type="stack"
                      cardContentTitle1={"Completed"}
                      cardContent1={<b>{data?.opd_data?.completed}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserInjured color="#bsbsbs" size={30} />}
                      stackicon={<FaCheck color="#bsbsbs" size={12} />}
                    />
                    {/* <ModuleCard cardTitle="OPD LEAVE" type="2" cardColor="card-3" cardContent={cardContent} cardContent2={cardContent2}/> */}
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "inpatient":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="In Bed"
                      type="2"
                      cardContentTitle1={"Patients"}
                      cardContent1={<b>{data?.ipd_data?.in_bed}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBed color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Discharged"
                      type="2"
                      cardContentTitle1={"Discharged"}
                      cardContent1={<b>{data?.ipd_data?.discharged}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiWalkFill color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Available Bed"
                      type="2"
                      cardContentTitle1={"Beds"}
                      cardContent1={<b>{data?.ipd_data?.available_bed}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdBed color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Total Patients"
                      type="2"
                      cardContentTitle1={"Patients"}
                      cardContent1={<b>{data?.ipd_data?.total_patients}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "edpatient":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="In Bed"
                      type="2"
                      cardContentTitle1={"Patients"}
                      cardContent1={<b>{data?.ed_data?.in_bed}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBed color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Discharged"
                      type="2"
                      cardContentTitle1={"Discharged"}
                      cardContent1={<b>{data?.ed_data?.discharged}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiWalkFill color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Available Bed"
                      type="2"
                      cardContentTitle1={"Beds"}
                      cardContent1={<b>{data?.ed_data?.available_bed}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdBed color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Total Patients"
                      type="2"
                      cardContentTitle1={"Patients"}
                      cardContent1={<b>{data?.ed_data?.total_patients}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "operationtheatre":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="OT SCHDEULED"
                      type="2"
                      cardContentTitle1={"Scheduled"}
                      cardContent1={<b>{data?.ot_data?.scheduled}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiHeartPulseFill color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="OT Approved"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={<b>{data?.ot_data?.approved}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiHeartPulseFill color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="OT Completed"
                      type="stack"
                      cardContentTitle1={"Completed"}
                      cardContent1={<b>{data?.ot_data?.completed}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiHeartPulseFill color="#bsbsbs" size={30} />}
                      stackicon={<FaCheck color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="OT Rejected"
                      type="stack"
                      cardContentTitle1={"Rejected"}
                      cardContent1={<b>{data?.ot_data?.rejected}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiHeartPulseFill color="#bsbsbs" size={30} />}
                      stackicon={<FaTimes color="#bsbsbs" size={12} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "billing":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="OPD Billing"
                      type="2"
                      cardContentTitle1={"Completed"}
                      cardContent1={<b>{data?.opd_billing_data?.completed}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdReceipt color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="OPD Paid"
                      type="stack"
                      cardContentTitle1={"Paid"}
                      cardContent1={<b>{data?.opd_billing_data?.paid}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdReceipt color="#bsbsbs" size={30} />}
                      stackicon={<FaCheck color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="OPD Discount"
                      type="stack"
                      cardContentTitle1={"discounts"}
                      cardContent1={<b>{data?.opd_billing_data?.discount}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdReceipt color="#bsbsbs" size={30} />}
                      stackicon={<FaPercentage color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="OPD Pending"
                      type="stack"
                      cardContentTitle1={"Pending"}
                      cardContent1={<b>{data?.opd_billing_data?.pending}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdReceipt color="#bsbsbs" size={30} />}
                      stackicon={<FaClock color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="IPD Billing"
                      type="2"
                      cardContentTitle1={"Completed"}
                      cardContent1={<b>{data?.ipd_billing_data?.completed}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdOutlineReceipt color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="IPD Advance"
                      type="stack"
                      cardContentTitle1={"Advance"}
                      cardContent1={<b>{data?.ipd_billing_data?.advance}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdOutlineReceipt color="#bsbsbs" size={30} />}
                      stackicon={<FaCheck color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="IPD Discount"
                      type="stack"
                      cardContentTitle1={"Discount"}
                      cardContent1={<b>{data?.ipd_billing_data?.discount}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdOutlineReceipt color="#bsbsbs" size={30} />}
                      stackicon={<FaPercentage color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="IPD Pending"
                      type="stack"
                      cardContentTitle1={"Pending"}
                      cardContent1={<b>{data?.ipd_billing_data?.pending}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdOutlineReceipt color="#bsbsbs" size={30} />}
                      stackicon={<FaClock color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="IPD Final"
                      type="2"
                      cardContentTitle1={"Final"}
                      cardContent1={<b>{data?.ipd_billing_data?.final}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaMoneyBillWave color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="IPD Refund"
                      type="2"
                      cardContentTitle1={"Refunded"}
                      cardContent1={<b>{data?.ipd_billing_data?.refund}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiRefund2Fill color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "metadata":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total Users"
                      type="2"
                      cardContentTitle1={"Users"}
                      cardContent1={<b>{data?.metadata?.users}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Total Roles"
                      type="2"
                      cardContentTitle1={"Roles"}
                      cardContent1={<b>{data?.metadata?.roles}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiUserSettingsFill color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "medicalstock":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={4}
                      cardTitle="Department Stock (Items)"
                      cardContentTitle1={"OPD"}
                      cardContent1={<b>{data?.stocks?.opd_department_stocks}</b>}
                      cardContentTitle2={"IPD"}
                      cardContent2={<b>{data?.stocks?.ipd_department_stocks}</b>}
                      cardContentTitle3={"ED"}
                      cardContent3={<b>{data?.stocks?.ed_department_stocks}</b>}
                      cardContentTitle4={"OT"}
                      cardContent4={<b>{data?.stocks?.ot_department_stocks}</b>}
                      type="4"
                      iconBoxColor="#b0d9fd"
                      icon={<FaBox color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={4}
                      cardTitle="Item Expiry (Expiring within 1 month)"
                      cardContentTitle1={"OPD"}
                      cardContent1={<b>{data?.stocks?.item_expiry}</b>}
                      cardContentTitle2={"IPD"}
                      cardContent2={<b>{data?.stocks?.item_expiry}</b>}
                      cardContentTitle3={"ED"}
                      cardContent3={<b>{data?.stocks?.item_expiry}</b>}
                      cardContentTitle4={"OT"}
                      cardContent4={<b>{data?.stocks?.item_expiry}</b>}
                      type="4"
                      iconBoxColor="#b0d9fd"
                      icon={<FaClock color="#bsbsbs" size={30} />}
                    />
                  </Row>
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Requested"}
                      cardContent1={<b>{data?.materialrequests?.requested}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={<b>{data?.materialrequests?.approved}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Dispatched"}
                      cardContent1={<b>{data?.materialrequests?.dispatched}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Delivered"}
                      cardContent1={<b>{data?.materialrequests?.delivered}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Rejected"}
                      cardContent1={<b>{data?.materialrequests?.rejected}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "nonmedicalstock":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Department Stock"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={<b>{data?.stocks?.department_stocks}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBox color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Item Expiry"
                      type="2"
                      cardContentTitle1={"Expiring within 2 weeks"}
                      cardContent1={<b>{data?.stocks?.item_expiry}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaClock color="#bsbsbs" size={30} />}
                    />
                  </Row>
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Requested"}
                      cardContent1={<b>{data?.materialrequests?.requested}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={<b>{data?.materialrequests?.approved}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Dispatched"}
                      cardContent1={<b>{data?.materialrequests?.dispatched}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Delivered"}
                      cardContent1={<b>{data?.materialrequests?.delivered}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Rejected"}
                      cardContent1={<b>{data?.materialrequests?.rejected}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        default:
          return <div>Dashboard</div>;
      }

    case "lis":
      switch (module) {
        case "frontdesk":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Patient from OPD"
                      type="2"
                      cardContentTitle1={"Patients"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<RiWalkFill color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Patient from IPD"
                      type="2"
                      cardContentTitle1={"Patients"}
                      cardContent1={
                        <b>
                          {data?.patient_from_ipd?.on_bed +
                            data?.patient_from_ipd?.discharged}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaBed color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Total Tests"
                      type="2"
                      cardContentTitle1={"Tests"}
                      cardContent1={
                        <b>
                          {data?.total_patients?.opd +
                            data?.total_patients?.ipd}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<RiTestTubeFill color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "sample":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total Samples"
                      type="stack"
                      cardContentTitle1={"Total"}
                      cardContent1={
                        <b>
                          {data?.samples?.total_samples}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<RiTestTubeFill color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Collection Pending"
                      type="stack"
                      cardContentTitle1={"Pending Tests"}
                      cardContent1={
                        <b>
                          {data?.collection?.pending}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<RiTestTubeFill color="#bsbsbs" size={30} />}
                      stackicon={<FaClock color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Collection Done"
                      type="stack"
                      cardContentTitle1={"Collected Tests"}
                      cardContent1={
                        <b>
                          {data?.collection?.done}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<RiTestTubeFill color="#bsbsbs" size={30} />}
                      stackicon={<FaCheck color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Accession Pending"
                      type="stack"
                      cardContentTitle1={"Pending Test Accession"}
                      cardContent1={
                        <b>
                          {data?.accession?.pending}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaBriefcaseMedical color="#bsbsbs" size={30} />}
                      stackicon={<FaClock color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Accession Done"
                      type="stack"
                      cardContentTitle1={"Completed Test Accession"}
                      cardContent1={
                        <b>
                          {data?.accession?.done}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaBriefcaseMedical color="#bsbsbs" size={30} />}
                      stackicon={<FaCheck color="#bsbsbs" size={12} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "result":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Pending For Entry"
                      type="stack"
                      cardContentTitle1={"Pending"}
                      cardContent1={
                        <b>
                          {data?.result?.pending_for_entry}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaClipboardList color="#bsbsbs" size={30} />}
                      stackicon={<FaClock color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Pending For Authorize"
                      type="stack"
                      cardContentTitle1={"Pending"}
                      cardContent1={
                        <b>
                          {data?.result?.pending_for_authorize}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaClipboard color="#bsbsbs" size={30} />}
                      stackicon={<FaClock color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Pending For Approve"
                      type="stack"
                      cardContentTitle1={"Pending"}
                      cardContent1={
                        <b>
                          {data?.result?.pending_for_approve}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaClipboard color="#bsbsbs" size={30} />}
                      stackicon={<FaClock color="#bsbsbs" size={12} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Reported"
                      type="2"
                      cardContentTitle1={"Reported"}
                      cardContent1={
                        <b>
                          {data?.result?.reported}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaClipboardCheck color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "account":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total Bill amount"
                      type="2"
                      cardContentTitle1={"Total"}
                      cardContent1={
                        <b>
                          {data?.result?.total_bill_amount}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaMoneyBillWave color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Discount"
                      type="2"
                      cardContentTitle1={"Discount"}
                      cardContent1={
                        <b>
                          {data?.result?.total_discount}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaPercentage color="#bsbsbs" size={30} />}
                    />
                    {/* <ModuleCard
                      size={3}
                      cardTitle="Refund"
                      type="2"
                      cardContentTitle1={"Refund"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<RiRefund2Line color="#bsbsbs" size={30} />}
                    /> */}
                    <ModuleCard
                      size={3}
                      cardTitle="Balance"
                      type="2"
                      cardContentTitle1={"Balance"}
                      cardContent1={
                        <b>
                          {data?.result?.total_balance}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdOutlineAccountBalanceWallet color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Paid"
                      type="2"
                      cardContentTitle1={"Paid"}
                      cardContent1={
                        <b>
                          {data?.result?.total_paid}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdOutlinePaid color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Credit"
                      type="2"
                      cardContentTitle1={"Credit"}
                      cardContent1={
                        <b>
                          {data?.result?.total_credit}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaCreditCard color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "metadata":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total Users"
                      type="2"
                      cardContentTitle1={"Users"}
                      cardContent1={<b>{data?.metadata?.users}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Total Roles"
                      type="2"
                      cardContentTitle1={"Roles"}
                      cardContent1={<b>{data?.metadata?.roles}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiUserSettingsFill color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "medicalstock":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Department Stock"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={<b>{data?.stocks?.department_stocks}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBox color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Item Expiry"
                      type="2"
                      cardContentTitle1={"Expiring within 2 weeks"}
                      cardContent1={<b>{data?.stocks?.item_expiry}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaClock color="#bsbsbs" size={30} />}
                    />
                  </Row>
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Requested"}
                      cardContent1={<b>{data?.materialrequests?.requested}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={<b>{data?.materialrequests?.approved}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Dispatched"}
                      cardContent1={<b>{data?.materialrequests?.dispatched}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Delivered"}
                      cardContent1={<b>{data?.materialrequests?.delivered}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Rejected"}
                      cardContent1={<b>{data?.materialrequests?.rejected}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "nonmedicalstock":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Department Stock"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={<b>{data?.stocks?.department_stocks}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBox color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Item Expiry"
                      type="2"
                      cardContentTitle1={"Expiring within 2 weeks"}
                      cardContent1={<b>{data?.stocks?.item_expiry}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaClock color="#bsbsbs" size={30} />}
                    />
                  </Row>
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Requested"}
                      cardContent1={<b>{data?.materialrequests?.requested}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={<b>{data?.materialrequests?.approved}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Dispatched"}
                      cardContent1={<b>{data?.materialrequests?.dispatched}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Delivered"}
                      cardContent1={<b>{data?.materialrequests?.delivered}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Rejected"}
                      cardContent1={<b>{data?.materialrequests?.rejected}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        default:
          return <div>Dashboard</div>;
      }

    case "ris":
      switch (module) {
        case "frontdesk":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard size={3}
                      cardTitle="Total Patients"
                      cardContentTitle1={"OPD"}
                      cardContent1={<b>{data?.patient_from_opd?.open + data?.patient_from_opd?.completed}</b>}
                      cardContentTitle2={"IPD"}
                      cardContent2={<b>{data?.patient_from_ipd?.on_bed + data?.patient_from_ipd?.discharged}</b>}
                      cardContentTitle3={"Total"}
                      cardContent3={<b>{data?.total_patients?.opd + data?.total_patients?.ipd}</b>}
                      type="3"
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard size={3}
                      cardTitle="Total Studies"
                      cardContentTitle1={"OPD"}
                      cardContent1={<b>{data?.study_from_opd?.open}</b>}
                      cardContentTitle2={"IPD"}
                      cardContent2={<b>{data?.study_from_ipd?.open}</b>}
                      cardContentTitle3={"Total"}
                      cardContent3={<b>{data?.study_from_opd?.open + data?.study_from_ipd?.open}</b>}
                      type="3"
                      iconBoxColor="#b0d9fd"
                      icon={<MdAssignment color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "result":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard size={3}
                      cardTitle="Pending Studies"
                      cardContentTitle1={"OPD"}
                      cardContent1={<b>{data?.study_from_opd?.pending}</b>}
                      cardContentTitle2={"IPD"}
                      cardContent2={<b>{data?.study_from_ipd?.pending}</b>}
                      cardContentTitle3={"Total"}
                      cardContent3={<b>{data?.study_from_opd?.pending + data?.study_from_ipd?.pending}</b>}
                      type="3"
                      iconBoxColor="#b0d9fd"
                      icon={<MdAssignmentLate color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard size={3}
                      cardTitle="Reported Studies"
                      cardContentTitle1={"OPD"}
                      cardContent1={<b>{data?.study_from_opd?.reported}</b>}
                      cardContentTitle2={"IPD"}
                      cardContent2={<b>{data?.study_from_ipd?.reported}</b>}
                      cardContentTitle3={"Total"}
                      cardContent3={<b>{data?.study_from_opd?.reported + data?.study_from_ipd?.reported}</b>}
                      type="3"
                      iconBoxColor="#b0d9fd"
                      icon={<MdAssignmentTurnedIn color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "metadata":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total Users"
                      type="2"
                      cardContentTitle1={"Users"}
                      cardContent1={<b>{data?.metadata?.users}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Total Roles"
                      type="2"
                      cardContentTitle1={"Roles"}
                      cardContent1={<b>{data?.metadata?.roles}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiUserSettingsFill color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "medicalstock":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Department Stock"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={<b>{data?.stocks?.department_stocks}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBox color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Item Expiry"
                      type="2"
                      cardContentTitle1={"Expiring within 2 weeks"}
                      cardContent1={<b>{data?.stocks?.item_expiry}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaClock color="#bsbsbs" size={30} />}
                    />
                  </Row>
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Requested"}
                      cardContent1={<b>{data?.materialrequests?.requested}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={<b>{data?.materialrequests?.approved}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Dispatched"}
                      cardContent1={<b>{data?.materialrequests?.dispatched}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Delivered"}
                      cardContent1={<b>{data?.materialrequests?.delivered}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Rejected"}
                      cardContent1={<b>{data?.materialrequests?.rejected}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "nonmedicalstock":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Department Stock"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={<b>{data?.stocks?.department_stocks}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBox color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Item Expiry"
                      type="2"
                      cardContentTitle1={"Expiring within 2 weeks"}
                      cardContent1={<b>{data?.stocks?.item_expiry}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaClock color="#bsbsbs" size={30} />}
                    />
                  </Row>
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Requested"}
                      cardContent1={<b>{data?.materialrequests?.requested}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={<b>{data?.materialrequests?.approved}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Dispatched"}
                      cardContent1={<b>{data?.materialrequests?.dispatched}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Delivered"}
                      cardContent1={<b>{data?.materialrequests?.delivered}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Rejected"}
                      cardContent1={<b>{data?.materialrequests?.rejected}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        default:
          return <div>Dashboard</div>;
      }

    case "pharmacy":
      switch (module) {
        case "billing":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Patient Billing"
                      type="2"
                      cardContentTitle1={"Patients"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaMoneyBillWave color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Department Billing"
                      type="2"
                      cardContentTitle1={"Dept"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaMoneyBillWave color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Item Consumptions"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaBoxOpen color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "order":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Purchase Orders"
                      type="2"
                      cardContent1={<b>{data?.purchaseorder?.total}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaTags color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Pending For Approval"
                      type="2"
                      cardContent1={<b>{data?.purchaseorder?.pending_for_approval}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserClock color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="HO Manager Approval"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.purchaseorder?.ho_manager_approval}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserCheck color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="HO Accounts Approval"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.purchaseorder?.ho_account_approval}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserCheck color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="PO Raised"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.purchaseorder?.wo_raised}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserTag color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Rejected"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.purchaseorder?.rejected}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserTimes color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );

        case "workorder":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Work Orders"
                      type="2"
                      cardContent1={<b>{data?.workorder?.total}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaTags color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Pending For Approval"
                      type="2"
                      cardContent1={<b>{data?.workorder?.pending_for_approval}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserClock color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="HO Manager Approval"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.workorder?.ho_manager_approval}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserCheck color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="HO Accounts Approval"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.workorder?.ho_account_approval}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserCheck color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="WO Raised"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.workorder?.wo_raised}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserTag color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Rejected"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.workorder?.rejected}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserTimes color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "inward":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total Inwards"
                      type="2"
                      cardContentTitle1={"Pending"}
                      cardContent1={
                        <b>
                          {data?.inward?.pending}
                        </b>
                      }
                      cardContentTitle2={"Fully Recieved"}
                      cardContent2={
                        <b>
                          {data?.inward?.received}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Inwards Without PO"
                      type="2"
                      cardContentTitle1={"New"}
                      cardContent1={
                        <b>
                          {data?.inward_without_po?.new}
                        </b>
                      }
                      cardContentTitle2={"Fully Recieved"}
                      cardContent2={
                        <b>
                          {data?.inward_without_po?.received}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdOutlineArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );

        case "stockstatus":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Indents"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      cardContentTitle2={"Dispatched"}
                      cardContent2={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Return To Vendor"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "stockdetails":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Stock"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Stock Consumptions"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Requests"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "metadata":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total Users"
                      type="2"
                      cardContentTitle1={"Users"}
                      cardContent1={<b>{data?.metadata?.users}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Total Roles"
                      type="2"
                      cardContentTitle1={"Roles"}
                      cardContent1={<b>{data?.metadata?.roles}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiUserSettingsFill color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "medicalstock":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Department Stock"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={<b>{data?.stocks?.department_stocks}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBox color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Item Expiry"
                      type="2"
                      cardContentTitle1={"Expiring within 2 weeks"}
                      cardContent1={<b>{data?.stocks?.item_expiry}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaClock color="#bsbsbs" size={30} />}
                    />
                  </Row>
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Requested"}
                      cardContent1={<b>{data?.materialrequests?.requested}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={<b>{data?.materialrequests?.approved}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Dispatched"}
                      cardContent1={<b>{data?.materialrequests?.dispatched}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Delivered"}
                      cardContent1={<b>{data?.materialrequests?.delivered}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Rejected"}
                      cardContent1={<b>{data?.materialrequests?.rejected}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        case "nonmedicalstock":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Department Stock"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={<b>{data?.stocks?.department_stocks}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBox color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Item Expiry"
                      type="2"
                      cardContentTitle1={"Expiring within 2 weeks"}
                      cardContent1={<b>{data?.stocks?.item_expiry}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaClock color="#bsbsbs" size={30} />}
                    />
                  </Row>
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Requested"}
                      cardContent1={<b>{data?.materialrequests?.requested}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={<b>{data?.materialrequests?.approved}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Dispatched"}
                      cardContent1={<b>{data?.materialrequests?.dispatched}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Delivered"}
                      cardContent1={<b>{data?.materialrequests?.delivered}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Rejected"}
                      cardContent1={<b>{data?.materialrequests?.rejected}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );

        default:
          return <div>insidedashboard</div>;
      }

    case "inventory":
      switch (module) {
        case "order":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Purchase Orders"
                      type="2"
                      cardContentTitle1={"Orders"}
                      cardContent1={
                        <b>
                          {data?.purchaseorder?.all}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaTags color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Pending For Approval"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.purchaseorder?.pending}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserClock color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="HO Manager Approval"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.purchaseorder?.managerapproved}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserCheck color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="HO Accounts Approval"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.purchaseorder?.accountsapproved}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserCheck color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="PO Raised"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.purchaseorder?.raised}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserTag color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Rejected"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.purchaseorder?.rejected}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserTimes color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );

        case "workorder":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Work Orders"
                      type="2"
                      cardContentTitle1={"Orders"}
                      cardContent1={
                        <b>
                          {data?.workorder?.all}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaTags color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Pending For Approval"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.workorder?.pending}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserClock color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="HO Manager Approval"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.workorder?.managerapproved}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserCheck color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="HO Accounts Approval"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.workorder?.accountsapproved}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserCheck color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="PO Raised"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.workorder?.raised}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserTag color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Rejected"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.workorder?.rejected}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<FaUserTimes color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );

        case "inward":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total Inwards"
                      type="2"
                      cardContentTitle1={"Pending"}
                      cardContent1={
                        <b>
                          {data?.inward?.pending}
                        </b>
                      }
                      cardContentTitle2={"Fully Recieved"}
                      cardContent2={
                        <b>
                          {data?.inward?.received}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Inwards Without PO"
                      type="2"
                      cardContentTitle1={"New"}
                      cardContent1={
                        <b>
                          {data?.inward_without_po?.new}
                        </b>
                      }
                      cardContentTitle2={"Fully Recieved"}
                      cardContent2={
                        <b>
                          {data?.inward_without_po?.received}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdOutlineArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );

        case "stockstatus":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Indents"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      cardContentTitle2={"Dispatched"}
                      cardContent2={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Return To Vendor"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );

        case "stockdetails":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Stock"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Stock Consumptions"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Requests"
                      type="2"
                      cardContent1={
                        <b>
                          {data?.patient_from_opd?.open +
                            data?.patient_from_opd?.completed}
                        </b>
                      }
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );

        case "stock":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Department Stock"
                      type="2"
                      cardContentTitle1={"Items"}
                      cardContent1={<b>{data?.stocks?.department_stocks}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaBox color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Item Expiry"
                      type="2"
                      cardContentTitle1={"Expiring within 2 weeks"}
                      cardContent1={<b>{data?.stocks?.item_expiry}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaClock color="#bsbsbs" size={30} />}
                    />
                  </Row>
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Requested"}
                      cardContent1={<b>{data?.materialrequests?.requested}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Approved"}
                      cardContent1={<b>{data?.materialrequests?.approved}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Dispatched"}
                      cardContent1={<b>{data?.materialrequests?.dispatched}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={2}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Delivered"}
                      cardContent1={<b>{data?.materialrequests?.delivered}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Material Request"
                      type="2"
                      cardContentTitle1={"Rejected"}
                      cardContent1={<b>{data?.materialrequests?.rejected}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<FaArchive color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );

        case "metadata":
          return (
            <Container fluid className="p-5">
              <Row>
                <Col sm={12}>
                  <ModuleDatePicker
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    fetchData={fetchData}
                  />
                  <Row>
                    <ModuleCard
                      size={3}
                      cardTitle="Total Users"
                      type="2"
                      cardContentTitle1={"Users"}
                      cardContent1={<b>{data?.metadata?.users}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<MdGroup color="#bsbsbs" size={30} />}
                    />
                    <ModuleCard
                      size={3}
                      cardTitle="Total Roles"
                      type="2"
                      cardContentTitle1={"Roles"}
                      cardContent1={<b>{data?.metadata?.roles}</b>}
                      iconBoxColor="#b0d9fd"
                      icon={<RiUserSettingsFill color="#bsbsbs" size={30} />}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          );
      }
    case "blood_bank":
      break;
    default:
      return <div>Dashboard</div>;
  }
  return <div>Dashboard</div>;
  // return <div>test</div>
};

export default ModuleDashboard;
