import React from "react";
import { FullPageTable } from "components";

const InventoryHoManagerApproval = (params) => {
  return (
    <FullPageTable
    form="https://nca.margytech.in/api/form/358"
      searchForm="https://nca.margytech.in/api/form/701"
      url="/workorder/workorder/"
      tableName="inventoryhomanagerapproval"
      tableTitle="homanagerapproval"
      formTitle="Approve Work Order"
      modalFullscreen={true}
      baseSearchParam="?status=APPROVED"
    />
  );
};

export default InventoryHoManagerApproval;
