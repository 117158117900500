import React from "react";
import { CustomPage } from "components";

const InsuranceMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/599"
            searchForm="https://nca.margytech.in/api/form/600"
            url="/master/insurance/"
            baseSearchParam=""
            tableName="insurance"
            tableTitle="Insurance"
            createFormButtonText="Add Insurance"
            formTitle="Insurance Master"
            modalFullscreen={false}
            addPermissions={['his:master:insurance:add']}
        />
    )
}

export default InsuranceMaster;