import React, { useRef, useState } from "react";
import { CustomPage } from "components";
import * as actions from "actions/CommonActions";
import CustomListDropdown from "./Utils/CustomListDropdown";
import { Button, Col, Container, Form, Row, Form as BootstrapForm } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const PharmacyDispatchConfirmation = ({ expandableRows, expandOnRowClicked, expandOnRowDoubleClicked, expandableRowsHideExpander }) => {
    const { requestId } = useParams();
    const [materialRequest, setMaterialRequest] = useState(null);
    const [materialItems, setMaterialItems] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const form = useRef(null);
    const navigate = useNavigate();

    const customStyles = {
        rows: {
            style: {
                minHeight: "72px",
            },
        },
        headCells: {
            style: {
                fontWeight: "bold",
                padding: "15px",
                border: "1px solid #eee",
                color: "#fff",
                borderBottom: "1px solid #999",
                backgroundColor: "#006699",
            },
        },
        cells: {
            style: {
                borderLeft: "1px solid #eee",
                borderRight: "1px solid #eee",
                minHeight: "50px",
            },
        },
    };

    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

    const columns = [
        {
            name: "Item Code",
            selector: row => row.item_data?.code
        },
        {
            name: "Item Name",
            selector: row => row.item_data?.name
        },
        {
            name: "Batch No.",
            selector: row => row.batch
        },
        {
            name: "Expiry",
            selector: row => row.expiry_date
        },
        {
            name: "Batch Quantity",
            selector: row => row.quantity
        },
        {
            name: "Requested Quantity",
            selector: row => row.required_quantity
        },
        {
            name: "Dispatch Quantity",
            selector: row => row.selected_quantity,
        },
        {
            name: "Action",
            selector: null,
            cell: (row, index) => <Button onClick={() => {
                const newData = materialItems.filter((item, indexElem) => index != indexElem)
                setMaterialItems(newData)
            }}>Delete</Button>,
            button: true,
        },
    ]

    const fetchMaterialRequest = async () => {
        const response = await actions.customAction("/purchaseorder/materialrequest/", requestId, "Material Requests", "loadMany", null)
        if (response) {
            setMaterialRequest(response);
        }
    }

    useEffect(() => {
        if (requestId) {
            fetchMaterialRequest()
        }

    }, [requestId])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const response = await actions.customAction("/purchaseorder/materialrequest/" + requestId + "/", "", "Material Dispatch", "update", {materialItems})
        if (response) {
            navigate(`/pharmacy/medicalstock/indentsummary/`);
        }
    }

    return (
        <>
            <Form ref={form} onSubmit={(e) => handleSubmit(e)}>
                {/* <Form ref={form}> */}
                <Container className="card-form">
                    <h1>Material Transfer to {materialRequest?.request_from_dept_data.name}</h1>
                    <Row className="mb-3 mt-4">
                        <Col sm="4">
                            <Form.Group>
                                <Form.Label>Select Requested Item</Form.Label>
                                <CustomListDropdown setMaterialItems={setMaterialItems} materialRequest={materialRequest} isOpen={isOpen} setIsOpen={setIsOpen} />
                            </Form.Group>
                        </Col>

                        <Col sm="4">
                            <Form.Group>
                                <Form.Label>Order ID</Form.Label>
                                <Form.Control
                                    value={materialRequest?.order_id}
                                    name="order_id"
                                    type="text"
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                            <Form.Group>
                                <Form.Label>Ordered By</Form.Label>
                                <Form.Control name="request_from_dept" type="text" value={materialRequest?.request_from_dept_data.name} disabled />
                            </Form.Group>
                        </Col>
                        <Col sm="4">
                            <Form.Group>
                                <Form.Label>Ordered On</Form.Label>
                                <Form.Control name="created_on" type="datetime" value={materialRequest?.created_on} disabled />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
                <Container className="mt-5">
                    <DataTable
                        data={materialItems}
                        columns={columns}
                        customStyles={customStyles}
                        expandableRows={expandableRows}
                        expandableRowsComponent={ExpandedComponent}
                        expandOnRowClicked={expandOnRowClicked}
                        expandOnRowDoubleClicked={expandOnRowDoubleClicked}
                        expandableRowsHideExpander={expandableRowsHideExpander}
                    />
                </Container>
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <Button type="submit">Dispatch</Button>
                </div>
            </Form>
        </>
    );
};

const Template = args => <PharmacyDispatchConfirmation {...args} />;

export const Basic = Template.bind({});

Basic.args = {
    expandableRows: true,
    expandOnRowClicked: false,
    expandOnRowDoubleClicked: false,
    expandableRowsHideExpander: false,
};

// export default {
//     title: 'Expandable/Basic',
//     component: Basic,
//     parameters: {
//         docs: {
//             page: doc,
//         },
//     },
// };

export default PharmacyDispatchConfirmation;
