import React, { useEffect, useState } from "react";
import { CustomPage, CustomPageNew } from "components";
import { useSelector } from "react-redux";

const TestMaster = () => {
    const currentlocation = useSelector((state) => state.adminReducer.currentLocation)
    const user = useSelector((state) => state.authReducer.user)
    const setting = useSelector((state) => state.adminReducer.setting);
    const multiLocation = setting.location_enabled


    return (
        <>
            {!currentlocation && user.is_admin ? <CustomPage
                // formSet={formSet}
                form="https://nca.margytech.in/api/form/453"
                searchForm="https://nca.margytech.in/api/form/550"
                url="/master/test/"
                baseSearchParam=""
                tableName="test"
                tableTitle="Test"
                createFormButtonText="Add Test"
                formTitle="Test Master"
                modalFullscreen={true}
                addPermissions={['lis:master:test:add']}
                
            /> : <CustomPage
            // formSet={formSet}
                    form="https://nca.margytech.in/api/form/721"
                    searchForm="https://nca.margytech.in/api/form/550"
                    url="/master/testlocationmapping/"
                    baseSearchParam={`?location__id=${currentlocation}`}
                    tableName="testlocationmapping"
                    tableTitle="Test Location mapping"
                    createFormButtonText="Map Test Location"
                    formTitle="Test Location Mapping"
                    modalFullscreen={true}
                    addPermissions={['lis:master:test:add']}
                    
            />}
        </>
    )
}

export default TestMaster;