import React from "react";
import { CustomPage } from "components";

const CabinMaster = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/123"
            searchForm="https://nca.margytech.in/api/form/566"
            url="/master/cabin/"
            baseSearchParam=""
            tableName="cabin"
            tableTitle="Cabin"
            createFormButtonText="Add Cabin"
            formTitle="Cabin Master"
            modalFullscreen={false}
            addPermissions={['his:master:cabin:add']}
        />
    )
}

export default CabinMaster;