import React from "react";
import { CustomPage } from "components";

const WorkOrderGeneration = () => {
  return (
    <CustomPage
      form="https://nca.margytech.in/api/form/446"
      searchForm="https://nca.margytech.in/api/form/701"
      url="/workorder/workorder/"
      baseSearchParam=""
      tableName="wogeneration"
      tableTitle="wo generation"
      createFormButtonText="Generate WO"
      formTitle="Generate Work Order"
      modalFullscreen={true}
    />
  );
};

export default WorkOrderGeneration;
