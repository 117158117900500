import React from "react";
import { FullPageTable } from "components";

const InventoryItemExpiry = (params) => {

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/700"
            url="/purchaseorder/stock/"
            tableName="pharmacyitemexpiry"
            tableTitle="item expiry"
            baseSearchParam="?departments=Inventory&item__item_type__type_description=NON-MEDICAL"
        />
    )
}

export default InventoryItemExpiry;