import React from "react";
import { CustomPage } from "components";

const LeaveSchedule = () => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/72"
            searchForm="https://nca.margytech.in/api/form/585"
            url="/frontdesk/leaveschedule/"
            baseSearchParam=""
            tableName="leaveschedule"
            tableTitle="Leave"
            createFormButtonText="Add New Leave"
            formTitle="Leave Schedule"
            modalFullscreen={false}
            addPermissions={['his:frontdesk:leaveschedule:add']}
        />
    )
}

export default LeaveSchedule;