import React, { useRef, useState } from "react";
import * as actions from "actions/CommonActions";
import CustomListDropDown from "./Utils/CustomListDropdown";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FullPageTable } from "components";
import { Link } from "react-router-dom";

const InventoryPOGenerationMain = () => {
    return (
        <>
            <div className="d-flex justify-content-end align-items-end mx-4 py-2">
                <Button as={Link} to={`/inventory/order/pogeneration/`}>Generate Purchase Order</Button>
            </div>
            <FullPageTable
                // form="https://nca.margytech.in/api/form/436"
                searchForm="https://nca.margytech.in/api/form/736"
                url="/purchaseorder/purchaseorder/"
                tableName="inventorypogeneration"
                tableTitle="Purchase Order"
                formTitle="Generate Purchase Order"
                modalFullscreen={true}
                baseSearchParam="?department__department_code=INVENTORY"
            />
        </>
    );
};

export default InventoryPOGenerationMain;
