import React, { useState, useRef, useEffect } from "react";
import { Container, Button, Form, Spinner, Accordion } from "react-bootstrap";
import { useParams, Outlet, Link, useNavigate } from 'react-router-dom';
import * as actions from "actions/TenantAuthActions";
import * as adminActions from "actions/AdminActions";

import { useDispatch, useSelector } from "react-redux";

const Template = () => {
    const dispatch = useDispatch();
    const [templates, setTemplates] = useState(null);
    const [isTemplateUnavailable, setIsTemplateUnavailable] = useState(false);

    const currentAdmissionCardTemplate = useSelector((state) => state.authReducer.currentAdmissionCardTemplate);
    const currnetInvoiceTemplate = useSelector((state) => state.authReducer.currnetInvoiceTemplate);
    const currnetReportTemplate = useSelector((state) => state.authReducer.currnetReportTemplate);

    const template_types = [
        { key: "INVOICE", value: "INVOICE", alias: "Invoice", selected: currnetInvoiceTemplate },
        { key: "REPORT", value: "REPORT", alias: "Report", selected: currnetReportTemplate },
        { key: "ADMISSION_CARD", value: "ADMISSION CARD", alias: "Admission Card", selected: currentAdmissionCardTemplate },
    ];

    const fetchTemplates = async () => {
        const response = await adminActions.loadTemplates();
        if (response) {
            setTemplates(response);
        }
    }

    useEffect(() => {
        fetchTemplates(templates);
    }, [])
    

    return (
        <Accordion className="m-4 mt-5" defaultActiveKey="0">
            {template_types.map((template_type, index) => {
                return (
                    <Accordion.Item eventKey={index.toString()}>
                        <Accordion.Header>{template_type.value}</Accordion.Header>
                        <Accordion.Body>
                            {templates?.filter(item => item.template_type === template_type?.key).map((template, index) => {
                                return (
                                    <Form.Group className="mb-3">
                                        <Form.Check
                                            checked={template_type.selected?.id === template.id ? true : false}
                                            name={template_type.key}
                                            type="radio"
                                            label={template.name}
                                            onClick={() => dispatch(actions.currentTemplate(template_type.key, template))}
                                        />
                                    </Form.Group>
                                )
                            })}
                            <div className={`${setIsTemplateUnavailable ? "d-none" : "d-block"}`}>
                                No Templates Available!
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                )
            })}
        </Accordion>
    );
}

export default Template;