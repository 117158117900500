import React from "react";
import { CustomPage } from "components";

const HisStockConsumption = (params) => {

    return (
        <CustomPage
            form="https://nca.margytech.in/api/form/275"
            searchForm="https://nca.margytech.in/api/form/699"
            url="/purchaseorder/stockconsumption/"
            tableName="pharmacystockconsumption"
            tableTitle="Stock consumption"
            baseSearchParam={"?departments=OPD,IPD,OT&item__item_type__type_description=NON-MEDICAL"}
            createFormButtonText="Stock Consumption"
            formTitle="Stock Consumption"
            modalFullscreen={true}
            
        />
    )
}

export default HisStockConsumption;