import React from "react";
import { FullPageTable } from "components";

const PharmacyReturnFromDept = () => {

    return (
        <FullPageTable
            form="https://nca.margytech.in/api/form/310"
            searchForm="https://nca.margytech.in/api/form/698"
            url="/purchaseorder/stocktransfer/"
            baseSearchParam="?transfer_to_depts=Pharmacy"
            tableName="stocktransferinward"
            tableTitle="Stock Transfer"
            formTitle="Return From Department"
            modalFullscreen={true}
        />
    );
};

export default PharmacyReturnFromDept;