import React from "react";
import { FullPageTable } from "components";

const PharmacySummary = (params) => {

    return (
        <FullPageTable
            searchForm="https://nca.margytech.in/api/form/690"
            url="/pharmacy/pharmacybilling/"
            tableName="pharmacybilling"
            tableTitle="Bill"
            baseSearchParam=""
        />
    )
}

export default PharmacySummary;